import { BuildTableListArgs, BuildTableListReturn } from './model';

export const buildTableList = ({
  model,
  preconfiguredModels,
}: BuildTableListArgs): BuildTableListReturn => {
  const preconfiguredModel = preconfiguredModels.find(
    (localModel) => localModel.name === model.name
  );

  if (!preconfiguredModel) {
    return [];
  }

  const modelTables = preconfiguredModel.revisions.reduce(
    (finalTables: string[], tables) => {
      return [...finalTables, ...tables.tables];
    },
    []
  );

  return [...new Set(modelTables)];
};
