import React from 'react';
import MainComponent from './AddConnector.main';
import SkeletonComponent from './AddConnector.skeleton';

const AddConnector: React.FC = () => {
  return (
    <React.Suspense fallback={<SkeletonComponent />}>
      <MainComponent />
    </React.Suspense>
  );
};

export default AddConnector;
