import { StandardizedConnector } from 'src/products/connector/types/connectorTypes';

export const applyTableSearch = (
  data: StandardizedConnector[],
  search: string
) => {
  if (!search) {
    return data;
  }

  const lowercaseSearch = search.toLowerCase();
  return data.filter((connector) => {
    const displayNameMatch = connector.displayName
      .toLowerCase()
      .includes(lowercaseSearch);
    const statusMatch = connector.status
      .toString()
      .toLowerCase()
      .includes(lowercaseSearch);

    const destinationsMatch = connector.models.some((model) =>
      model.destinations.some((destination) =>
        destination.name.toLowerCase().includes(lowercaseSearch)
      )
    );

    const sourcesMatch = connector.sources.some((source) =>
      source.name.toLowerCase().includes(lowercaseSearch)
    );

    return displayNameMatch || statusMatch || destinationsMatch || sourcesMatch;
  });
};
