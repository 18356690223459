import React from 'react';
import {
  Box,
  Divider,
  Grid,
  styled,
  Typography,
  useTheme,
} from '@cherre-frontend/ui';
import StatusBox from './StatusBox';
import TableStatusChip from 'src/products/connector/components/ConnectorStatusChip';
import ConnectorBreadcrumbs from 'src/products/connector/components/ConnectorBreadcrumbs';
import { useGetOverview } from './Overview/hooks/useGetOverview';
import RunningComponentIndicator from 'src/products/connector/components/RunningComponentIndicator';

const HeaderContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  border-bottom: ${({ theme }) => `1px ${theme.palette.grey[400]} solid`};
  width: 100%;
`;
const BreadcrumbsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  width: 100%;
`;
const ConnectorDetailsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 24px;
  margin-right: 8px;
  padding-bottom: 8px;
`;
const Details = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 60%;
`;
const DetailContainer = styled(Box)`
  display: grid;
  gap: 8px;
`;
const ConnectorProgressContainer = styled(Box)`
  display: flex;
  flex-direction: row;
`;
const ProgressStep = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 16px;
`;
const ProgressHeaderText = styled(Typography)`
  font-weight: 700;
`;

const Step = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 8px;
`;

const DetailsContainer = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;

const DetailsText = styled(Typography)`
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ConnectorDetailsHeader: React.FC = () => {
  const theme = useTheme();

  const { overviewData } = useGetOverview();

  return (
    <HeaderContainer id='connector-details-header'>
      <BreadcrumbsContainer>
        <ConnectorBreadcrumbs
          connectorName={overviewData?.data.displayName || ''}
        />
      </BreadcrumbsContainer>
      <ConnectorDetailsContainer>
        <img src='/assets/connector-details-artwork.svg' />
        <Details>
          <Grid container direction='column' gap='10px'>
            <Grid item width='100%' direction='row'>
              <DetailsContainer>
                <DetailContainer>
                  <Typography>Connection Status</Typography>
                  {overviewData ? (
                    <TableStatusChip
                      status={overviewData.data.status}
                      statusMessage={overviewData.data.statusMessage}
                    />
                  ) : (
                    <Typography>Status not found</Typography>
                  )}
                </DetailContainer>
                <DetailContainer>
                  <Typography>Created On</Typography>
                  <DetailsText title={overviewData?.data.created}>
                    {overviewData?.data.created || 'Created date not found'}
                  </DetailsText>
                </DetailContainer>
                <DetailContainer>
                  <Typography>Last Run</Typography>
                  <DetailsText title={overviewData?.data.lastRun || undefined}>
                    {overviewData?.data.lastRun || 'N/A'}
                  </DetailsText>
                </DetailContainer>
                <DetailContainer>
                  <Typography>Update Schedule</Typography>
                  <DetailsText title={overviewData?.data.updateSchedule}>
                    {overviewData?.data.updateSchedule ||
                      'Update schedule not found'}
                  </DetailsText>
                </DetailContainer>
              </DetailsContainer>
            </Grid>
            {overviewData?.data.connectorRunningObject.isRunning && (
              <Grid item display='flex' justifyContent='flex-start'>
                <RunningComponentIndicator
                  message={
                    overviewData?.data.connectorRunningObject.runningMessage
                  }
                />
              </Grid>
            )}
          </Grid>
        </Details>
        <ConnectorProgressContainer>
          <Divider
            style={{
              borderRightWidth: '1px',
              borderColor: theme.palette.grey[400],
            }}
          />
          <ProgressStep>
            <ProgressHeaderText>Connection Progress</ProgressHeaderText>
            <Step>
              <StatusBox
                label={'Source'}
                status={overviewData?.data.connectorStatus.sources}
              />
              <StatusBox
                label={'Model'}
                status={overviewData?.data.connectorStatus.models}
              />
              <StatusBox
                label={'Destination'}
                status={overviewData?.data.connectorStatus.destinations}
              />
            </Step>
          </ProgressStep>
        </ConnectorProgressContainer>
      </ConnectorDetailsContainer>
    </HeaderContainer>
  );
};

export default ConnectorDetailsHeader;
