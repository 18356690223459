import React from 'react';
import { useDataValidationFormContext } from './dataValidationFormEngine';
import { DynamicDataValidationField } from './DataValidationField';
import { Box, Typography } from '@cherre-frontend/ui';
import { DataValidationCustomRuleType } from './DataValidationCustomRuleType';

const gridAreaNameMap = {
  ruleType: 'ruleType',
  tableRef: 'tableRef',
  fieldsArray: 'fieldsArray',
  field: 'fieldsArray',
  filter: 'filter',
};

interface DynamicDataValidationFieldListProps {
  isCreateMode: boolean;
}

export const DynamicDataValidationFieldList = ({
  isCreateMode,
}: DynamicDataValidationFieldListProps) => {
  const { watch, setValue } = useDataValidationFormContext();

  const generalProperties = watch('generalProperties');
  const specificProperties = watch('specificProperties');

  const hasRules = generalProperties.length + specificProperties.length > 0;

  return (
    <Box
      sx={{
        marginTop: '20px',
        paddingTop: '26px',
        borderTop: '1px solid #E0E0E0',
      }}
    >
      <Box
        flexDirection='column'
        sx={{
          display: 'grid',
          gap: '20px',
          gridTemplateColumns: '1fr 1fr',
          gridTemplateRows: 'auto',
          gridAutoFlow: 'row',
          gridAutoRows: 'auto',
          marginBottom: '20px',
        }}
        style={{
          gridTemplateAreas: hasRules
            ? `"ruleType ."
              "tableRef fieldsArray"
              "filter filter"
              "fields fields"
            `
            : `"ruleType ."`,
        }}
      >
        {isCreateMode && (
          <Box
            gridArea='ruleType'
            sx={{
              gridColumn: '1 / -1',
            }}
          >
            <DataValidationCustomRuleType />
          </Box>
        )}

        {generalProperties.map((field, index) => {
          const gridArea = gridAreaNameMap[field.parameterName] || 'fields';
          const isFullRow = ['fields', 'filter'].includes(gridArea);

          if (isCreateMode && field.parameterName === 'ruleType') {
            return null;
          }

          let helperText: React.ReactNode;
          // TODO: This is hardcoded for the Demo, should be a backend settings in the future
          if (field.parameterName === 'coverage' && !isCreateMode) {
            if (field.value === '1') {
              setValue(`generalProperties.${index}.value`, `100%`);
            }

            helperText = (
              <Typography mt={1}>
                Coverage is the percentage of records that must comply with this
                check
              </Typography>
            );
          }

          return (
            <Box
              key={index}
              data-name={field.parameterName}
              sx={{
                gridArea,
                gridColumn: isFullRow ? '1 / -1' : 'auto',
              }}
            >
              <DynamicDataValidationField
                {...field}
                propertiesType='generalProperties'
                index={index}
                isCreateMode={isCreateMode}
              />
              {helperText}
            </Box>
          );
        })}
      </Box>

      <Box
        flexDirection='column'
        sx={{
          display: 'grid',
          gap: '20px',
        }}
      >
        {specificProperties.map((field, index) => {
          if (isCreateMode && field.parameterName === 'ruleType') {
            return null;
          }

          return (
            <Box key={index} data-name={field.parameterName}>
              <DynamicDataValidationField
                {...field}
                propertiesType='specificProperties'
                index={index}
                isCreateMode={isCreateMode}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
