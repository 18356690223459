import React, { useMemo } from 'react';
import { Grid, Typography } from '@cherre-frontend/ui';
import LinkWithArrow from './LinkWithArrow';
import ActivityOverviewCard from './ActivityOverviewCard';
import { useHistory } from 'react-router';
import { useCherreSetState } from '@cherre-frontend/data-fetching';
import { connectorDetailsTabState } from 'src/products/connector/recoil';
import { ConnectorDetailsTabValues } from '../../connector-details';
import { Activity } from 'src/services/ConnectorDemo/data/ConnectorLanding';
import { sortActivities } from './utils';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';
import ActivityCreateConnectorSection from './ActivityCreateConnectorSection';

type ActivityOverviewSectionTypes = {
  activities: Activity[];
};
const ActivityOverviewSection: React.FC<ActivityOverviewSectionTypes> = ({
  activities,
}) => {
  const history = useHistory();
  const setTab = useCherreSetState(connectorDetailsTabState);
  const canAddConnector = useFeatureFlag('ConnectorsCreateConnector');
  const _activities = useMemo(
    () => [...activities].sort(sortActivities).slice(0, 5),
    [activities]
  );

  return (
    <Grid container direction='column'>
      <Grid item>
        <Grid container direction='row' justifyContent='space-between'>
          <Grid item>
            <Typography fontSize='20px' fontWeight='bold'>
              Activity Overview
            </Typography>
          </Grid>
          {activities.length > 0 && (
            <Grid item marginRight='9px'>
              <LinkWithArrow
                linkText='View All Connectors'
                onClick={() => history.push('/connectors/inventory')}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item marginTop='20px'>
        {activities.length > 0 ? (
          <Grid container direction='column' gap={2}>
            {_activities.map((activity, index) => (
              <Grid item key={index} xs={12}>
                <ActivityOverviewCard
                  activity={activity}
                  onClick={() => {
                    setTab(ConnectorDetailsTabValues.OVERVIEW);
                    history.push(`/connectors/${activity.connectorId}`);
                  }}
                  handleValidationRuleLink={(event) => {
                    event.stopPropagation();
                    setTab(ConnectorDetailsTabValues.VALIDATION);
                    history.push(`/connectors/${activity.connectorId}`);
                  }}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <ActivityCreateConnectorSection canAddConnector={canAddConnector} />
        )}
      </Grid>
    </Grid>
  );
};

export default ActivityOverviewSection;
