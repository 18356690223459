import { graphql } from 'relay-runtime';

export const ActivateRuleMutation = graphql`
  mutation activateRuleMutation(
    $ruleGeneralProperties: [RulePropertyInput!]!
    $ruleSpecificProperties: [RulePropertyInput]
    $ruleLibraryItemRef: RuleLibraryItemRefInput!
    $connectorComponentRef: ConnectorComponentRefInput
    $notificationProperties: [RulePropertyInput]
  ) {
    activateRule(
      ruleGeneralProperties: $ruleGeneralProperties
      ruleSpecificProperties: $ruleSpecificProperties
      ruleLibraryItemRef: $ruleLibraryItemRef
      connectorComponentRef: $connectorComponentRef
      notificationProperties: $notificationProperties
    ) {
      name
      connectorComponentRef {
        kind
        name
        version
      }
      ruleGeneralProperties {
        propertyName
        propertyValue
      }
      ruleSpecificProperties {
        propertyName
        propertyValue
      }
      checkStatus
      checkStatusMessage
    }
  }
`.default;
