import React, { useEffect } from 'react';
import {
  Box,
  CheckIcon,
  DownloadFileIcon,
  Grid,
  Typography,
  UpdateIcon,
  useTheme,
} from '@cherre-frontend/ui';
import { useCherreSetState } from '@cherre-frontend/data-fetching';
import axios from 'axios';
import { useSnackbarCherre } from 'src/products/core-prospect/hooks';
import { getTableAndColumnName } from './utils';
import RuleStatusChip, {
  RuleStatusChipType,
} from './components/RuleStatusChip';
import ObservabilityCard from './components/ObservabilityCard';
import { RuleTypes } from './types';
import { dataValidationRuleForm } from '../../../../../../recoil';
import { connectorDetailsSelectedTable } from '../../../../../../../../recoil';
import DataValidationTestResultsLoader from './components/DataValidationTestResultsLoader';
import RuleResultsChart from './components/RuleResultsChart';
import DataValidationTestDetailsTable from './components/DataValidationRuleDetailsTable';
import { RulesQueryDataRuleLibraryItem } from '../../../../queries/types';
import { useGetDataValidationResults } from './useGetDataValidationResults';

export enum ValidationDetailsTabValues {
  VALIDATION_DETAILS = 'Validation Rule Details',
  VALIDATION_RESULTS = 'Validation Rule Results',
}

export interface DataValidationResultsProps {
  rule: RulesQueryDataRuleLibraryItem;
}

export const DataValidationResults = ({ rule }: DataValidationResultsProps) => {
  const ruleDetails = useGetDataValidationResults(rule);

  const setSelectedTable = useCherreSetState(connectorDetailsSelectedTable);

  const setForm = useCherreSetState(dataValidationRuleForm);

  const theme = useTheme();

  const { enqueueSnackbar } = useSnackbarCherre();

  useEffect(() => {
    if (ruleDetails) {
      // setDataValidationState(
      //   ruleDetails?.entityLink.includes('::columns::')
      //     ? DataValidationState.COLUMN
      //     : DataValidationState.TABLE
      // );
      const { column, table } = getTableAndColumnName(ruleDetails?.entityLink);
      if (table) {
        setSelectedTable(table);
      }
      let rule_type =
        ruleDetails?.testDefinition.name
          .split(/(?=[A-Z])/)
          .map((string) => string.toLowerCase())
          .join('_') ?? '';
      if (rule_type === 'table_custom_s_q_l_query') {
        rule_type = RuleTypes.TABLE_CUSTOM_SQL_QUERY;
      }
      if (
        rule_type &&
        rule_type !== RuleTypes.COLUMN_VALUES_TO_BE_NOT_NULL &&
        rule_type !== RuleTypes.COLUMN_VALUES_TO_BE_UNIQUE
      ) {
        const mappedParameters = ruleDetails?.parameterValues.map(
          (parameter) => {
            const values = Object.values(parameter) as any[];
            return { [values[0]]: values[1] };
          }
        );
        if (mappedParameters) {
          const formValues = Object.assign({}, ...mappedParameters);
          setForm((prevState) => ({
            ...prevState,
            name: ruleDetails.ruleName ?? '',
            column,
            rule_type: rule_type,
            description: ruleDetails.testDefinition.description ?? '',
            [rule_type]: formValues,
          }));
        }
      } else if (rule_type) {
        setForm((prevState) => ({
          ...prevState,
          column,
          name: ruleDetails?.ruleName ?? '',
          rule_type: rule_type,
          description: ruleDetails?.testDefinition.description ?? '',
        }));
      }
    }
  }, [ruleDetails]);

  const handleDownloadCSV = async () => {
    try {
      const response = await axios.get(
        `/api/v1/qualytics/downloadSourceRecordCSV/${ruleDetails?.anomalyID}`
      );
      const blob = new Blob([response.data], {
        type: response.headers['content-type'],
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', 'check_failures.csv');
      link.click();
    } catch (error) {
      console.error(error);
      enqueueSnackbar('An error occurred while downloading the CSV file.', {
        variant: 'error',
      });
    }
  };

  if (ruleDetails === null) {
    return (
      <>
        <DataValidationTestResultsLoader />
      </>
    );
  }
  // if (ruleDetails=== 'hasError') {
  //   return (
  //     <>
  //       <h1>Error</h1>
  //     </>
  //   );
  // }

  const hasStatusFailed = ruleDetails?.status === 'Failed';
  const hasStatusSuccess = ruleDetails?.status === 'Success';
  const haveFailedRowHistory = ruleDetails?.failedRowCountHistory.length > 0;
  const haveToRenderDetailsTable =
    hasStatusFailed &&
    haveFailedRowHistory &&
    ruleDetails?.failedSourceRecords.records;
  const haveToRenderTableEmptyState = hasStatusSuccess;

  return (
    <Grid container direction='column'>
      <Grid container direction='row'>
        <Grid item xs={6}>
          <Grid container direction='column'>
            <Grid container direction='row' gap={2}>
              <Grid item>
                <Typography fontSize='14px' fontWeight='bold'>
                  Status:
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {ruleDetails.status ? (
                    <RuleStatusChip
                      status={ruleDetails?.status as RuleStatusChipType}
                    />
                  ) : (
                    'N/A'
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction='row' gap={1} marginTop='20px'>
              <Grid item xs={4}>
                <ObservabilityCard
                  cardHeader='Last Run'
                  cardContent={ruleDetails ? ruleDetails.lastRun : ''}
                />
              </Grid>
              <Grid item xs={4}>
                {ruleDetails.tableRecordCount === null ? (
                  <ObservabilityCard
                    cardHeader='Table Has Not Been Profiled'
                    cardContent='Please contact an admin'
                  />
                ) : (
                  <ObservabilityCard
                    cardHeader='Total # of Check Failures'
                    cardContent={`${ruleDetails.failedRecordCount} Rows / ${ruleDetails.tableRecordCount} Rows`}
                  />
                )}
              </Grid>
            </Grid>
            <Grid
              container
              direction='row'
              marginTop='28px'
              marginLeft='12px'
              alignItems='center'
              position='relative'
            >
              <Grid item>
                <DownloadFileIcon
                  color='primary'
                  sx={{ width: '14px', height: '16px' }}
                />
              </Grid>
              <Grid item marginLeft='8px'>
                <Typography
                  color='primary'
                  sx={{
                    '&:hover': {
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    },
                  }}
                  fontWeight='semi-bold'
                  fontSize='14px'
                  onClick={handleDownloadCSV}
                >
                  Download Check Failures to CSV
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} justifyContent='flex-end'>
          {ruleDetails && ruleDetails.failedRowCountHistory.length > 0 ? (
            <RuleResultsChart data={ruleDetails.failedRowCountHistory} />
          ) : (
            <Box
              width='100%'
              height='200px'
              display='flex'
              justifyContent='center'
              alignItems='center'
              sx={{
                borderRadius: '10px',
                backgroundColor: theme.palette.grey[100],
              }}
            >
              <Typography fontSize='14px' fontWeight='bold'>
                No history of failed row counts.
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
      <Grid container marginTop='20px'>
        {ruleDetails?.failedSourceRecords && (
          <Grid item xs={12}>
            {haveToRenderDetailsTable ? (
              <DataValidationTestDetailsTable
                data={ruleDetails.failedSourceRecords}
                showUpgradeOverlay={false}
              />
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: theme.palette.grey[100],
                  width: '100%',
                  padding: '20px',
                  borderRadius: '10px',
                }}
              >
                {haveToRenderTableEmptyState && (
                  <>
                    <CheckIcon
                      sx={{
                        color: theme.palette.success.dark,
                        width: '24px',
                        height: '24px',
                      }}
                    />
                    <Typography
                      fontSize='14px'
                      marginLeft='4px'
                      fontWeight='bold'
                    >
                      All current checks have passed! No current failures to
                      display.
                    </Typography>
                  </>
                )}
                {ruleDetails.status === 'Scheduled' && (
                  <>
                    <UpdateIcon
                      sx={{
                        color: theme.palette.info.main,
                        width: '24px',
                        height: '24px',
                      }}
                    />
                    <Typography
                      fontSize='14px'
                      marginLeft='4px'
                      fontWeight='bold'
                    >
                      Check passes or failures will be displayed after the next
                      run.
                    </Typography>
                  </>
                )}
              </Box>
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
