import React from 'react';
import {
  Box,
  Grid,
  useTheme,
  Typography,
  Button,
  AddCircleOutlineIcon,
} from '@cherre-frontend/ui';
import { CherreConnectorSource } from 'src/services/ConnectorDemo/data/ConnectorLanding';

type SourceLibraryCardTypes = {
  source: CherreConnectorSource;
};

const SourceLibraryCard: React.FC<SourceLibraryCardTypes> = ({ source }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.grey[50],
        borderRadius: '10px',
        width: '100%',
        padding: '10px',
      }}
    >
      <Grid container direction='row' alignItems='center'>
        <Grid item container alignItems='center' xs>
          <Box>
            <img
              style={{ height: '40px' }}
              src={source.logoPath}
              alt='connector-icon'
            />
          </Box>
          <Box marginLeft='10px'>
            <Grid container direction='column'>
              <Grid item>
                <Typography fontSize='14px' fontWeight='bold'>
                  {source.sourceName}
                </Typography>
              </Grid>
              <Grid item>
                <Grid container direction='row' alignItems='center'>
                  <Typography
                    fontSize='12px'
                    fontWeight='medium'
                    sx={{ marginRight: 0.5, color: theme.palette.grey[500] }}
                  >
                    {source.category}
                  </Typography>
                  <Typography
                    sx={{ marginRight: 0.5, color: theme.palette.grey[500] }}
                  >
                    &middot;
                  </Typography>
                  <Typography
                    fontSize='12px'
                    fontWeight='medium'
                    sx={{ color: theme.palette.grey[500] }}
                  >
                    {source.numActiveConnectors} Active connectors
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item>
          <Button
            variant='text'
            sx={{ width: '130px', height: '40px' }}
            endIcon={
              <AddCircleOutlineIcon
                sx={{ color: theme.palette.primary.main }}
              />
            }
          >
            Create New
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

SourceLibraryCard.displayName = 'SourceLibaryCard';
export default SourceLibraryCard;
