import { graphql } from 'relay-runtime';

export const DataValidationCustomRulesQuery = graphql`
  query dataValidationCustomRulesQuery($owner: String) {
    customRules(owner: $owner) {
      name
      version
      provider
      displayName
      description
      tableRef
      categories
      active
      status
      rliGeneralProperties {
        parameterName
        defaultValue
        defaultValues
        externalObjectType
        order
        uiReadOnly
        visibility
      }
      rliSpecificProperties {
        parameterName
        defaultValue
        defaultValues
        externalObjectType
        order
        uiReadOnly
        visibility
      }
      rule {
        name
        connectorComponentRef {
          kind
          name
          version
        }
        ruleGeneralProperties {
          propertyName
          propertyValue
          propertyValues
        }
        ruleSpecificProperties {
          propertyName
          propertyValue
          propertyValues
        }
        notificationProperties {
          propertyName
          propertyValue
          propertyValues
        }
        checkStatus
        checkStatusMessage
        checkId
        datastoreId
        containerId
      }
    }
  }
`.default;
