import React, { FC, ReactElement, useMemo } from 'react';
import ModelCard from './ModelCard';
import ModelCardEmptyState from './ModelCardEmptyState';
import { filterModels, FilterModelsReturn } from './utils/filterModels';
import { preconfiguredModelsQuery } from 'src/services/DOM/preconfiguredModels/__generated__/preconfiguredModelsQuery.graphql';
import { PreConfiguredModelsQuery } from 'src/services/DOM/preconfiguredModels';
import { useLazyLoadQuery } from 'react-relay';
import { Button, styled } from '@cherre-frontend/ui';
import { PartialAddConnectorType } from '../../dataState';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  padding-top: 20px;
  gap: 20px;
`;

const ModelCardContainer = styled('div')`
  display: flex;
  gap: 20px;
`;

const ButtonContainer = styled('div')`
  display: flex;
  justify-content: end;
  gap: 12px;
  width: 100%;
`;

type ModelCarrouselProps = {
  chosenModel: string;
  onChooseModel: (model: FilterModelsReturn) => void;
  selectedModels?: PartialAddConnectorType['models'];
  selectedSources?: PartialAddConnectorType['sources'];
  onCancel: () => void;
  onConfirm: () => void;
};

/**
 * Component representing the model cards list.
 *
 * @component
 * @param {ModelCarrouselProps} props - The properties for the ModelCarrousel component.
 * @param {string} props.chosenModel - The current chosen model name.
 * @param {function} [props.onChooseModel] - Emits the chosen model object.
 * @param {function} [props.onCancel] - The callback when the button Cancel is clicked.
 * @param {function} [props.onConfirm] - The callback when the button Next is clicked.
 * @param {Array} [props.selectedModels] - The list of chosen models to Connector creation.
 * @param {Array} [props.selectedSources] - The list of chosen sources to Connector creation.
 *
 *
 *
 * @returns {JSX.Element} The rendered ModelCarrousel component.
 */

const ModelCarrousel: FC<ModelCarrouselProps> = ({
  chosenModel,
  onChooseModel,
  selectedModels,
  selectedSources,
  onCancel,
  onConfirm,
}): ReactElement => {
  const { preconfiguredModels } = useLazyLoadQuery<preconfiguredModelsQuery>(
    PreConfiguredModelsQuery,
    {}
  );

  const models = useMemo(
    () =>
      filterModels({ selectedModels, selectedSources, preconfiguredModels }),
    [selectedModels, preconfiguredModels]
  );

  return (
    <Container>
      <ModelCardContainer>
        {models.map((model) => (
          <ModelCard
            key={model.name}
            title={model.displayName}
            image={model.image}
            selected={model.name === chosenModel}
            onClick={() => onChooseModel(model)}
          />
        ))}
        <ModelCardEmptyState />
      </ModelCardContainer>

      <ButtonContainer>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          disabled={!models.length || !chosenModel}
          onClick={onConfirm}
          variant='contained'
        >
          Next
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default ModelCarrousel;
