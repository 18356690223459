import React, { useState } from 'react';
import {
  AddIcon,
  Box,
  CloseIcon,
  IconButton,
  RemoveIcon,
  Typography,
  useTheme,
  styled,
} from '@cherre-frontend/ui';
import {
  TransformWrapper,
  TransformComponent,
  useControls,
} from 'react-zoom-pan-pinch';
import ConnectorButton from 'src/products/connector/pages/connector-details/components/Overview/ConnectorButton';
import ComingSoon from 'src/products/connector/components/ComingSoon';
import { useGetOverview } from '../Overview/hooks/useGetOverview';

const ControlButton = styled(IconButton)`
  padding: 10px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.palette.grey[100]};
`;

const Controls = () => {
  const { zoomIn, zoomOut, resetTransform } = useControls();
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: 'absolute',
        zIndex: 1,
        bottom: 0,
        left: '50%',
        transform: 'translate(-50%, -16px)',
        backgroundColor: 'white',
        padding: '10px 20px',
        borderRadius: '5px',
        border: `1px ${theme.palette.grey[500]} solid`,
        width: 'fit-content',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '16px',
      }}
    >
      <Typography style={{ fontSize: '12px', fontWeight: '700' }}>
        Map Controls
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
        <ControlButton onClick={() => zoomOut()}>
          <RemoveIcon style={{ color: theme.palette.grey[900] }} />
        </ControlButton>
        <ControlButton onClick={() => zoomIn()}>
          <AddIcon style={{ color: theme.palette.grey[900] }} />
        </ControlButton>
        <ControlButton onClick={() => resetTransform()}>
          <CloseIcon style={{ color: theme.palette.grey[900] }} />
        </ControlButton>
      </Box>
    </Box>
  );
};

const DataModelContent = () => {
  const [error, setError] = useState(false);

  const { overviewData: { data: connector } = {} } = useGetOverview();

  const model = connector?.models[0];

  if (!model || error) {
    return (
      <Box width='100%' padding={2}>
        <ComingSoon pageName='Data Model' />
      </Box>
    );
  }

  const fileType = model.preconfiguredModelRef ? 'preconfigured' : 'custom';

  const _name = model.preconfiguredModelRef || model.name;

  const fileName = `${_name}-${model.revision}.png`;

  const initial_scale = 1;
  const initial_x = 800;
  const initial_y = 0;

  return (
    <TransformWrapper
      initialScale={initial_scale}
      minScale={0.2}
      maxScale={12}
      initialPositionX={initial_x}
      initialPositionY={initial_y}
      limitToBounds={false}
    >
      <>
        <a
          href={`/api/v1/erds/${fileType}/${fileName}`}
          download={fileName}
          target='_blank'
          style={{
            position: 'absolute',
            right: 0,
            transform: 'translate(-32px, 16px)',
            zIndex: 1,
          }}
        >
          <ConnectorButton variant='contained'>Download</ConnectorButton>
        </a>
        <Controls />
        <TransformComponent
          wrapperStyle={{
            width: '100%',
            height: '100%',
            backgroundColor: '#f5f6f6',
          }}
        >
          <img
            src={`/api/v1/erds/${fileType}/${fileName}`}
            alt={fileName}
            onError={() => setError(true)}
            style={{ height: '100%', width: '100%' }}
          />
        </TransformComponent>
      </>
    </TransformWrapper>
  );
};

DataModelContent.displayName = 'DataModelContent';

export default DataModelContent;
