import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@cherre-frontend/ui';
import { DialogContentText } from '@material-ui/core';
import {
  DeactivateRule,
  RulesQueryDataRuleLibraryItem,
} from '../../../../queries/types';
import { useMutation } from 'react-relay';
import { useCurrentConnector } from '../../../../hooks/useCurrentConnector';
import { DeactivateRuleMutation } from '../../../../queries/deactivateRuleMutation';
import {
  DataValidationAlert,
  DataValidationAlertProps,
  DataValidationAlertType,
} from '../DataValidationAlert';

interface DataValidationDeactivateDialogProps {
  rule: Exclude<RulesQueryDataRuleLibraryItem['rule'], null>;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onAlert(alert: DataValidationAlertProps | null): void;
}

export const DataValidationDeactivateDialog = ({
  rule,
  open,
  onOpenChange,
  onAlert,
}: DataValidationDeactivateDialogProps) => {
  const [error, setError] = React.useState<string | null>(null);

  const handleOpenChange = (open: boolean) => {
    setError(null);
    onOpenChange(open);
  };

  const { refetchRuleLibraryItems } = useCurrentConnector();

  const [commit, isLoading] = useMutation<DeactivateRule>(
    DeactivateRuleMutation
  );

  const handleDeactivate = () => {
    setError(null);
    commit({
      variables: { ruleName: rule.name },
      onError: (error) => setError(error.message),
      onCompleted: (response, errors) => {
        if (errors) {
          setError(errors[0].message);
          return;
        }
        refetchRuleLibraryItems();
        onAlert({
          message:
            'You deactivated a rule. To reactivate the rule, click on the ‘Activate’ button.',
          type: DataValidationAlertType.SUCCESS,
        });
        handleOpenChange(false);
      },
    });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => handleOpenChange(false)}
        aria-labelledby='active-rule-dialog-title'
        aria-describedby='active-rule-dialog-description'
        sx={{
          '& .MuiPaper-root': { maxWidth: '640px', padding: '20px' },
          '& .MuiDialogContent-root': { paddingBottom: 0 },
          '& .MuiDialogContentText-root': {
            fontSize: '14px',
            color: 'black',
            marginBottom: 0,
          },
        }}
      >
        <DialogTitle
          id='active-rule-dialog-title'
          sx={{
            fontSize: '20px',
            fontWeight: '700',
            padding: 0,
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          Are you sure you want to deactivate this rule?
        </DialogTitle>

        <DialogContent sx={{ padding: 0 }}>
          <DialogContentText id='active-rule-dialog-description'>
            All instances of this rule will be deactivated. You can re-activate
            the rule at any time from the table of data validation rules.
          </DialogContentText>

          {error && (
            <DataValidationAlert
              message='Something went wrong!'
              type={DataValidationAlertType.ERROR}
            />
          )}
        </DialogContent>
        <DialogActions sx={{ padding: 0 }}>
          <Button
            onClick={() => handleOpenChange(false)}
            sx={{ minWidth: '80px' }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeactivate}
            variant='contained'
            sx={{ minWidth: '80px' }}
          >
            {isLoading ? (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CircularProgress sx={{ color: 'white' }} size={20} />
              </Box>
            ) : (
              'Deactivate'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
