import React from 'react';
import { Grid, Skeleton } from '@cherre-frontend/ui';

const TableProfileContentLoader = () => {
  return (
    <Grid container direction='column' sx={{ width: '100%' }} padding={2}>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        sx={{ width: '100%' }}
      >
        <Grid item xs={6}>
          <Skeleton variant='text' width={210} height={30} animation='wave' />
        </Grid>

        <Grid item xs={6}>
          <Grid
            container
            justifyContent='flex-end'
            direction='row'
            alignItems='center'
          >
            <Grid item width='480px'>
              <Skeleton
                variant='rounded'
                width={480}
                height={40}
                animation='wave'
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction='row' paddingTop='60px' columnSpacing='30px'>
        {Array.from(new Array(5)).map((_, index) => (
          <Grid item sx={{ height: '70px' }} xs={12 / 5} key={index}>
            <Skeleton variant='rounded' animation='wave' height={70} />
          </Grid>
        ))}
      </Grid>
      <Grid
        container
        direction='row'
        paddingTop='20px'
        justifyContent='space-between'
      >
        <Grid item>
          <Skeleton variant='text' animation='wave' width={100} height={20} />
        </Grid>
        <Grid item>
          <Skeleton
            variant='rounded'
            animation='wave'
            width='100px'
            height={30}
          />
        </Grid>
      </Grid>
      <Grid container paddingTop='20px'>
        <Grid item width='100%'>
          <Skeleton
            variant='rounded'
            animation='wave'
            width='100%'
            height={200}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

TableProfileContentLoader.displayName = 'TableProfileContentLoader';
export default TableProfileContentLoader;
