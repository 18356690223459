import React, { createContext } from 'react';
import {
  CustomRulesQuery,
  RulesQuery,
  ValidationContainerQueryDataResponse,
} from '../queries/types';
import { useCurrentConnector } from '../hooks/useCurrentConnector';

interface DataValidationV2ProviderProps {
  children: React.ReactNode;
}

interface DataValidationV2ContextProps {
  ruleLibraryItems: RulesQuery['response']['ruleLibraryItems'];
  customRules: CustomRulesQuery['response']['customRules'];
  owner: string;
  connectorComponentName: string;
  connectorComponentModelName: string;
  validationContainer: ValidationContainerQueryDataResponse;
}

const DataValidationV2Context = createContext<DataValidationV2ContextProps>({
  ruleLibraryItems: [],
  customRules: [],
  owner: '',
  connectorComponentModelName: '',
  connectorComponentName: '',
  validationContainer: [],
});

export const DataValidationV2Provider = ({
  children,
}: DataValidationV2ProviderProps) => {
  const {
    ruleLibraryItems,
    customRules,
    owner,
    connectorComponentModelName,
    connectorComponentName,
    validationContainer,
  } = useCurrentConnector();

  return (
    <DataValidationV2Context.Provider
      value={{
        owner,
        ruleLibraryItems,
        customRules,
        connectorComponentModelName,
        connectorComponentName,
        validationContainer,
      }}
    >
      {children}
    </DataValidationV2Context.Provider>
  );
};

export const useDataValidationV2 = () => {
  const context = React.useContext(DataValidationV2Context);

  if (context === undefined) {
    throw new Error(
      'useDataValidationV2 must be used within a DataValidationV2Provider'
    );
  }

  return context;
};
