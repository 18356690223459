import React from 'react';
import { Box, Grid, Typography } from '@cherre-frontend/ui';
import { RulesQueryDataRuleLibraryItem } from '../../../../queries/types';

export const DataValidationOverviewItem = ({
  title,
  data,
}: {
  title: string;
  data: string | number;
}) => {
  return (
    <Grid item xs={3}>
      <Box padding='20px' bgcolor='#EEEEEE' borderRadius='10px'>
        <Typography fontWeight='bold'>{title}</Typography>
        <Typography>{data}</Typography>
      </Box>
    </Grid>
  );
};

interface DataValidationOverviewProps {
  ruleLibraryItems: RulesQueryDataRuleLibraryItem[];
}

export const DataValidationOverview = ({
  ruleLibraryItems,
}: DataValidationOverviewProps) => {
  const dataValidationByStatus = ruleLibraryItems.reduce(
    (acc, curr) => {
      if (curr.status === 'aborted') {
        acc.aborted += 1;
      } else if (curr.status === 'failed') {
        acc.failed += 1;
      } else {
        acc.success += 1;
      }

      return acc;
    },
    {
      aborted: 0,
      failed: 0,
      success: 0,
    } as Record<Exclude<RulesQueryDataRuleLibraryItem['status'], null>, number>
  );

  const totalActiveValidations = ruleLibraryItems.filter(
    (validation) => validation.active
  );

  return (
    <Box>
      <Grid container spacing='30px'>
        <DataValidationOverviewItem
          title='Total Checks'
          data={totalActiveValidations.length}
        />
        <DataValidationOverviewItem
          title='Success'
          data={dataValidationByStatus.success}
        />
        <DataValidationOverviewItem
          title='Aborted'
          data={dataValidationByStatus.aborted}
        />
        <DataValidationOverviewItem
          title='Failed'
          data={dataValidationByStatus.failed}
        />
      </Grid>
    </Box>
  );
};
