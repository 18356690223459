import React from 'react';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import {
  useConnectorUIState,
  useSourceUIState,
} from '../../../uiState/contexts';
import { SFTPSourceProvider, useAddConnector } from '../../../dataState';

const STEPS = [FirstStep, SecondStep];

const SFTPSource: React.FC = () => {
  const [{ index, repeatedSource }] = useConnectorUIState();
  const [data] = useAddConnector();
  const [{ sourceName, step, provider }] = useSourceUIState();
  const Component = STEPS[step];
  const initialData = data.sources?.[index] || {};

  if (!sourceName || (repeatedSource && !data.sources)) {
    return null;
  }
  return (
    <SFTPSourceProvider
      initialState={{
        preconfiguredSourceName: sourceName,
        provider,
        sftpType: 'custom',
        ...initialData,
      }}
    >
      <Component />
    </SFTPSourceProvider>
  );
};

export default SFTPSource;
