import React from 'react';
import { useTheme } from '@material-ui/core';
import { Box, Tooltip } from '@cherre-frontend/ui';
import { RuleItem } from './useDataValidationTableColumns';
import { ErrorOutline } from '@material-ui/icons';
import {
  getRuleItemStatus,
  RuleStatus,
} from '../../../../helpers/getRuleItemStatus';

interface DataValidationRuleStatusBadgeProps {
  ruleItem: RuleItem;
}

const labelMap: Record<RuleStatus, string> = {
  INACTIVE: 'Inactive', // Rule was not activated
  INACTIVE_ERROR: 'Inactive', // Rule was not activated due to an error
  SUCCESS: 'Success', // Rule was activated and passed
  FAILED: 'Failed', // Rule was activated and has failed validation
  CREATING: 'Creating', // Rule is being created
  RUNNING: 'Running', // Rule is running
  ACTIVE: 'Active', // Rule is active
} as const;

export const DataValidationRuleStatusBadge = ({
  ruleItem,
}: DataValidationRuleStatusBadgeProps) => {
  const theme = useTheme();

  const bgcolorMap: Record<RuleStatus, string> = {
    INACTIVE: theme.palette.grey[500],
    INACTIVE_ERROR: theme.palette.grey[500],
    SUCCESS: theme.palette.success.dark,
    FAILED: theme.palette.error.main,
    CREATING: theme.palette.info.main,
    RUNNING: theme.palette.info.main,
    ACTIVE: theme.palette.success.dark,
  };

  const _status = getRuleItemStatus(ruleItem);

  if (_status === RuleStatus.ACTIVE || _status === RuleStatus.CREATING) {
    return null;
  }

  const label = labelMap[_status];
  const bgcolor = bgcolorMap[_status];

  const hasError = _status === RuleStatus.INACTIVE_ERROR;

  const labelComponent = (
    <Box
      data-status={_status}
      sx={{
        bgcolor,
        color: 'white',
        borderRadius: '20px',
        fontSize: '12px',
        padding: '3px 7px',
        display: 'flex',
        gap: '4px',
        alignItems: 'center',
      }}
    >
      {hasError && <ErrorOutline style={{ fontSize: '16px' }} />}
      {label}
    </Box>
  );

  if (hasError) {
    return (
      <Tooltip
        title={ruleItem.rule?.checkStatusMessage || 'Internal error'}
        aria-label='rule-error'
        componentsProps={{
          tooltip: {
            sx: {
              borderRadius: '4px !important',
              boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important',
            },
          },
        }}
      >
        <Box>{labelComponent}</Box>
      </Tooltip>
    );
  }

  return labelComponent;
};
