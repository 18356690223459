import React, { ReactElement, Suspense, useCallback } from 'react';
import { styled, Typography } from '@cherre-frontend/ui';
import { useConnectorUIState, useModelUIState } from '../../uiState/contexts';
import { useAddConnector } from '../../dataState';
import ModelCarrousel from './ModelCarrousel';
import ModelCarrouselSkeleton from './ModelCarrouselSkeleton';

const Container = styled('div')`
  border-radius: 5px;
  background: #eee;
  padding: 30px;
  grid-template-columns: 1fr;
  display: grid;
`;

const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.primary};
  font-feature-settings: 'liga' off;

  /* Headings/H6 */
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

/**
 * ModelSelector component allows users to choose a model from a list of available models.
 *
 * This component uses the `useModelUIState`, `useAddConnector` and `useConnectorUIState` hook
 * to get the currently selected model. It then renders a list of `ModelCard` components inside a `ModelCarroussel`.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 */

const ModelSelector: React.FC = (): ReactElement => {
  const [selectedModel, setSelectedModel] = useModelUIState();
  const [connectorData, setConnectorValue] = useAddConnector();
  const [, setUIState] = useConnectorUIState();

  const handleCancelAction = useCallback((): void => {
    setUIState({ step: 'default', index: 0 });
  }, []);

  const handleChoseModel = useCallback((model): void => {
    const { displayName, name, provider, stableVersion } = model;
    setSelectedModel({ displayName, name, provider, stableVersion });
  }, []);

  const handleSaveModel = useCallback((): void => {
    setConnectorValue((prev) => {
      const prevModels = prev.models || [];
      const models = [...prevModels, selectedModel];
      const data = { ...prev, models };
      return data;
    });
    handleCancelAction();
  }, [setConnectorValue, handleCancelAction, selectedModel]);

  return (
    <Container>
      <Title>Choose a model:</Title>

      <Suspense fallback={<ModelCarrouselSkeleton />}>
        <ModelCarrousel
          chosenModel={selectedModel.name}
          selectedModels={connectorData.models}
          selectedSources={connectorData.sources}
          onCancel={handleCancelAction}
          onChooseModel={handleChoseModel}
          onConfirm={handleSaveModel}
        />
      </Suspense>
    </Container>
  );
};

export default ModelSelector;
