import { useCherreValueLoadable } from '@cherre-frontend/data-fetching';
import {
  ConnectorEnvironments,
  useConnectorEnvironment,
} from 'src/hooks/useConnectorEnvironment';
import {
  connectorLandingActivityOverviewDemo,
  connectorLandingScheduleDemo,
  connectorLandingSourcesDemo,
  connectorLandingStatusesDemo,
} from 'src/services/ConnectorDemo/recoil';
import {
  connectorLandingActivityOverview,
  connectorLandingSchedule,
  connectorLandingSources,
  connectorLandingStatuses,
} from 'src/services/DOM/connectorLanding/recoil';

export const useConnectorLanding = () => {
  const environment = useConnectorEnvironment();

  if (environment === ConnectorEnvironments.MVP) {
    const connectorStatuses = useCherreValueLoadable(connectorLandingStatuses);
    const connectorActivityOverview = useCherreValueLoadable(
      connectorLandingActivityOverview
    );
    const connectorSources = useCherreValueLoadable(connectorLandingSources);
    const connectorSchedule = useCherreValueLoadable(connectorLandingSchedule);
    return {
      connectorStatuses,
      connectorActivityOverview,
      connectorSources,
      connectorSchedule,
    };
  }

  const connectorStatuses = useCherreValueLoadable(
    connectorLandingStatusesDemo
  );
  const connectorActivityOverview = useCherreValueLoadable(
    connectorLandingActivityOverviewDemo
  );
  const connectorSources = useCherreValueLoadable(connectorLandingSourcesDemo);
  const connectorSchedule = useCherreValueLoadable(
    connectorLandingScheduleDemo
  );
  return {
    connectorStatuses,
    connectorActivityOverview,
    connectorSources,
    connectorSchedule,
  };
};
