import React, { useMemo } from 'react';
import {
  Box,
  SearchInput,
  Grid,
  Typography,
  AddCircleIcon,
  styled,
} from '@cherre-frontend/ui';
import {
  useCherreStateDebounced,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import Filters from '../../components/Filters';
import {
  connectorInventoryTableFiltersState,
  connectorTableSearch,
} from '../../recoil';
import ConnectorInventoryTable from './components/ConnectorInventoryTable';
import ConnectorButton from '../connector-details/components/Overview/ConnectorButton';
import { useConnectorInventory } from './hooks/useConnectorInventory';
import ConnectorInventorySkeletonLoader from './components/ConnectorInventorySkeletonLoader';
import { ConnectorsPageContainer } from '../../components/ConnectorsPageContainer';
import { useHistory } from 'react-router';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { applyTableFilters } from './utils/applyTableFilters';
import { applyTableSearch } from './utils/applyTableSearch';
import ConnectorBreadcrumbs from '../../components/ConnectorBreadcrumbs';

const ContentWrapper = styled(Grid)`
  height: 100%;
  width: 100%;
  padding: 10px 32px;
  background-color: ${({ theme }) => theme.palette.background.default};
  overflow-y: scroll;
`;

const HeaderContainer = styled(Grid)`
  margin-bottom: 16px;
  width: 100%;
`;

const ImageContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32px;
`;

const ActionContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

const SearchInputStyled = styled(SearchInput)`
  & .MuiInputBase-root {
    width: 325px;
    background-color: white;
  }
`;

const ButtonContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  color: white;
`;

const InventoryContainer = styled(Grid)`
  width: 100%;
`;

const ConnectorInventoryPage: React.FC = () => {
  const { push } = useHistory();

  const canAddConnector = useFeatureFlag('ConnectorsCreateConnector');

  const [search, , setSearch] = useCherreStateDebounced(connectorTableSearch, {
    ms: 100,
  });
  const filters = useCherreValue(connectorInventoryTableFiltersState);

  const {
    data: connectorList,
    loading,
    error: connectorListError,
  } = useConnectorInventory();

  const tableData = useMemo(() => {
    if (filters && filters.length > 0) {
      return applyTableFilters(connectorList, filters);
    }

    if (search) {
      return applyTableSearch(connectorList, search);
    }
    return connectorList;
  }, [filters, search, connectorList]);

  if (loading) {
    return <ConnectorInventorySkeletonLoader />;
  }

  if (connectorListError) {
    return <h1>Error...</h1>;
  }

  return (
    <ConnectorsPageContainer>
      <ContentWrapper>
        <Grid container direction='column'>
          <Grid item xs={12}>
            <ConnectorBreadcrumbs />
            <HeaderContainer
              container
              direction='row'
              alignItems='center'
              justifyContent='space-between'
            >
              <ImageContainer>
                <img
                  src='/assets/connector-details-artwork.svg'
                  alt='Connector Artwork'
                />
                <Typography variant='h3'>Connectors</Typography>
              </ImageContainer>
              <ActionContainer>
                <SearchInputStyled
                  value={search ?? ''}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <Filters />
                {canAddConnector && (
                  <ConnectorButton
                    variant='contained'
                    onClick={() => {
                      push('/connectors/add');
                    }}
                  >
                    <ButtonContainer>
                      <Typography>Add a Connector</Typography>
                      <AddCircleIcon style={{ color: 'white' }} />
                    </ButtonContainer>
                  </ConnectorButton>
                )}
              </ActionContainer>
            </HeaderContainer>
            <InventoryContainer item xs={12}>
              <ConnectorInventoryTable data={tableData} />
            </InventoryContainer>
          </Grid>
        </Grid>
      </ContentWrapper>
    </ConnectorsPageContainer>
  );
};

export default ConnectorInventoryPage;
