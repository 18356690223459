import { useSelector } from 'react-redux';
import { useLazyLoadQuery, useQueryLoader } from 'react-relay';
import { useParams } from 'react-router';
import {
  ConnectorsQuery,
  CustomRulesQuery,
  RulesQuery,
} from '../queries/types';
import { GetConnectorsByOwnerQuery } from '../queries/getConnectorsByOwnerQuery';
import { DataValidationRulesQuery } from '../queries/dataValidationRulesQuery';
import { useEffect } from 'react';
import { DATA_VALIDATION_V2_POOLING_INTERVAL } from '../constants';
import { DataValidationCustomRulesQuery } from '../queries/dataValidationCustomRulesQuery';
import { validationContainerQuery } from '../queries/__generated__/validationContainerQuery.graphql';
import { ValidationContainerQuery } from '../queries/validationContainerQuery';

export const useCurrentConnector = () => {
  const params = useParams<{ connector_name: string }>();

  const connectorName = params.connector_name;

  const { domOwner: owner, organizationId } = useSelector(
    (state) => state.user.profile.value
  );

  if (!owner) {
    throw new Error('Owner not found');
  }

  if (!organizationId) {
    throw new Error('Organization ID not found');
  }

  const { connectors } = useLazyLoadQuery<ConnectorsQuery>(
    GetConnectorsByOwnerQuery,
    {}
  );

  const currentConnector = connectors.find(
    (connector) => connector.name === connectorName
  );

  if (!currentConnector) {
    throw new Error('Connector not found');
  }

  const connectorComponentModel = currentConnector.models[0];

  const connectorComponentModelName = connectorComponentModel.name;

  const connectorComponentName = connectorComponentModel.preconfiguredModelRef;

  if (!connectorComponentName) {
    throw new Error('Connector component name not found');
  }

  const _connectorComponentVersion =
    connectorComponentModel.publishedRevisions[0].revisionNumber;

  if (!_connectorComponentVersion) {
    throw new Error('Connector component version not found');
  }

  // Use useQueryLoader to support refetching of ruleLibraryItems
  const [, loadRulesQuery] = useQueryLoader<RulesQuery>(
    DataValidationRulesQuery
  );

  const connectorComponentVersion = String(_connectorComponentVersion);

  // Initial ruleLibraryItems fetch
  const { ruleLibraryItems } = useLazyLoadQuery<RulesQuery>(
    DataValidationRulesQuery,
    {
      owner,
      connectorComponentKind: 'model',
      connectorComponentName: connectorComponentModelName,
      connectorComponentVersion,
    },
    { fetchPolicy: 'network-only' }
  );

  const { customRules } = useLazyLoadQuery<CustomRulesQuery>(
    DataValidationCustomRulesQuery,
    { owner },
    { fetchPolicy: 'network-only' }
  );

  const { validationContainer } = useLazyLoadQuery<validationContainerQuery>(
    ValidationContainerQuery,
    {
      modelName: connectorComponentModelName,
      owner,
    }
  );

  // Function to refetch rule library items
  const refetchRuleLibraryItems = () => {
    loadRulesQuery(
      {
        owner,
        connectorComponentKind: 'model',
        connectorComponentName: connectorComponentModelName,
        connectorComponentVersion,
      },
      { fetchPolicy: 'network-only' }
    );
  };

  useEffect(() => {
    const fetchInterval = setInterval(() => {
      refetchRuleLibraryItems();
    }, DATA_VALIDATION_V2_POOLING_INTERVAL);

    return () => {
      clearInterval(fetchInterval);
    };
  }, []);

  return {
    owner,
    organizationId,
    connectorComponentName,
    connectorComponentModelName,
    ruleLibraryItems,
    refetchRuleLibraryItems,
    customRules,
    validationContainer,
  };
};
