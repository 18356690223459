import React from 'react';
import {
  Box,
  Divider,
  Grid,
  styled,
  TextField,
  Typography,
  useTheme,
} from '@cherre-frontend/ui';
import StatusBox, { Status } from '../../components/StatusBox';
import ConnectorBreadcrumbs from 'src/products/connector/components/ConnectorBreadcrumbs';
import { useAddConnector } from '../../dataState';
import { useConnectorUIState } from '../../uiState/contexts';

const HeaderContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const BreadcrumbsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  width: 100%;
`;
const ConnectorDetailsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 24px;
  margin-right: 8px;
  padding-bottom: 8px;
  gap: 32px;
`;
const Details = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const DetailContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const NameTextField = styled(TextField)`
  input {
    overflow: hidden;
    font-feature-settings: 'liga' off;
    text-overflow: ellipsis;

    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; /* 125% */
    letter-spacing: 0.24px;
  }
`;
const ConnectorProgressContainer = styled(Box)`
  display: flex;
  flex-direction: row;
`;
const ProgressStep = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 16px;
`;
const ProgressHeaderText = styled(Typography)`
  font-weight: 700;
`;

const Step = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 8px;
`;

/**
 * ConnectorDetailsHeader component displays the header section for the Add Connector page.
 * It includes breadcrumbs, connector details, and a progress indicator for the connector creation steps.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 *
 * @example
 * <ConnectorDetailsHeader />
 *
 * @remarks
 * This component uses several custom hooks and components:
 * - `useTheme` to access the current theme.
 * - `useAddConnector` to manage connector data state.
 * - `useConnectorUIState` to manage the UI state of the connector creation steps.
 *
 * The component determines the status of each step (source, model, destination) and displays
 * the corresponding status using the `StatusBox` component.
 */
const ConnectorDetailsHeader: React.FC = () => {
  const theme = useTheme();

  const [connectorData, setConnectorData] = useAddConnector();
  const [{ step }] = useConnectorUIState();

  const hasSource = Boolean(connectorData.sources?.length);
  const inSource = step === 'source';

  const hasModel = Boolean(connectorData.models?.length);
  const inModel = step === 'model';

  const hasDestination = Boolean(connectorData.destinations?.length);
  const inDestination = step === 'destination';

  const getStatus = (done: boolean, doing: boolean): Status => {
    if (doing) {
      return 'in-progress';
    }
    if (done) {
      return 'done';
    }
    return 'not-started';
  };

  return (
    <HeaderContainer id='connector-details-header'>
      <BreadcrumbsContainer>
        <ConnectorBreadcrumbs connectorName='Add a Connector' />
      </BreadcrumbsContainer>
      <ConnectorDetailsContainer>
        <img src='/assets/connector-details-artwork.svg' />
        <Details>
          <Grid container direction='column' gap='10px'>
            <Grid
              container
              display='flex'
              justifyContent='space-between'
              alignItems='start'
            >
              <DetailContainer>
                <NameTextField
                  value={connectorData.name}
                  onChange={(e) => setConnectorData({ name: e.target.value })}
                  variant='standard'
                />
              </DetailContainer>
            </Grid>
          </Grid>
        </Details>
        <ConnectorProgressContainer>
          <Divider
            style={{
              borderRightWidth: '1px',
              borderColor: theme.palette.grey[400],
            }}
          />
          <ProgressStep>
            <ProgressHeaderText>Connector Creation Progress</ProgressHeaderText>
            <Step>
              <StatusBox
                label={'Source'}
                status={getStatus(hasSource, inSource)}
              />
              <StatusBox
                label={'Model'}
                status={getStatus(hasModel, inModel)}
              />
              <StatusBox
                label={'Destination'}
                status={getStatus(hasDestination, inDestination)}
              />
            </Step>
          </ProgressStep>
        </ConnectorProgressContainer>
      </ConnectorDetailsContainer>
    </HeaderContainer>
  );
};

export default ConnectorDetailsHeader;
