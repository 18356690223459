import { buildChosenTables } from '../buildChosenTables';
import {
  BuildDestinationsDraftArgs,
  BuildDestinationsDraftReturn,
} from './model';

export const buildDestinationsDraft = ({
  state,
  selectedDestination,
  formData,
}: BuildDestinationsDraftArgs): BuildDestinationsDraftReturn => {
  const prevDestinations = state.destinations || [];
  const chosenTables = formData.map((data) => {
    const tables = buildChosenTables(data.form);

    return {
      tables,
      selectedAll: data.selectedAll,
      modelName: data.modelName,
      stableVersion: data.stableVersion,
      displayName: data.displayName,
    };
  });
  const newDestination = {
    destinationName: selectedDestination.destinationName,
    chosenTables,
  };
  const findDestination = prevDestinations.find(
    (destination) =>
      destination.destinationName === selectedDestination.destinationName
  );

  if (!findDestination) {
    return [...prevDestinations, newDestination];
  }

  return prevDestinations.map((destination) => {
    if (destination.destinationName === findDestination.destinationName) {
      return newDestination;
    }
    return destination;
  });
};
