import React, { useMemo } from 'react';
import {
  Box,
  Select,
  MenuItem,
  OutlinedInput,
  useTheme,
  SelectChangeEvent,
} from '@cherre-frontend/ui';
import { useCherreState } from '@cherre-frontend/data-fetching';
import { dataValidationRuleForm } from '../../recoil';
import StyledLabel from './Label';
import { SelectOption } from './utils/types';

type FormSelectProps = {
  title: string;
  menuOptions: SelectOption[];
  required?: boolean;
  fieldName: string;
};

const FormSelect: React.FC<FormSelectProps> = ({
  title,
  menuOptions,
  required,
  fieldName,
}) => {
  const theme = useTheme();
  const [form, setForm] = useCherreState(dataValidationRuleForm);

  const value = useMemo(() => {
    if (form) {
      return form[form.rule_type][fieldName];
    } else {
      return '';
    }
  }, [form]);

  if (form) {
    if (form[form.rule_type][fieldName] === '') {
      setForm((oldState) => {
        return {
          ...oldState,
          [form.rule_type]: {
            ...oldState[form.rule_type],
            [fieldName]: menuOptions[0].value,
          },
        };
      });
    }
  }

  const handleSelect = (e: SelectChangeEvent) => {
    if (form) {
      setForm((oldState) => {
        return {
          ...oldState,
          [form.rule_type]: {
            ...oldState[form.rule_type],
            [fieldName]: e.target.value,
          },
        };
      });
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <StyledLabel
        style={{ fontSize: '14px', fontWeight: 600 }}
        required={required}
      >
        {title}
      </StyledLabel>
      <Select
        sx={{
          borderRadius: '6px',
          '.MuiSelect-select': {
            height: '22px',
            paddingTop: '6px',
            paddingBottom: '6px',
          },
          '&.MuiOutlinedInput-input': {
            paddingTop: '6px',
            paddingBottom: '6px',
          },
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.grey[400],
            borderRadius: '6px',
          },
          '.MuiOutlinedInput-notchedOutline:hover': {
            borderColor: theme.palette.grey[400],
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.grey[400],
          },
        }}
        MenuProps={{
          sx: {
            '&& .Mui-selected': {
              backgroundColor: 'white',
            },
            '&& .Mui-selected:hover': {
              backgroundColor: theme.palette.grey[100],
            },
          },
        }}
        input={
          <OutlinedInput
            notched={false}
            sx={{
              '.MuiOutlinedInput-notchedOutline': {
                height: '32px',
                border: `1px solid ${theme.palette.grey[400]}`,
              },
            }}
          />
        }
        label={value}
        value={value}
        variant='outlined'
        onChange={handleSelect}
      >
        {menuOptions.map((option, index) => (
          <MenuItem key={`option-${index}`} value={option.value}>
            {option.label ?? option.value}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

FormSelect.displayName = 'FormSelect';
export default FormSelect;
