import { styled, Typography } from '@cherre-frontend/ui';

const StyledLabel = styled(Typography)<{ required?: boolean }>`
  &:after {
    ${({ required }) =>
      required &&
      `
    content: '*';
    color: red;
    `}
  }
`;

export default StyledLabel;
