import React, { ReactElement } from 'react';
import {
  SourceCardContainer,
  SourceCarrouselSkeletonsContainer,
  StyledSkeleton,
} from './skeleton.styles';

/**
 * Component representing the skeletor loading state when the client is trying to retrieve the data from graphql query.
 *
 * @returns {JSX.Element} The rendered SourceCarrouselSkeletons component.
 */

const SourceCarrouselSkeletons = (): ReactElement => (
  <SourceCarrouselSkeletonsContainer>
    <SourceCardContainer>
      <StyledSkeleton
        variant='rectangular'
        animation='wave'
        width={202}
        height={90}
      />
      <StyledSkeleton
        variant='rectangular'
        animation='wave'
        width={202}
        height={90}
      />
    </SourceCardContainer>
  </SourceCarrouselSkeletonsContainer>
);

export default SourceCarrouselSkeletons;
