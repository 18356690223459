import React, { useMemo } from 'react';
import { Box, styled } from '@cherre-frontend/ui';
import OverviewDivider from '../../../../components/OverviewDivider';
import OverviewCard, { OverviewType } from './OverviewCard';
import ConnectorButton from './ConnectorButton';
import { useGetOverview } from './hooks/useGetOverview';
import { buildValidationStatusOverviewCard } from './util/buildValidationStatusOverviewCard';
import { ValidationStatus } from '../../../../components/ValidationStatusPopover';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';

const Container = styled('div')`
  display: grid;
  grid-template-columns: 1fr 46px 1fr 46px 1fr;
  gap: 32px;
`;

const OverviewContent: React.FC = () => {
  const { overviewData } = useGetOverview();
  const canAddConnector = useFeatureFlag('ConnectorsCreateConnector');

  const modelStatus = useMemo<ValidationStatus>(
    () => buildValidationStatusOverviewCard(overviewData?.data),
    [overviewData]
  );

  return (
    <Box sx={{ width: '100%', paddingX: '16px' }}>
      <>
        <Container>
          <OverviewCard
            data={overviewData?.data.sources || []}
            failed={
              overviewData?.data.failedConnectorComponent ===
              OverviewType.SOURCE
            }
            type={OverviewType.SOURCE}
          />
          <OverviewDivider />
          <OverviewCard
            data={overviewData?.data.models || []}
            failed={
              overviewData?.data.failedConnectorComponent === OverviewType.MODEL
            }
            type={OverviewType.MODEL}
          />
          <OverviewDivider status={modelStatus} />
          <OverviewCard
            data={overviewData?.data.destinations || []}
            failed={
              overviewData?.data.failedConnectorComponent ===
              OverviewType.DESTINATION
            }
            type={OverviewType.DESTINATION}
          />
        </Container>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
            marginTop: '40px',
          }}
        >
          {canAddConnector && (
            <ConnectorButton variant='contained' disabled>
              Save Connection
            </ConnectorButton>
          )}
        </Box>
      </>
    </Box>
  );
};

export default OverviewContent;
