import { preconfiguredModelsQuery } from 'src/services/DOM/preconfiguredModels/__generated__/preconfiguredModelsQuery.graphql';
import { PartialAddConnectorType } from '../../../dataState';

type FilterModelsArgs = {
  selectedModels?: PartialAddConnectorType['models'];
  selectedSources?: PartialAddConnectorType['sources'];
  preconfiguredModels: preconfiguredModelsQuery['response']['preconfiguredModels'];
};

export type FilterModelsReturn = {
  image: string;
} & preconfiguredModelsQuery['response']['preconfiguredModels'][0];

export const filterModels = ({
  selectedModels = [],
  selectedSources = [],
  preconfiguredModels,
}: FilterModelsArgs): FilterModelsReturn[] => {
  const sourceNames = selectedSources.map(
    (source) => source.preconfiguredSourceName
  );
  const availablesModels = preconfiguredModels.filter((model) => {
    return model.compatiblePreconfiguredSources.some((source) =>
      sourceNames.includes(source as string)
    );
  });
  const modelsNames = selectedModels.map((model) => model.name);

  return availablesModels
    .filter((model) => !modelsNames.includes(model.name))
    .map((model) => ({
      ...model,
      image: `/assets/connector-icons/rounded-logos/${model.provider}-rounded.svg`,
    }));
};
