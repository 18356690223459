/**
 * @generated SignedSource<<19ed1f38bd560df222cb1f91fe1d5b3e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type validationContainerQuery$variables = {
  modelName: string;
  owner?: string | null;
};
export type validationContainerQuery$data = {
  readonly validationContainer: ReadonlyArray<{
    readonly containerId: number;
    readonly isCustomTable: boolean;
    readonly tableAlias: string;
    readonly tableName: string;
  } | null> | null;
};
export type validationContainerQuery = {
  response: validationContainerQuery$data;
  variables: validationContainerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "modelName"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "owner"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "modelName",
        "variableName": "modelName"
      },
      {
        "kind": "Variable",
        "name": "owner",
        "variableName": "owner"
      }
    ],
    "concreteType": "ValidationContainerInfo",
    "kind": "LinkedField",
    "name": "validationContainer",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "tableName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "tableAlias",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "containerId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isCustomTable",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "validationContainerQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "validationContainerQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "78bcf3602b24d3ae35ee36d3520f8d8a",
    "id": null,
    "metadata": {},
    "name": "validationContainerQuery",
    "operationKind": "query",
    "text": "query validationContainerQuery(\n  $owner: String\n  $modelName: String!\n) {\n  validationContainer(owner: $owner, modelName: $modelName) {\n    tableName\n    tableAlias\n    containerId\n    isCustomTable\n  }\n}\n"
  }
};
})();

(node as any).hash = "a5eacb04d1a717468d969705e8075c0b";

export default node;
