import React from 'react';
import { Paper, Typography, useTheme, Grid } from '@cherre-frontend/ui';

type ObservabilityCardProps = {
  cardHeader: string;
  cardContent: string;
};

const ObservabilityCard: React.FC<ObservabilityCardProps> = ({
  cardHeader,
  cardContent,
}) => {
  const theme = useTheme();
  return (
    <Paper
      elevation={0}
      sx={{
        borderRadius: '3px',
        padding: '20px',
        backgroundColor: theme.palette.grey[100],
        width: '100%',
        height: '100%',
      }}
    >
      <Grid
        container
        direction='column'
        justifyItems='center'
        alignItems='start'
      >
        <Grid item>
          <Typography fontSize='12px' fontWeight='bold'>
            {cardHeader}
          </Typography>
        </Grid>
        <Grid item>
          <Typography fontSize='12px'>{cardContent}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ObservabilityCard;
