import React from 'react';
import { TogglesIcon } from '@cherre-frontend/ui';
import { RouteListType } from 'src/utils/routing';
import { createRoute } from 'src/utils/routing/routeFactory';
import { usePermission } from 'src/hooks';
import { RelayEnvironmentScope } from '@cherre-frontend/data-fetching';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { RelayEnvironmentProvider } from 'react-relay';
import { DomEnvironment } from 'src/products/shell/DomRelayEnvironment';
const CONNECTOR_GROUP = 'Connectors';

const RelayWrapper = <P extends React.ComponentType>(
  Component: React.LazyExoticComponent<P>
): P => {
  return ((props) => (
    <RelayEnvironmentProvider environment={DomEnvironment}>
      <RelayEnvironmentScope environment='dom'>
        <Component {...props} />
      </RelayEnvironmentScope>
    </RelayEnvironmentProvider>
  )) as any;
};

export const ConnectorsLandingRoute = createRoute({
  path: '/connectors',
  menu: {
    title: 'Connectors',
    icon: TogglesIcon,
    group: 'CoreConnect',
  },
  component: RelayWrapper(
    React.lazy(() => import('./pages/connectors-landing'))
  ),
  permissionHook: () => usePermission('Connectors'),
});

export const ConnectorInventoryRoute = createRoute({
  path: '/connectors/inventory',
  component: RelayWrapper(
    React.lazy(() => import('./pages/connector-inventory'))
  ),
  permissionHook: () => usePermission('Connectors'),
});

export const ConnectorDetailsRoute = createRoute({
  path: '/connectors/:connector_name',
  component: RelayWrapper(
    React.lazy(() => import('./pages/connector-details'))
  ),
  permissionHook: () => usePermission('Connectors'),
});

export const ValidationTestDetailsRoute = createRoute({
  path: '/connectors/:connector_name/validationDetails/:rule_id',
  component: RelayWrapper(
    React.lazy(() => import('./pages/connector-details'))
  ),
  permissionHook: () => usePermission('Connectors'),
});

export const AddConnectorRoute = createRoute({
  path: '/connectors/add',
  component: RelayWrapper(React.lazy(() => import('./pages/add-connector'))),
  permissionHook: () =>
    usePermission('Connectors') && useFeatureFlag('ConnectorsCreateConnector'),
});

export const EditDraftConnectorRoute = createRoute({
  path: '/connectors/add/:id',
  component: RelayWrapper(React.lazy(() => import('./pages/add-connector'))),
  permissionHook: () =>
    usePermission('Connectors') && useFeatureFlag('ConnectorsCreateConnector'),
});

export const connectorRoutes = [
  {
    permissions: ['Connectors'],
    header: CONNECTOR_GROUP,
    routes: [
      AddConnectorRoute.routeDescriptor,
      EditDraftConnectorRoute.routeDescriptor,
      ConnectorsLandingRoute.routeDescriptor,
      ConnectorInventoryRoute.routeDescriptor,
      ConnectorDetailsRoute.routeDescriptor,
      ValidationTestDetailsRoute.routeDescriptor,
    ],
  },
] satisfies RouteListType;
