import React, { useEffect } from 'react';
import { useSFTPSource, useAddConnector, useHasChanged } from './contexts';
import {
  SFTPSourceFirstStepSchema,
  SFTPSourceSchema,
  AddConnectorSchema,
  AddConnectorType,
} from './schemas';
import { useConnectorUIState, useSourceUIState } from '../uiState/contexts';
import axios from 'axios';
import { useAppContext } from '@cherre-frontend/core';
import { useHistory, useParams } from 'react-router';
import { useMutation } from 'react-relay';
import { CreateSourceMutation } from 'src/services/DOM/mutations/createSource';
import { CreateModelMutation } from 'src/services/DOM/mutations/createModel';
import { UpdateModelMutation } from 'src/services/DOM/mutations/updateModel';
import { buildSourcePayload } from './util/buildSourcePayload';
import { CreateConnectorMutation } from 'src/services/DOM/mutations/createConnector';
import { AddDestinationToModelMutation } from 'src/services/DOM/mutations/addDestinationToModel';
import { buildCronSchedule } from './util/buildCronSchedule';
import { createModelMutation } from 'src/services/DOM/mutations/createModel/__generated__/createModelMutation.graphql';
import { createSourceMutation } from 'src/services/DOM/mutations/createSource/__generated__/createSourceMutation.graphql';
import { deleteDraftConnector } from './util/deleteDraftConnector';
import { buildMapperRequests } from './util/buildMapperRequests';
import { updateModelMutation } from 'src/services/DOM/mutations/updateModel/__generated__/updateModelMutation.graphql';
import { addDestinationToModelMutation } from 'src/services/DOM/mutations/addDestinationToModel/__generated__/addDestinationToModelMutation.graphql';
import { createConnectorMutation } from 'src/services/DOM/mutations/createConnector/__generated__/createConnectorMutation.graphql';

/**
 * Custom hook that creates a stable callback reference.
 *
 * This hook ensures that the callback function reference remains stable
 * across re-renders, preventing unnecessary re-renders of components
 * that depend on this callback.
 *
 * @template T - The type of the callback function.
 * @param {T} cbk - The callback function to be memoized.
 * @returns {(...args: Parameters<T>) => ReturnType<T>} - A memoized version of the callback function.
 */

const useEvent = <T extends (...params: any[]) => any>(cbk: T): T => {
  const cbkRef = React.useRef(cbk);
  cbkRef.current = cbk;
  return React.useCallback<any>(
    (...args: Parameters<T>) => cbkRef.current(...args),
    []
  );
};

// SOURCE

/**
 * Custom hook that handles the transition to the next step in the SFTP source setup process.
 *
 * This hook uses the current SFTP source value and validates it against the `SFTPSourceFirstStepSchema`.
 * If the validation is successful, it provides a callback function to move to the next step in the UI state.
 *
 * @returns A tuple containing:
 * - `result.success` (boolean): Indicates whether the SFTP source value is valid.
 * - `cbk` (function): Callback function to move to the next step if the validation is successful.
 */
export const useGoToNextStepSFTPSource = () => {
  const [value] = useSFTPSource();
  const [, setUIState] = useSourceUIState();
  const result = React.useMemo(
    () => SFTPSourceFirstStepSchema.safeParse(value),
    [value]
  );
  const cbk = useEvent(() => {
    if (result.success) {
      setUIState((prev) => ({ ...prev, step: prev.step + 1 }));
    }
  });

  return [result.success, cbk] as const;
};

/**
 * Custom hook to verify the data fields typed by the user.
 *
 * The hook uses the zod schema to verify the inserted data. The entry verification is triggered by
 * two events: onBlur and onChange
 *
 * @returns A tuple containing:
 * - `error object`: An object containing the fields error
 * - `onBlurCallback`: A callback function to verify the data when the user blur the input
 * - `onChangeCallback`: A callback function to verify the data when the input is changed
 */

type ErrorForm = { [key: string]: string };

export const useSftpSourceFormError = (): [
  ErrorForm,
  (text: string, field: string) => void,
  (text: string, field: string) => void
] => {
  const [error, setError] = React.useState({});

  const onBlurCallback = React.useCallback(
    (text, field) => {
      const parser = (
        SFTPSourceSchema.safeParse({ [field]: text }) as any
      ).error?.format();

      if (!parser) {
        return;
      }

      const hasError = Boolean(parser[field]?._errors[0]);

      if (parser[field] && hasError) {
        error[field] = parser[field]._errors[0];
      }

      setError({ ...error });
    },
    [error]
  );

  const onChangeCallback = React.useCallback(
    (text, field) => {
      const parser = (
        SFTPSourceSchema.safeParse({ [field]: text }) as any
      ).error?.format();

      if (!parser) {
        return;
      }

      const hasError = Boolean(parser[field]?._errors[0]);

      if (!hasError && error[field]) {
        error[field] = undefined;
      }

      setError({ ...error });
    },
    [error]
  );

  return [error, onBlurCallback, onChangeCallback];
};

/**
 * Custom hook to save SFTP source data.
 *
 * This hook uses the current SFTP source value and connector UI state to
 * validate and save the SFTP source data. If the validation is successful,
 * it updates the connector value with the new source data and resets the
 * UI state to the default step.
 *
 * @returns A tuple containing:
 * - `result.success`: A boolean indicating whether the SFTP source data is valid.
 * - `cbk`: A callback function to save the SFTP source data if the current step is 'source' and the validation is successful.
 */
export const useSaveSFTPSource = () => {
  const [value] = useSFTPSource();
  const [uiState, setUIState] = useConnectorUIState();
  const [, setConnectorValue] = useAddConnector();

  const result = React.useMemo(
    () => SFTPSourceSchema.safeParse(value),
    [value]
  );

  const cbk = useEvent(() => {
    if (uiState.step !== 'source') {
      return;
    }
    if (result.success) {
      setConnectorValue((prev) => {
        const sources = [...(prev.sources || [])];
        sources[uiState.index] = result.data;
        return { ...prev, sources };
      });
      setUIState({ step: 'default' as const, index: 0 });
    }
  });

  return [result.success, cbk] as const;
};

// CONNECTOR

/**
 * Custom hook to save a connector as a draft.
 *
 * This hook provides a callback function to save the current connector data as a draft.
 * It handles both creating a new draft and updating an existing draft based on the presence of an `id`.
 * The hook also manages the saving state and displays appropriate success or error messages using a snackbar.
 *
 * @returns A tuple containing:
 * - A boolean indicating whether the data has changed and is not currently saving.
 * - A callback function to save the connector as a draft.
 */
export const useSaveConnectorAsDraft = () => {
  const [hasChanged, setHasChanged] = useHasChanged();
  const [value, setValue] = useAddConnector();
  const { id } = useParams<{ id?: string }>();
  const { push } = useHistory();
  const { showSnackbar } = useAppContext();
  const [isSaving, setIsSaving] = React.useState(false);
  const cbk = useEvent(async () => {
    if (!hasChanged) {
      return;
    }

    try {
      setIsSaving(true);
      let result;
      if (!id) {
        result = await axios.post('/api/v1/dom/draft', value);
      } else {
        result = await axios.put(`/api/v1/dom/draft/${id}`, value);
      }
      setValue(result.data.draft);
      setHasChanged(false);
      showSnackbar({ type: 'success', message: 'Draft saved successfully' });
      if (!id) {
        push(`/connectors/add/${result.data.id}`);
      }
    } catch (e) {
      console.error(e);
      showSnackbar({ type: 'error', message: 'Failed to save draft' });
    } finally {
      setIsSaving(false);
    }
  });

  return [hasChanged && !isSaving, cbk] as const;
};

/**
 * Custom hook to handle the submission of adding a connector.
 *
 * @param onSubmit - A callback function that is called with the payload when the form is successfully submitted.
 * @returns A tuple containing:
 *   - A boolean indicating whether the form validation was successful.
 *   - A callback function to trigger the submission process.
 */
export const useSubmitAddConnector = (
  onSubmit: (payload: AddConnectorType) => void
) => {
  const [value] = useAddConnector();

  const result = React.useMemo(
    () => AddConnectorSchema.safeParse(value),
    [value]
  );

  const onSubmitRef = React.useRef(onSubmit);
  onSubmitRef.current = onSubmit;

  const cbk = React.useCallback(() => {
    if (result.success) {
      onSubmitRef.current(result.data);
    }
  }, [result]);

  return [result.success, cbk] as const;
};

/**
 * Custom hook to handle the connector creation.
 *
 * @returns A tuple containing:
 *   - A boolean indicating whether the source, model and destination have been set up by the user.
 *   - A boolean indicating the service is running.
 *   - A callback function to trigger the creation process.
 */

export const useCreateConnector = (): [boolean, boolean, () => void] => {
  const [addConnectorData, setAddConnectorData] = useAddConnector();
  const [uiState] = useConnectorUIState();
  const { id: draftId } = useParams<{ id?: string }>();
  const [requestMapperStatus, setRequestMapperStatus] = React.useState({});
  const [hasError, setHasError] = React.useState(false);
  const cacheMapperStatus = React.useRef({});

  const [createSource, creatingSource] =
    useMutation<createSourceMutation>(CreateSourceMutation);
  const [createModel, creatingModel] =
    useMutation<createModelMutation>(CreateModelMutation);
  const [updateModel, updatingModel] =
    useMutation<updateModelMutation>(UpdateModelMutation);
  const [addDestinationToModel, addingDestinationToModel] =
    useMutation<addDestinationToModelMutation>(AddDestinationToModelMutation);
  const { showSnackbar } = useAppContext();
  const [createConnector, creatingConnector] =
    useMutation<createConnectorMutation>(CreateConnectorMutation);
  const history = useHistory();

  const { models, destinations, sources, name: displayName } = addConnectorData;

  useEffect(() => {
    cacheMapperStatus.current = buildMapperRequests(addConnectorData);
  }, [addConnectorData]);

  useEffect(() => {
    axios.get('/api/v1/user/me').then((response) => {
      setAddConnectorData((prevState) => ({
        ...prevState,
        domOwner: response.data.domOwner,
      }));
    });
  }, []);

  useEffect(() => {
    if (!hasError) {
      return;
    }

    showSnackbar({ type: 'error', message: 'Failed to create connector' });
    setHasError(false);
  }, [hasError]);

  useEffect(() => {
    const keys = Object.keys(requestMapperStatus);
    const allPassed =
      keys.length > 0 ? keys.every((key) => requestMapperStatus[key]) : false;

    if (!allPassed) {
      return;
    }

    if (draftId) {
      void deleteDraftConnector(draftId);
    }
    history.push('/connectors/inventory');
  }, [draftId, requestMapperStatus]);

  const onCompleteUpdateModel = React.useCallback(
    ({ response, destinationName, modelFromState }): void => {
      const {
        updateModel: { name: modelName },
      } = response;

      addDestinationToModel({
        variables: {
          destinationName,
          modelName,
        },
        onCompleted: () => {
          cacheMapperStatus.current[`destination_${modelFromState.modelName}`] =
            true;
          setRequestMapperStatus({ ...cacheMapperStatus.current });
        },
        onError: () => {
          setHasError(true);
        },
      });
    },
    []
  );

  const onCompleteCreateModel = React.useCallback(
    ({ response, destinationName, modelFromState }): void => {
      const {
        createModel: { name: modelName },
      } = response;

      updateModel({
        variables: {
          modelName,
          egressedTables: [
            {
              revisionNumber: modelFromState.stableVersion,
              tables: modelFromState.tables,
            },
          ],
        },
        onCompleted: (response) => {
          cacheMapperStatus.current[
            `update_model_${modelFromState.modelName}`
          ] = true;
          onCompleteUpdateModel({ response, destinationName, modelFromState });
        },
        onError: () => {
          setHasError(true);
        },
      });
    },
    [requestMapperStatus]
  );

  const onCompleteCreateSourceWithOnlyModels = React.useCallback(
    ({ schedule, connectorName }): void => {
      models?.forEach((model) => {
        createModel({
          variables: {
            connectorName,
            preconfiguredModelName: model.name,
            displayName: model.displayName,
            schedule,
          },
          onCompleted: () => {
            cacheMapperStatus.current[`model_${model.name}`] = true;
            setRequestMapperStatus({ ...cacheMapperStatus.current });
          },
          onError: () => {
            setHasError(true);
          },
        });
      });
    },
    [destinations, requestMapperStatus]
  );

  const onCompleteCreateSourceWithDestinations = React.useCallback(
    ({ schedule, connectorName }): void => {
      destinations?.forEach((destination) => {
        const { destinationName, chosenTables } = destination;

        chosenTables.forEach((model) => {
          createModel({
            variables: {
              connectorName,
              preconfiguredModelName: model.modelName,
              displayName: model.displayName,
              egressedTables: model.tables,
              schedule,
            },
            onCompleted: (response) => {
              cacheMapperStatus.current[`model_${model.modelName}`] = true;
              onCompleteCreateModel({
                response,
                destinationName,
                modelFromState: model,
              });
            },
            onError: () => {
              setHasError(true);
            },
          });
        });
      });
    },
    [destinations, requestMapperStatus]
  );

  const onCompleteCreateConnector = React.useCallback(
    ({ response, source }): void => {
      const {
        createConnector: { name: connectorName },
      } = response;
      const { preconfiguredSourceName } = source;
      const envs = buildSourcePayload(source);
      const schedule = buildCronSchedule(source);

      createSource({
        variables: {
          preconfiguredSourceName,
          connectorName,
          schedule,
          envs,
          displayName,
        },

        onCompleted: () => {
          cacheMapperStatus.current[
            `source_${source.preconfiguredSourceName}`
          ] = true;
          if (destinations) {
            onCompleteCreateSourceWithDestinations({
              schedule,
              connectorName,
            });
          } else {
            onCompleteCreateSourceWithOnlyModels({ schedule, connectorName });
          }
        },
        onError: () => {
          setHasError(true);
        },
      });
    },
    [requestMapperStatus]
  );

  const onSaveConnector = React.useCallback((): void => {
    sources?.forEach((source) => {
      createConnector({
        variables: {
          displayName: displayName as string,
          modelNames: [],
          sourceNames: [],
        },
        onCompleted: (response) => {
          onCompleteCreateConnector({ response, source });
        },
      });
    });
  }, [createSource, sources]);

  return [
    Boolean(models && sources && !uiState.repeatedSource),
    creatingConnector ||
      creatingModel ||
      creatingSource ||
      updatingModel ||
      addingDestinationToModel,
    onSaveConnector,
  ];
};
