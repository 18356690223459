import React from 'react';

import { Box, Typography, Button, AddCircleIcon } from '@cherre-frontend/ui';
import { DataValidationOverview } from './components/DataValidationOverview';
import { FlexRow } from '../../../../../../../../components/Flex';
import { DataValidationTable } from './components/DataValidationTable';
import { useDataValidationListData } from './hooks/useDataValidationListData';
import { DataValidationTableFilters } from './components/DataValidationTable/DataValidationTableFilters';
import { useCherreState } from '@cherre-frontend/data-fetching';
import { connectorDataValidationTabParam } from '../../../../../../recoil';

export const DataValidationList = () => {
  const { data, categories, filters, setFilters } = useDataValidationListData();

  const [, setDataValidationV2Param] = useCherreState(
    connectorDataValidationTabParam
  );

  const handleCreate = () => {
    setDataValidationV2Param({
      content: 'details',
      rule: 'new',
      detailsTab: null,
    });
  };

  return (
    <Box padding={2} data-testid='data-validation-v2-list'>
      <FlexRow justifyContent='space-between' alignItems='center'>
        <Typography variant='h4'>Data Validation</Typography>

        <Button
          onClick={handleCreate}
          variant='contained'
          endIcon={<AddCircleIcon />}
        >
          Create Rule
        </Button>
      </FlexRow>

      <Box marginTop='20px'>
        <DataValidationOverview ruleLibraryItems={data} />
      </Box>

      <Box marginTop='40px'>
        <DataValidationTableFilters
          categories={categories}
          filters={filters}
          setFilters={setFilters}
        />

        <DataValidationTable ruleLibraryItems={data} />
      </Box>
    </Box>
  );
};
