import React, { useMemo, useState } from 'react';
import {
  useCherreSetState,
  useCherreState,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import {
  Box,
  CircularProgress,
  Typography,
  useTheme,
} from '@cherre-frontend/ui';
import ConnectorButton from 'src/products/connector/pages/connector-details/components/Overview/ConnectorButton';
import {
  connectorDetailsDataValidationState,
  dataValidationRuleForm,
  dataValidationTestsTrigger,
} from '../../recoil';
import DefaultFormInputs from './DefaultFormInputs';
import useRuleUI from './utils/useRuleUI';
import { DataValidationState, RuleTypes } from './utils/types';
import RuleUI from './RuleUI';
import {
  emptyForm,
  getSubmittableRuleType,
  handleFormValue,
} from './utils/const';
import axios from 'axios';
import { connectorDetailsSelectedTable } from '../../../../recoil';
import { useGetValidationRuleColumns } from '../../../../../../services/OpenMetadata/hooks/useGetValidationRuleColumns';
import { useSnackbarCherre } from 'src/products/core-prospect/hooks';
import CreateValidationRuleFormLoader from './CreateValidationRuleFormLoader';
import { VariantType } from 'notistack';
import { schema } from './utils/validation';
import NotificationFormInputs from './NotificationFormInputs';
import { useSelector } from 'react-redux';
import { openMetadataTablesDemo } from 'src/services/ConnectorDemo/recoil';

const CreateValidationRuleForm: React.FC = () => {
  const theme = useTheme();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dataValidationState, setDataValidationState] = useCherreState(
    connectorDetailsDataValidationState
  );
  const [form, setForm] = useCherreState(dataValidationRuleForm);
  const ruleUI = useRuleUI();
  const tables = useCherreValue(openMetadataTablesDemo);
  const selectedTableName = useCherreValue(connectorDetailsSelectedTable);
  const setValidationTestName = useCherreSetState(dataValidationTestsTrigger);

  const { enqueueSnackbar } = useSnackbarCherre();

  const { latestTableData } = useGetValidationRuleColumns();

  const user = useSelector((state) => state.user.profile.value);

  const isValid = useMemo(() => {
    if (form) {
      const currentValue = {
        name: form.name,
        testDefinition: form.rule_type,
        ...(dataValidationState === DataValidationState.COLUMN && {
          column: form.column,
        }),
        parameterValues:
          form.rule_type !== RuleTypes.COLUMN_VALUES_TO_BE_NOT_NULL &&
          form.rule_type !== RuleTypes.COLUMN_VALUES_TO_BE_UNIQUE &&
          form.rule_type !== ''
            ? [
                ...Object.keys(form[form?.rule_type]).map((field) => ({
                  name: field,
                  value: form[form.rule_type][field],
                })),
              ]
            : [],
      };
      return schema.safeParse(currentValue).success;
    }
    return false;
  }, [form]);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const activeTable = tables?.find((table) => {
      return table === selectedTableName;
    });
    let payload;
    if (form) {
      if (
        form.rule_type !== RuleTypes.COLUMN_VALUES_TO_BE_NOT_NULL &&
        form.rule_type !== RuleTypes.COLUMN_VALUES_TO_BE_UNIQUE
      ) {
        payload = {
          name: form?.name,
          displayName: form?.name,
          description: form?.description,
          testDefinition: getSubmittableRuleType(form.rule_type),
          parameterValues: [
            ...Object.keys(form[form?.rule_type]).map((field) => ({
              name: field,
              value: handleFormValue(form[form.rule_type][field]),
            })),
          ],
          testSuite: `${activeTable}.testSuite`,
          entityLink: `<#E::table::${activeTable}${
            dataValidationState === DataValidationState.COLUMN
              ? `::columns::${form.column}`
              : ``
          }>`,
          userId: user.id,
        };
      } else {
        payload = {
          name: form?.name,
          displayName: form?.name,
          description: form?.description,
          testDefinition: getSubmittableRuleType(form.rule_type),
          parameterValues: [],
          testSuite: `${activeTable}.testSuite`,
          entityLink: `<#E::table::${activeTable}${
            dataValidationState === DataValidationState.COLUMN
              ? `::columns::${form.column}`
              : ``
          }>`,
          userId: user.id,
        };
      }
      let message = '';
      let variant: VariantType = 'success';
      try {
        await axios.post(
          `/api/v1/openmetadata/dataValidation/testCases`,
          payload
        );
        message = 'Successfully created rule.';
        variant = 'success';
        setForm(emptyForm);
        setDataValidationState(DataValidationState.DASHBOARD);
        //Trigger to force reload
        setValidationTestName(payload.name);
      } catch (error) {
        message = 'There was an error creating a new rule.';
        variant = 'error';
      } finally {
        setIsSubmitting(false);
        enqueueSnackbar(message, {
          variant: variant,
        });
      }
    }
  };

  const getFormTitle = () => {
    switch (dataValidationState) {
      case DataValidationState.TABLE:
        return 'Create a Table Rule';
      case DataValidationState.COLUMN:
        return 'Create a Column Rule';
      default:
        return '';
    }
  };

  if (latestTableData.state === 'loading') {
    return <CreateValidationRuleFormLoader />;
  }
  if (latestTableData.state === 'hasError') {
    return <h1>Error</h1>;
  }
  return (
    <Box sx={{ paddingLeft: '16px', paddingTop: '32px' }}>
      <Typography variant='h4'>{getFormTitle()}</Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '36px',
          marginTop: '40px',
          width: '800px',
        }}
      >
        <DefaultFormInputs
          menuOptions={
            latestTableData.contents
              ? latestTableData.contents.columns ?? []
              : []
          }
        >
          <RuleUI ruleUI={ruleUI} />
        </DefaultFormInputs>
        <NotificationFormInputs />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '16px',
            justifyContent: 'flex-end',
            paddingBottom: '24px',
          }}
        >
          <ConnectorButton
            variant='outlined'
            onClick={() => {
              setForm(emptyForm);
              document
                .getElementById('connector-details-header')
                ?.scrollIntoView();
              setDataValidationState(DataValidationState.DASHBOARD);
            }}
          >
            Cancel
          </ConnectorButton>
          <ConnectorButton
            variant='contained'
            onClick={() => {
              handleSubmit();
            }}
            disabled={isSubmitting || !isValid}
          >
            {isSubmitting ? (
              <CircularProgress
                style={{
                  display: 'flex',
                  color: theme.palette.grey[500],
                  height: '20px',
                  width: '20px',
                }}
              />
            ) : (
              'Add Rule'
            )}
          </ConnectorButton>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateValidationRuleForm;
