import React, { ReactElement } from 'react';
import { styled, Typography } from '@cherre-frontend/ui';

const Container = styled('div')`
  display: flex;
  border-radius: 5px;
  flex-direction: column;
  gap: 10px;
  background: ${({ theme }) => theme.palette.common.white};
  padding: 20px 20px 9px 20px;
  align-items: center;
  width: 260px;
  cursor: initial;
`;

const ImageHeder = styled('img')`
  width: 24px;
  height: 24px;
`;

const ImageContactPlan = styled('img')``;

const Title = styled(Typography)`
  grid-area: title;
  color: ${({ theme }) => theme.palette.grey[400]};
  text-align: center;
  font-feature-settings: 'liga' off;
  font-style: normal;
  font-size: 20px;
  font-weight: 700;
  line-height: 24.5px;
  letter-spacing: 0.01em;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

const DescriptionContactPlan = styled('p')`
  font-size: 12px;
  font-weight: 500;
  line-height: 14.63px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin: 0;
  color: ${({ theme }) => theme.palette.common.white};
`;

const HeaderContainer = styled('div')`
  display: flex;
  gap: 10px;
`;

const ContactPlanContainer = styled('div')`
  border-radius: 10px;
  background-color: #9f4182;
  display: flex;
  gap: 6px;
  padding: 10px;
`;

/**
 * Component representing a empty state card informing the user to updrage your contracted plan.
 *
 * @returns {JSX.Element} The rendered ModelCard component.
 */

const ModelCardEmptyState: React.FC = (): ReactElement => {
  return (
    <Container>
      <HeaderContainer>
        <ImageHeder src='/assets/icons/edit-grey-400.svg' />
        <Title>Custom Use Case Model</Title>
      </HeaderContainer>
      <ContactPlanContainer>
        <ImageContactPlan src='/assets/cherre-premium-logo.svg' />
        <DescriptionContactPlan>
          To add your own model, contact Cherre to upgrade your plan!
        </DescriptionContactPlan>
      </ContactPlanContainer>
    </Container>
  );
};

export default ModelCardEmptyState;
