import React, { useMemo } from 'react';
import { Box, Filter, SearchInput, styled } from '@cherre-frontend/ui';
import { DataValidationListFilters } from '../../hooks/useDataValidationListData';

const TableSearchInput = styled(SearchInput)`
  min-width: 300px;
  height: 34px;
  & .MuiInputBase-root {
    border-radius: 10px;
    display: flex;
    flex-direction: row-reverse;
    padding-right: 8px;
    padding-left: 8px;
  }
  & .input {
    height: 34px;
    border-radius: 10px;
  }
  & .MuiSvgIcon-root {
    width: 21px;
    height: 21px;
  }
`;

const validationStatusOptions = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Aborted',
    value: 'aborted',
  },
  {
    label: 'Failed',
    value: 'failed',
  },
  {
    label: 'Success',
    value: 'success',
  },
];

interface DataValidationTableFiltersProps {
  categories: string[];
  filters: DataValidationListFilters;
  setFilters: (filters: DataValidationListFilters) => void;
}

export const DataValidationTableFilters = ({
  categories,
  filters,
  setFilters,
}: DataValidationTableFiltersProps) => {
  const categoryOptions = [
    {
      label: 'All',
      value: 'all',
    },
    ...categories.map((category) => ({
      label: category,
      value: category,
    })),
  ];

  const selectedValidationStatus = useMemo(() => {
    if (filters.validationStatus === 'all') {
      return undefined;
    }

    return validationStatusOptions.find(
      (option) => option.value === filters.category
    );
  }, [filters.category]);

  const selectedCategory = useMemo(() => {
    if (filters.category === 'all') {
      return undefined;
    }

    return validationStatusOptions.find(
      (option) => option.value === filters.category
    );
  }, [filters.category]);

  return (
    <Box marginBottom='10px' display='flex' gap='10px'>
      <TableSearchInput
        onChange={(e) => setFilters({ ...filters, search: e.target.value })}
        value={filters.search}
      />

      <Filter
        selected={selectedCategory}
        label='Category'
        options={categoryOptions}
        onChange={(value) => {
          setFilters({
            ...filters,
            category: value?.value || 'all',
          });
        }}
      />

      <Filter
        selected={selectedValidationStatus}
        label='Validation Status'
        options={validationStatusOptions}
        onChange={(value) => {
          setFilters({
            ...filters,
            validationStatus: value?.value || 'all',
          });
        }}
      />
    </Box>
  );
};
