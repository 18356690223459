import React from 'react';
import { Grid } from '@cherre-frontend/ui';
import StatusCard from './StatusCard';
import { ConnectorStatuses } from 'src/services/ConnectorDemo/data/ConnectorLanding';

type ConnectorStatusSectionTypes = {
  connectorStatusData: ConnectorStatuses | null;
};
const ConnectorStatusSection: React.FC<ConnectorStatusSectionTypes> = ({
  connectorStatusData,
}) => {
  return (
    <>
      <Grid
        container
        direction='row'
        gap={1}
        justifyContent='space-evenly'
        wrap='nowrap'
      >
        <Grid item xs={4}>
          <StatusCard
            statusText='Active Pipelines'
            status='active'
            value={connectorStatusData?.activePipelines ?? 0}
          />
        </Grid>
        <Grid item xs={4}>
          <StatusCard
            statusText='Connectors Running'
            status='running'
            value={connectorStatusData?.runningConnectors ?? 0}
          />
        </Grid>
        <Grid item xs={4}>
          <StatusCard
            statusText='Failed Checks'
            status='failed'
            value={connectorStatusData?.failedChecks ?? 0}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ConnectorStatusSection;
