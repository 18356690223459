import { graphql } from 'relay-runtime';

export const GetValidationCustomTableQuery = graphql`
  query getValidationCustomTableQuery($owner: String, $tableName: String!) {
    validationCustomTable(owner: $owner, tableName: $tableName) {
      name
      displayName
      sqlInput
      sqlParsed
      dialect
      ready
    }
  }
`.default;
