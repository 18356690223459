import React from 'react';
import { Grid, Skeleton } from '@cherre-frontend/ui';

const DataValidationContentLoader: React.FC = () => {
  return (
    <Grid container direction='column' sx={{ width: '100%' }} padding={2}>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        sx={{ width: '100%' }}
      >
        <Grid item xs={6}>
          <Skeleton variant='text' width={210} height={40} animation='wave' />
        </Grid>
        <Grid item xs={6}>
          <Grid
            container
            justifyContent='flex-end'
            direction='row'
            alignItems='center'
            sx={{ gap: '16px' }}
          >
            <Grid item>
              <Skeleton
                variant='rounded'
                width={140}
                height={30}
                animation='wave'
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction='row' paddingTop='20px' columnSpacing='30px'>
        {Array.from(new Array(4)).map((_, index) => (
          <Grid item xs={3} key={index}>
            <Skeleton variant='rounded' height={70} animation='wave' />
          </Grid>
        ))}
      </Grid>
      <Grid
        container
        paddingTop='20px'
        width={'100%'}
        gap={1}
        justifyContent='flex-start'
      >
        <Grid item>
          <Skeleton variant='rounded' width='200px' height={30} />
        </Grid>
        <Grid item>
          <Skeleton
            variant='rounded'
            width='80px'
            height={30}
            sx={{ borderRadius: '20px' }}
          />
        </Grid>
        <Grid item>
          <Skeleton
            variant='rounded'
            width='100px'
            height={30}
            sx={{ borderRadius: '20px' }}
          />
        </Grid>
      </Grid>
      <Grid container paddingTop='12px'>
        <Grid item width='100%'>
          <Skeleton variant='rounded' height={300} />
        </Grid>
      </Grid>
    </Grid>
  );
};

DataValidationContentLoader.displayName = 'DataValidationContentLoader';
export default DataValidationContentLoader;
