import React, { useMemo } from 'react';
import {
  Box,
  IconButton,
  AddIcon,
  DeleteIcon,
  useTheme,
} from '@cherre-frontend/ui';
import { useCherreState } from '@cherre-frontend/data-fetching';
import { dataValidationRuleForm } from '../../recoil';
import StyledLabel from './Label';
import { StyledTextField } from './DefaultTextInput';

type Props = {
  title: string;
  placeholder: string;
  required?: boolean;
  fieldName: string;
};

const FormValueList: React.FC<Props> = ({
  title,
  placeholder,
  required,
  fieldName,
}) => {
  const [form, setForm] = useCherreState(dataValidationRuleForm);
  const theme = useTheme();

  const getIsAddDisabled = useMemo(() => {
    if (form) {
      return form[form.rule_type][fieldName].some((value) => value === '');
    }
    return false;
  }, [form]);

  const values: string[] = useMemo(() => {
    if (form) {
      return form[form.rule_type][fieldName];
    } else {
      return '';
    }
  }, [form]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <StyledLabel
        style={{ fontSize: '14px', fontWeight: 600 }}
        required={required}
      >
        {title}
      </StyledLabel>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        {values.map((value, index) => {
          return (
            <Box
              key={`value-container-${index}`}
              sx={{ display: 'flex', flexDirection: 'row' }}
            >
              <StyledTextField
                placeholder={placeholder}
                value={value}
                sx={{ width: '100%', height: '34px' }}
                onChange={(e) => {
                  if (form) {
                    setForm((oldState) => {
                      const currentArray = [
                        ...oldState[form.rule_type][fieldName],
                      ];
                      currentArray.splice(index, 1, e.target.value);
                      return {
                        ...oldState,
                        [form.rule_type]: {
                          ...oldState[form.rule_type],
                          [fieldName]: currentArray,
                        },
                      };
                    });
                  }
                }}
              />
              <IconButton
                sx={{ height: '34px' }}
                onClick={() => {
                  if (form) {
                    if (form[form.rule_type][fieldName].length === 1) {
                      setForm((oldState) => {
                        return {
                          ...oldState,
                          [form.rule_type]: {
                            [fieldName]: [''],
                          },
                        };
                      });
                    } else if (form[form.rule_type][fieldName].length > 1) {
                      setForm((oldState) => {
                        const currentArray = [
                          ...oldState[form.rule_type][fieldName],
                        ];
                        currentArray.splice(index, 1);
                        return {
                          ...oldState,
                          [form.rule_type]: {
                            ...oldState[form.rule_type],
                            [fieldName]: currentArray,
                          },
                        };
                      });
                    }
                  }
                }}
              >
                <DeleteIcon style={{ color: theme.palette.grey[500] }} />
              </IconButton>
            </Box>
          );
        })}
        <IconButton
          sx={{
            padding: '8px, 12px',
            backgroundColor: theme.palette.primary.main,
            borderRadius: '6px',
            width: '42px',
            '&.Mui-disabled': {
              backgroundColor: theme.palette.grey[400],
            },
          }}
          disabled={getIsAddDisabled}
          onClick={() => {
            if (form) {
              setForm((oldState) => {
                return {
                  ...oldState,
                  [form.rule_type]: {
                    ...oldState[form.rule_type],
                    [fieldName]: [...oldState[form.rule_type][fieldName], ''],
                  },
                };
              });
            }
          }}
        >
          <AddIcon style={{ color: 'white' }} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default FormValueList;
