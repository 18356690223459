import React from 'react';
import { Grid, useTheme } from '@cherre-frontend/ui';
import { useSelector } from 'react-redux';
import moment from 'moment';
import ActivityOverviewSection from './components/ActivityOverviewSection';
import ConnectorStatusSection from './components/ConnectorStatusSection';
import CherreSourceLibrarySection from './components/CherreSourceLibrarySection';
import TechnicalLandingPageHeader from './components/TechnicalLandingPageHeader';
import DataDeliveryScheduleSection from './components/DataDeliveryScheduleSection';
import { useConnectorLanding } from './hooks/useConnectorLanding';
import LandingPageSkeletonLoader from './components/LandingPageSkeletonLoader';
import { ConnectorsPageContainer } from '../../components/ConnectorsPageContainer';

const ConnectorsLandingPage = () => {
  const user = useSelector((state) => state.user.profile.value);

  const theme = useTheme();
  const {
    connectorStatuses,
    connectorActivityOverview,
    connectorSources,
    connectorSchedule,
  } = useConnectorLanding();

  if (
    connectorStatuses.state === 'loading' ||
    connectorActivityOverview.state === 'loading' ||
    connectorSources.state === 'loading' ||
    connectorSchedule.state === 'loading'
  ) {
    return <LandingPageSkeletonLoader />;
  }

  if (
    connectorStatuses.state === 'hasError' ||
    connectorActivityOverview.state === 'hasError' ||
    connectorSources.state === 'hasError' ||
    connectorSchedule.state === 'hasError'
  ) {
    return <h1>An Error Occurred</h1>;
  }

  return (
    <ConnectorsPageContainer>
      <Grid
        container
        direction='column'
        sx={{
          backgroundColor: theme.palette.background.default,
          minHeight: '100%',
          overflow: 'scroll',
        }}
      >
        <Grid container direction='column' padding='20px'>
          <TechnicalLandingPageHeader
            firstName={user.firstName ?? ''}
            lastSeen={moment().format('MMM D, YYYY')}
          />
          <Grid container direction='row' gap={4} flexWrap='nowrap'>
            <Grid item xs={8}>
              <Grid container paddingTop='36px' direction='column'>
                <Grid item>
                  <ConnectorStatusSection
                    connectorStatusData={connectorStatuses.contents.data}
                  />
                  <Grid item marginTop='40px'>
                    <ActivityOverviewSection
                      activities={connectorActivityOverview.contents.data}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container direction='column'>
                <Grid item>
                  <CherreSourceLibrarySection
                    sources={connectorSources.contents.data}
                  />
                </Grid>
                <Grid item marginTop='40px'>
                  <DataDeliveryScheduleSection
                    scheduleData={connectorSchedule.contents.data}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ConnectorsPageContainer>
  );
};

export default ConnectorsLandingPage;
