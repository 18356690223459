import moment from 'moment';
import { getScheduledRunDates } from 'src/products/connector/utils/cronScheduledRunDates';

export const getSourceRunDetails = (sources) => {
  if (!sources || sources.length === 0) {
    return { nextRun: '', lastRun: '' };
  }

  const runDates = sources.map((source) => {
    const { nextRun, lastRun } = getScheduledRunDates(source.schedule);
    return { nextRun, lastRun };
  });

  const nextRunDate = runDates.reduce((earliest, current) => {
    return moment(current.nextRun, 'MMM D YYYY h:mm A').isBefore(
      moment(earliest, 'MMM D YYYY h:mm A')
    )
      ? current.nextRun
      : earliest;
  }, runDates[0].nextRun);

  const lastRunDate = runDates.reduce((latest, current) => {
    return moment(current.lastRun, 'MMM D YYYY h:mm A').isAfter(
      moment(latest, 'MMM D YYYY h:mm A')
    )
      ? current.lastRun
      : latest;
  }, runDates[0].lastRun);

  return {
    nextRun: nextRunDate,
    lastRun: lastRunDate,
  };
};
