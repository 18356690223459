import React, { ReactElement } from 'react';
import { styled, Typography } from '@cherre-frontend/ui';
import { DESTINATIONS } from '../../mockData';
import DestinationCard from '../../../../../../components/Connectors/DestinationCard';
import { useDestinationUIState } from '../../uiState/contexts';

const Container = styled('div')`
  border-radius: 5px;
  background: #eee;
  padding: 30px;
  grid-template-columns: 1fr;
  display: grid;
`;

const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.primary};
  font-feature-settings: 'liga' off;

  /* Headings/H6 */
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const DestinationCarroussel = styled('div')`
  display: flex;
  overflow-x: auto;
  padding-top: 20px;
  gap: 10px;
`;

/**
 * DestinationSelector component allows users to choose a destination from a list of available destinations.
 *
 * This component fetchs the DESTINATIONS list and uses the `useDestinationUIState` hook
 * to get the currently selected destination. It then renders a list of `DestinationCard` components inside a `DestinationCarroussel`.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 */

const DestinationSelector: React.FC = (): ReactElement => {
  const [selectedDestination] = useDestinationUIState();

  return (
    <Container>
      <Title>Choose a destination:</Title>
      <DestinationCarroussel>
        {DESTINATIONS.map((destination) => (
          <DestinationCard
            key={destination.destinationName}
            image={destination.image}
            selected={
              destination.destinationName ===
              selectedDestination.destinationName
            }
          />
        ))}
      </DestinationCarroussel>
    </Container>
  );
};

export default DestinationSelector;
