import axios from 'axios';
import moment from 'moment';
import { RulesQueryDataRuleLibraryItem } from '../../../../queries/types';
import { determineFailedRecordCountText } from '../../../../../../../validation-details/utils/determineFailedRecordCountText';
import { buildRuleDetailStatus } from '../../../../../../../../utils/buildRuleDetailStatus';
import { RuleDetails } from 'src/products/connector/types/ruleTypes';
import { useEffect, useState } from 'react';

// check id: 126346
// table id: 8516
// data store id: 141

type RuleDetailsResponse = {
  anomalyID: number;
  lastRun: string;
  anomalousRecords: number;
  tableRecordCount: number;
  failedRowCountData: {
    id: string;
    date: string;
    failedRowCount: number;
  }[];
  failedColumns: string[];
  failedSourceRecords: any[];
};

export const useGetDataValidationResults = (
  ruleItem: RulesQueryDataRuleLibraryItem
) => {
  const rule = ruleItem.rule;

  if (!rule || !rule.checkId || !rule.datastoreId || !rule.containerId) {
    throw new Error('Rule does not have required fields');
  }

  const [data, setData] = useState<RuleDetailsResponse | null>(null);

  const ruleId = rule.checkId.toString();

  const urlParams = new URLSearchParams({
    tableID: rule.containerId.toString(),
    datastoreID: rule.datastoreId.toString(),
    lastRun: moment().toISOString(),
  });

  useEffect(() => {
    axios
      .get<{ data: RuleDetailsResponse }>(
        `/api/v1/qualytics/validationRule/${ruleId}?${urlParams.toString()}`
      )
      .then(({ data }) => setData(data.data))
      .catch(console.log);
  }, []);

  if (!data) {
    return null;
  }

  const failedRecordCount = determineFailedRecordCountText(
    {},
    data.tableRecordCount,
    data.anomalousRecords
  );

  let keys: string[] = [];
  const failedRecords = data.failedSourceRecords.map((record, idx) => ({
    ...record,
    _frontend_id: idx.toString(),
  }));

  if (Array.isArray(failedRecords) && failedRecords.length > 0) {
    keys = Object.keys(failedRecords[0]).filter(
      (key) =>
        ![
          'anomaly_uuid',
          '_qualytics_source_partition',
          '_frontend_id',
        ].includes(key)
    );
  }

  const ruleDetails: RuleDetails = {
    id: ruleId ?? '',
    anomalyID: data.anomalyID,
    lastRun: moment().format('MMM DD, YYYY h:mm A'),
    failedRecordCount: failedRecordCount,
    failedSourceRecords: {
      records: failedRecords ? failedRecords : [],
      columns: keys,
      failedColumns: data.failedColumns,
    },
    status: buildRuleDetailStatus(
      data.anomalousRecords,
      (ruleItem.status as 'Failed') || 'Failed'
    ),
    ruleName: ruleItem.displayName ?? '',
    tableRecordCount: data.tableRecordCount,
    canEdit: false,
    failedRowCountHistory: data.failedRowCountData,
    parameterValues: [],
    testDefinition: {
      description: '',
      type: '',
      name: '',
    },
    entityLink: '',
  };

  return ruleDetails;
};
