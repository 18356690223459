import React from 'react';
import {
  Box,
  Grid,
  useTheme,
  Typography,
  WarningIcon,
  Tooltip,
  WatchLaterIcon,
} from '@cherre-frontend/ui';
import ConnectorStatusChip from 'src/products/connector/components/ConnectorStatusChip';
import { Activity } from 'src/services/ConnectorDemo/data/ConnectorLanding';

type ActivityOverviewCardTypes = {
  activity: Activity;
  onClick: () => void;
  handleValidationRuleLink: (event: React.MouseEvent) => void;
};

const ActivityOverviewCard: React.FC<ActivityOverviewCardTypes> = ({
  activity,
  onClick,
  handleValidationRuleLink,
}) => {
  const theme = useTheme();
  const { isRunning, runningMessage } = activity.connectorRunningObject;
  return (
    <Grid
      container
      style={{
        backgroundColor: isRunning
          ? theme.palette.warning.light
          : theme.palette.grey[50],
        borderRadius: '10px',
        width: '100%',
        cursor: 'pointer',
        position: 'relative',
      }}
      sx={{ gap: '4px' }}
      onClick={onClick}
      alignItems='center'
    >
      <Grid item width='100%'>
        {isRunning && (
          <Grid
            display='flex'
            width='100%'
            justifyContent='flex-end'
            position='absolute'
            padding='8px 8px 0px 10px'
          >
            <Tooltip
              title={runningMessage}
              enterDelay={200}
              style={{ cursor: 'initial' }}
              componentsProps={{
                tooltip: {
                  sx: {
                    maxWidth: 'none',
                  },
                },
              }}
            >
              <WatchLaterIcon
                sx={{
                  color: theme.palette.warning.dark,
                  width: '16px',
                  height: '16px',
                }}
              />
            </Tooltip>
          </Grid>
        )}
        <Grid
          container
          direction='row'
          padding='20px'
          alignItems='center'
          width='100%'
        >
          <Grid item xs={3} display='flex' justifyContent='flex-start'>
            <Typography fontSize='14px' fontWeight='medium' noWrap>
              {activity.activityName}
            </Typography>
          </Grid>

          <Grid item xs={3} display='flex' justifyContent='flex-start'>
            <ConnectorStatusChip
              status={activity.connectorStatus}
              statusMessage={activity.connectorStatusMessage}
            />
          </Grid>

          <Grid item xs={3} display='flex' justifyContent='flex-start'>
            {activity.lastRun && (
              <Typography fontSize='12px' fontWeight='medium' noWrap>
                Last Run: {activity.lastRun}
              </Typography>
            )}
          </Grid>

          {activity.numFailedValidationChecks !== undefined &&
            activity.numFailedValidationChecks > 0 && (
              <Grid item xs={3} display='flex' justifyContent='flex-start'>
                <Box display='flex' alignItems='center'>
                  <WarningIcon
                    style={{
                      color: theme.palette.warning.dark,
                      width: 20,
                      height: 20,
                      marginRight: '4px',
                    }}
                  />
                  <Typography
                    fontSize='12px'
                    fontWeight='medium'
                    noWrap
                    onClick={(event) => handleValidationRuleLink(event)}
                    sx={{
                      cursor: 'pointer',
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    {activity.numFailedValidationChecks > 1
                      ? `${activity.numFailedValidationChecks} Failed Validation Checks`
                      : `${activity.numFailedValidationChecks} Failed Validation Check`}
                  </Typography>
                </Box>
              </Grid>
            )}

          {activity.frequency && (
            <Grid item xs={1}>
              <Typography fontSize='12px' fontWeight='medium'>
                {activity.frequency}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

ActivityOverviewCard.displayName = 'ConnectorActivityOverviewCard';
export default ActivityOverviewCard;
