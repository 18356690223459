import React from 'react';
import { Box, Typography, useTheme, styled } from '@cherre-frontend/ui';
import { OverviewCardType, OverviewType } from './OverviewCard';
import ConnectorCardLogo from './ConnectorCardLogo';

type Props = {
  card: OverviewCardType<OverviewType.SOURCE | OverviewType.DESTINATION>;
};

const TextField = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey[600]};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ConnectorCard: React.FC<Props> = ({ card }) => {
  const theme = useTheme();

  return (
    <Box
      style={{
        backgroundColor:
          card.type === OverviewType.SOURCE && card.statusObject?.isRunning
            ? theme.palette.warning.light
            : 'white',
        borderRadius: '5px',
        padding: '10px 20px',
        marginBottom: '30px',
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '8px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <ConnectorCardLogo provider={card.provider} />
          <Typography
            variant='h5'
            sx={{ color: theme.palette.grey[600], fontWeight: 700 }}
          >
            {card.displayName ?? card.name}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'grid' }}>
        <TextField title={card.revision}>
          <b>Revision:</b> {card.revision}
        </TextField>
        <TextField title={card.created}>
          <b>Created:</b> {card.created}
        </TextField>
        <TextField title={card.creator}>
          <b>Creator:</b> {card.creator}
        </TextField>
        {card.sourceAvailable && (
          <TextField title={card.sourceAvailable}>
            <b>Source Available:</b> {card.sourceAvailable}
          </TextField>
        )}
        {card.frequency && (
          <TextField title={card.frequency}>
            <b>Frequency:</b> {card.frequency}
          </TextField>
        )}
        {card.runTime && (
          <TextField title={card.runTime}>
            <b>Run Time:</b> {card.runTime}
          </TextField>
        )}
      </Box>
    </Box>
  );
};

export default ConnectorCard;
