// Define the tuple for external object types and derive the union type.
export const externalObjectTypes = ['number', 'array', 'string'] as const;
export type ExternalObjectType = (typeof externalObjectTypes)[number];

// Define the tuple for parameter names and derive the union type.
export const parameterNames = [
  'coverage',
  'fieldsArray',
  'ruleType',
  'tableRef',
  'field',
  'filter',
  'inclusive_max',
  'inclusive_min',
  'max',
  'min',
  'expression',
  'list',
  'value',
  'inclusive',
  'field_name',
] as const;

export type ParameterName = (typeof parameterNames)[number];

// Create a mapping from parameter names to human-readable labels.
export const parameterNameLabels: Record<ParameterName, string> = {
  coverage: 'Coverage',
  fieldsArray: 'Fields',
  field: 'Field',
  ruleType: 'Rule Type',
  tableRef: 'Table Reference',
  filter: 'Filter Clause',
  inclusive_max: 'Inclusive Max',
  inclusive_min: 'Inclusive Min',
  max: 'Max',
  min: 'Min',
  inclusive: 'Inclusive',
  expression: 'Expression',
  list: 'List',
  value: 'Value',
  field_name: 'Field Name',
};
