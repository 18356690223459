import React, { useState, useRef, useEffect } from 'react';
import {
  MenuItem,
  SelectChangeEvent,
  Select,
  OutlinedInput,
} from '@cherre-frontend/ui';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import CustomDateRangePicker from '../../../../components/CustomDateRangePicker';
import { Range, RangeKeyDict } from 'react-date-range';
import moment from 'moment';

type DateRangeSelectProps = {
  onChange: (event: SelectChangeEvent<string>) => void;
  value: string;
  onDateRangeChange: (newRange: Range) => void;
  customDateRange: Range[];
};

const DateRangeSelect: React.FC<DateRangeSelectProps> = ({
  onChange,
  value,
  onDateRangeChange,
}) => {
  const [showPicker, setShowPicker] = useState(false);
  const [localRange, setLocalRange] = useState<Range[]>([
    {
      startDate: moment().subtract(Number(value), 'days').toDate(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutsidePicker(event: MouseEvent) {
      const isOutsideWrapper =
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node);

      if (isOutsideWrapper) {
        setShowPicker(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutsidePicker);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsidePicker);
    };
  }, []);

  const handleCustomDateRangeChange = (newRange: RangeKeyDict) => {
    setLocalRange([newRange.selection]);
  };

  const handleApplyDateRange = (newRange: Range) => {
    onDateRangeChange(newRange);
    setShowPicker(false);
  };

  return (
    <div style={{ position: 'relative' }} ref={wrapperRef}>
      <Select
        size='small'
        fullWidth
        label={value}
        value={value}
        variant='outlined'
        onChange={onChange}
        input={<OutlinedInput notched={false} />}
        renderValue={(selectedValue) => {
          switch (selectedValue) {
            case '1':
              selectedValue = 'Yesterday';
              break;
            case '3':
              selectedValue = 'Last 3 Days';
              break;
            case '7':
              selectedValue = 'Last 7 Days';
              break;
            case '14':
              selectedValue = 'Last 14 Days';
              break;
            case '30':
              selectedValue = 'Last 30 Days';
              break;
            case '60':
              selectedValue = 'Last 60 Days';
              break;
            case 'custom':
              selectedValue = 'Custom Range';
              break;
          }
          return selectedValue;
        }}
      >
        <MenuItem value='1'>Yesterday</MenuItem>
        <MenuItem value='3'>Last 3 days</MenuItem>
        <MenuItem value='7'>Last 7 days</MenuItem>
        <MenuItem value='14'>Last 14 days</MenuItem>
        <MenuItem value='30'>Last 30 days</MenuItem>
        <MenuItem value='60'>Last 60 days</MenuItem>
        <MenuItem
          value='custom'
          data-value='custom'
          onClick={(e) => {
            setShowPicker(true);
            e.stopPropagation();
          }}
        >
          Custom Range
        </MenuItem>
      </Select>

      {showPicker && (
        <div
          ref={wrapperRef}
          style={{
            position: 'absolute',
            top: '-175px',
            left: 'auto',
            right: '155px',
            zIndex: 1000,
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <CustomDateRangePicker
            ranges={localRange}
            onChange={(item) => handleCustomDateRangeChange(item)}
            handleApplyDateDange={() => handleApplyDateRange(localRange[0])}
          />
        </div>
      )}
    </div>
  );
};

DateRangeSelect.displayName = 'DateRangeSelect';
export default DateRangeSelect;
