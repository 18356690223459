import { ConnectorStatus } from 'src/products/connector/recoil';
import { Activity } from 'src/services/ConnectorDemo/data/ConnectorLanding';

/**
 * Sorts an array of `Activity` objects based on specific criteria:
 *
 * 1. Activities with a `connectorStatus` of `ConnectorStatus.FAILED` are prioritized.
 * 2. Among activities with the same `connectorStatus`, those with a non-zero `numFailedValidationChecks` are prioritized.
 * 3. If both `connectorStatus` and `numFailedValidationChecks` are the same, activities are sorted by their `lastRun` date in descending order (most recent first).
 * 4. If none of the above criteria apply, the order remains unchanged.
 *
 * @param a - The first `Activity` object to compare.
 * @param b - The second `Activity` object to compare.
 * @returns A negative number if `a` should come before `b`, a positive number if `b` should come before `a`, or `0` if their order is equal.
 */
export const sortActivities = (a: Activity, b: Activity) => {
  // sort by failed connectors first
  if (
    a.connectorStatus === ConnectorStatus.FAILED &&
    b.connectorStatus !== ConnectorStatus.FAILED
  ) {
    return -1;
  }
  if (
    a.connectorStatus !== ConnectorStatus.FAILED &&
    b.connectorStatus === ConnectorStatus.FAILED
  ) {
    return 1;
  }
  //sort by failed validation checks next
  if (a.numFailedValidationChecks && !b.numFailedValidationChecks) {
    return -1;
  }
  if (!a.numFailedValidationChecks && b.numFailedValidationChecks) {
    return 1;
  }
  // sort by last run date
  if (a.lastRun && b.lastRun) {
    return new Date(b.lastRun).getTime() - new Date(a.lastRun).getTime();
  }
  return 0;
};
