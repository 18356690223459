import { useCherreValueLoadable } from '@cherre-frontend/data-fetching';
import axios from 'axios';
import { useEffect, useMemo, useState } from 'react';
import {
  ConnectorEnvironments,
  useConnectorEnvironment,
} from 'src/hooks/useConnectorEnvironment';
import { StandardizedConnector } from 'src/products/connector/types/connectorTypes';
import { connectorInventoryDemo } from 'src/services/ConnectorDemo/recoil';
import { connectorInventory } from 'src/services/DOM/connectorInventory/recoil';
import { orderConnectorList } from '../utils/orderConnectorList';
import { enhanceDraftConnectorList } from '../utils/enhanceDraftConnectorList';

export const useConnectorInventory = (): {
  loading: boolean;
  error: boolean;
  data: StandardizedConnector[];
} => {
  const environment = useConnectorEnvironment();
  const mvpEnv = environment === ConnectorEnvironments.MVP;
  const sourceData = mvpEnv ? connectorInventory : connectorInventoryDemo;
  const [isLoading, setIsLoading] = useState(mvpEnv);
  const [draftConnectorList, setDraftConnectorList] = useState([]);

  useEffect(() => {
    if (!mvpEnv) {
      return;
    }
    setIsLoading(true);
    axios
      .get('/api/v1/dom/draft/list')
      .then((result) => {
        setDraftConnectorList(result.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [mvpEnv]);

  const connectors = useCherreValueLoadable(sourceData);
  const connectorsList = useMemo(
    () => connectors.contents.data || [],
    [connectors]
  );
  const mappedDraftConnectorList = useMemo(
    () => enhanceDraftConnectorList(draftConnectorList),
    [draftConnectorList]
  );
  const data = useMemo(
    () => orderConnectorList(mappedDraftConnectorList, connectorsList),
    [connectorsList, draftConnectorList]
  );

  return {
    data,
    loading: connectors.state === 'loading' || isLoading,
    error: connectors.state === 'hasError' && draftConnectorList.length === 0,
  };
};
