import moment from 'moment';
import cronParser from 'cron-parser';
import { printLocalDatetimeWithTimezone } from 'src/products/connector/utils/printLocalDatetimeWithTimezone';

export const getNextScheduledRun = (
  cronExpression: string,
  startDate: Date
): Date => {
  const interval = cronParser.parseExpression(cronExpression, {
    currentDate: startDate,
  });
  return interval.next().toDate();
};

export const generateDataDeliverySchedule = (
  cronExpression: string,
  occurrences = 20,
  startDate = new Date()
) => {
  const interval = cronParser.parseExpression(cronExpression, {
    currentDate: startDate,
  });
  const nextOccurrences: Date[] = [];

  const occurrenceMap: Record<string, Date[]> = {};
  // Fetch 20 days worth of occurrences starting with 10 days before current date and 10 days after
  while (nextOccurrences.length < occurrences) {
    const nextOccurrence = interval.next().toDate();
    const dayKey = moment(nextOccurrence).format('YYYY-MM-DD');

    // Some schedules can have runs as often as every 10 minutes but we only need at least one occurrence for each day
    if (!occurrenceMap[dayKey]) {
      occurrenceMap[dayKey] = [];
      nextOccurrences.push(nextOccurrence); // Add the first occurrence for the day
    } else if (occurrenceMap[dayKey].length < 1) {
      occurrenceMap[dayKey].push(nextOccurrence);
    }
  }

  const formattedDates = nextOccurrences.map((date) =>
    printLocalDatetimeWithTimezone('MMM D h:mm A', date)
  );
  const shortDates = nextOccurrences.map((date) =>
    moment(date).format('MMM D')
  );

  return {
    nextOccurrences,
    formattedDates,
    shortDates,
  };
};
