import React, { useMemo } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LabelList,
} from 'recharts';

import { format } from 'date-fns';
import { TableProfileItem } from 'src/services/OpenMetadata/types';
import { Box, Typography, useTheme } from '@cherre-frontend/ui';
import { formatNumbers } from './utils/formatNumbers';
import { calculateThirtyDayAverageVolume } from './utils/calculateThirtyDayAverage';

type DataVolumeChartTypes = {
  data: TableProfileItem[];
  thirtyDayData: TableProfileItem[];
};

enum AxisKeys {
  ROW_COUNT = 'rowCount',
  TIMESTAMP = 'timestamp',
}

const DataVolumeChart: React.FC<DataVolumeChartTypes> = ({
  data,
  thirtyDayData,
}) => {
  const dataVolumeData = [...data];
  const sortedData = dataVolumeData.sort((a, b) => a.timestamp - b.timestamp);
  const theme = useTheme();

  const formatTooltipVolume = (value) => {
    return value.toLocaleString();
  };

  const formatTooltipTimestampValue = (value) => {
    return format(new Date(value), 'MMM dd, HH:mm');
  };
  const formatXAxisTimestampValue = (value) => {
    return format(new Date(value), 'MMM dd');
  };

  const thirtyDayAverage = useMemo(() => {
    return calculateThirtyDayAverageVolume(thirtyDayData);
  }, [data]);

  const dataWithPercentageChange = useMemo(() => {
    return sortedData.map((item, index) => {
      if (index === 0) {
        return { ...item, change: '0.0' };
      }

      const previous = sortedData[index - 1].rowCount;
      const current = item.rowCount;

      // Handle the case where previous is 0
      let change;
      if (previous === 0) {
        change = current > 0 ? 100 : 0; // 100% increase if going from 0 to a bigger number, or 0% if both are 0
      }
      if (previous !== 0) {
        change = ((current - previous) / previous) * 100;
      }

      return {
        ...item,
        change: change === 0 ? '0.0' : change.toFixed(1).toString(),
      };
    });
  }, [sortedData]);

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        padding='10px'
        borderRadius='10px'
        sx={{
          border: '1px solid',
          backgroundColor: theme.palette.common.white,
          borderColor: theme.palette.grey[200],
          width: 'auto',
          height: '40px',
          position: 'absolute',
          top: '20px',
          left: '70px',
          zIndex: 99999999,
        }}
      >
        <Typography fontSize='12px' fontWeight='medium'>
          30 Day Average: {thirtyDayAverage}
        </Typography>
      </Box>
      <ResponsiveContainer
        width='100%'
        height={300}
        style={{ position: 'relative' }}
        data-testid='chart-svg-container'
      >
        <LineChart
          width={500}
          height={300}
          data={dataWithPercentageChange}
          margin={{
            top: 5,
            right: 30,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray='3 3' vertical={false} />
          <XAxis
            dataKey={AxisKeys.TIMESTAMP}
            axisLine={false}
            tickLine={false}
            padding={{ left: 20, right: 20 }}
            tickFormatter={formatXAxisTimestampValue}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            allowDataOverflow
            tickCount={8}
            domain={[0, (dataMax) => dataMax * 2]}
            tickFormatter={formatNumbers}
            interval='preserveStartEnd'
          />
          <Tooltip
            formatter={formatTooltipVolume}
            labelFormatter={formatTooltipTimestampValue}
          />
          <Line
            type='monotone'
            dataKey={AxisKeys.ROW_COUNT}
            name='Row Count'
            stroke='#FF6294'
            activeDot={{
              r: 3,
              stroke: '#DF2467',
              fill: '#DF2467',
            }}
            dot={{ stroke: '#DF2467', strokeWidth: 1, fill: '#DF2467' }}
          >
            {/* LabelList expects the contents to be an SVG so we have to build it manually */}
            <LabelList
              dataKey='change'
              position='top'
              content={({ x, y, value }) =>
                value !== '0.0' ? (
                  <g
                    transform={`translate(${x}, ${
                      parseFloat(y as string) -
                      (parseFloat(value as string) > 0 ? 20 : -20)
                    })`}
                  >
                    <rect
                      x={-25}
                      y={-15}
                      width={50}
                      height={30}
                      fill={
                        parseFloat(value as string) > 0
                          ? theme.palette.success.light
                          : theme.palette.error.light
                      }
                      rx={5}
                      ry={5}
                    />
                    <text
                      x={0}
                      y={0}
                      dy={5}
                      fill={
                        parseFloat(value as string) > 0
                          ? theme.palette.success.dark
                          : theme.palette.error.dark
                      }
                      textAnchor='middle'
                      fontSize={12}
                    >
                      {parseFloat(value as string) > 0
                        ? `+${value}%`
                        : `-${value}%`}
                    </text>
                  </g>
                ) : null
              }
            />
          </Line>
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

DataVolumeChart.displayName = 'DataVolumeChart';
export default DataVolumeChart;
