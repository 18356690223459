import {
  styled,
  TextField,
  Typography,
  Select,
  TimePickerInput,
} from '@cherre-frontend/ui';

export const Container = styled('div')`
  border-radius: 5px;
  background: #eee;
  padding: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const Title = styled(Typography)`
  grid-area: title;
  color: ${({ theme }) => theme.palette.text.primary};
  font-feature-settings: 'liga' off;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const Description = styled(Typography)`
  grid-area: description;
  color: ${({ theme }) => theme.palette.text.primary};

  /* Body/Normal */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17.5px; /* 125% */
  letter-spacing: 0.14px;
`;

export const Or = styled(Typography)`
  grid-area: or;
  color: ${({ theme }) => theme.palette.text.primary};
  font-feature-settings: 'liga' off;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
`;

export const FieldBox = styled('div')`
  display: grid;
  margin-bottom: 24px;
  gap: 8px;
`;

export const FieldTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.primary};
  font-feature-settings: 'liga' off;

  /* Headings/H6 */
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const FieldDescription = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey[900]};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.5px; /* 145.833% */
  letter-spacing: 0.12px;
`;

export const FieldInput = styled(TextField)`
  max-width: 430px;
  height: 34px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.palette.grey[500]};
  background: ${({ theme }) => theme.palette.grey[50]};
  button {
    padding: 6px;
    color: ${({ theme }) => theme.palette.primary.main};
    svg {
      font-size: 20px;
    }
  }
  input {
    height: 15px;
    border-radius: 6px;
    padding: 8.5px 14px;
  }
  fieldset {
    border: none;
  }

  p {
    color: #e53935;
    font-weight: 500;
    font-size: 14px;
    line-height: 17.5px;
    letter-spacing: 1%;
    margin-top: 8px;
  }
`;

export const FieldTimePicker = styled(TimePickerInput)`
  max-width: 430px;
  height: 34px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.palette.grey[500]};
  background: ${({ theme }) => theme.palette.grey[50]};
  input {
    height: 15px;
    border-radius: 6px;
    border: none;
    padding: 8.5px 14px;
  }
  fieldset {
    border: none;
  }
`;

export const FieldSelect = styled(Select)`
  max-width: 430px;
  height: 34px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.palette.grey[500]};
  background: ${({ theme }) => theme.palette.grey[50]};
  fieldset {
    border: none;
  }
`;

export const Actions = styled('div')`
  display: flex;
  justify-content: flex-end;
  grid-area: actions;
  flex-direction: row;
  gap: 10px;
`;
