import { useMemo } from 'react';
import { useCherreState } from '@cherre-frontend/data-fetching';
import { dataValidationRuleForm } from '../../../recoil';
import { FormInputs, FormUI, RuleTypes } from './types';

const useRuleUI = () => {
  const [form] = useCherreState(dataValidationRuleForm);

  const ruleUI: FormUI[] = useMemo(() => {
    switch (form?.rule_type) {
      case RuleTypes.COLUMN_VALUES_TO_BE_NOT_NULL:
      case RuleTypes.COLUMN_VALUES_TO_BE_UNIQUE:
        return [];
      case RuleTypes.COLUMN_VALUES_TBB:
      case RuleTypes.TABLE_ROW_COUNT_TBB:
        return [
          {
            title: 'Min',
            type: FormInputs.TEXT,
            placeholder: 'Enter a min',
            fieldName: 'minValue',
            numeric: true,
          },
          {
            title: 'Max',
            type: FormInputs.TEXT,
            placeholder: 'Enter a max',
            fieldName: 'maxValue',
            numeric: true,
          },
        ];
      case RuleTypes.COLUMN_VALUE_STD_DEV_TBB:
        return [
          {
            title: 'Min',
            type: FormInputs.TEXT,
            placeholder: 'Enter a min',
            fieldName: 'minValueForStdDevInCol',
            numeric: true,
          },
          {
            title: 'Max',
            type: FormInputs.TEXT,
            placeholder: 'Enter a max',
            fieldName: 'maxValueForStdDevInCol',
            numeric: true,
          },
        ];
      case RuleTypes.COLUMN_VALUE_LENGTHS_TBB:
        return [
          {
            title: 'Min',
            type: FormInputs.TEXT,
            placeholder: 'Enter a min',
            fieldName: 'minLength',
            numeric: true,
          },
          {
            title: 'Max',
            type: FormInputs.TEXT,
            placeholder: 'Enter a max',
            fieldName: 'maxLength',
            numeric: true,
          },
        ];
      case RuleTypes.COLUMN_VALUE_MAX_TBB:
        return [
          {
            title: 'Min',
            type: FormInputs.TEXT,
            placeholder: 'Enter a min',
            fieldName: 'minValueForMaxInCol',
            numeric: true,
          },
          {
            title: 'Max',
            type: FormInputs.TEXT,
            placeholder: 'Enter a max',
            fieldName: 'maxValueForMaxInCol',
            numeric: true,
          },
        ];
      case RuleTypes.COLUMN_VALUE_MIN_TBB:
        return [
          {
            title: 'Min',
            type: FormInputs.TEXT,
            placeholder: 'Enter a min',
            fieldName: 'minValueForMinInCol',
            numeric: true,
          },
          {
            title: 'Max',
            type: FormInputs.TEXT,
            placeholder: 'Enter a max',
            fieldName: 'maxValueForMinInCol',
            numeric: true,
          },
        ];
      case RuleTypes.COLUMN_VALUE_MEAN_TBB:
        return [
          {
            title: 'Min',
            type: FormInputs.TEXT,
            placeholder: 'Enter a min',
            fieldName: 'minValueForMeanInCol',
            numeric: true,
          },
          {
            title: 'Max',
            type: FormInputs.TEXT,
            placeholder: 'Enter a max',
            fieldName: 'maxValueForMeanInCol',
            numeric: true,
          },
        ];
      case RuleTypes.COLUMN_VALUE_MEDIAN_TBB:
        return [
          {
            title: 'Min',
            type: FormInputs.TEXT,
            placeholder: 'Enter a min',
            fieldName: 'minValueForMedianInCol',
            numeric: true,
          },
          {
            title: 'Max',
            type: FormInputs.TEXT,
            placeholder: 'Enter a max',
            fieldName: 'maxValueForMedianInCol',
            numeric: true,
          },
        ];
      case RuleTypes.COLUMN_VALUES_SUM_TBB:
        return [
          {
            title: 'Min',
            type: FormInputs.TEXT,
            placeholder: 'Enter a min',
            fieldName: 'minValueForColSum',
            numeric: true,
          },
          {
            title: 'Max',
            type: FormInputs.TEXT,
            placeholder: 'Enter a max',
            fieldName: 'maxValueForColSum',
            numeric: true,
          },
        ];
      case RuleTypes.TABLE_COLUMN_COUNT_TBB:
        return [
          {
            title: 'Min',
            type: FormInputs.TEXT,
            placeholder: 'Enter a min',
            fieldName: 'minColValue',
            numeric: true,
          },
          {
            title: 'Max',
            type: FormInputs.TEXT,
            placeholder: 'Enter a max',
            fieldName: 'maxColValue',
            numeric: true,
          },
        ];
      case RuleTypes.COLUMN_VALUES_MISSING_COUNT:
        return [
          {
            title: 'Missing Count',
            type: FormInputs.TEXT,
            required: true,
            placeholder: 'Enter a missing count',
            fieldName: 'missingCountValue',
            numeric: true,
          },
          {
            title: 'Missing Value To Match',
            type: FormInputs.TEXT,
            placeholder: 'Enter a missing value to match',
            fieldName: 'missingValueMatch',
          },
        ];
      case RuleTypes.COLUMN_VALUES_TO_MATCH_REGEX:
        return [
          {
            title: 'RegEx Pattern',
            type: FormInputs.TEXT,
            placeholder: 'Enter a RegEx Pattern',
            fieldName: 'regex',
          },
        ];
      case RuleTypes.COLUMN_VALUES_TO_NOT_MATCH_REGEX:
        return [
          {
            title: 'RegEx Pattern',
            type: FormInputs.TEXT,
            placeholder: 'Enter a RegEx Pattern',
            fieldName: 'forbiddenRegex',
          },
        ];
      case RuleTypes.COLUMN_VALUES_TO_BE_IN_SET:
        return [
          {
            title: 'Allowed Values',
            type: FormInputs.VALUE_LIST,
            placeholder: 'Enter allowed value',
            fieldName: 'allowedValues',
          },
        ];
      case RuleTypes.COLUMN_VALUES_TO_BE_NOT_IN_SET:
        return [
          {
            title: 'Forbidden Values',
            type: FormInputs.VALUE_LIST,
            placeholder: 'Enter forbidden value',
            fieldName: 'forbiddenValues',
          },
        ];
      case RuleTypes.TABLE_ROW_COUNT_TO_EQUAL:
        return [
          {
            title: 'Count',
            type: FormInputs.TEXT,
            placeholder: 'Enter a count',
            required: true,
            fieldName: 'value',
            numeric: true,
          },
        ];
      case RuleTypes.TABLE_COLUMN_COUNT_TO_EQUAL:
        return [
          {
            title: 'Count',
            type: FormInputs.TEXT,
            placeholder: 'Enter a count',
            required: true,
            fieldName: 'columnCount',
            numeric: true,
          },
        ];
      case RuleTypes.TABLE_COLUMN_NAME_TO_EXIST:
        return [
          {
            title: 'Column Name',
            type: FormInputs.TEXT,
            placeholder: 'Enter a column name',
            required: true,
            fieldName: 'columnName',
          },
        ];
      case RuleTypes.TABLE_COLUMN_TO_MATCH_SET:
        return [
          {
            title: 'Column Names',
            type: FormInputs.TEXT,
            placeholder: 'Enter column names',
            required: true,
            fieldName: 'columnNames',
          },
          {
            title: 'Ordered',
            type: FormInputs.SWITCH,
            fieldName: 'ordered',
          },
        ];
      case RuleTypes.TABLE_ROW_INSERTED_COUNT_TBB:
        return [
          {
            title: 'Min Row Count',
            type: FormInputs.TEXT,
            placeholder: 'Enter a min row count',
            fieldName: 'min_row_count',
            numeric: true,
          },
          {
            title: 'Max Row Count',
            type: FormInputs.TEXT,
            placeholder: 'Enter a max row count',
            fieldName: 'max_row_count',
            numeric: true,
          },
          {
            title: 'Column Name',
            type: FormInputs.SELECT,
            menuOptions: [
              { label: 'Column 1', value: 'column 1' },
              { label: 'Column 2', value: 'column 2' },
              { label: 'Column 3', value: 'column 3' },
              { label: 'Column 4', value: 'column 4' },
            ],
            fieldName: 'column_name',
          },
          {
            title: 'Range Type',
            type: FormInputs.TEXT,
            placeholder: 'Enter a range type',
            required: true,
            fieldName: 'range_type',
          },
          {
            title: 'Interval',
            type: FormInputs.TEXT,
            placeholder: 'Enter an interval',
            required: true,
            fieldName: 'interval',
          },
        ];
      case RuleTypes.TABLE_CUSTOM_SQL_QUERY:
        return [
          {
            title: 'SQL Expression',
            type: FormInputs.TEXT,
            placeholder: 'Enter SQL expression',
            multiline: true,
            fieldName: 'sqlExpression',
          },
          {
            title: 'Strategy',
            type: FormInputs.SELECT,
            menuOptions: [
              { label: 'Rows', value: 'rows' },
              { label: 'Columns', value: 'columns' },
              { label: 'Count', value: 'COUNT' },
            ],
            fieldName: 'strategy',
          },
          {
            title: 'Threshold',
            type: FormInputs.TEXT,
            placeholder: 'Enter a threshold',
            fieldName: 'threshold',
            numeric: true,
          },
        ];
      default:
        return [];
    }
  }, [form]);

  return ruleUI;
};

export default useRuleUI;
