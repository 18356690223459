import { Source } from 'src/services/DOM/types/connectorTypes';
import { getNextScheduledRun } from './generateDataDeliverySchedule';

export const getEarliestSourceRun = (sources: Source[]): Source | null => {
  const earliestSourceRun = sources.reduce<Source | null>(
    (earliest, source) => {
      if (!earliest) {
        return source;
      }

      const nextRun = getNextScheduledRun(source.schedule, new Date());
      const earliestNextRun = getNextScheduledRun(
        earliest.schedule,
        new Date()
      );

      return nextRun < earliestNextRun ? source : earliest;
    },
    null
  );

  return earliestSourceRun;
};
