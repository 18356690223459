import React, { useMemo } from 'react';
import { useCherreState } from '@cherre-frontend/data-fetching';
import { Box } from '@cherre-frontend/ui';
import ConnectorButton from 'src/products/connector/pages/connector-details/components/Overview/ConnectorButton';
import { dataValidationRuleForm } from '../../recoil';
import useRuleUI from './utils/useRuleUI';
import { DataValidationState, RuleTypes } from './utils/types';
import RuleUI from './RuleUI';
import { schema } from './utils/validation';
import NotificationFormInputs from './NotificationFormInputs';
import DefaultEditFormInputs from './DefaultEditFormInputs';
import { usePermission } from 'src/hooks';
import { validationDetailsDataValidationState } from '../../../validation-details/recoil';
import {
  ConnectorEnvironments,
  useConnectorEnvironment,
} from 'src/hooks/useConnectorEnvironment';

type EditValidationRuleFormTypes = {
  handleDelete: () => void;
  canEdit: boolean;
};

const EditValidationRuleForm: React.FC<EditValidationRuleFormTypes> = ({
  handleDelete,
  canEdit,
}) => {
  const [dataValidationState] = useCherreState(
    validationDetailsDataValidationState
  );

  const environment = useConnectorEnvironment();

  const [form] = useCherreState(dataValidationRuleForm);
  const ruleUI = useRuleUI();
  const isCherreAdmin = usePermission('CherreAdmin');

  const isValid = useMemo(() => {
    if (form) {
      const currentValue = {
        name: form.name,
        testDefinition: form.rule_type,
        ...(dataValidationState === DataValidationState.COLUMN && {
          column: form.column,
        }),
        parameterValues:
          form.rule_type !== RuleTypes.COLUMN_VALUES_TO_BE_NOT_NULL &&
          form.rule_type !== RuleTypes.COLUMN_VALUES_TO_BE_UNIQUE &&
          form.rule_type !== ''
            ? [
                ...Object.keys(form[form?.rule_type]).map((field) => ({
                  name: field,
                  value: form[form.rule_type][field],
                })),
              ]
            : [],
      };
      return schema.safeParse(currentValue).success;
    }
    return false;
  }, [form]);

  return (
    <Box sx={{ paddingLeft: '16px', paddingTop: '32px' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '36px',
          marginTop: '40px',
          width: '100%',
        }}
      >
        <DefaultEditFormInputs>
          <RuleUI ruleUI={ruleUI} />
        </DefaultEditFormInputs>
        <NotificationFormInputs />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '16px',
            justifyContent: 'flex-end',
            paddingBottom: '24px',
          }}
        >
          <ConnectorButton
            variant='text'
            onClick={handleDelete}
            disabled={
              (!isCherreAdmin && !canEdit) ||
              environment === ConnectorEnvironments.MVP
            }
          >
            Delete Rule
          </ConnectorButton>
          <ConnectorButton
            variant='contained'
            disabled={!isValid || environment === ConnectorEnvironments.MVP}
          >
            Update Rule
          </ConnectorButton>
        </Box>
      </Box>
    </Box>
  );
};

export default EditValidationRuleForm;
