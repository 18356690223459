import { z } from 'zod';

//SOURCES

/**
 * Schema for validating availability data.
 *
 * This schema ensures that the availability data includes:
 * - `hour`: An integer between 0 and 23.
 * - `minute`: An integer between 0 and 59.
 * - `timezone`: A non-empty string representing the timezone.
 */
export const AvailabilitySchema = z.object({
  hour: z.number().int().min(0).max(23),
  minute: z.number().int().min(0).max(59),
  timezone: z.string().min(1),
});

/**
 * Schema for validating an object with a daily update frequency.
 *
 * This schema ensures that the `type` property is a literal string 'daily'.
 */
export const UpdateFrequencyDailySchema = z.object({
  type: z.literal('daily'),
});

/**
 * Schema for validating an update frequency that occurs only on weekdays.
 *
 * This schema ensures that the `type` property is strictly set to the literal value 'weekdays'.
 */
export const UpdateFrequencyWeekdaysOnly = z.object({
  type: z.literal('weekdays'),
});

/**
 * Schema for validating the update frequency when it is set to weekly.
 *
 * @property {string} type - The type of update frequency, which must be 'weekly'.
 * @property {string} day - The day of the week for the update, represented as a string.
 *                          Must be a string with a minimum value of 0 and a maximum value of 6.
 */
export const UpdateFrequencyWeeklySchema = z.object({
  type: z.literal('weekly'),
  day: z.number().int().min(0).max(6),
});

/**
 * Schema for validating the update frequency when it is set to monthly.
 *
 * Properties:
 * - `type`: A literal string 'monthly' indicating the type of update frequency.
 * - `day`: A number representing the day of the month, which must be an integer between 1 and 31.
 */
const UpdateFrequencyMonthlySchema = z.object({
  type: z.literal('monthly'),
  day: z.number().int().min(1).max(31),
});

/**
 * Schema for update frequency options.
 * This schema allows for multiple update frequency types including:
 * - Daily updates
 * - Weekdays only updates
 * - Weekly updates
 * - Monthly updates
 */
export const UpdateFrequencySchema = z.discriminatedUnion('type', [
  UpdateFrequencyDailySchema,
  UpdateFrequencyWeekdaysOnly,
  UpdateFrequencyWeeklySchema,
  UpdateFrequencyMonthlySchema,
]);

/**
 * Schema for the first step of the custom SFTP source configuration.
 *
 * This schema validates the following fields:
 * - `sftpType`: Must be the literal string 'custom'.
 * - `hostname`: Must be a valid URL string.
 * - `username`: Must be a non-empty string.
 * - `password`: Must be a non-empty string.
 * - `port`: Must be an integer between 1 and 65535.
 */
export const CustomSFTPSourceFirstStepSchema = z.object({
  sftpType: z.literal('custom'),
  hostname: z
    .string()
    .url({ message: 'The host name requires a URL, please try again.' }),
  username: z.string().min(1, 'The username is required, please try again.'),
  password: z.string().min(1, 'The password is required, please try again.'),
  port: z.coerce
    .number({ invalid_type_error: 'The port requires a number' })
    .int()
    .min(1, 'The min value is 1')
    .max(65535, 'The max value is 65535'),
});

/**
 * Schema for the first step of Cherre SFTP source configuration.
 *
 * This schema validates that the `sftpType` property is a literal string 'cherre'.
 */
export const CherreSFTPSourceFirstStepSchema = z.object({
  sftpType: z.literal('cherre'),
});

/**
 * Schema for the first step of the SFTP source configuration.
 * This schema uses a discriminated union based on the `sftpType` property.
 * It can be either `CustomSFTPSourceFirstStepSchema` or `CherreSFTPSourceFirstStepSchema`.
 */
export const SFTPSourceFirstStepSchema = z.discriminatedUnion('sftpType', [
  CustomSFTPSourceFirstStepSchema,
  CherreSFTPSourceFirstStepSchema,
]);

/**
 * Schema for the second step of configuring an SFTP source.
 *
 * @property preconfiguredSourceName - The name of the preconfigured source. Must be a non-empty string.
 * @property folder - The folder path. Must be a non-empty string.
 * @property availability - The availability configuration, defined by the `AvailabilitySchema`.
 * @property updateFrequency - The update frequency configuration, defined by the `UpdateFrequencySchema`.
 */
export const SFTPSourceSecondStepSchema = z.object({
  preconfiguredSourceName: z.string().min(1),
  provider: z.string().min(1),
  folder: z.string().min(1),
  username: z.string().min(1),
  availability: AvailabilitySchema,
  updateFrequency: UpdateFrequencySchema,
});

/**
 * Merges the CherreSFTPSourceFirstStepSchema and SFTPSourceSecondStepSchema
 * to create a comprehensive schema for Cherre SFTP Source.
 */
export const CherreSFTPSourceSchema = CherreSFTPSourceFirstStepSchema.merge(
  SFTPSourceSecondStepSchema
);

/**
 * Merges the schema for the first step of the custom SFTP source with the schema for the second step.
 * This combined schema is used to validate the data for the custom SFTP source configuration.
 */
export const CustomSFTPSourceSchema = CustomSFTPSourceFirstStepSchema.merge(
  SFTPSourceSecondStepSchema
);

/**
 * Schema for SFTP source configuration.
 * Uses a discriminated union based on the 'sftpType' property.
 * It can be either a CustomSFTPSourceSchema or a CherreSFTPSourceSchema.
 */
export const SFTPSourceSchema = z.union([
  CustomSFTPSourceSchema,
  CherreSFTPSourceSchema,
]);

/**
 * A partial schema for SFTP source configuration.
 * This schema allows for partial validation of the SFTP source properties,
 * meaning that not all properties are required to be present.
 *
 * @constant {object} PartialSFTPSourceSchema - The partial schema derived from SFTPSourceSchema.
 */
export const PartialSFTPSourceSchema = z.union([
  CustomSFTPSourceSchema.partial(),
  CherreSFTPSourceSchema.partial(),
]);

/**
 * Represents the type inferred from the SFTPSourceSchema.
 * This type is used to define the structure of data for an SFTP source.
 */
export type SFTPSourceType = z.infer<typeof SFTPSourceSchema>;
/**
 * Type representing a partial SFTP source, inferred from the PartialSFTPSourceSchema.
 * This type is used to define the structure of a partial SFTP source object.
 */
export type PartialSFTPSourceType = z.infer<typeof PartialSFTPSourceSchema>;

// Models

/**
 * Schema for validating pre configured model data.
 *
 * This schema ensures that the availability data includes:
 * - `name`: A non-empty string representing the chosen model name.
 * - `provider`: A non-empty string representing the chosen provider model.
 * - `displayName`: A non-empty string representing the chosen friendly model name.
 *
 */
export const PreConfiguredModelSchema = z.object({
  name: z.string().min(1),
  provider: z.string().min(1),
  displayName: z.string().min(1),
  stableVersion: z.number().int().nullable(),
});

// Destinations

/**
 * Schema for validating pre configured destination data.
 *
 * This schema ensures that the availability data includes:
 * - `destinationName`: A non-empty string representing the chosen destination name.
 * - `tables`: A array of string representing the chosen tables.
 * - `selectedAll`: The boolean value representing user wants select all tables to sync
 *
 */
const PreConfiguredDestinationChosenTablesSchema = z.object({
  tables: z.array(z.string()),
  selectedAll: z.boolean(),
  modelName: z.string(),
  stableVersion: z.number().int().nullable(),
  displayName: z.string(),
});

export const PreConfiguredDestinationSchema = z.object({
  destinationName: z.string().min(1),
  chosenTables: z.array(PreConfiguredDestinationChosenTablesSchema),
});

//CONNECTOR

/**
 * Schema for adding a connector.
 *
 * This schema validates the structure of the data required to add a new connector.
 *
 * @property {string} name - The name of the connector. Must be at least 3 characters long.
 * @property {Array<SFTPSourceSchema>} sources - An array of source schemas, specifically SFTP sources.
 * @property {Array<PreConfiguredModelSchema>} models - An array of models. The schema for models is not specified and can be any type.
 * @property {Array<PreConfiguredDestinationSchema>} destinations - An array of destinations. The schema for destinations is not specified and can be any type.
 */
export const AddConnectorSchema = z.object({
  name: z.string().min(3),
  domOwner: z.string().min(1),
  sources: z.array(SFTPSourceSchema),
  models: z.array(PreConfiguredModelSchema),
  destinations: z.array(PreConfiguredDestinationSchema),
});

/**
 * A partial schema for adding a connector.
 * This schema is derived from the complete AddConnectorSchema,
 * but allows for partial data to be provided.
 */
export const PartialAddConnectorSchema = AddConnectorSchema.partial();

/**
 * Type definition for the AddConnectorSchema.
 *
 * This type is inferred from the AddConnectorSchema using Zod's infer method.
 * It represents the shape of data expected for adding a connector.
 *
 * @typedef {AddConnectorType}
 */
export type AddConnectorType = z.infer<typeof AddConnectorSchema>;
/**
 * Type representing a partial form of the Add Connector schema.
 * This type is inferred from the `PartialAddConnectorSchema` using Zod's `infer` method.
 *
 * @typedef {PartialAddConnectorType}
 */
export type PartialAddConnectorType = z.infer<typeof PartialAddConnectorSchema>;
