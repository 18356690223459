import React from 'react';
import { useDataValidationV2 } from '../../../../providers/DataValidationV2Provider';
import { Box, FormControl, MenuItem, Select } from '@cherre-frontend/ui';
import {
  mapDynamicFields,
  useDataValidationFormContext,
} from './dataValidationFormEngine';

export const DataValidationCustomRuleType = () => {
  const { setValue } = useDataValidationFormContext();
  const { customRules } = useDataValidationV2();

  const options =
    customRules?.map((rule) => ({
      label: rule?.displayName || '',
      value: rule?.name || '',
    })) || [];

  const handleChange = (value: string) => {
    const customRule = customRules?.find((rule) => rule?.name === value);

    if (!customRule) {
      return;
    }

    setValue('name', customRule.name);

    setValue(
      'generalProperties',
      mapDynamicFields(customRule, 'rliGeneralProperties'),
      { shouldDirty: true }
    );
    setValue(
      'specificProperties',
      mapDynamicFields(customRule, 'rliSpecificProperties'),
      { shouldDirty: true }
    );
  };

  return (
    <FormControl sx={{ width: '50%', flex: 1 }}>
      <Box
        sx={{
          fontSize: '14px',
          fontWeight: '600',
          display: 'block',
          marginBottom: '5px',
          color: 'black',
        }}
        component='label'
      >
        Rule Type
        <Box component='span' color='#E53935'>
          *
        </Box>
      </Box>

      <Select
        inputProps={{ sx: { padding: '8px' } }}
        onChange={(e) => handleChange(e.target.value as string)}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
