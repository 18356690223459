import { TestStatus } from 'src/products/connector/pages/connector-details/components/DataValidation/types';
import { TestStatusList } from '../pages/connector-details/components/DataValidation/constants';

export const buildRuleDetailStatus = (
  anomalusRecords: number | null,
  selectedRuleStatus?: TestStatus
): TestStatus => {
  if (!selectedRuleStatus) {
    return TestStatusList.SCHEDULED;
  }

  if (anomalusRecords === null) {
    return TestStatusList.SUCCESS;
  }

  if (anomalusRecords && TestStatusList[selectedRuleStatus]) {
    return TestStatusList.FAILED;
  }

  return selectedRuleStatus;
};
