export const getTableAndColumnName = (entityLink?: string) => {
  let column = '';
  let table = '';
  if (entityLink) {
    if (entityLink.includes('::columns::')) {
      const splitString = entityLink.split('::');
      column = splitString[splitString.length - 1];
      if (column.endsWith('>')) {
        column = column.slice(0, -1);
        const splitTableString = entityLink.split('::columns::');
        table = splitTableString[0].split('<#E::table::')[1];
        return { column, table };
      }
    }
    const splitTableString = entityLink.split('::columns::');
    table = splitTableString[0].split('<#E::table::')[1].slice(0, -1);
  }
  return { column, table };
};
