import {
  string,
  object,
  withDefault,
  writableArray,
  bool,
  number,
  nullable,
} from '@recoiljs/refine';
import { ConnectorDetailsRoute, ConnectorInventoryRoute } from '../routes';
import { atom, selector, refine } from '@cherre-frontend/data-fetching';
import moment from 'moment';
import { Range } from 'react-date-range';

export enum ConnectorStatus {
  SUCCESS = 'success',
  FAILED = 'failed',
  SCHEDULED = 'scheduled',
  DRAFT = 'draft',
  DEACTIVATED = 'deactivated',
  LOADING = 'loading',
}

export const connectorDetailsSelectedConnectorName = selector({
  key: 'connector-details-selected-connector-name',
  get: ({ get }) =>
    get(ConnectorDetailsRoute.routeParamSelector)?.connector_name,
});

export const connectorInventoryTableFiltersState =
  ConnectorInventoryRoute.searchParamsSelectorFamily({
    key: 'connector-inventory-table-filters',
    refine: withDefault(writableArray(string()), []),
  });

export const connectorTableSearch =
  ConnectorInventoryRoute.searchParamsSelectorFamily({
    key: 'connector-inventory-table-search',
    refine: withDefault(string(), ''),
  });

export const addConnectorSelectedNavLink =
  ConnectorDetailsRoute.searchParamsSelectorFamily({
    key: 'add-connector-selected-nav-link',
    refine: withDefault(string(), ''),
  });

export const connectorDetailsTabState = atom<string>({
  key: 'connector-details-tab-state',
  default: 'Overview',
});

export const connectorDetailsTabParam =
  ConnectorDetailsRoute.searchParamsSelectorFamily({
    key: 'connector-details-tab-param',
    refine: withDefault(string(), 'Overview'),
  });

export const connectorDataValidationTabParam =
  ConnectorDetailsRoute.searchParamsSelectorFamily({
    key: 'connector-data-validation-tab-param',
    refine: withDefault(
      nullable(
        object({
          content: string(),
          rule: nullable(string()),
          detailsTab: nullable(string()),
        })
      ),
      {
        content: '',
        rule: '',
        detailsTab: '',
      }
    ),
  });

export const connectorInventoryTableState =
  ConnectorInventoryRoute.searchParamsSelectorFamily({
    key: 'connector-inventory-table',
    refine: withDefault(
      object({
        pagination: object({
          pageSize: refine.allowedNumbers([25, 50, 100]),
          pageIndex: number(),
        }),
        sorting: writableArray(object({ id: string(), desc: bool() })),
      }),
      {
        pagination: { pageSize: 25, pageIndex: 0 },
        sorting: [{ id: 'name', desc: false }],
      }
    ),
  });

export const connectorDetailsSelectedTable =
  ConnectorDetailsRoute.searchParamsSelectorFamily({
    key: 'connector-details-selected-table',
    refine: withDefault(string(), ''),
  });

export const connectorDetailsSelectedDateFilter =
  ConnectorDetailsRoute.searchParamsSelectorFamily({
    key: 'connector-details-selected-date-filter',
    refine: withDefault(string(), '3'),
  });

export const connectorDetailsCustomDateRange = atom<Range[]>({
  key: 'connector-details-custom-date-range',
  default: [
    {
      startDate: moment().subtract(3, 'days').toDate(),
      endDate: new Date(),
      key: 'selection',
    },
  ],
});

export const dataValidationRuleStatusFilter =
  ConnectorDetailsRoute.searchParamsSelectorFamily({
    key: 'data-validation-rule-status-filter',
    refine: withDefault(writableArray(string()), []),
  });
export const dataValidationSelectedTableFilter =
  ConnectorDetailsRoute.searchParamsSelectorFamily({
    key: 'data-validation-selected-table-filter',
    refine: withDefault(string(), ''),
  });

export const qualyticsDataVolumeReportDate =
  ConnectorDetailsRoute.searchParamsSelectorFamily({
    key: 'qualytics-data-volume-report-date',
    refine: withDefault(string(), moment().format('YYYY-MM-DD')),
  });
export const qualyticsDataVolumeTimeframe =
  ConnectorDetailsRoute.searchParamsSelectorFamily({
    key: 'qualytics-data-volume-timeframe',
    refine: withDefault(string(), 'week'),
  });
