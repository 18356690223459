import React from 'react';
import {
  styled,
  Typography,
  IconButton,
  AddCircleIcon,
} from '@cherre-frontend/ui';

const Container = styled('div')`
  display: grid;
  grid-template-columns: 1fr 30px 50px;
  grid-template-rows: 59px 20px auto 20px auto;
  grid-template-areas:
    'title . button'
    'subtitle . button'
    'list list list';
  padding: 20px 30px;
  border-radius: 5px;
  background: #eee;
`;

const Title = styled(Typography)`
  grid-area: title;

  color: ${({ theme }) => theme.palette.text.primary};

  text-align: center;
  font-feature-settings: 'liga' off;
  /* Headings/H5 */
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24.5px; /* 122.5% */
  letter-spacing: 0.2px;
`;

const Subtitle = styled(Typography)`
  grid-area: subtitle;

  color: ${({ theme }) => theme.palette.text.primary};

  text-align: center;
  /* Body/Small */
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const Button = styled(IconButton)`
  grid-area: button;

  background-color: white;
  border-radius: 6px;
  padding: 16px;
  height: 50px;
  width: 50px;
  cursor: pointer;

  &:hover {
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
    background: ${({ theme }) => theme.palette.primary.main}1a;
  }

  &:active {
    background: ${({ theme }) => theme.palette.primary.main}3a;
  }
`;

const List = styled('div')`
  display: grid;
  grid-area: list;
  grid-template-columns: 1fr;
  gap: 10px;
`;

const HeaderContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

/**
 * Props for the Step component.
 *
 * @typedef {Object} StepProps
 * @property {string} title - The title of the step.
 * @property {string} subtitle - The subtitle of the step.
 * @property {() => void} onClick - The function to call when the step is clicked.
 * @property {React.ReactNode} children - The content to be displayed within the step.
 */
export type StepProps = {
  title: string;
  subtitle: string;
  onClick: () => void;
  children?: React.ReactNode;
  addTestID?: string;
  disabled?: boolean;
};

/**
 * Step component that displays a title, subtitle, a button with an icon, and a list of children elements.
 *
 * @component
 * @param {StepProps} props - The properties for the Step component.
 * @param {string} props.title - The title to be displayed.
 * @param {string} props.subtitle - The subtitle to be displayed.
 * @param {() => void} props.onClick - The function to be called when the button is clicked.
 * @param {React.ReactNode} props.children - The child elements to be displayed in the list.
 * @returns {JSX.Element} The rendered Step component.
 */
const Step: React.FC<StepProps> = ({
  title,
  subtitle,
  onClick,
  children,
  addTestID,
  disabled,
}) => {
  return (
    <Container>
      <HeaderContainer>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </HeaderContainer>

      <Button onClick={onClick} disabled={disabled} data-testid={addTestID}>
        <AddCircleIcon color='primary' />
      </Button>
      <List>{children}</List>
    </Container>
  );
};

export default Step;
