import React, { useMemo } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label,
} from 'recharts';
import { format } from 'date-fns';
import { useTheme } from '@cherre-frontend/ui';
import { RuleResult } from 'src/products/connector/types/ruleTypes';
import moment from 'moment';

type RuleResultsChartTypes = {
  data: RuleResult[];
};

enum AxisKeys {
  ROW_COUNT = 'failedRowCount',
  TIMESTAMP = 'date',
}

const formatYAxisValueTick = (value) => {
  if (value >= 1e9) {
    return `${(value / 1e9).toFixed(1)}B`;
  } else if (value >= 1e6) {
    return `${(value / 1e6).toFixed(1)}M`;
  } else if (value >= 1e3) {
    return `${(value / 1e3).toFixed(1)}k`;
  } else {
    return value.toString();
  }
};

const formatTooltipVolume = (value) => {
  const numValue = Number(value);

  if (Math.abs(numValue) < 1e-6) {
    return '0';
  }

  return numValue.toLocaleString();
};

const formatTimestampValue = (value) => {
  return format(new Date(value), 'MMM dd');
};

const RuleResultsChart: React.FC<RuleResultsChartTypes> = ({ data }) => {
  const ruleResultsData = [...data];
  const sortedData = ruleResultsData.sort((a, b) => {
    return moment(a.date).valueOf() - moment(b.date).valueOf();
  });

  const theme = useTheme();

  const maxDomainValue = useMemo(() => {
    const maxVolume = Math.max(...data.map((d) => d.failedRowCount));
    //Adds a 15% buffer to max volume
    return maxVolume * 1.15;
  }, [data]);

  return (
    <ResponsiveContainer width='100%' height={200}>
      <LineChart
        width={500}
        height={200}
        data={sortedData}
        margin={{
          top: 5,
          right: 30,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray='3 3' vertical={false} />
        <XAxis
          dataKey={AxisKeys.TIMESTAMP}
          axisLine={false}
          tickLine={false}
          padding={{ left: 20, right: 20 }}
          tickFormatter={formatTimestampValue}
        >
          <Label
            style={{
              textAnchor: 'middle',
              fontSize: '14px',
              fill: theme.palette.grey[500],
              fontWeight: 'bold',
              marginTop: '50px',
            }}
            value='Date'
            position='insideBottom'
            dy={10}
          />
        </XAxis>
        <YAxis
          dataKey={(data) => data.failedRowCount}
          axisLine={false}
          tickLine={false}
          allowDataOverflow
          tickCount={10}
          domain={[0, maxDomainValue]}
          tickFormatter={formatYAxisValueTick}
        >
          <Label
            style={{
              textAnchor: 'middle',
              fontSize: '14px',
              fill: theme.palette.grey[500],
              fontWeight: 'bold',
            }}
            angle={270}
            dx={-20}
            value='Failed Row Count'
          />
        </YAxis>
        <Tooltip
          formatter={formatTooltipVolume}
          labelFormatter={formatTimestampValue}
        />
        <Line
          type='monotone'
          dataKey={(data) => data.failedRowCount}
          name='Failed Row Count'
          stroke='#FF6294'
          activeDot={{
            r: 3,
            stroke: '#DF2467',
            fill: '#DF2467',
          }}
          dot={{ stroke: '#DF2467', strokeWidth: 1, fill: '#DF2467' }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

RuleResultsChart.displayName = 'RuleResultsChart';
export default RuleResultsChart;
