import React from 'react';
import { Box, Button } from '@cherre-frontend/ui';
import { RulesQueryDataRuleLibraryItem } from '../../../../../queries/types';
import { DataValidationActivateDialog } from './DataValidationActivateDialog';
import { connectorDataValidationTabParam } from '../../../../../../../../../recoil';
import { useCherreState } from '@cherre-frontend/data-fetching';

interface DataValidationFormActionsProps {
  rule?: RulesQueryDataRuleLibraryItem;
}

export const DataValidationFormActions = ({
  rule,
}: DataValidationFormActionsProps) => {
  const [, setDataValidationV2Param] = useCherreState(
    connectorDataValidationTabParam
  );

  const handleBack = () => {
    setDataValidationV2Param({
      content: 'list',
      rule: null,
      detailsTab: null,
    });
  };

  return (
    <Box display='flex' justifyContent='end' gap='20px' paddingTop='26px'>
      <Button
        variant='text'
        sx={{ minWidth: '110px' }}
        type='button'
        onClick={handleBack}
      >
        Back
      </Button>

      <DataValidationActivateDialog rule={rule} />
    </Box>
  );
};
