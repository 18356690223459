import React from 'react';
import {
  styled,
  Button,
  RadioGroup,
  Radio,
  MenuItem,
} from '@cherre-frontend/ui';
import * as C from './styles';
import { useSaveSFTPSource, useSFTPSource } from '../../../dataState';
import { useConnectorUIState } from '../../../uiState/contexts';
import {
  getAvailability,
  setAvailability,
  getFrequencyOptions,
} from './SecondStep.utils';

const Container = styled(C.Container)`
  grid-template-areas: 'title title' 'description description' 'path path' 'availability frequency' 'actions actions';
`;

/**
 * SFTPSourceSecondStep component is responsible for rendering the second step
 * of the SFTP source configuration process. It allows users to configure the
 * folder path, source availability, and update frequency for the SFTP source.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 *
 * @example
 * <SFTPSourceSecondStep />
 *
 * @remarks
 * This component uses custom hooks `useSFTPSource`, `useSaveSFTPSource`, and
 * `useConnectorUIState` to manage state and handle saving the configuration.
 *
 * @description
 * The component renders a form with the following fields:
 * - Folder Path: Input field for specifying the folder path.
 * - Source Availability: Time picker for selecting the time of day the source data will be available.
 * - Update Frequency: Radio buttons for selecting the update frequency (Daily, Weekdays Only, Weekly, Monthly) and a dropdown for selecting the specific day if applicable.
 *
 * The component also includes "Cancel" and "Save" buttons. The "Save" button is
 * disabled until the form is in a state that can be saved.
 */
const SFTPSourceSecondStep: React.FC = () => {
  const [data, setData] = useSFTPSource();
  const [canSave, save] = useSaveSFTPSource();
  const [, setUIState] = useConnectorUIState();
  return (
    <Container>
      <C.Title>Configure your source:</C.Title>
      <C.Description>
        Select your folder for these files to live in. This is the location that
        Cherre will ingest data from for this connector.{' '}
        <b>
          Please ensure that all current Yardi ETL files live here and all
          future data deliveries for this connector are routed to this folder.
          Only one folder can be selected.
        </b>
      </C.Description>
      <C.FieldBox style={{ gridArea: 'path' }}>
        <C.FieldTitle>Folder Path:</C.FieldTitle>
        <C.FieldInput
          size='small'
          value={data.folder || ''}
          onChange={(e) => setData({ folder: e.target.value })}
          inputProps={{ 'data-testid': 'sftp-source-folder' }}
        />
      </C.FieldBox>
      <C.FieldBox style={{ gridArea: 'availability' }}>
        <C.FieldTitle>Source Availability:</C.FieldTitle>
        <C.FieldDescription>
          Note: Choose the time of day that the source data will be available to
          ingest.
        </C.FieldDescription>
        <C.FieldTimePicker
          value={getAvailability(data.availability)}
          onChange={(newValue) =>
            setData({ availability: setAvailability(newValue) })
          }
          slotProps={{
            textField: {
              inputProps: { 'data-testid': 'sftp-source-availability' },
            },
          }}
        />
      </C.FieldBox>
      <C.FieldBox style={{ gridArea: 'frequency' }}>
        <C.FieldTitle>Update Frequency:</C.FieldTitle>
        <RadioGroup
          row
          value={data.updateFrequency?.type || null}
          onChange={(e) =>
            setData({
              updateFrequency: { type: e.target.value as any, day: 1 },
            })
          }
        >
          <Radio label='Daily' value='daily' />
          <Radio label='Weekdays Only' value='weekdays' />
          <Radio label='Weekly' value='weekly' />
          <Radio label='Monthly' value='monthly' />
        </RadioGroup>
        <C.FieldSelect
          value={(data.updateFrequency as any)?.day ?? ''}
          disabled={getFrequencyOptions(data.updateFrequency).length === 0}
          onChange={(e) =>
            data.updateFrequency &&
            setData({
              updateFrequency: {
                type: data.updateFrequency.type,
                day: e.target.value as any,
              },
            })
          }
          inputProps={{ 'data-testid': 'sftp-source-frequency' }}
        >
          {getFrequencyOptions(data.updateFrequency).map(([label, value]) => (
            <MenuItem key={label} value={value}>
              {label}
            </MenuItem>
          ))}
        </C.FieldSelect>
      </C.FieldBox>
      <C.Actions>
        <Button onClick={() => setUIState({ step: 'default', index: 0 })}>
          Cancel
        </Button>
        <Button onClick={save} disabled={!canSave} variant='contained'>
          Save
        </Button>
      </C.Actions>
    </Container>
  );
};

export default SFTPSourceSecondStep;
