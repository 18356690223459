import React from 'react';
import { alpha } from '@mui/material/styles';
import {
  FilterChip,
  Grid,
  Menu,
  MenuItem,
  useTheme,
} from '@cherre-frontend/ui';

type RuleTableTypes = {
  tables: string[];
  tableFilterState: string;
  onTableChange: (value: string) => void;
};

const RuleTableFilter: React.FC<RuleTableTypes> = ({
  tables,
  tableFilterState,
  onTableChange,
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleFilterClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleFilterChange = (value: string) => () => {
    onTableChange(value);
    handleFilterClose();
  };

  return (
    <Grid display='flex' gap='10px'>
      <FilterChip
        label={tableFilterState ? tableFilterState : 'Select Table'}
        onClick={handleFilterClick}
        active={tableFilterState !== ''}
        sx={{
          height: '29px',
          ...(tableFilterState !== '' && {
            backgroundColor: 'transparent',
            color: theme.palette.secondary.main,
            border: `1px ${theme.palette.secondary.main} solid`,
            '&:hover': {
              backgroundColor: alpha(theme.palette.secondary.main, 0.12),
            },
            '& .MuiSvgIcon-root': {
              color: theme.palette.secondary.main,
              '&:hover': {
                color: alpha(theme.palette.secondary.main, 0.7),
              },
            },
          }),
        }}
        onDelete={handleFilterChange(`All Tables`)}
      />
      <Menu
        id={`select-table-menu`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleFilterClose}
        data-testid={`select-table-menu`}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ left: '100px', height: '500px' }}
      >
        <MenuItem
          style={{ width: '100%', gap: '6px' }}
          onClick={handleFilterChange(`All Tables`)}
        >
          All Tables
        </MenuItem>
        {tables.map((table) => (
          <MenuItem
            style={{ width: '100%', gap: '6px' }}
            onClick={handleFilterChange(table)}
            key={table}
          >
            {table}
          </MenuItem>
        ))}
      </Menu>
    </Grid>
  );
};

RuleTableFilter.displayName = 'RuleTableFilter';
export default RuleTableFilter;
