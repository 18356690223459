import React from 'react';
import { Skeleton, Box, useTheme, Divider, styled } from '@cherre-frontend/ui';

const StyledHeaderLoadingSkeleton = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[400]};
`;

const StyledBreadcrumbs = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 16px;
`;

const StyledHeaderContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
`;

const StyledHeaderChildren = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 60%;
  align-items: center;
`;

const StyledColumn = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledProgressBox = styled(Box)`
  display: flex;
  flex-direction: row;
`;

const StyledProgressChild = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  justify-content: center;
`;

const HeaderLoadingSkeleton = () => {
  const theme = useTheme();

  return (
    <StyledHeaderLoadingSkeleton theme={theme}>
      <StyledBreadcrumbs>
        <Skeleton variant='text' width={100} height={30} />
        <Skeleton variant='text' width={100} height={30} />
        <Skeleton variant='text' width={100} height={30} />
        <Skeleton variant='text' width={100} height={30} />
      </StyledBreadcrumbs>
      <StyledHeaderContainer>
        <Skeleton variant='rectangular' width={100} height={70} />
        <StyledHeaderChildren>
          <StyledColumn>
            <Skeleton variant='text' width={150} height={20} />
            <Skeleton
              variant='rounded'
              width={150}
              height={20}
              sx={{ borderRadius: '20px' }}
            />
          </StyledColumn>
          <StyledColumn>
            <Skeleton variant='text' width={150} height={20} />
            <Skeleton variant='rectangular' width={150} height={20} />
          </StyledColumn>
          <StyledColumn>
            <Skeleton variant='text' width={150} height={20} />
            <Skeleton variant='rectangular' width={150} height={20} />
          </StyledColumn>
          <StyledColumn>
            <Skeleton variant='text' width={150} height={20} />
            <Skeleton variant='rectangular' width={150} height={20} />
          </StyledColumn>
        </StyledHeaderChildren>
        <StyledProgressBox>
          <Divider
            style={{
              borderRightWidth: '1px',
              borderColor: theme.palette.grey[400],
            }}
          />
          <StyledProgressChild>
            <Skeleton variant='text' width={200} height={30} />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '20px',
                marginTop: '8px',
              }}
            >
              <Skeleton variant='rounded' width={80} height={30} />
              <Skeleton variant='rounded' width={80} height={30} />
              <Skeleton variant='rounded' width={80} height={30} />
            </Box>
          </StyledProgressChild>
        </StyledProgressBox>
      </StyledHeaderContainer>
    </StyledHeaderLoadingSkeleton>
  );
};
HeaderLoadingSkeleton.displayName = 'HeaderLoadingSkeleton';
export default HeaderLoadingSkeleton;
