import React from 'react';
import { Box } from '@cherre-frontend/ui';
import UpgradePlanCard from './UpgradePlanCard';

type TableUpgradeOverlayTypes = {
  message: string;
  rowCount: number;
};

const TableUpgradeOverlay: React.FC<TableUpgradeOverlayTypes> = ({
  message,
  rowCount,
}) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: rowCount === 1 ? '84px' : '124px',
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        backdropFilter: 'blur(3px)',
        zIndex: 1300,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        padding: 2,
        boxSizing: 'border-box',
        textAlign: 'center',
      }}
    >
      <Box marginTop='50px'>
        <UpgradePlanCard text={message} />
      </Box>
    </Box>
  );
};

export default TableUpgradeOverlay;
