import { RuleTypes } from './types';

export const emptyForm = {
  name: '',
  rule_type: '',
  column: '',
  description: '',
  [RuleTypes.COLUMN_VALUES_TBB]: {
    minValue: '',
    maxValue: '',
  },
  [RuleTypes.COLUMN_VALUE_STD_DEV_TBB]: {
    minValueForStdDevInCol: '',
    maxValueForStdDevInCol: '',
  },
  [RuleTypes.COLUMN_VALUE_LENGTHS_TBB]: {
    minLength: '',
    maxLength: '',
  },
  [RuleTypes.COLUMN_VALUE_MIN_TBB]: {
    minValueForMinInCol: '',
    maxValueForMinInCol: '',
  },
  [RuleTypes.COLUMN_VALUES_MISSING_COUNT]: {
    missingCountValue: '',
    missingValueMatch: '',
  },
  [RuleTypes.COLUMN_VALUE_MAX_TBB]: {
    minValueForMaxInCol: '',
    maxValueForMaxInCol: '',
  },
  [RuleTypes.COLUMN_VALUES_TO_NOT_MATCH_REGEX]: {
    forbiddenRegex: '',
  },
  [RuleTypes.COLUMN_VALUE_MEDIAN_TBB]: {
    minValueForMedianInCol: '',
    maxValueForMedianInCol: '',
  },
  [RuleTypes.COLUMN_VALUES_TO_MATCH_REGEX]: {
    regex: '',
  },
  [RuleTypes.COLUMN_VALUE_MEAN_TBB]: {
    minValueForMeanInCol: '',
    maxValueForMeanInCol: '',
  },
  [RuleTypes.COLUMN_VALUES_SUM_TBB]: {
    minValueForColSum: '',
    maxValueForColSum: '',
  },
  [RuleTypes.COLUMN_VALUES_TO_BE_IN_SET]: {
    allowedValues: [''],
  },
  [RuleTypes.COLUMN_VALUES_TO_BE_NOT_IN_SET]: {
    forbiddenValues: [''],
  },
  [RuleTypes.TABLE_ROW_COUNT_TO_EQUAL]: {
    value: '',
  },
  [RuleTypes.TABLE_COLUMN_COUNT_TO_EQUAL]: {
    columnCount: '',
  },
  [RuleTypes.TABLE_COLUMN_NAME_TO_EXIST]: {
    columnName: '',
  },
  [RuleTypes.TABLE_ROW_COUNT_TBB]: {
    minValue: '',
    maxValue: '',
  },
  [RuleTypes.TABLE_COLUMN_COUNT_TBB]: {
    minColValue: '',
    maxColValue: '',
  },
  [RuleTypes.TABLE_COLUMN_TO_MATCH_SET]: {
    columnNames: '',
    ordered: false,
  },
  [RuleTypes.TABLE_ROW_INSERTED_COUNT_TBB]: {
    min_row_count: '',
    max_row_count: '',
    column_name: '',
    range_type: '',
    interval: '',
  },
  [RuleTypes.TABLE_CUSTOM_SQL_QUERY]: {
    sqlExpression: '',
    strategy: '',
    threshold: '',
  },
};

export const getReadableRuleType = (ruleType: string) => {
  const words = ruleType.split('_');
  const capitalizedWords = words.map(
    (word) => `${word.charAt(0).toUpperCase()}${word.slice(1)}`
  );
  return capitalizedWords.join(' ');
};

export const getSubmittableRuleType = (ruleType: string) => {
  const words = ruleType.split('_');
  const capitalizedWords = words.map((word, index) => {
    if (index === 0) {
      return word;
    }
    return `${word.charAt(0).toUpperCase()}${word.slice(1)}`;
  });
  return capitalizedWords.join('');
};

export const columnRuleTypeSelectOptions = [
  {
    label: getReadableRuleType(RuleTypes.COLUMN_VALUES_TBB),
    value: RuleTypes.COLUMN_VALUES_TBB,
  },
  {
    label: getReadableRuleType(RuleTypes.COLUMN_VALUE_STD_DEV_TBB),
    value: RuleTypes.COLUMN_VALUE_STD_DEV_TBB,
  },
  {
    label: getReadableRuleType(RuleTypes.COLUMN_VALUES_TO_BE_NOT_NULL),
    value: RuleTypes.COLUMN_VALUES_TO_BE_NOT_NULL,
  },
  {
    label: getReadableRuleType(RuleTypes.COLUMN_VALUE_LENGTHS_TBB),
    value: RuleTypes.COLUMN_VALUE_LENGTHS_TBB,
  },
  {
    label: getReadableRuleType(RuleTypes.COLUMN_VALUE_MIN_TBB),
    value: RuleTypes.COLUMN_VALUE_MIN_TBB,
  },
  {
    label: getReadableRuleType(RuleTypes.COLUMN_VALUES_MISSING_COUNT),
    value: RuleTypes.COLUMN_VALUES_MISSING_COUNT,
  },
  {
    label: getReadableRuleType(RuleTypes.COLUMN_VALUES_TO_BE_UNIQUE),
    value: RuleTypes.COLUMN_VALUES_TO_BE_UNIQUE,
  },
  {
    label: getReadableRuleType(RuleTypes.COLUMN_VALUE_MAX_TBB),
    value: RuleTypes.COLUMN_VALUE_MAX_TBB,
  },
  {
    label: getReadableRuleType(RuleTypes.COLUMN_VALUES_TO_NOT_MATCH_REGEX),
    value: RuleTypes.COLUMN_VALUES_TO_NOT_MATCH_REGEX,
  },
  {
    label: getReadableRuleType(RuleTypes.COLUMN_VALUE_MEDIAN_TBB),
    value: RuleTypes.COLUMN_VALUE_MEDIAN_TBB,
  },
  {
    label: getReadableRuleType(RuleTypes.COLUMN_VALUES_TO_MATCH_REGEX),
    value: RuleTypes.COLUMN_VALUES_TO_MATCH_REGEX,
  },
  {
    label: getReadableRuleType(RuleTypes.COLUMN_VALUE_MEAN_TBB),
    value: RuleTypes.COLUMN_VALUE_MEAN_TBB,
  },
  {
    label: getReadableRuleType(RuleTypes.COLUMN_VALUES_SUM_TBB),
    value: RuleTypes.COLUMN_VALUES_SUM_TBB,
  },
  {
    label: getReadableRuleType(RuleTypes.COLUMN_VALUES_TO_BE_IN_SET),
    value: RuleTypes.COLUMN_VALUES_TO_BE_IN_SET,
  },
  {
    label: getReadableRuleType(RuleTypes.COLUMN_VALUES_TO_BE_NOT_IN_SET),
    value: RuleTypes.COLUMN_VALUES_TO_BE_NOT_IN_SET,
  },
];

export const tableRuleTypeSelectOptions = [
  {
    label: getReadableRuleType(RuleTypes.TABLE_ROW_COUNT_TO_EQUAL),
    value: RuleTypes.TABLE_ROW_COUNT_TO_EQUAL,
  },
  {
    label: getReadableRuleType(RuleTypes.TABLE_COLUMN_COUNT_TO_EQUAL),
    value: RuleTypes.TABLE_COLUMN_COUNT_TO_EQUAL,
  },
  {
    label: getReadableRuleType(RuleTypes.TABLE_COLUMN_NAME_TO_EXIST),
    value: RuleTypes.TABLE_COLUMN_NAME_TO_EXIST,
  },
  {
    label: getReadableRuleType(RuleTypes.TABLE_ROW_COUNT_TBB),
    value: RuleTypes.TABLE_ROW_COUNT_TBB,
  },
  {
    label: getReadableRuleType(RuleTypes.TABLE_COLUMN_COUNT_TBB),
    value: RuleTypes.TABLE_COLUMN_COUNT_TBB,
  },
  {
    label: getReadableRuleType(RuleTypes.TABLE_COLUMN_TO_MATCH_SET),
    value: RuleTypes.TABLE_COLUMN_TO_MATCH_SET,
  },
  {
    label: getReadableRuleType(RuleTypes.TABLE_CUSTOM_SQL_QUERY),
    value: RuleTypes.TABLE_CUSTOM_SQL_QUERY,
  },
];

export const convertToNumber = (value: string) => {
  if (typeof value === 'boolean') {
    return value;
  }
  if (value.match(/[^0-9]/) || value === '') {
    return value;
  } else {
    return parseInt(value);
  }
};

export const handleFormValue = (value: string | string[]) => {
  if (Array.isArray(value)) {
    return JSON.stringify(value.filter((string) => string !== ''));
  } else {
    return convertToNumber(value);
  }
};
