import React from 'react';
import {
  AddCircleIcon,
  Box,
  IconButton,
  Typography,
  useTheme,
} from '@cherre-frontend/ui';
import ConnectorCard from 'src/products/connector/pages/connector-details/components/Overview/ConnectorCard';
import ModelConnectorCard from './ModelConnectorCard';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';

export enum OverviewType {
  SOURCE = 'Source',
  MODEL = 'Model',
  DESTINATION = 'Destination',
}

interface OverviewCardBase {
  name: string;
  displayName: string | null;
  revision: string;
  created: string;
  creator: string;
  sourceAvailable?: string;
  frequency?: string;
  runTime?: string;
  provider?: string | null;
}

//Only source and model can have isRunning type
export type OverviewCardType<T extends OverviewType> =
  T extends OverviewType.DESTINATION
    ? OverviewCardBase & {
        type: T;
        statusObject?: never;
      }
    : OverviewCardBase & {
        type: T;
        statusObject: { isRunning: boolean; status: string | null } | null;
      };

type Props<T extends OverviewType> = {
  data: OverviewCardType<T>[];
  type: T;
  failed?: boolean;
};

const OverviewCard = <T extends OverviewType>({
  data,
  type,
  failed,
}: Props<T>) => {
  const theme = useTheme();
  const canAddConnector = useFeatureFlag('ConnectorsCreateConnector');

  const getTypedText = (subheaderType: OverviewType, steps?: boolean) => {
    switch (subheaderType) {
      case OverviewType.SOURCE:
        return steps
          ? '1'
          : 'Add any new or existing sources for this connector.';
      case OverviewType.MODEL:
        return steps
          ? '2'
          : 'Add models to transform the data into usable tables.';
      case OverviewType.DESTINATION:
        return steps
          ? '3'
          : 'Add any destinations you want this data to flow to.';
      default:
        return '';
    }
  };

  return (
    <Box
      sx={{
        minHeight: '315px',
        padding: '20px 30px 0px',
        backgroundColor: failed
          ? theme.palette.error.light
          : theme.palette.grey[100],
        height: 'fit-content',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '24px',
          marginBottom: '20px',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            variant='h5'
            style={{ textAlign: 'center', fontWeight: '700' }}
          >{`Step ${getTypedText(type, true)}: ${type}s`}</Typography>
          <Typography variant='body1' style={{ textAlign: 'center' }}>
            {getTypedText(type)}
          </Typography>
        </Box>
        {canAddConnector && (
          <IconButton
            sx={{
              height: '50px',
              width: '50px',
              backgroundColor: 'white',
              padding: '16px',
              borderRadius: '6px',
            }}
          >
            <AddCircleIcon color='primary' />
          </IconButton>
        )}
      </Box>
      {data.map((card) =>
        type === OverviewType.MODEL ? (
          <ModelConnectorCard
            key={card.name}
            card={card as OverviewCardType<OverviewType.MODEL>}
          />
        ) : (
          <ConnectorCard
            key={card.name}
            card={
              card as OverviewCardType<
                OverviewType.SOURCE | OverviewType.DESTINATION
              >
            }
          />
        )
      )}
    </Box>
  );
};

export default OverviewCard;
