import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { buildCheckboxInitialOptions } from '../utils/buildCheckboxInitialOptions';
import { buildIndeterminate } from '../utils/buildIndeterminate';
import { CheckboxTableProps, ModelCheckboxTables } from './model';
import {
  CheckboxContainer,
  CheckboxListContainer,
  CheckboxOptionsContainer,
  CheckboxSelectAllContainer,
  CustomCheckbox,
  CustomFormControlLabel,
} from './styles';
import { BuildIndeterminateReturn } from '../utils/buildIndeterminate/model';

/**
 * CheckboxTable component allows users to select the desired tables for the connector .
 *
 * This component uses `buildCheckboxInitialOptions` to manage the Select all checkbox action, `buildIndeterminate` to get the indeterminate checkbox state
 *
 *
 * @state {object} form - The current checkbox list state with all checkbox checked state.
 * @state {function} setForm - Function to update the checkbox list state.
 * @state {boolean} selectAll - The current checkbox Select all checked state.
 * @state {function} setSelectAll - Function to update the checkbox Select all checked state.
 *
 * @hook
 * - `useMemo` to manage the indeterminate checkbox state every time the state `form` changes. The useMemo is using the function `buildIndeterminate` for that.
 * - `useEffect` to change the state of Select all checkbox when all checkboxes was checked by the user
 * - `useEffect` to change the state of Select all checkbox when all checkboxes was unchecked by the user
 * - `useEffect` to emit the form and selectAll state outside component
 *
 *
 * @component
 * @param {CheckboxTableProps} props - The properties for the CheckboxTable component.
 * @param {function} props.onChangeForm - The callback function responsable for emit the form and selectAll state.
 * @param {object} props.checkboxForm - The object representing the checkbox list initial state.
 * @param {array} props.options - The checbox list labels
 * @param {boolean} props.selectedAll - The initial state for Select all option
 * @returns {ReactElement} The rendered ModelCard component.
 */

const CheckboxTable: FC<CheckboxTableProps> = ({
  onChangeForm,
  checkboxForm,
  options,
  selectedAll,
}): ReactElement => {
  const [form, setForm] = useState<ModelCheckboxTables>(checkboxForm);
  const [selectAll, setSelectAll] = useState<boolean>(selectedAll);
  const { indeterminate, allCheckboxesIsEmpty, allCheckboxesIsChecked } =
    useMemo<BuildIndeterminateReturn>(
      () => buildIndeterminate({ form }),
      [form]
    );

  useEffect(() => {
    if (allCheckboxesIsChecked) {
      setSelectAll(true);
    }
  }, [allCheckboxesIsChecked]);

  useEffect(() => {
    if (allCheckboxesIsEmpty) {
      setSelectAll(false);
    }
  }, [allCheckboxesIsEmpty]);

  useEffect(() => {
    const data = {
      selectedAll: selectAll,
      form,
    };
    onChangeForm(data);
  }, [form, selectAll]);

  const handleOnChange = useCallback(
    (option: string, value: boolean): void => {
      form[option] = value;
      const newForm = { ...form };
      setForm(newForm);
    },
    [form]
  );

  const handleSelectAll = useCallback((event): void => {
    const { checked } = event.target;
    const { checkboxForm } = buildCheckboxInitialOptions({
      selectedAll: checked,
      options,
    });
    setForm(checkboxForm);
    setSelectAll(checked);
  }, []);

  return (
    <CheckboxListContainer>
      <CheckboxSelectAllContainer>
        <CustomFormControlLabel
          checked={selectAll}
          onChange={handleSelectAll}
          control={
            <CustomCheckbox indeterminate={indeterminate} color='error' />
          }
          label={'Select all'}
        />
      </CheckboxSelectAllContainer>

      <CheckboxOptionsContainer>
        {options.map((option) => (
          <CheckboxContainer key={option}>
            <CustomFormControlLabel
              checked={form[option]}
              onChange={(event: any): void =>
                handleOnChange(option, event.target.checked)
              }
              control={<CustomCheckbox color='error' />}
              label={option}
            />
          </CheckboxContainer>
        ))}
      </CheckboxOptionsContainer>
    </CheckboxListContainer>
  );
};

export default CheckboxTable;
