import { useCherreState } from '@cherre-frontend/data-fetching';
import {
  Container,
  Table,
  useTable,
  tableBaseStyle,
  useTheme,
  Box,
} from '@cherre-frontend/ui';
import React, { useMemo } from 'react';
import { dataValidationDetailsTableState } from '../recoil';
import TableUpgradeOverlay from './TableUpgradeOverlay';
import { NoItemsFound } from 'src/products/data-submission-portal/components/NoBatchesFound';

type DataValidationRuleDetailsTableTypes = {
  data: {
    records: { [key: string]: string | number }[];
    columns: string[];
    failedColumns: string[];
  };
  showUpgradeOverlay: boolean;
};

const DataValidationRuleDetailsTable: React.FC<
  DataValidationRuleDetailsTableTypes
> = ({ data, showUpgradeOverlay }) => {
  const [tableState, setTableState] = useCherreState(
    dataValidationDetailsTableState
  );

  const theme = useTheme();
  const itemsPerPage = 25;

  const getPaginatedData = (
    allData: any[],
    pageIndex: number,
    pageSize: number
  ) => {
    const start = pageIndex * pageSize;
    const end = start + pageSize;
    return allData.slice(start, end);
  };

  const paginatedData = useMemo(() => {
    const pageIndex = tableState?.pagination?.pageIndex ?? 0;
    const pageSize = tableState?.pagination?.pageSize ?? itemsPerPage;
    return getPaginatedData(data.records, pageIndex, pageSize);
  }, [data.records, tableState?.pagination]);

  const columns = useMemo(() => {
    const { columns } = data;

    return columns.map((columnName) => ({
      grow: true,
      accessorKey: columnName,
      header: columnName
        .split('_')
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(' '),
      size: 350,
      minSize: 150,
      maxSize: 400,
      isSizeAuto: true,
      Cell: ({ cell }) => (
        <Box
          sx={{
            backgroundColor: data.failedColumns.includes(columnName)
              ? `${theme.palette.error.light} !important`
              : theme.palette.common.white,
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '10.5px 10px 10.5px 10px',
            fontSize: '14px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {cell.getValue() ?? 'null'}
        </Box>
      ),
    }));
  }, [data]);

  const table = useTable({
    data: paginatedData,
    skeletonRowCount: 5,
    columns,
    enableSelectAll: false,
    enableRowActions: false,
    enableRowSelection: false,
    enableBottomToolbar: true,
    enablePagination: true,
    positionActionsColumn: 'last',
    layoutMode: 'grid',
    muiTablePaperProps: {
      sx: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        flex: 1,
      },
      elevation: 0,
    },
    muiTableContainerProps: {
      sx: {
        flex: 1,
        minHeight: '300px',
      },
    },
    state: {
      ...tableState,
    },
    manualSorting: false,
    manualPagination: true,
    onSortingChange: (sort) =>
      setTableState((s) => ({
        ...s,
        sorting: typeof sort === 'function' ? sort(s.sorting) : sort,
      })),
    onPaginationChange: (p) => {
      setTableState((s) => ({
        ...s,
        pagination: typeof p === 'function' ? p(s.pagination) : p,
      }));
    },
    getRowId: (row) => row._frontend_id as string,
    enableMultiSort: false,
    rowCount: data.records.length || 0,
    muiTableHeadRowProps: {
      sx: {
        ...tableBaseStyle.muiTableHeadRowProps.sx,
        '& th': {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          color: theme.palette.grey[700],
          '& .Mui-TableHeadCell-Content': {
            alignItems: 'center',
          },
        },
      },
    },
    muiTableBodyRowProps: {
      ...tableBaseStyle.muiTableBodyRowProps.sx,
      sx: {
        '& > :first-of-type': {
          fontWeight: 'bold',
        },
      },
    },
    muiTableBodyCellProps: {
      sx: {
        padding: '0px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  });

  return (
    <Container style={{ minWidth: '100%', position: 'relative' }}>
      {data.records.length === 0 && (
        <NoItemsFound title='Cannot find failed source records' />
      )}
      {data.records.length > 0 && (
        <>
          <Table table={table} />
          {showUpgradeOverlay && (
            <TableUpgradeOverlay
              rowCount={data.records.length}
              message='To view detailed validation test results, contact Cherre to upgrade your plan!'
            />
          )}
        </>
      )}
    </Container>
  );
};

export default DataValidationRuleDetailsTable;
