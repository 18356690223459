import { useCherreValueLoadable } from '@cherre-frontend/data-fetching';
import {
  ConnectorEnvironments,
  useConnectorEnvironment,
} from 'src/hooks/useConnectorEnvironment';
import {
  openMetadataTableLatestDataDemo,
  openMetadataTablesDemo,
} from 'src/services/ConnectorDemo/recoil';
import { selectedConnectorPublishedTables } from 'src/services/DOM/connectorValidationRules/recoil';
import { qualyticsObservabilityData } from 'src/services/Qualytics/recoil';

export const useGetColumnProfile = () => {
  const environment = useConnectorEnvironment();

  if (environment === ConnectorEnvironments.MVP) {
    const tables = useCherreValueLoadable(selectedConnectorPublishedTables);
    const latestTableData = useCherreValueLoadable(qualyticsObservabilityData);

    return { tables, latestTableData };
  } else {
    const tables = useCherreValueLoadable(openMetadataTablesDemo);
    const latestTableData = useCherreValueLoadable(
      openMetadataTableLatestDataDemo
    );

    return { tables, latestTableData };
  }
};
