import { ConnectorDetailOverview } from 'src/services/ConnectorDemo/data/ConnectorOverview';
import { ValidationStatus } from '../../../../../components/ValidationStatusPopover';

export const buildValidationStatusOverviewCard = (
  overviewData?: ConnectorDetailOverview
): ValidationStatus => {
  if (!overviewData) {
    return ValidationStatus.NOT_RUN;
  }

  const { models } = overviewData;
  const hasModelApproved = models.every(
    (model) => model.statusObject?.status === 'success'
  );

  if (hasModelApproved) {
    return ValidationStatus.PASSED;
  }

  const hasModelFailed = models.some(
    (model) => model.statusObject?.status === 'failed'
  );

  if (hasModelFailed) {
    return ValidationStatus.FAILED;
  }

  return ValidationStatus.NOT_RUN;
};
