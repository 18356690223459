import { BuildIndeterminateArgs, BuildIndeterminateReturn } from './model';

export const buildIndeterminate = ({
  form,
}: BuildIndeterminateArgs): BuildIndeterminateReturn => {
  const checkboxes = Object.keys(form);
  const allCheckboxesIsEmpty = checkboxes.every((checkbox) => !form[checkbox]);
  const allCheckboxesIsChecked = checkboxes.every((checkbox) => form[checkbox]);
  const indeterminate = !allCheckboxesIsEmpty && !allCheckboxesIsChecked;

  return {
    indeterminate,
    allCheckboxesIsEmpty,
    allCheckboxesIsChecked,
  };
};
