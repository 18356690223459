import { styled, Typography } from '@cherre-frontend/ui';

export const Container = styled('div')`
  border-radius: 5px;
  background: #eee;
  padding: 30px;
  grid-template-columns: 1fr;
  display: grid;
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.primary};
  font-feature-settings: 'liga' off;

  /* Headings/H6 */
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
