import { graphql } from 'relay-runtime';

export const GetConnectorsByOwnerQuery = graphql`
  query getConnectorsByOwnerQuery {
    connectors {
      name
      models {
        provider
        name
        preconfiguredModelRef
        publishedRevisions {
          revisionNumber
        }
      }
    }
  }
`.default;
