import React from 'react';
import { DateRangePicker, Range, RangeKeyDict } from 'react-date-range';
import { Box, Button } from '@cherre-frontend/ui';

type CustomDateRangePickerProps = {
  onChange: (rangesByKey: RangeKeyDict) => void;
  ranges: Range[];
  handleApplyDateDange: (range: any) => void;
};
const CustomDateRangePicker: React.FC<CustomDateRangePickerProps> = ({
  onChange,
  ranges,
  handleApplyDateDange,
}) => {
  return (
    <>
      <style>
        {`
          .rdrDefinedRangesWrapper {
            display: none;
            font-size: 12px;
            width: 226px;
            border-right: solid 1px #eff2f7;
            background: #fff;
          }
          .rdrDefinedRangesWrapper .rdrStaticRangeSelected {
            color: currentColor;
            font-weight: 600;
          }
          .rdrInputRanges {     
            display: none; 
          }
          .rdrDayToday .rdrDayNumber span:after {
            background: #DF2467;
            }
        `}
      </style>
      <Box sx={{ mb: '8px', width: '100%' }} data-testid='custom-date-picker'>
        <DateRangePicker
          onChange={onChange}
          showPreview
          moveRangeOnFirstSelection={false}
          retainEndDateOnFirstSelection
          months={2}
          ranges={ranges}
          direction='horizontal'
          staticRanges={[]}
          inputRanges={[]}
          color='#DF2467'
          rangeColors={['#DF2467', '#DF2467', '#DF2467']}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            backgroundColor: 'white',
          }}
        >
          <Button
            size='small'
            variant='text'
            color='primary'
            onClick={handleApplyDateDange}
          >
            Apply
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default CustomDateRangePicker;
