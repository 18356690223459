import React, { useRef } from 'react';
import {
  SelectChangeEvent,
  MenuItem,
  OutlinedInput,
  Select,
} from '@cherre-frontend/ui';

type TimeframeSelectProps = {
  onChange: (event: SelectChangeEvent<unknown>) => void;
  value: string;
};

const TimeframeSelect: React.FC<TimeframeSelectProps> = ({
  onChange,
  value,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  return (
    <div style={{ position: 'relative' }} ref={wrapperRef}>
      <Select
        size='small'
        fullWidth
        label={value}
        value={value}
        variant='outlined'
        onChange={onChange}
        input={<OutlinedInput notched={false} />}
      >
        <MenuItem value='week'>Week</MenuItem>
        <MenuItem value='month'>Month</MenuItem>
        <MenuItem value='quarter'>Quarter</MenuItem>
        <MenuItem value='year'>Year</MenuItem>
      </Select>
    </div>
  );
};

TimeframeSelect.displayName = 'TimeframeSelect';
export default TimeframeSelect;
