import { StatusBoxState } from '../../pages/connector-details/components/StatusBox';
import { ConnectorStatus } from '../../recoil';
import {
  BuildConnectorStatusArgs,
  BuildConnectorStatusReturn,
  BuildConnectorStepFailedConnectorArgs,
  BuildConnectorStepFailedConnectorReturn,
  BuildConnectorStepStatusArgs,
  BuildConnectorStepStatusReturn,
} from './model';

const buildConnectorStatus = ({
  status,
}: BuildConnectorStatusArgs): BuildConnectorStatusReturn => {
  if (!status) {
    return ConnectorStatus.SCHEDULED;
  }

  return status as ConnectorStatus;
};

const buildConnectorStepStatus = ({
  connectorStatus,
  stepStatusObject,
}: BuildConnectorStepStatusArgs): BuildConnectorStepStatusReturn => {
  if (connectorStatus === ConnectorStatus.DEACTIVATED) {
    return StatusBoxState.DEACTIVATED;
  }

  if (connectorStatus === ConnectorStatus.SCHEDULED) {
    return StatusBoxState.SCHEDULED;
  }

  if (connectorStatus === ConnectorStatus.LOADING) {
    return StatusBoxState.LOADING;
  }

  if (stepStatusObject.isRunning) {
    return StatusBoxState.RUNNING;
  }

  if (stepStatusObject.isFailed) {
    return StatusBoxState.FAILED;
  }

  if (stepStatusObject.notReady) {
    return StatusBoxState.NOT_READY;
  }

  return StatusBoxState.SUCCESS;
};

const buildStepStatusObject = (
  data
): BuildConnectorStepStatusArgs['stepStatusObject'] => {
  if (!data.length) {
    return {
      isFailed: false,
      isRunning: false,
      notReady: true,
      failedComponent: undefined,
    };
  }

  return data.reduce(
    (finalObject: BuildConnectorStepStatusArgs['stepStatusObject'], value) => {
      if (
        finalObject.isFailed ||
        finalObject.isRunning ||
        finalObject.notReady
      ) {
        return finalObject;
      }

      if (!value.statusObject) {
        finalObject.notReady = true;
        return finalObject;
      }

      if (!finalObject.notReady) {
        finalObject.notReady =
          value.statusObject.isRunning === null ||
          value.statusObject.status === null;
      }

      if (!finalObject.isRunning) {
        finalObject.isRunning = Boolean(value.statusObject?.isRunning);
      }

      if (!finalObject.isFailed) {
        finalObject.isFailed =
          value.statusObject.status === StatusBoxState.FAILED ||
          value.statusObject.status === StatusBoxState.UPSTREAM_FAILED;
        finalObject.failedComponent = value.displayName;
      }

      return finalObject;
    },
    {
      isFailed: false,
      isRunning: false,
      notReady: false,
      failedComponent: undefined,
    }
  );
};

export const buildConnectorAllStatuses = ({
  sources,
  models,
  status,
}: BuildConnectorStepFailedConnectorArgs): BuildConnectorStepFailedConnectorReturn => {
  const modelStatusObject = buildStepStatusObject(models);
  const sourceStatusObject = buildStepStatusObject(sources);
  const destinationStatuses: any[] = [];
  models.forEach((model) => {
    model.destinationStatuses?.forEach((destination) => {
      destinationStatuses.push({
        displayName: destination?.destinationName,
        statusObject: destination?.statusObject,
      });
    });
  });
  const destinationStatusObject = buildStepStatusObject(destinationStatuses);
  const connectorStatus = buildConnectorStatus({
    status,
  });
  let failedComponentName;
  let failedConnectorComponent;
  const connectorStatusObject = {
    sources: buildConnectorStepStatus({
      connectorStatus: status,
      stepStatusObject: sourceStatusObject,
    }),
    models: buildConnectorStepStatus({
      connectorStatus: status,
      stepStatusObject: modelStatusObject,
    }),
    destinations: buildConnectorStepStatus({
      connectorStatus: status,
      stepStatusObject: destinationStatusObject,
    }),
  };

  if (modelStatusObject.isFailed) {
    failedConnectorComponent = 'Model';
    failedComponentName = modelStatusObject.failedComponent;
  }

  if (sourceStatusObject.isFailed) {
    failedConnectorComponent = 'Source';
    failedComponentName = sourceStatusObject.failedComponent;
  }

  if (destinationStatusObject.isFailed) {
    failedConnectorComponent = 'Destination';
    failedComponentName = destinationStatusObject.failedComponent;
  }

  return {
    connectorStatusObject,
    failedComponentName,
    connectorStatus,
    failedConnectorComponent,
  };
};
