import moment from 'moment';
const cronParser = require('cron-parser');

export const getScheduledRunDates = (cronExpression) => {
  try {
    const interval = cronParser.parseExpression(cronExpression);
    const nextRunDate = interval.next().toDate();
    const prevRunDate = interval.prev().toDate();

    return {
      nextRun: moment(nextRunDate).format('MMM D YYYY h:mm A'),
      lastRun: moment(prevRunDate).format('MMM D YYYY h:mm A'),
    };
  } catch (err) {
    console.error('Error parsing cron expression:', err);
    return {
      nextRun: 'Not Scheduled',
      lastRun: 'N/A',
    };
  }
};
