import React from 'react';
import {
  styled,
  IconButton,
  Typography,
  ExpandIcon,
  Button,
} from '@cherre-frontend/ui';

const Container = styled(Button)<{ selected: boolean }>`
  padding: 0;
  cursor: pointer;

  & span {
    display: grid;
    grid-template-columns: 16px 16px auto 16px 16px;
    grid-template-rows: auto 10px auto;
    grid-template-areas: '. . image lightning expand' '. . . . .' 'title title title title title';
    border-radius: 10px;

    padding: 10px;
    align-items: center;
    width: 200px;
  }

  background: ${({ theme, selected }) =>
    selected ? `${theme.palette.primary.main}1a` : theme.palette.grey[50]};
  border: ${({ theme, selected }) =>
    selected ? `1px solid ${theme.palette.primary.main}` : '1px solid white'};

  &:hover {
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
    background: ${({ theme }) => theme.palette.primary.main}1a;
  }

  &:active {
    background: ${({ theme }) => theme.palette.primary.main}3a;
  }
`;

const Image = styled('img')`
  grid-area: image;
  height: 24.27px;
`;

const Lightning = styled('img')`
  grid-area: lightning;
  width: 16px;
  height: 16px;
`;

const Expand = styled(IconButton)`
  grid-area: expand;
  width: 16px;
  height: 16px;
  font-size: 16px;
`;

const Title = styled(Typography)`
  grid-area: title;
  color: ${({ theme }) => theme.palette.grey[700]};
  text-align: center;
  font-feature-settings: 'liga' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export type SourceCardProps = {
  title: string;
  image: string;
  isLightning?: boolean;
  selected?: boolean;
  onClick: () => void;
};

/**
 * Component representing a source card.
 *
 * @component
 * @param {SourceCardProps} props - The properties for the SourceCard component.
 * @param {string} props.title - The title of the source card.
 * @param {string} props.image - The image URL for the source card.
 * @param {boolean} [props.isLightning=false] - Indicates if the lightning icon should be displayed.
 * @param {boolean} [props.selected=false] - Indicates if the source card is selected.
 * @returns {JSX.Element} The rendered SourceCard component.
 */
const SourceCard: React.FC<SourceCardProps> = ({
  title,
  image,
  isLightning = false,
  selected = false,
  onClick,
}) => {
  return (
    <Container selected={selected} onClick={onClick}>
      <Image src={image} />
      {isLightning && <Lightning src='/assets/Lightning.svg' />}
      <Expand>
        <ExpandIcon sx={{ fontSize: '16px' }} />
      </Expand>
      <Title>{title}</Title>
    </Container>
  );
};

export default SourceCard;
