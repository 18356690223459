import React, { FC, ReactElement, useEffect, useMemo } from 'react';
import { useTheme, WarningIcon } from '@cherre-frontend/ui';
import {
  WarningMessageAddConnectorContainer,
  MessageContainer,
} from './styles';
import { useAddConnector } from '../../dataState';
import { useLazyLoadQuery } from 'react-relay';
import { ConnectorsListQuery } from 'src/services/DOM/queries/connectorsList';
import { connectorsListQuery } from 'src/services/DOM/queries/connectorsList/__generated__/connectorsListQuery.graphql';
import { verifyTakenSourceByConnector } from '../util/verifyTakenSourceByConnector';
import { useConnectorUIState } from '../../uiState/contexts';

/**
 * Component rendering a warning banner when user chooses a source already been taken by active connector
 *
 * @hook
 * - `useEffect` to update the global store with the repeatedSource new value.
 * - `useAddConnector` to get the source list set by the user
 * - `useConnectorUIState` to update the global state with value repeatedSource
 * - `useLazyLoadQuery` to get the connectors list
 * @returns {JSX.Element} The rendered WarningMessageAddConnector component.
 */

const WarningMessageAddConnector: FC = (): ReactElement | null => {
  const theme = useTheme();
  const [connectorData] = useAddConnector();
  const [uiState, setUiState] = useConnectorUIState();

  const { connectors } = useLazyLoadQuery<connectorsListQuery>(
    ConnectorsListQuery,
    {},
    { fetchPolicy: 'store-and-network' }
  );

  const repeatedSource = useMemo(() => {
    const chosenSource = connectorData?.sources?.[0].provider;

    return (
      uiState.repeatedSource ||
      verifyTakenSourceByConnector({
        connectors,
        chosenSource,
      })
    );
  }, [connectorData, connectors, uiState]);

  console.log({ connectorData, uiState, repeatedSource });

  useEffect(() => {
    setUiState((prev) => ({ ...prev, repeatedSource }));
  }, [repeatedSource]);

  if (!repeatedSource) {
    return null;
  }

  return (
    <WarningMessageAddConnectorContainer theme={theme}>
      <WarningIcon
        sx={{
          height: '20px',
          width: '20px',
          color: 'FFFFFF',
        }}
      />
      <MessageContainer>
        There is another active connector using the selected source, you cannot
        create a new connector. Please select a different source.
      </MessageContainer>
    </WarningMessageAddConnectorContainer>
  );
};

export default WarningMessageAddConnector;
