import React, { FC, ReactElement } from 'react';
import { Button, DeleteIcon, EditIcon, MenuItem } from '@cherre-frontend/ui';

type StepsMenuProps = {
  onClickEdit?: () => void;
  onClickDelete?: () => void;
};

/**
 * The `StepsMenu` component renders a float container with two buttons:
 * - "Edit": Allows edit the added item.
 * - "Delete": Delete the added item.
 *
 *
 *
 * @component
 * @param {StepsMenuProps} props - The properties for the StepsMenu component.
 * @param {function} props.onClickEdit - The callback triggered when the Edit item is clicked.
 * @param {function} props.onClickDelete - The callback triggered when the Delete item is clicked.
 * @returns {JSX.Element} The rendered component.
 */

const StepsMenu: FC<StepsMenuProps> = ({
  onClickEdit,
  onClickDelete,
}): ReactElement => {
  return (
    <>
      {onClickEdit && (
        <MenuItem>
          <Button startIcon={<EditIcon />} onClick={onClickEdit}>
            Edit
          </Button>
        </MenuItem>
      )}
      {onClickDelete && (
        <MenuItem>
          <Button startIcon={<DeleteIcon />} onClick={onClickDelete}>
            Delete
          </Button>
        </MenuItem>
      )}
    </>
  );
};

export default StepsMenu;
