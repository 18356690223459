import { createGlobalState } from '../utils';
import type {
  ConnectorUIStateType,
  ModelUIStateType,
  SourceUIStateType,
  DestinationUIStateType,
} from './schemas';

export const [ConnectorUIStateProvider, useConnectorUIState] =
  createGlobalState<ConnectorUIStateType>({
    step: 'default' as const,
    index: 0,
  });

export const [SourceUIStateProvider, useSourceUIState] =
  createGlobalState<SourceUIStateType>({
    sourceName: '',
    sourceType: 'SFTP' as const,
    provider: '',
    step: 0,
  });

export const [ModelUIStateProvider, useModelUIState] =
  createGlobalState<ModelUIStateType>({
    name: '',
    displayName: '',
    provider: '',
    stableVersion: 0,
  });

export const [DestinationUIStateProvider, useDestinationUIState] =
  createGlobalState<DestinationUIStateType>({
    destinationName: 'snowflake',
    chosenTables: [],
  });
