import React from 'react';
import { Grid, Skeleton } from '@cherre-frontend/ui';

const LandingPageSkeletonLoader: React.FC = () => {
  return (
    <Grid container direction='column' sx={{ width: '100%' }} padding={2}>
      <Grid container direction='column'>
        <Grid container direction='row'>
          <Grid item xs={6}>
            <Grid container direction='column'>
              <Grid item>
                <Skeleton
                  variant='text'
                  width={230}
                  height={60}
                  animation='wave'
                />
              </Grid>
              <Grid item>
                <Skeleton
                  variant='text'
                  width={200}
                  height={30}
                  animation='wave'
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container direction='column'>
              <Grid item justifyContent='end' display='flex'>
                <Skeleton
                  variant='rounded'
                  width={210}
                  height={40}
                  animation='wave'
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        direction='row'
        gap={4}
        justifyContent='space-evenly'
        wrap='nowrap'
      >
        <Grid item xs={8} />
        <Grid item xs={4} justifyContent='start'>
          <Skeleton variant='text' height={60} width={250} animation='wave' />
        </Grid>
      </Grid>
      <Grid container direction='column'>
        <Grid
          container
          direction='row'
          gap={4}
          justifyContent='space-evenly'
          wrap='nowrap'
        >
          <Grid item xs={8}>
            <Grid container direction='column'>
              <Grid item>
                <Grid
                  container
                  direction='row'
                  gap={1}
                  justifyContent='space-evenly'
                  wrap='nowrap'
                >
                  <Grid item xs={4}>
                    <Skeleton variant='rounded' height={100} animation='wave' />
                  </Grid>
                  <Grid item xs={4}>
                    <Skeleton variant='rounded' height={100} animation='wave' />
                  </Grid>
                  <Grid item xs={4}>
                    <Skeleton variant='rounded' height={100} animation='wave' />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item marginTop='16px'>
                <Grid container direction='row' justifyContent='space-between'>
                  <Grid item>
                    <Skeleton
                      variant='text'
                      height={65}
                      width={250}
                      animation='wave'
                    />
                  </Grid>
                  <Grid item>
                    <Skeleton
                      variant='text'
                      height={45}
                      width={160}
                      animation='wave'
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item paddingTop='8px'>
                <Grid container direction='column' gap={2}>
                  <Grid item>
                    <Skeleton
                      variant='rounded'
                      height={63}
                      width='100%'
                      animation='wave'
                    />
                  </Grid>
                  <Grid item>
                    <Skeleton
                      variant='rounded'
                      height={63}
                      width='100%'
                      animation='wave'
                    />
                  </Grid>
                  <Grid item>
                    <Skeleton
                      variant='rounded'
                      height={63}
                      width='100%'
                      animation='wave'
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={4}>
            <Grid container direction='column' gap={1}>
              <Grid item xs={6}>
                <Grid container direction='column' gap={1}>
                  <Grid item>
                    <Skeleton
                      variant='rounded'
                      height={63}
                      width='100%'
                      animation='wave'
                    />
                  </Grid>
                  <Grid item>
                    <Skeleton
                      variant='rounded'
                      height={63}
                      width='100%'
                      animation='wave'
                    />
                  </Grid>
                  <Grid item>
                    <Skeleton
                      variant='rounded'
                      height={63}
                      width='100%'
                      animation='wave'
                    />
                  </Grid>
                  <Grid item>
                    <Skeleton
                      variant='rounded'
                      height={63}
                      width='100%'
                      animation='wave'
                    />
                  </Grid>
                  <Grid item marginTop={0}>
                    <Skeleton
                      variant='text'
                      height={45}
                      width={160}
                      animation='wave'
                      sx={{ marginLeft: '12px' }}
                    />
                  </Grid>
                  <Grid item paddingTop='16px'>
                    <Skeleton
                      variant='text'
                      height={65}
                      width={300}
                      animation='wave'
                    />
                  </Grid>
                  <Grid item paddingTop='8px'>
                    <Skeleton
                      variant='rounded'
                      height={300}
                      width='100%'
                      animation='wave'
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

LandingPageSkeletonLoader.displayName = 'LandingPageSkeletonLoader';
export default LandingPageSkeletonLoader;
