import React, { useEffect, useState } from 'react';
import {
  MuiAutocomplete as Autocomplete,
  TextField,
} from '@cherre-frontend/ui';

import { useDspRelayEnvironment } from '../../../../../hooks/useDspRelayEnvironment';
import axios from 'axios';
import { useSelector } from 'react-redux';

interface DataValidationFormEmailsProps {
  onChange: (data: string[]) => void;
  values: string[];
}

type User = {
  email: string;
};

export const DataValidationFormEmailsContent = ({
  onChange,
  values,
}: DataValidationFormEmailsProps) => {
  const [users, setUsers] = useState<User[]>([]);

  const organizationId = useSelector(
    (state) => state.user.profile.value.organizationId
  );

  if (!organizationId) {
    throw new Error('Organization ID is missing');
  }

  useEffect(() => {
    axios
      .get<User[]>(`/api/v1/organizations/${organizationId}/users`)
      .then(({ data }) => setUsers(data));
  }, [organizationId]);

  const options = users.map(({ email }) => email);

  return (
    <Autocomplete
      multiple
      value={values}
      options={options}
      onChange={(e, data) => onChange(data.map((d) => d))}
      getOptionLabel={(option) => option}
      renderInput={(params) => (
        <TextField
          sx={{ borderBottomColor: 'gray.500' }}
          {...params}
          variant='standard'
        />
      )}
    />
  );
};

export const DataValidationFormEmails = (
  props: DataValidationFormEmailsProps
) => {
  const environment = useDspRelayEnvironment();

  if (!environment) {
    return null;
  }

  return <DataValidationFormEmailsContent {...props} />;
};
