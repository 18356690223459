import { useCherreState } from '@cherre-frontend/data-fetching';
import { Table, tableBaseStyle, useTable, Box } from '@cherre-frontend/ui';
import { NoItemsFound } from 'src/products/data-submission-portal/components/NoBatchesFound';
import React, { useCallback, useMemo } from 'react';
import { columnProfileTableState } from '../../recoil';
import ColumnProfileTableProgressElement from './ColumnProfileTableProgressElement';
import { ColumnItem } from 'src/services/OpenMetadata/types';

type ColumnProfileTableProps = {
  data: ColumnItem[];
};

const ColumnProfileTable: React.FC<ColumnProfileTableProps> = ({ data }) => {
  const [tableState, setTableState] = useCherreState(columnProfileTableState);

  const convertDecimalToPercentage = useCallback(
    (decimalValue: number, roundValue = true): number => {
      const percentage = decimalValue * 100;
      if (!roundValue) {
        return Number(percentage.toFixed(2));
      }

      return Math.round(percentage);
    },
    []
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        size: 150,
      },
      {
        accessorKey: 'dataType',
        header: 'Data Type',
        size: 60,
      },
      {
        accessorKey: 'profile.nullProportion',
        header: 'Null %',
        size: 60,
        Cell: ({ row }) => {
          const profile = row.original.profile;
          if (!profile || profile.nullProportion === undefined) {
            return <ColumnProfileTableProgressElement value={0} />;
          }
          if (profile) {
            const convertedValue = convertDecimalToPercentage(
              row.original.profile.nullProportion
            );
            return <ColumnProfileTableProgressElement value={convertedValue} />;
          }
        },
      },
      {
        accessorKey: 'profile.distinctProportion',
        header: 'Distinct %',
        size: 60,
        Cell: ({ row }) => {
          const { profile } = row.original;
          if (!profile || profile.distinctProportion === undefined) {
            return <ColumnProfileTableProgressElement value={0} />;
          }

          const convertedValue = convertDecimalToPercentage(
            row.original.profile.distinctProportion,
            false
          );

          return <ColumnProfileTableProgressElement value={convertedValue} />;
        },
      },
      {
        accessorKey: 'profile.valuesCount',
        header: 'Value Count',
        size: 70,
        accessorFn: (row) =>
          !row.profile || row.profile.valuesCount === undefined
            ? 0
            : row.profile.valuesCount.toLocaleString(),
      },
    ],
    []
  );

  const table = useTable({
    data: data,
    skeletonRowCount: 5,
    columns,
    enableSelectAll: false,
    enableRowActions: false,
    enableRowSelection: false,
    enableBottomToolbar: false,
    enablePagination: false,
    positionActionsColumn: 'last',
    layoutMode: 'grid',
    muiTablePaperProps: {
      sx: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        flex: 1,
      },
      elevation: 0,
    },
    muiTableContainerProps: {
      sx: {
        flex: 1,
      },
    },
    state: {
      sorting: tableState?.sorting,
    },
    manualSorting: false,
    onSortingChange: (sort) =>
      setTableState((s) => ({
        ...s,
        sorting: typeof sort === 'function' ? sort(s.sorting) : sort,
      })),
    getRowId: (row) => row.name as string,
    enableMultiSort: false,
    muiTableHeadRowProps: {
      sx: {
        ...tableBaseStyle.muiTableHeadRowProps.sx,
        '& th': {
          color: 'black',
          '& .Mui-TableHeadCell-Content': {
            alignItems: 'center',
          },
        },
      },
    },
    muiTableBodyRowProps: {
      sx: { ...tableBaseStyle.muiTableBodyRowProps.sx },
    },
  });

  if (data && data.length === 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <NoItemsFound title='No Columns Found' />
      </Box>
    );
  }

  return (
    <div
      style={{ paddingTop: '20px', display: 'flex', flex: 1, width: '100%' }}
    >
      <Table table={table} />
    </div>
  );
};

export default ColumnProfileTable;
