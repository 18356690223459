import { graphql } from 'relay-runtime';

export const DeactivateRuleMutation = graphql`
  mutation deactivateRuleMutation($ruleName: String!) {
    deactivateRule(ruleName: $ruleName) {
      name
      namespace
      owner
    }
  }
`.default;
