import React, { FC, ReactElement } from 'react';
import { useTheme, WarningIcon } from '@cherre-frontend/ui';
import {
  ConnectorErrorBannerContainer,
  MessageContainer,
  MessageContent,
  StatusContent,
} from './styles';
import { ConnectorErrorBannerProps } from './model';

const ConnectorErrorBanner: FC<ConnectorErrorBannerProps> = ({
  statusMessage,
  connectorComponentName,
}): ReactElement => {
  const theme = useTheme();

  return (
    <ConnectorErrorBannerContainer theme={theme}>
      <WarningIcon
        sx={{
          height: '20px',
          width: '20px',
          color: 'FFFFFF',
        }}
      />
      <MessageContainer>
        <StatusContent>{statusMessage}</StatusContent>
        <MessageContent> - </MessageContent>
        <MessageContent>Something has gone wrong in </MessageContent>
        <MessageContent>{connectorComponentName} </MessageContent>
        <MessageContent>
          for this connector, a detailed error report has been sent
          automatically to Cherre Support for investigation.
        </MessageContent>
      </MessageContainer>
    </ConnectorErrorBannerContainer>
  );
};

export default ConnectorErrorBanner;
