import { useTheme, WatchLaterIcon, Box, Typography } from '@cherre-frontend/ui';
import React from 'react';

type RunningComponentIndicatorProps = {
  message: string;
};

const RunningComponentIndicator: React.FC<RunningComponentIndicatorProps> = ({
  message,
}) => {
  const theme = useTheme();
  return (
    <Box display='flex' justifyContent='center' alignItems='center'>
      <WatchLaterIcon
        sx={{
          color: theme.palette.grey[400],
          width: '16px',
          height: '16px',
        }}
      />
      <Typography
        fontSize='12px'
        marginLeft='4px'
        color={theme.palette.grey[400]}
      >
        {message}
      </Typography>
    </Box>
  );
};

export default RunningComponentIndicator;
