import {
  VerifyTakenSourceByConnectorArgs,
  VerifyTakenSourceByConnectorReturn,
} from './model';

export const verifyTakenSourceByConnector = ({
  connectors,
  chosenSource,
}: VerifyTakenSourceByConnectorArgs): VerifyTakenSourceByConnectorReturn => {
  if (!chosenSource) {
    return false;
  }
  return connectors.some((connector) => {
    if (connector.statusObject?.status === 'deactivated') {
      return false;
    }

    return connector.sources.some((source) => source.provider === chosenSource);
  });
};
