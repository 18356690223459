import React from 'react';
import { Grid } from '@cherre-frontend/ui';
import FormSelect from './FormSelect';
import FormSwitch from './FormSwitch';
import FormTextInput from './FormTextInput';
import FormValueList from './FormValueList';
import { FormUI, FormInputs } from './utils/types';
import { useLocation } from 'react-router';

type Props = {
  ruleUI: FormUI[];
};

const RuleUI: React.FC<Props> = ({ ruleUI }) => {
  const gridSize = ruleUI.length > 1 ? 6 : 12;
  const path = useLocation().pathname;

  return (
    <Grid
      container
      direction='row'
      spacing={3}
      sx={{
        '& .MuiGrid-item': {
          paddingRight: path.includes('/validationDetails') ? '12px' : '0px',
        },
      }}
    >
      {ruleUI.map((ui, index) => {
        switch (ui.type) {
          case FormInputs.TEXT:
            return (
              <Grid
                item
                key={`text-input-${index}`}
                xs={ui.fieldName === 'sqlExpression' ? 12 : gridSize}
              >
                <FormTextInput
                  title={ui.title}
                  required={ui.required}
                  placeholder={ui.placeholder ?? ''}
                  fieldName={ui.fieldName}
                  multiline={ui.multiline}
                  numeric={ui.numeric}
                />
              </Grid>
            );
          case FormInputs.SELECT:
            return (
              <Grid item key={`select-${index}`} xs={gridSize}>
                <FormSelect
                  title={ui.title}
                  required={ui.required}
                  fieldName={ui.fieldName}
                  menuOptions={ui.menuOptions ?? []}
                />
              </Grid>
            );
          case FormInputs.VALUE_LIST:
            return (
              <Grid item key={`value-list-${index}`} xs={gridSize}>
                <FormValueList
                  title={ui.title}
                  required={ui.required}
                  placeholder={ui.placeholder ?? ''}
                  fieldName={ui.fieldName}
                />
              </Grid>
            );
          case FormInputs.SWITCH:
            return (
              <Grid
                item
                key={`switch-${index}`}
                xs={gridSize}
                sx={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  marginBottom: '6px',
                }}
              >
                <FormSwitch title={ui.title} fieldName={ui.fieldName} />
              </Grid>
            );
          default:
            return;
        }
      })}
    </Grid>
  );
};

export default RuleUI;
