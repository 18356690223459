import React from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import { useDataValidationForm } from './dataValidationFormEngine';
import {
  Box,
  ControlledCheckbox,
  ControlledInput,
  Typography,
} from '@cherre-frontend/ui';
import { RulesQueryDataRuleLibraryItem } from '../../../../queries/types';
import { DynamicDataValidationFieldList } from './DynamicDataValidationFieldList';
import { DataValidationFormActions } from './DataValidationFormActions';
import StyledLabel from '../../../../../DataValidation/Label';
import { DataValidationFormEmails } from './DataValidationFormEmails/DataValidationFormEmails';

interface DataValidationFormProps {
  ruleItem?: RulesQueryDataRuleLibraryItem;
  isCreateMode: boolean;
}

export const DataValidationForm = ({
  ruleItem,
  isCreateMode,
}: DataValidationFormProps) => {
  const form = useDataValidationForm(ruleItem);

  return (
    <FormProvider {...form}>
      <Box display='flex' gap='20px' flexDirection='column'>
        <div>
          <ControlledInput
            name='name'
            label='Name'
            control={form.control}
            required
            disabled
            sx={{ maxWidth: '50%' }}
          />
        </div>

        <div>
          <ControlledInput
            name='description'
            label='Description'
            control={form.control}
            required
            disabled={!isCreateMode}
            multiline
            rows={2}
          />
        </div>
      </Box>

      <DynamicDataValidationFieldList isCreateMode={isCreateMode} />

      <Box
        sx={{
          padding: '26px 0',
          borderBottom: '1px solid #E0E0E0',
          borderTop: '1px solid #E0E0E0',
          marginTop: '20px',
        }}
      >
        <Typography fontWeight='600'>
          How do you want to get alerted if a check fails?
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '20px',
          }}
        >
          <ControlledCheckbox
            name='emailNotification'
            control={form.control}
            label='E-mail'
            color='primary'
          />

          <ControlledCheckbox
            name='inAppNotification'
            control={form.control}
            label='In app notification (Coming soon)'
            disabled
            color='primary'
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <StyledLabel style={{ fontSize: '14px', fontWeight: 600 }}>
            Who do you want to notify if a check fails?
          </StyledLabel>
          <Controller
            name='notificationEmails'
            control={form.control}
            render={({ field }) => (
              <DataValidationFormEmails
                onChange={field.onChange}
                values={field.value || []}
              />
            )}
          />
        </Box>
      </Box>

      <DataValidationFormActions rule={ruleItem} />
    </FormProvider>
  );
};
