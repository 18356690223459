import React from 'react';
import {
  Box,
  Grid,
  useTheme,
  Typography,
  CheckCircleIcon,
  WarningIcon,
  Theme,
  ConnectorRunningIcon,
} from '@cherre-frontend/ui';

type Status = 'active' | 'running' | 'failed';

type StatusCardTypes = {
  value: number;
  statusText: string;
  status: Status;
};

const getStatusProperties = (status: Status, theme: Theme) => {
  switch (status) {
    case 'active':
      return {
        statusIcon: (
          <CheckCircleIcon
            sx={{
              color: theme.palette.success.dark,
              width: '20px',
              height: '20px',
            }}
          />
        ),
        statusColor: theme.palette.success.dark,
      };
    case 'running':
      return {
        statusIcon: (
          <ConnectorRunningIcon
            style={{
              width: '20px',
              height: '20px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: theme.palette.warning.dark,
            }}
          />
        ),
        statusColor: theme.palette.warning.dark,
      };
    case 'failed':
      return {
        statusIcon: (
          <WarningIcon
            sx={{
              color: theme.palette.error.main,
              width: '20px',
              height: '20px',
            }}
          />
        ),
        statusColor: theme.palette.error.main,
      };
    default:
      return {
        statusIcon: null,
        statusColor: theme.palette.text.primary,
      };
  }
};

const StatusCard: React.FC<StatusCardTypes> = ({
  value,
  statusText,
  status,
}) => {
  const theme = useTheme();
  const { statusIcon, statusColor } = getStatusProperties(status, theme);

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.grey[50],
        borderRadius: '20px',
        width: '100%',
      }}
    >
      <Grid container direction='column' padding='16px 20px'>
        <Grid item>
          <Typography
            fontSize='30px'
            fontWeight='bold'
            color={status === 'failed' ? 'error' : 'black'}
          >
            {value}
          </Typography>
        </Grid>
        <Grid item container direction='row' alignItems='center'>
          <Grid item>{statusIcon}</Grid>
          <Grid item>
            <Typography
              fontSize='14px'
              fontWeight='medium'
              marginLeft='6px'
              color={statusColor}
            >
              {statusText}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

StatusCard.displayName = 'LandingPageStatusCard';
export default StatusCard;
