import React, { useMemo } from 'react';

import { DataValidationList } from './components/DataValidationList';
import { DataValidationV2TabContent } from './hooks/useDataValidationV2SearchParams';
import { DataValidationDetails } from './components/DataValidationDetails';
import { DataValidationV2Provider } from './providers/DataValidationV2Provider';
import { useCherreState } from '@cherre-frontend/data-fetching';
import { connectorDataValidationTabParam } from '../../../../recoil';

export const DataValidationV2Content = () => {
  const [dataValidationV2Content] = useCherreState(
    connectorDataValidationTabParam
  );
  const content = useMemo(() => {
    switch (dataValidationV2Content?.content) {
      case DataValidationV2TabContent.DETAILS:
        return <DataValidationDetails />;
      default:
        return <DataValidationList />;
    }
  }, [dataValidationV2Content]);

  return <DataValidationV2Provider>{content}</DataValidationV2Provider>;
};
