import { styled, Checkbox, FormControlLabel } from '@cherre-frontend/ui';

export const CustomCheckbox = styled(Checkbox)`
  padding: 0;
`;

export const CustomFormControlLabel = styled(FormControlLabel)`
  margin-left: 0;
  margin-right: 0;
  display: flex;
  gap: 12px;
`;

export const CheckboxContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.07px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 8px;
`;

export const CheckboxListContainer = styled('div')`
  margin-top: 12px;
`;

export const CheckboxSelectAllContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.07px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 8px;
  color: #df2467;
`;

export const CheckboxOptionsContainer = styled('div')`
  display: grid;
  grid-template-columns: auto auto;
`;
