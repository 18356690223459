import React from 'react';
import { Grid, Skeleton } from '@cherre-frontend/ui';

const DataValidationTestResultsLoader = () => {
  return (
    <Grid container direction='column' padding='32px'>
      <Grid container direction='column'>
        <Grid item>
          <Skeleton variant='text' height='50px' width='200px' />
        </Grid>
        <Grid item marginTop='20px'>
          <Skeleton variant='rounded' height='30px' width='380px' />
        </Grid>
      </Grid>
      <Grid container direction='row' marginTop='50px'>
        <Grid item xs={6}>
          <Grid
            container
            direction='row'
            gap={1}
            display='flex'
            alignItems='center'
          >
            <Grid item>
              <Skeleton variant='text' width='60px' height='30px' />
            </Grid>
            <Grid item marginLeft='8px'>
              <Skeleton
                variant='rounded'
                width='90px'
                height='25px'
                sx={{ borderRadius: '20px' }}
              />
            </Grid>
          </Grid>
          <Grid container direction='column' gap={1} marginTop='20px'>
            <Grid container direction='row'>
              <Grid item>
                <Skeleton variant='rounded' width='235px' height='75px' />
              </Grid>
              <Grid item marginLeft='8px'>
                <Skeleton variant='rounded' width='235px' height='75px' />
              </Grid>
            </Grid>
            <Grid container marginTop='20px'>
              <Grid item>
                <Skeleton variant='rounded' width='200px' height='25px' />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid
            container
            display='flex'
            justifyContent='end'
            alignItems='center'
          >
            <Grid item xs={10}>
              <Skeleton variant='rounded' height='200px' width='100%' />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        marginTop='20px'
        display='flex'
        justifyContent='center'
        alignItems='center'
      >
        <Skeleton variant='rounded' height='300px' width='100%' />
      </Grid>
    </Grid>
  );
};

DataValidationTestResultsLoader.displayName = 'DataValidationTestResultsLoader';
export default DataValidationTestResultsLoader;
