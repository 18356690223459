import React from 'react';
import {
  Checkbox,
  FilterChip,
  Grid,
  Menu,
  MenuItem,
  useTheme,
} from '@cherre-frontend/ui';
import {
  connectorInventoryTableFiltersState,
  ConnectorStatus,
} from '../recoil';
import { useCherreState } from '@cherre-frontend/data-fetching';
import { alpha } from '@mui/material/styles';
import capitalize from 'src/utils/capitalize';

const Filters: React.FC = () => {
  const theme = useTheme();
  const [filterState, setFilterState] = useCherreState(
    connectorInventoryTableFiltersState
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  if (!filterState) {
    return <></>;
  }

  const handleFilterClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleFilterChange = (value: string) => () => {
    if (value === 'Any Status') {
      setFilterState([]);
      handleFilterClose();
    } else {
      setFilterState((oldState) => {
        if (oldState.includes(value)) {
          return oldState.filter((status) => status !== value);
        } else {
          return oldState.concat(value);
        }
      });
    }
  };

  const filterData = [
    ConnectorStatus.SUCCESS,
    ConnectorStatus.SCHEDULED,
    ConnectorStatus.FAILED,
    ConnectorStatus.DEACTIVATED,
    ConnectorStatus.DRAFT,
  ];

  return (
    <Grid display='flex' gap='10px'>
      <FilterChip
        label={filterState.length === 0 ? `Any Status` : filterState.join(', ')}
        onClick={handleFilterClick}
        active={filterState.length > 0}
        sx={{
          height: '29px',
          ...(filterState.length > 0 && {
            backgroundColor: 'transparent',
            color: theme.palette.secondary.main,
            border: `1px ${theme.palette.secondary.main} solid`,
            '&:hover': {
              backgroundColor: alpha(theme.palette.secondary.main, 0.12),
            },
            '& .MuiSvgIcon-root': {
              color: theme.palette.secondary.main,
              '&:hover': {
                color: alpha(theme.palette.secondary.main, 0.7),
              },
            },
          }),
        }}
        onDelete={handleFilterChange(`Any Status`)}
      />
      <Menu
        id={`status-filter-menu`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleFilterClose}
        data-testid={`status-filter-menu`}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ left: '100px' }}
      >
        <MenuItem
          style={{ width: '200px', gap: '6px' }}
          onClick={handleFilterChange(`Any Status`)}
        >
          Any Status
        </MenuItem>
        {filterData.map((value) => (
          <MenuItem
            style={{ width: '200px', gap: '6px' }}
            onClick={handleFilterChange(value)}
          >
            <Checkbox disableRipple checked={filterState.includes(value)} />
            {capitalize(value)}
          </MenuItem>
        ))}
      </Menu>
    </Grid>
  );
};

export default Filters;

Filters.displayName = 'Filters';
