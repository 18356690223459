import React, { ReactElement } from 'react';
import { Skeleton, styled } from '@cherre-frontend/ui';

const Container = styled('div')`
  margin-top: 12px;
`;

const CheckboxOptionsContainer = styled('div')`
  display: grid;
  grid-template-columns: auto auto;
  margin-top: 8px;
`;

/**
 * Component representing the skeletor loading state when the client is trying to retrieve the data from graphql query.
 *
 * @returns {ReactElement} The rendered CheckboxTableSkeleton component.
 */

const CheckboxTableSkeleton = (): ReactElement => (
  <Container>
    <Skeleton
      sx={{ marginBottom: '20px' }}
      variant='rectangular'
      width={200}
      height={20}
    />
    <Skeleton
      sx={{ marginBottom: '8px' }}
      variant='rectangular'
      width={200}
      height={17}
    />
    <Skeleton
      sx={{ marginBottom: '12px' }}
      variant='rectangular'
      width={200}
      height={17}
    />

    <Skeleton variant='rectangular' width={150} height={29} />
    <CheckboxOptionsContainer>
      <Skeleton
        variant='rectangular'
        sx={{ marginBottom: '8px' }}
        width={150}
        height={29}
      />
      <Skeleton
        variant='rectangular'
        sx={{ marginBottom: '8px' }}
        width={150}
        height={29}
      />
      <Skeleton
        variant='rectangular'
        sx={{ marginBottom: '8px' }}
        width={150}
        height={29}
      />
      <Skeleton
        variant='rectangular'
        sx={{ marginBottom: '8px' }}
        width={150}
        height={29}
      />
      <Skeleton
        variant='rectangular'
        sx={{ marginBottom: '8px' }}
        width={150}
        height={29}
      />
      <Skeleton
        variant='rectangular'
        sx={{ marginBottom: '8px' }}
        width={150}
        height={29}
      />
      <Skeleton
        variant='rectangular'
        sx={{ marginBottom: '8px' }}
        width={150}
        height={29}
      />
      <Skeleton
        variant='rectangular'
        sx={{ marginBottom: '8px' }}
        width={150}
        height={29}
      />
    </CheckboxOptionsContainer>
  </Container>
);

export default CheckboxTableSkeleton;
