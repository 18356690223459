import React from 'react';
import {
  RuleItem,
  useDataValidationTableColumns,
} from './useDataValidationTableColumns';
import { Table, useTable } from '@cherre-frontend/ui';

interface DataValidationTableProps {
  ruleLibraryItems: RuleItem[];
}

export const DataValidationTable = ({
  ruleLibraryItems: data,
}: DataValidationTableProps) => {
  const columns = useDataValidationTableColumns();

  const table = useTable({
    data,
    columns,
    skeletonRowCount: 5,
    enableSelectAll: false,
    enableRowActions: false,
    enableRowSelection: false,
    enableBottomToolbar: false,
    enablePagination: false,
    enableMultiSort: false,
    manualSorting: false,
    positionActionsColumn: 'last',
    layoutMode: 'grid',
    muiTablePaperProps: {
      sx: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        flex: 1,
      },
      elevation: 0,
    },
    muiTableContainerProps: {
      sx: {
        flex: 1,
      },
    },
  });

  return <Table table={table} />;
};
