import {
  string,
  object,
  withDefault,
  writableArray,
  bool,
  number,
} from '@recoiljs/refine';
import { refine, atom, selector } from '@cherre-frontend/data-fetching';
import { ConnectorDetailsRoute } from '../../../routes';
import { RuleTypes } from '../components/DataValidation/utils/types';
import { dataValidationSelectedTableFilter } from 'src/products/connector/recoil';
import { selectedConnectorQualyticsValidationRules } from 'src/services/Qualytics/recoil';

export const columnProfileTableState =
  ConnectorDetailsRoute.searchParamsSelectorFamily({
    key: 'connector-details-column-profile-table',
    refine: withDefault(
      object({
        sorting: writableArray(object({ id: string(), desc: bool() })),
      }),
      {
        sorting: [{ id: 'name', desc: false }],
      }
    ),
  });

export const connectorDetailsColumnProfileSelectedTable =
  ConnectorDetailsRoute.searchParamsSelectorFamily({
    key: 'connector-details-column-profile-selected-table',
    refine: withDefault(string(), ''),
  });

export const connectorDetailsColumnProfileTableSearch =
  ConnectorDetailsRoute.searchParamsSelectorFamily({
    key: 'connector-details-column-profile-table-search',
    refine: withDefault(string(), ''),
  });

export const dataValidationTableState =
  ConnectorDetailsRoute.searchParamsSelectorFamily({
    key: 'connector-details-data-validation-table',
    refine: withDefault(
      object({
        pagination: object({
          pageSize: refine.allowedNumbers([25, 50, 100]),
          pageIndex: number(),
        }),
        sorting: writableArray(object({ id: string(), desc: bool() })),
      }),
      {
        pagination: { pageSize: 25, pageIndex: 0 },
        sorting: [],
      }
    ),
  });

export const dataDictionaryTableState =
  ConnectorDetailsRoute.searchParamsSelectorFamily({
    key: 'connector-details-data-dictionary-table',
    refine: withDefault(
      object({
        sorting: writableArray(object({ id: string(), desc: bool() })),
      }),
      {
        sorting: [{ id: 'name', desc: false }],
      }
    ),
  });

export const connectorDetailsDataValidationState =
  ConnectorDetailsRoute.searchParamsSelectorFamily({
    key: 'connector-details-data-validation-page-state',
    refine: withDefault(
      refine.stringLiterals([
        'Dashboard',
        'Table_Selection',
        'Table',
        'Column',
        'Details',
      ] as const),
      'Dashboard'
    ),
  });

export const dataValidationRuleForm = atom({
  key: 'data-validation-rule-form-state',
  default: {
    name: '',
    rule_type: '',
    column: '',
    description: '',
    [RuleTypes.COLUMN_VALUES_TBB]: {
      minValue: '',
      maxValue: '',
    },
    [RuleTypes.COLUMN_VALUE_STD_DEV_TBB]: {
      minValueForStdDevInCol: '',
      maxValueForStdDevInCol: '',
    },
    [RuleTypes.COLUMN_VALUE_LENGTHS_TBB]: {
      minLength: '',
      maxLength: '',
    },
    [RuleTypes.COLUMN_VALUE_MIN_TBB]: {
      minValueForMinInCol: '',
      maxValueForMinInCol: '',
    },
    [RuleTypes.COLUMN_VALUES_MISSING_COUNT]: {
      missingCountValue: '',
      missingValueMatch: '',
    },
    [RuleTypes.COLUMN_VALUE_MAX_TBB]: {
      minValueForMaxInCol: '',
      maxValueForMaxInCol: '',
    },
    [RuleTypes.COLUMN_VALUES_TO_NOT_MATCH_REGEX]: {
      forbiddenRegex: '',
    },
    [RuleTypes.COLUMN_VALUE_MEDIAN_TBB]: {
      minValueForMedianInCol: '',
      maxValueForMedianInCol: '',
    },
    [RuleTypes.COLUMN_VALUES_TO_MATCH_REGEX]: {
      regex: '',
    },
    [RuleTypes.COLUMN_VALUE_MEAN_TBB]: {
      minValueForMeanInCol: '',
      maxValueForMeanInCol: '',
    },
    [RuleTypes.COLUMN_VALUES_SUM_TBB]: {
      minValueForColSum: '',
      maxValueForColSum: '',
    },
    [RuleTypes.COLUMN_VALUES_TO_BE_IN_SET]: {
      allowedValues: [''],
    },
    [RuleTypes.COLUMN_VALUES_TO_BE_NOT_IN_SET]: {
      forbiddenValues: [''],
    },
    [RuleTypes.TABLE_ROW_COUNT_TO_EQUAL]: {
      value: '',
    },
    [RuleTypes.TABLE_COLUMN_COUNT_TO_EQUAL]: {
      columnCount: '',
    },
    [RuleTypes.TABLE_COLUMN_NAME_TO_EXIST]: {
      columnName: '',
    },
    [RuleTypes.TABLE_ROW_COUNT_TBB]: {
      minValue: '',
      maxValue: '',
    },
    [RuleTypes.TABLE_COLUMN_COUNT_TBB]: {
      minColValue: '',
      maxColValue: '',
    },
    [RuleTypes.TABLE_COLUMN_TO_MATCH_SET]: {
      columnNames: '',
      ordered: false,
    },
    [RuleTypes.TABLE_ROW_INSERTED_COUNT_TBB]: {
      min_row_count: '',
      max_row_count: '',
      column_name: '',
      range_type: '',
      interval: '',
    },
    [RuleTypes.TABLE_CUSTOM_SQL_QUERY]: {
      sqlExpression: '',
      strategy: '',
      threshold: '',
    },
  },
});

export const dataValidationTableSearch =
  ConnectorDetailsRoute.searchParamsSelectorFamily({
    key: 'data-validation-table-search',
    refine: withDefault(string(), ''),
  });

export const dataValidationTestsTrigger = atom({
  key: 'data-validation-tests-trigger',
  default: '',
});

export const selectedConnectorName = selector<string | null>({
  key: 'SELECTED-CONNECTOR-NAME',
  get: ({ get }) => {
    const connectorName = get(
      ConnectorDetailsRoute.routeParamSelector
    )?.connector_name;
    return connectorName || null;
  },
});

export const filteredValidationRules = selector({
  key: 'DOM/FILTERED-VALIDATION-RULES',
  scoped: true,
  get:
    () =>
    async ({ get }): Promise<any> => {
      const selectedTable = get(dataValidationSelectedTableFilter);

      if (!selectedTable) {
        return { data: [] };
      }

      const rules = get(selectedConnectorQualyticsValidationRules);

      const filteredRules = rules?.data.filter((rule) => {
        return rule.tableName === selectedTable;
      });
      return { data: filteredRules };
    },
});
