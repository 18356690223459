import { SFTPSourceType } from '../../../dataState';
import moment from 'moment-timezone';

/**
 * Retrieves the current time zone label.
 *
 * This function uses the `moment-timezone` library to guess the user's time zone
 * and formats it into a human-readable label. If the formatted time zone contains
 * alphabetic characters, it returns the formatted string directly. Otherwise, it
 * prefixes the formatted string with "UTC".
 *
 * @returns {string} The current time zone label.
 */
const getTZ = () => {
  const timeZone = moment.tz.guess();
  const timezoneMoment = moment().tz(timeZone).format('z');
  const timezoneLabel = /[a-zA-Z]/g.test(timezoneMoment)
    ? timezoneMoment
    : `UTC${timezoneMoment}`;
  return timezoneLabel;
};

/**
 * Sets the availability time based on the provided moment object.
 *
 * @param {moment.Moment | null} value - The moment object representing the time, or null.
 * @returns {{ hour: number, minute: number, timezone: string }} An object containing the hour, minute, and timezone.
 */
export const setAvailability = (value: moment.Moment | null) => {
  if (!value) {
    return { hour: 0, minute: 0, timezone: getTZ() };
  }

  const desiredTime = value.isValid() ? value : moment(new Date());

  const hour = desiredTime.get('hour');
  const minute = desiredTime.get('minute');
  const timezone = getTZ();
  return { hour, minute, timezone };
};

/**
 * Retrieves the availability time as a moment object.
 *
 * @param {SFTPSourceType['availability']} [availability] - The availability object containing hour and minute.
 * @returns {moment.Moment} - A moment object set to the specified availability time or the current time if availability is not provided.
 */
export const getAvailability = (
  availability?: SFTPSourceType['availability']
): moment.Moment | null => {
  if (!availability) {
    return null;
  }
  const value = moment();
  value.set('hour', availability.hour);
  value.set('minute', availability.minute);
  return value;
};

type Frequency = SFTPSourceType['updateFrequency'];

/**
 * Returns an array of frequency options based on the provided frequency type.
 *
 * @param {Frequency} [frequency] - The frequency object containing the type of frequency.
 * @returns {Array<[string, number]>} An array of frequency options, where each option is a tuple containing a string description and a corresponding numeric value.
 *
 * The possible frequency types and their corresponding options are:
 * - 'daily': Returns an empty array.
 * - 'weekdays': Returns an empty array.
 * - 'weekly': Returns an array of options for each day of the week.
 * - 'monthly': Returns an array of options for each day of the month.
 * - Default: Returns an empty array.
 */
export const getFrequencyOptions = (frequency?: Frequency) => {
  if (!frequency) {
    return [];
  }
  switch (frequency.type) {
    case 'daily':
      return [];
    case 'weekdays':
      return [];
    case 'weekly':
      return [
        ['Every Sunday', 0],
        ['Every Monday', 1],
        ['Every Tuesday', 2],
        ['Every Wednesday', 3],
        ['Every Thursday', 4],
        ['Every Friday', 5],
        ['Every Saturday', 6],
      ];
    case 'monthly':
      return [
        ['Every 1st', 1],
        ['Every 2nd', 2],
        ['Every 3rd', 3],
        ['Every 4th', 4],
        ['Every 5th', 5],
        ['Every 6th', 6],
        ['Every 7th', 7],
        ['Every 8th', 8],
        ['Every 9th', 9],
        ['Every 10th', 10],
        ['Every 11th', 11],
        ['Every 12th', 12],
        ['Every 13th', 13],
        ['Every 14th', 14],
        ['Every 15th', 15],
        ['Every 16th', 16],
        ['Every 17th', 17],
        ['Every 18th', 18],
        ['Every 19th', 19],
        ['Every 20th', 20],
        ['Every 21st', 21],
        ['Every 22nd', 22],
        ['Every 23rd', 23],
        ['Every 24th', 24],
        ['Every 25th', 25],
        ['Every 26th', 26],
        ['Every 27th', 27],
        ['Every 28th', 28],
        ['Every 29th', 29],
        ['Every 30th', 30],
        ['Every 31st', 31],
      ];
    default:
      return [];
  }
};
