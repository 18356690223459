import { TableProfileItem } from 'src/services/OpenMetadata/types';
import { formatNumbers } from './formatNumbers';

export const calculateThirtyDayAverageVolume = (data: TableProfileItem[]) => {
  const totalPoints = data.length;
  const start = Math.max(0, totalPoints - 30);
  const recentPoints = data.slice(start, totalPoints);

  const total = recentPoints.reduce((sum, point) => sum + point.rowCount, 0);
  const avg = total / recentPoints.length;

  return formatNumbers(avg);
};
