import { graphql } from 'relay-runtime';

export const ValidationContainerQuery = graphql`
  query validationContainerQuery($owner: String, $modelName: String!) {
    validationContainer(owner: $owner, modelName: $modelName) {
      tableName
      tableAlias
      containerId
      isCustomTable
    }
  }
`.default;
