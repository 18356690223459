import React, { FC, ReactElement, useCallback } from 'react';
import {
  AddCircleIcon,
  Box,
  Grid,
  Typography,
  useTheme,
} from '@cherre-frontend/ui';
import { ActivityCreateConnectorSectionProps } from './model';
import { useHistory } from 'react-router';
import ConnectorButton from '../../../connector-details/components/Overview/ConnectorButton';

const ActivityCreateConnectorSection: FC<
  ActivityCreateConnectorSectionProps
> = ({ canAddConnector }): ReactElement => {
  const { push } = useHistory();
  const theme = useTheme();

  const handleRedirectToAddConnector = useCallback(() => {
    push('/connectors/add');
  }, []);

  return (
    <Grid
      container
      direction='column'
      justifyContent='center'
      alignItems='center'
      sx={{ backgroundColor: theme.palette.grey[50], padding: '20px' }}
      borderRadius='20px'
    >
      {!canAddConnector && (
        <Grid item>
          <Typography
            fontSize='16px'
            fontWeight='600'
            align='center'
            padding='20px'
          >
            It looks like there aren't any connectors for your organization yet.
            Contact your organization admin or Cherre representative to get
            started.
          </Typography>
        </Grid>
      )}
      {canAddConnector && (
        <>
          <Grid item>
            <Typography
              fontSize='16px'
              fontWeight='600'
              align='center'
              padding='20px'
            >
              It looks like you haven't created any connectors yet. Click on add
              a connector below to create your first connector and to view
              related activity data!
            </Typography>
          </Grid>
          <Grid item>
            <ConnectorButton
              variant='contained'
              onClick={handleRedirectToAddConnector}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                <Typography>Add a Connector</Typography>
                <AddCircleIcon style={{ color: 'white' }} />
              </Box>
            </ConnectorButton>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ActivityCreateConnectorSection;
