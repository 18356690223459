/* eslint-disable no-fallthrough */
import React, { useMemo } from 'react';
import { Breadcrumbs, Link, useTheme } from '@cherre-frontend/ui';
import { useHistory, useLocation } from 'react-router';
import {
  useCherreValue,
  useCherreState,
  constSelector,
} from '@cherre-frontend/data-fetching';
import {
  addConnectorSelectedNavLink,
  connectorDetailsSelectedConnectorName,
  connectorDetailsTabState,
} from '../recoil';
import {
  validationDetailsRuleId,
  validationDetailsTabState,
} from '../pages/validation-details/recoil';
import { ConnectorDetailsTabValues } from '../pages/connector-details';

type ConnectorBreadcrumb = {
  text?: string;
  href?: string;
  onClick?: () => void;
};

type ConnectorBreadcrumbsProps = {
  connectorName?: string;
};

const ConnectorBreadcrumbs: React.FC<ConnectorBreadcrumbsProps> = ({
  connectorName,
}) => {
  const path = useLocation().pathname;
  const theme = useTheme();
  const history = useHistory();
  const connector_name = useCherreValue(connectorDetailsSelectedConnectorName);
  const test_case_id = useCherreValue(
    path.includes('/validationDetails')
      ? validationDetailsRuleId
      : constSelector('')
  );
  const [activeNavLink, setActiveNavLink] = useCherreState(
    addConnectorSelectedNavLink
  );

  const [connectorTab, setConnectorTab] = useCherreState(
    connectorDetailsTabState
  );
  const [validationDetailsTab, setValidationDetailsTab] = useCherreState(
    validationDetailsTabState
  );

  const generatedBreadcrumbs = useMemo(() => {
    const breadcrumbArray: ConnectorBreadcrumb[] = [];
    if (
      connectorTab === ConnectorDetailsTabValues.OBSERVABILITY &&
      activeNavLink !== ''
    ) {
      breadcrumbArray.push({ text: activeNavLink });
    }

    if (path.includes('/validationDetails')) {
      breadcrumbArray.push({
        text: validationDetailsTab,
        onClick: () => {
          setValidationDetailsTab(validationDetailsTab ?? '');
          history.push(
            `/connectors/${connector_name}/validationDetails/${test_case_id}`
          );
        },
      });
    }

    if (path.includes('/connectors/add')) {
      breadcrumbArray.push({
        text: 'Add Connector',
        onClick: () => {
          history.push(`/connectors/add`);
        },
      });
    } else if (path.includes('/connectors') && connectorName) {
      breadcrumbArray.push({
        text: connectorTab,
        onClick: () => {
          setConnectorTab(connectorTab ?? '');
          history.push(`/connectors/${connector_name}`);
          setActiveNavLink('Table Profile');
        },
      });
      breadcrumbArray.push({
        text: connectorName,
        onClick: () => {
          setConnectorTab(ConnectorDetailsTabValues.OVERVIEW);
          history.push(`/connectors/${connector_name}`);
        },
      });
    }

    breadcrumbArray.push(
      {
        text: 'Connectors',
        onClick: () => {
          history.push('/connectors/inventory');
        },
      },
      {
        text: 'Home',
        onClick: () => {
          history.push('/connectors');
        },
      }
    );

    return breadcrumbArray.reverse();
  }, [
    path,
    connectorTab,
    validationDetailsTab,
    connectorName,
    test_case_id,
    activeNavLink,
  ]);

  return (
    <Breadcrumbs
      sx={{
        '& .MuiBreadcrumbs-separator': {
          color: theme.palette.grey[400],
        },
      }}
    >
      {generatedBreadcrumbs.map((breadcrumb, index) => (
        <Link
          key={`${breadcrumb.text}-${index}`}
          href={breadcrumb.href}
          onClick={breadcrumb.onClick}
          sx={{
            color:
              index === generatedBreadcrumbs.length - 1
                ? theme.palette.grey[800]
                : theme.palette.grey[400],
            cursor: 'pointer',
          }}
          underline={
            index === generatedBreadcrumbs.length - 1 ? 'none' : 'hover'
          }
        >
          {breadcrumb.text}
        </Link>
      ))}
    </Breadcrumbs>
  );
};

export default ConnectorBreadcrumbs;
