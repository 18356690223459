import {
  ConnectorEnvironments,
  useConnectorEnvironment,
} from 'src/hooks/useConnectorEnvironment';
import { validationRuleTableList } from 'src/services/ConnectorDemo/recoil';
import {
  openMetadataDataValidationTests,
  openMetadataDataValidationTestSummary,
  selectedOpenMetadataDataValidationTests,
} from 'src/services/OpenMetadata/recoil';
import {
  qualyticsDataValidationTestSummary,
  selectedConnectorQualyticsValidationRules,
} from 'src/services/Qualytics/recoil';
import { filteredValidationRules } from '../../../recoil';
import { selectedConnectorPublishedTables } from 'src/services/DOM/connectorValidationRules/recoil';
import { useCherreValueLoadable } from '@cherre-frontend/data-fetching';

export const useGetDataValidation = () => {
  const environment = useConnectorEnvironment();

  if (environment === ConnectorEnvironments.MVP) {
    const dataValidationRules = useCherreValueLoadable(
      selectedConnectorQualyticsValidationRules
    );
    const dataValidationTestSummary = useCherreValueLoadable(
      qualyticsDataValidationTestSummary
    );
    const tables = useCherreValueLoadable(selectedConnectorPublishedTables);
    const filteredTableRules = useCherreValueLoadable(filteredValidationRules);
    return {
      validationRules: dataValidationRules,
      validationRulesSummary: dataValidationTestSummary,
      tables,
      filteredTableTests: filteredTableRules,
    };
  } else {
    const dataValidationTests = useCherreValueLoadable(
      openMetadataDataValidationTests
    );
    const dataValidationTestSummary = useCherreValueLoadable(
      openMetadataDataValidationTestSummary
    );
    const tables = useCherreValueLoadable(validationRuleTableList);
    const filteredTableTests = useCherreValueLoadable(
      selectedOpenMetadataDataValidationTests
    );
    return {
      validationRules: dataValidationTests,
      validationRulesSummary: dataValidationTestSummary,
      tables,
      filteredTableTests,
    };
  }
};
