import { RuleItem } from '../components/DataValidationList/components/DataValidationTable/useDataValidationTableColumns';

export enum RuleStatus {
  INACTIVE = 'INACTIVE',
  INACTIVE_ERROR = 'INACTIVE_ERROR',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  CREATING = 'CREATING',
  RUNNING = 'RUNNING',
  ACTIVE = 'ACTIVE',
}

export const getRuleItemStatus = (ruleItem: RuleItem): RuleStatus => {
  const { rule } = ruleItem;

  const { checkStatus, checkStatusMessage } = rule || {};

  // Inactive without warning => { check_status: "INACTIVE", check_message: "",  active: false}
  if (!rule || (checkStatus === 'INACTIVE' && checkStatusMessage === '')) {
    return RuleStatus.INACTIVE;
  }

  // Empty(waiting the creation to end) => { check_status: "CREATING",  active: true}
  if (checkStatus === 'CREATING') {
    return RuleStatus.CREATING;
  }

  // Active => { check_status: "", check_message: "",  active: true}
  if (!checkStatus && !checkStatusMessage) {
    return RuleStatus.ACTIVE;
  }

  // Inactive with warning => { check_status: "INACTIVE", check_message: "something…",  active: true}
  if (checkStatus === 'INACTIVE' && checkStatusMessage) {
    return RuleStatus.INACTIVE_ERROR;
  }

  // Scheduled => { check_status: "", check_message: "",  active: true}
  if (checkStatus === 'RUNNING') {
    return RuleStatus.RUNNING;
  }

  // Failed => { check_status: "FAIL",  active: true}
  if (checkStatus === 'FAIL') {
    return RuleStatus.FAILED;
  }

  // Success => { check_status: "SUCCESS",  active: true}
  if (checkStatus === 'SUCCESS') {
    return RuleStatus.SUCCESS;
  }

  return RuleStatus.INACTIVE_ERROR;
};
