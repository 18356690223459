import React, { useEffect } from 'react';
import {
  Grid,
  Typography,
  SelectChangeEvent,
  DatePickerInput,
  Box,
} from '@cherre-frontend/ui';
import {
  connectorDetailsSelectedTable,
  connectorDetailsSelectedDateFilter,
  connectorDetailsCustomDateRange,
  qualyticsDataVolumeReportDate,
  qualyticsDataVolumeTimeframe,
} from '../../../../recoil';
import { useCherreState } from '@cherre-frontend/data-fetching';
import ObservabilityCard from '../../../../components/ObservabilityCard';
import { formatBytes } from 'src/utils/formatBytes';
import TableProfileContentLoader from './TableProfileContentLoader';
import { formatCreationDate } from 'src/utils/formatCreationDate';
import DateRangeSelect from './DateRangeSelect';
import { Range } from 'react-date-range';
import moment, { Moment } from 'moment';
import ConnectorTableAutocomplete from 'src/products/connector/components/ConnectorTableAutocomplete';
import DataVolumeChart from './DataVolumeChart';
import { useGetTableProfile } from './hooks/useGetTableProfile';
import {
  ConnectorEnvironments,
  useConnectorEnvironment,
} from 'src/hooks/useConnectorEnvironment';
import TimeframeSelect from './TimeframeSelect';

export interface RangeWithSelection {
  selection: Range;
}

const TableProfileContent = () => {
  const [selectedTable, setSelectedTable] = useCherreState(
    connectorDetailsSelectedTable
  );

  //Filter state for OM
  const [selectedDateFilter, setSelectedDateFilter] = useCherreState(
    connectorDetailsSelectedDateFilter
  );
  const [customDateRange, setCustomDateRange] = useCherreState(
    connectorDetailsCustomDateRange
  );

  //filter state for Qualytics
  const [selectedQualyticsReportDate, setSelectedQualyticsReportDate] =
    useCherreState(qualyticsDataVolumeReportDate);

  const [selectedQualyticsTimeframe, setSelectedQualyticsTimeframe] =
    useCherreState(qualyticsDataVolumeTimeframe);

  const handleTableSelect = (_, value) => {
    setSelectedTable(value.label);
  };

  //OM components
  const handleDateFilterSelect = (e: SelectChangeEvent<string>) => {
    const { value } = e.target;
    setSelectedDateFilter(value);
    if (value !== 'custom') {
      const newRange = {
        startDate: moment().subtract(Number(value), 'days').toDate(),
        endDate: new Date(),
        key: 'selection',
      };
      setCustomDateRange([newRange]);
    }
  };

  const handleDateRangeChange = (newRange: Range) => {
    const { startDate, endDate } = newRange;
    setCustomDateRange([newRange]);
    const formattedStartDate = moment(startDate).format('MM/DD/YYYY');
    const formattedEndDate = moment(endDate).format('MM/DD/YYYY');
    const dateRangeString = `Custom Range: ${formattedStartDate} - ${formattedEndDate}`;
    setSelectedDateFilter(dateRangeString);
  };

  //Qualytics components
  const handleReportDateChange = (value: unknown) => {
    const formattedDate = moment(value as Moment).format('YYYY-MM-DD');
    setSelectedQualyticsReportDate(formattedDate);
  };
  const handleTimeframeChange = (e: SelectChangeEvent<unknown>) => {
    const { value } = e.target;
    setSelectedQualyticsTimeframe(value as string);
  };

  const { tableDataVolume, tables, latestTableData, thirtyDayAverage } =
    useGetTableProfile();

  const environment = useConnectorEnvironment();

  useEffect(() => {
    setSelectedDateFilter('3');
  }, []);

  if (
    tables.state === 'loading' ||
    tableDataVolume.state === 'loading' ||
    latestTableData.state === 'loading' ||
    thirtyDayAverage.state === 'loading'
  ) {
    return <TableProfileContentLoader />;
  }

  if (
    tables.state === 'hasError' ||
    tableDataVolume.state === 'hasError' ||
    latestTableData.state === 'hasError' ||
    thirtyDayAverage.state === 'hasError'
  ) {
    return <h1>An error occurred</h1>;
  }

  return (
    <Grid container direction='column' sx={{ width: '100%' }} padding={2}>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        sx={{ width: '100%' }}
      >
        <Grid item xs={6}>
          <Typography fontSize='24px' lineHeight='30px' fontWeight='bold'>
            Table Profile
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Grid
            container
            justifyContent='flex-end'
            direction='row'
            alignItems='center'
          >
            <Grid item width='480px'>
              <ConnectorTableAutocomplete
                menuOptions={tables.contents}
                onChange={handleTableSelect}
                value={{
                  label: selectedTable
                    ? selectedTable
                    : tables.contents[0] || '',
                  id: selectedTable ? selectedTable : tables.contents[0] || '',
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {latestTableData.contents && (
        <Grid container direction='row' paddingTop='30px' columnSpacing='30px'>
          <Grid item sx={{ height: '70px' }} xs={12 / 5}>
            <ObservabilityCard
              cardHeader='Row Count'
              cardContent={`${
                latestTableData.contents.profile.rowCount
                  ? latestTableData.contents.profile.rowCount.toLocaleString()
                  : '--'
              }`}
            />
          </Grid>
          <Grid item sx={{ height: '70px' }} xs={12 / 5}>
            <ObservabilityCard
              cardHeader='Column Count'
              cardContent={`${
                latestTableData.contents.profile.columnCount
                  ? latestTableData.contents.profile.columnCount.toString()
                  : '--'
              }`}
            />
          </Grid>
          <Grid item sx={{ height: '70px' }} xs={12 / 5}>
            <ObservabilityCard
              cardHeader='Profile Sample'
              cardContent={`${
                latestTableData.contents.profile.profileSample
                  ? `${latestTableData.contents.profile.profileSample}%`
                  : '--'
              }`}
            />
          </Grid>
          <Grid item sx={{ height: '70px' }} xs={12 / 5}>
            <ObservabilityCard
              cardHeader='Size'
              cardContent={`${
                latestTableData.contents.profile.sizeInByte
                  ? formatBytes(latestTableData.contents.profile.sizeInByte)
                  : '0 Bytes'
              }`}
            />
          </Grid>
          <Grid item sx={{ height: '70px' }} xs={12 / 5}>
            <ObservabilityCard
              cardHeader='Creation Date'
              cardContent={
                latestTableData.contents.profile.createDateTime
                  ? formatCreationDate(
                      latestTableData.contents.profile.createDateTime
                    )
                  : '--'
              }
            />
          </Grid>
        </Grid>
      )}

      <Grid
        container
        direction='row'
        paddingTop='20px'
        justifyContent='space-between'
      >
        <Grid item>
          <Typography fontSize='16px' lineHeight='19.5px' fontWeight='bold'>
            Data Volume
          </Typography>
        </Grid>
        <Grid item>
          {environment === ConnectorEnvironments.DEMO ? (
            <DateRangeSelect
              onChange={handleDateFilterSelect}
              value={selectedDateFilter ?? ''}
              onDateRangeChange={handleDateRangeChange}
              customDateRange={customDateRange as Range[]}
            />
          ) : (
            <Box
              display='flex'
              flexDirection='row'
              justifyContent='center'
              alignItems='flex-start'
              gap={1}
            >
              <TimeframeSelect
                value={selectedQualyticsTimeframe ?? 'week'}
                onChange={handleTimeframeChange}
              />
              <DatePickerInput
                maxDate={moment()}
                value={selectedQualyticsReportDate}
                onChange={handleReportDateChange}
              />
            </Box>
          )}
        </Grid>
      </Grid>
      <Grid container paddingTop='20px'>
        <Grid
          item
          width='100%'
          sx={{
            borderRadius: '8px',
            boxShadow: '0px 1px 2px 0px #1F293714',
            padding: '24px',
          }}
        >
          {tableDataVolume.contents && tableDataVolume.contents.length === 0 ? (
            <div
              style={{
                height: 200,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Typography fontSize='16px' lineHeight='19.5px' fontWeight='bold'>
                No Data Available
              </Typography>
            </div>
          ) : (
            <DataVolumeChart
              data={tableDataVolume.contents || []}
              thirtyDayData={thirtyDayAverage.contents || []}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

TableProfileContent.displayName = 'TableProfileContent';

export default TableProfileContent;
