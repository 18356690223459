import React, { useState } from 'react';
import {
  Button,
  Menu,
  MenuItem,
  ArrowDropDownIcon,
  CancelIcon,
  ReplayIcon,
} from '@cherre-frontend/ui';
import { RulesQueryDataRuleLibraryItem } from '../../../../queries/types';
import { DataValidationDeactivateDialog } from './DataValidationDeactivateDialog';
import { DataValidationAlertProps } from '../DataValidationAlert';
import { DataValidationRunNowDialog } from './DataValidationRunNowDialog';
import { useCurrentConnector } from '../../../../hooks/useCurrentConnector';

interface DataValidationActionsProps {
  rule: RulesQueryDataRuleLibraryItem;
  onAlert(alert: DataValidationAlertProps | null): void;
}

export const DatavaValidationActions = ({
  rule,
  onAlert,
}: DataValidationActionsProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [deactivateDialogOpen, setDeactivateDialogOpen] = useState(false);
  const [runDialogOpen, setRunDialogOpen] = useState(false);
  const { refetchRuleLibraryItems } = useCurrentConnector();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const handleDeactivate = () => {
    setDeactivateDialogOpen(true);
    handleClose();
  };

  const handleRun = () => {
    setRunDialogOpen(true);
    handleClose();
  };

  const handleRunDialogOpenChange = (open: boolean) => {
    setRunDialogOpen(false);
    if (!open) {
      refetchRuleLibraryItems();
    }
  };

  if (!rule.rule || !rule.active) {
    return null;
  }

  return (
    <div>
      <Button
        aria-controls='data-validation-actions'
        aria-haspopup='true'
        onClick={handleClick}
        variant='contained'
        startIcon={<ArrowDropDownIcon />}
      >
        Actions
      </Button>
      <Menu
        id='data-validation-actions'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          borderRadius: '10px',
          marginTop: '5px',
        }}
      >
        <MenuItem
          onClick={handleRun}
          sx={{
            color: '#212121',
            display: 'flex',
            gap: '4px',
            alignItems: 'center',
          }}
        >
          <ReplayIcon sx={{ fontSize: '16px', color: '#9E9E9E' }} />
          Run Rule
        </MenuItem>

        <MenuItem
          onClick={handleDeactivate}
          sx={{
            color: '#212121',
            display: 'flex',
            gap: '4px',
            alignItems: 'center',
          }}
        >
          <CancelIcon sx={{ fontSize: '16px', color: '#9E9E9E' }} />
          Deactivate Rule
        </MenuItem>
      </Menu>

      <DataValidationRunNowDialog
        open={runDialogOpen}
        onOpenChange={setRunDialogOpen}
      />

      <DataValidationDeactivateDialog
        rule={rule.rule}
        open={deactivateDialogOpen}
        onOpenChange={handleRunDialogOpenChange}
        onAlert={onAlert}
      />
    </div>
  );
};
