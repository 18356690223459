import { getSourceRunDetails } from '../../pages/connector-inventory/utils/getSourceRunDetails';
import { printLocalDatetimeWithTimezone } from '../printLocalDatetimeWithTimezone';
import {
  CreateConnectorStatusMessageArgs,
  CreateConnectorStatusMessageReturn,
} from './model';

const capitalizeStatus = (connectorStatus: string | null | undefined) => {
  const status = connectorStatus || 'Scheduled'; // If status is null, default to 'Scheduled'
  return status.charAt(0).toUpperCase() + status.slice(1);
};

export const createConnectorStatusMessage = ({
  connectorStatus,
  connectorEndDateTime, //Success/Failed badge will use endDatetime
  failedConnectorComponent,
  simplifiedErrorMessage = false,
  sources,
}: CreateConnectorStatusMessageArgs): CreateConnectorStatusMessageReturn => {
  const status = capitalizeStatus(connectorStatus);
  const statusDateTime = printLocalDatetimeWithTimezone(
    'MMM D h:mm A z',
    connectorEndDateTime
  );

  if (status === 'Scheduled') {
    const { nextRun } = getSourceRunDetails(sources);
    const formattedDatetime = printLocalDatetimeWithTimezone(
      'MMM D h:mm A z',
      nextRun
    );
    return `${status} ${formattedDatetime}`.trim();
  }

  if (
    status === 'Failed' &&
    failedConnectorComponent &&
    simplifiedErrorMessage
  ) {
    return `${failedConnectorComponent} ${status}`.trim();
  }

  if (
    status === 'Failed' &&
    failedConnectorComponent &&
    !simplifiedErrorMessage
  ) {
    return `${failedConnectorComponent} ${status} ${statusDateTime}`.trim();
  }

  return `${status} ${statusDateTime}`.trim();
};
