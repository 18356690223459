import React, { ReactElement } from 'react';
import { Button, EditIcon, DatasetsIcon } from '@cherre-frontend/ui';
import { useCreateConnector, useSaveConnectorAsDraft } from '../../dataState';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Container, CustomButton } from './styles';

/**
 *
 * @remarks
 * The `Actions` component renders a container with two buttons:
 * - "Save as Draft": Enabled when `canCreateDraft` is true and triggers the `onCreateDraft` function when clicked.
 * - "Create Connector": Enabled when `canCreateConnector` is true and triggers the `onCreateConnector` function when clicked. When the `onCreateConnector` is triggered
 * the state `creatingConnector` changes to true disabling the button "Save as Draft" and "Create Connector" button render a spinner inside of it
 *
 * @hook
 * - useSaveConnectorAsDraft: Determine the state of the "Save as Draft" button.
 * - useCreateConnector: Determine the state of the "Create Connectort" button and trigger the action to create the connector

 *
 * @component
 * @returns {ReactElement} The rendered component.
 */
const Actions: React.FC = (): ReactElement => {
  const [canCreateDraft, onCreateDraft] = useSaveConnectorAsDraft();
  const [canCreateConnector, creatingConnector, onCreateConnector] =
    useCreateConnector();

  return (
    <Container>
      <Button
        disabled={!canCreateDraft || creatingConnector}
        onClick={onCreateDraft}
        endIcon={<EditIcon />}
      >
        Save as Draft
      </Button>
      <CustomButton
        endIcon={<DatasetsIcon />}
        onClick={onCreateConnector}
        variant='contained'
        disabled={!canCreateConnector || creatingConnector}
      >
        {creatingConnector ? (
          <CircularProgress size={24} />
        ) : (
          'Create Connector'
        )}
      </CustomButton>
    </Container>
  );
};

export default Actions;
