import React from 'react';

/**
 * A utility type that represents a state tuple.
 *
 * @template T - The type of the state value.
 * @typedef {Array} StateOf
 * @property {T} 0 - The current state value.
 * @property {React.Dispatch<React.SetStateAction<T>>} 1 - A function to update the state value.
 */
type StateOf<T> = [T, React.Dispatch<React.SetStateAction<T>>];

/**
 * A utility type that represents a React Context for a given state type.
 *
 * @template T - The type of the state for which the context is created.
 */
type ContextOf<T> = React.Context<StateOf<T> | null>;

/**
 * Creates a new React context with a generic type.
 *
 * @template T - The type of the context value.
 * @returns A React context with a value of type `StateOf<T>` or `null`.
 */
const createContext = <T,>() => React.createContext<null | StateOf<T>>(null);

/**
 * Custom hook to use a non-nullable context value.
 *
 * @template T - The type of the context value.
 * @param {React.Context<T>} context - The React context to use.
 * @returns {() => NonNullable<T>} A function that returns the non-nullable context value.
 * @throws Will throw an error if the context value is null or undefined.
 */
const useContext =
  <T,>(context: React.Context<T>): (() => NonNullable<T>) =>
  () => {
    const value = React.useContext(context);
    if (!value) {
      throw new Error('useContext must be used within a Provider');
    }
    return value;
  };

/**
 * A function type that creates a React functional component for a given context and initial state.
 *
 * @template T - The type of the context state.
 * @param context - The React context object for the state.
 * @param initialState - The initial state value for the context.
 * @param hasChangedContext - The React context object that indicates if the state has changed.
 * @returns A React functional component that accepts an optional initial state prop.
 */
type CreateProvider = <T>(
  context: ContextOf<T>,
  initialState: T,
  hasChangedContext: ContextOf<boolean>
) => React.FC<{ initialState?: T }>;

/**
 * Creates a provider component that manages state and context.
 *
 * @param context - The React context to provide.
 * @param globalInitialState - The initial state to use globally.
 * @param hasChangedContext - The context to track if the state has changed.
 * @returns A provider component that wraps its children with the provided context and state management.
 *
 * @template T - The type of the initial state.
 *
 * @example
 * const MyProvider = createProvider(MyContext, initialState, HasChangedContext);
 *
 * <MyProvider initialState={customInitialState}>
 *   <MyComponent />
 * </MyProvider>
 */
const createProvider: CreateProvider =
  (context, globalInitialState, hasChangedContext) =>
  ({ children, initialState }) => {
    const [value, setValue] = React.useState(
      initialState
        ? { ...globalInitialState, ...initialState }
        : globalInitialState
    );
    const [hasChanged, setHasChanged] = React.useState(false);
    const setPartialValue: typeof setValue = React.useCallback(
      (newValue: any) =>
        setValue((prev) => {
          setHasChanged(true);
          const partialValue =
            typeof newValue === 'function' ? newValue(prev) : newValue;
          switch (typeof partialValue) {
            case 'object':
              return { ...prev, ...partialValue };
            default:
              return partialValue;
          }
        }),
      []
    );
    return (
      <hasChangedContext.Provider value={[hasChanged, setHasChanged]}>
        <context.Provider value={[value, setPartialValue]}>
          {children}
        </context.Provider>
      </hasChangedContext.Provider>
    );
  };

/**
 * Creates a global state with the given initial state.
 *
 * @template T - The type of the initial state.
 * @param {T} initialState - The initial state to be used for the global state.
 * @returns {readonly [Provider: React.ComponentType, useGlobalState: () => StateOf<T>, useHasChanged: () => StateOf<boolean>]}
 * A tuple containing the Provider component, a hook to access and alter the global state, and a hook to check if the state has changed.
 */
export const createGlobalState = <T,>(initialState: T) => {
  const context = createContext<T>();
  const useGlobalState = useContext(context);

  const hasChangedContext = createContext<boolean>();
  const useHasChanged = useContext(hasChangedContext);

  const Provider = createProvider(context, initialState, hasChangedContext);

  return [Provider, useGlobalState, useHasChanged] as const;
};
