import React from 'react';
import { useDataValidationV2 } from '../../../../providers/DataValidationV2Provider';
import { ControlledSelect, FormControl } from '@cherre-frontend/ui';
import { useDataValidationFormContext } from './dataValidationFormEngine';

interface DataValidationTablesProps {
  index: number;
  propertiesType: 'generalProperties' | 'specificProperties';
}

export const DataValidationTablesContent = ({
  index,
  propertiesType,
}: DataValidationTablesProps) => {
  const { validationContainer } = useDataValidationV2();
  const { control } = useDataValidationFormContext();

  const options =
    validationContainer?.map((field) => ({
      label: field?.tableName || '',
      value: field?.tableName || '',
    })) || [];

  return (
    <FormControl sx={{ width: '100%', flex: 1 }}>
      <ControlledSelect
        control={control}
        name={`${propertiesType}.${index}.value`}
        options={options}
        label='Select Table'
        required
        placeholder='Select Table'
        inputProps={{ sx: { padding: '8px' } }}
      />
    </FormControl>
  );
};

export const DataValidationTables = (props: DataValidationTablesProps) => {
  return <DataValidationTablesContent {...props} />;
};
