import { ConnectorStatus } from 'src/products/connector/recoil';

export const orderConnectorList = (draftConnectorList, connectorsList) => {
  const desiredOrder = {
    [ConnectorStatus.SUCCESS]: 4,
    [ConnectorStatus.DRAFT]: 1,
    [ConnectorStatus.SCHEDULED]: 3,
    [ConnectorStatus.DEACTIVATED]: 5,
    [ConnectorStatus.FAILED]: 2,
  };
  const list = [...draftConnectorList, ...connectorsList];

  return list.sort(
    (next, previous) =>
      desiredOrder[next.status] - desiredOrder[previous.status]
  );
};
