import React from 'react';
import { Grid, Typography } from '@cherre-frontend/ui';
import { connectorDetailsSelectedTable } from 'src/products/connector/recoil';
import { useCherreState } from '@cherre-frontend/data-fetching';
import DataDictionaryTable from './DataDictionaryTable';
import DataDictionaryContentLoader from './DataDictionaryContentLoader';
import ConnectorTableAutocomplete from 'src/products/connector/components/ConnectorTableAutocomplete';
import { useGetDataDictionary } from './hooks/useGetDataDictionary';

const DataDictionaryContent: React.FC = () => {
  const [selectedTable, setSelectedTable] = useCherreState(
    connectorDetailsSelectedTable
  );

  const dataDictionary = useGetDataDictionary();
  const tables = dataDictionary.tables;
  const latestTableData = dataDictionary.latestTableData;

  const handleTableSelect = (_, value) => {
    setSelectedTable(value.label);
  };

  if (tables.state === 'loading' || latestTableData.state === 'loading') {
    return <DataDictionaryContentLoader />;
  }

  if (tables.state === 'hasError' || latestTableData.state === 'hasError') {
    return <h1>An error occurred</h1>;
  }

  return (
    <Grid container direction='column' sx={{ width: '100%' }} padding={2}>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        sx={{ width: '100%' }}
      >
        <Grid item xs={6}>
          <Typography fontSize='24px' lineHeight='30px' fontWeight='bold'>
            Data Dictionary
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Grid
            container
            justifyContent='flex-end'
            direction='row'
            alignItems='center'
            sx={{ gap: '16px' }}
          >
            <Grid item width='480px'>
              <ConnectorTableAutocomplete
                menuOptions={tables.contents}
                onChange={handleTableSelect}
                value={{
                  label: selectedTable ? selectedTable : tables.contents[0],
                  id: selectedTable ? selectedTable : tables.contents[0],
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container paddingTop='12px'>
        <Grid item width='100%'>
          {latestTableData.contents ? (
            <DataDictionaryTable data={latestTableData.contents.columns} />
          ) : (
            <div
              style={{
                height: 200,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Typography fontSize='16px' lineHeight='19.5px' fontWeight='bold'>
                No Data Available
              </Typography>
            </div>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

DataDictionaryContent.displayName = 'DataDictionaryContent';

export default DataDictionaryContent;
