import React, { FC, ReactElement, Suspense } from 'react';
import CheckBoxConnectorChooseTables from '../CheckBoxConnectorChooseTables';
import CheckboxTableSkeleton from '../CheckboxTableSkeleton';
import { Container } from './styles';

/**
 * ModelTablesSection component handle with react-relay loading request inside CheckBoxConnectorChooseTables component.
 *
 *
 * @component
 * @returns {ReactElement} The rendered CheckBoxConnectorChooseTables component.
 */

const ModelTablesSection: FC = (): ReactElement => {
  return (
    <Container>
      <Suspense fallback={<CheckboxTableSkeleton />}>
        <CheckBoxConnectorChooseTables />
      </Suspense>
    </Container>
  );
};

export default ModelTablesSection;
