import { SOURCES } from '../../../../mockData';
import { buildCheckboxInitialOptions } from '../buildCheckboxInitialOptions';
import { buildTableList } from '../buildTableList';
import { buildCheckboxOptionFromState } from '../buildCheckboxOptionFromState';
import {
  BuildTableModelsSectionsArgs,
  BuildTableModelsSectionsReturn,
} from './model';

export const buildTableModelsSections = ({
  addConnectorData,
  preconfiguredModels,
  selectedDestination,
}: BuildTableModelsSectionsArgs): BuildTableModelsSectionsReturn[] => {
  const sources = addConnectorData.sources?.map(
    (source) => source.preconfiguredSourceName
  );

  const { displayName: sourceDisplayName = '' } =
    SOURCES.find((source) => sources?.includes(source.sourceName)) || {};

  const modelSections = addConnectorData.models?.map((model) => {
    const options: string[] = buildTableList({
      preconfiguredModels,
      model,
    });

    const { checkboxForm, selectedAll } = addConnectorData.destinations
      ? buildCheckboxOptionFromState({
          connectorDataDestinations: addConnectorData.destinations,
          selectedDestination,
          options,
          model,
        })
      : buildCheckboxInitialOptions({
          options,
        });

    return {
      sourceDisplayName,
      modelDisplayName: model.displayName,
      modelName: model.name,
      modelStableVersion: model.stableVersion,
      selectedAll,
      options,
      checkboxForm,
    };
  });

  return modelSections || [];
};
