import React from 'react';
import { Alert, AlertTitle } from '@cherre-frontend/ui';
import { ErrorOutline, CheckCircle } from '@material-ui/icons';

export enum DataValidationAlertType {
  ERROR = 'error',
  SUCCESS = 'success',
}

export interface DataValidationAlertProps {
  message: string;
  type: DataValidationAlertType;
}

export const DataValidationAlert = ({
  message,
  type,
}: DataValidationAlertProps) => {
  const isError = type === DataValidationAlertType.ERROR;

  const Icon = isError ? ErrorOutline : CheckCircle;

  return (
    <Alert
      icon={<Icon htmlColor='#FFF' fontSize='inherit' />}
      sx={{
        color: '#FFF',
        backgroundColor: isError ? '#E53935' : '#43A047',
        padding: '6px 10px !important',
        margin: '10px 0',
      }}
    >
      <AlertTitle sx={{ margin: 0, padding: 0 }}>{message}</AlertTitle>
    </Alert>
  );
};
