import { Button, styled } from '@cherre-frontend/ui';

export const Container = styled('div')`
  padding-top: 60px;
  padding-bottom: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

export const CustomButton = styled(Button)`
  span {
    &:first-child {
      min-width: 170px;
    }
  }
`;
