import React from 'react';
import {
  Box,
  styled,
  CheckIcon,
  Avatar,
  Typography,
} from '@cherre-frontend/ui';

export type Status = 'not-started' | 'in-progress' | 'done';

type Props = {
  label: string;
  status: Status;
};

const Container = styled(Box)`
  background-color: ${({ theme }) => theme.palette.grey[100]};
  display: flex;
  flex-direction: row;
  padding: 10px;
  border-radius: 10px;
  gap: 10px;
`;

const AvatarStyled = styled(Avatar)<{ status: Status }>`
  background: ${({ theme, status }) =>
    status === 'done' ? theme.palette.success.dark : theme.palette.grey[100]};
  height: 20px;
  width: 20px;
  border: 2px solid
    ${({ theme, status }) =>
      status === 'done'
        ? theme.palette.success.dark
        : theme.palette.primary.main};
`;

const InProgress = styled('div')`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: ${({ theme }) => theme.palette.primary.main};
`;

const NotStarted = styled('div')`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: ${({ theme }) => theme.palette.grey[100]};
`;

/**
 * Renders a status icon based on the provided status.
 *
 * @param {Status} status - The current status which determines the icon to be displayed.
 * @returns {JSX.Element} The corresponding status icon wrapped in an AvatarStyled component.
 *
 * @example
 * // Renders a check icon for 'done' status
 * renderStatusIcon('done');
 *
 * @example
 * // Renders an in-progress icon for 'in-progress' status
 * renderStatusIcon('in-progress');
 *
 * @example
 * // Renders a not started icon for any other status
 * renderStatusIcon('not-started');
 */
const renderStatusIcon = (status: Status) => {
  switch (status) {
    case 'done':
      return (
        <CheckIcon
          style={{ height: '12px', width: '12px' }}
          data-testid='DONE-ICON'
        />
      );
    case 'in-progress':
      return <InProgress data-testid='IN-PROGRESS-ICON' />;
    default:
      return <NotStarted data-testid='NOT-STARTED-ICON' />;
  }
};

/**
 * StatusBox component displays a status icon and a label.
 *
 * @component
 * @param {object} props - The properties object.
 * @param {string} props.label - The label to display.
 * @param {string} props.status - The status to determine which icon to display.
 * @returns {JSX.Element} The rendered StatusBox component.
 */
const StatusBox: React.FC<Props> = ({ label, status }) => {
  return (
    <Container>
      <AvatarStyled status={status}>{renderStatusIcon(status)}</AvatarStyled>
      <Typography style={{ fontWeight: '700' }}>{label}</Typography>
    </Container>
  );
};

export default StatusBox;
