export const formatNumbers = (value: number): string => {
  if (value >= 1e9 || Math.round(value / 1e9) >= 1) {
    return `${(value / 1e9).toFixed(1)}B`;
  } else if (value >= 1e6 || Math.round(value / 1e6)) {
    return `${(value / 1e6).toFixed(1)}M`;
  } else if (value >= 1000) {
    return `${(value / 1000).toFixed(1)}k`;
  } else {
    return value.toFixed(0).toString();
  }
};
