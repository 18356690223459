import React, { SyntheticEvent } from 'react';
import {
  MuiAutocomplete as Autocomplete,
  Popper,
  TextField,
} from '@cherre-frontend/ui';

const CustomPopper = function (props) {
  return (
    <Popper
      {...props}
      placement='bottom-end'
      style={{ width: 'fit-content' }}
    />
  );
};

type ConnectorTableAutocompleteProps = {
  menuOptions: string[];
  onChange: (
    event: SyntheticEvent<Element, Event>,
    value: { label: string; id: string }
  ) => void;
  value: { label: string; id: string };
};

const ConnectorTableAutocomplete: React.FC<ConnectorTableAutocompleteProps> = ({
  menuOptions,
  onChange,
  value,
}) => {
  const tableNames = menuOptions.map((option) => {
    return {
      label: option,
      id: option,
    };
  });

  return (
    <Autocomplete
      value={value}
      renderInput={(params) => (
        <TextField
          {...params}
          variant='outlined'
          size='small'
          placeholder='Select Table'
        />
      )}
      fullWidth
      onChange={onChange}
      options={tableNames}
      size='small'
      disableClearable
      noOptionsText='No tables found'
      PopperComponent={CustomPopper}
      isOptionEqualToValue={(option, value) => option.id === value.id}
    />
  );
};

ConnectorTableAutocomplete.displayName = 'ConnectorTableAutoComplete';
export default ConnectorTableAutocomplete;
