import { useCherreState } from '@cherre-frontend/data-fetching';
import { Table, styled, tableBaseStyle, useTable } from '@cherre-frontend/ui';
import { NoItemsFound } from 'src/products/data-submission-portal/components/NoBatchesFound';
import React, { useMemo } from 'react';
import { dataDictionaryTableState } from '../../recoil';
import { ColumnItem } from 'src/services/OpenMetadata/types';

const Container = styled('div')`
  padding-top: 20px;
  display: flex;
  flex: 1;
  width: '100%';
`;

export enum Status {
  PASSED = 'Passed',
  FAILED = 'Failed',
}

type DataDictionaryTableProps = {
  data: ColumnItem[];
};

const DataDictionaryTable: React.FC<DataDictionaryTableProps> = ({ data }) => {
  const [tableState, setTableState] = useCherreState(dataDictionaryTableState);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        size: 150,
      },
      {
        accessorKey: 'type',
        header: 'Type',
        size: 60,
        accessorFn: (row) => row.dataType ?? 'No Description',
      },
      {
        accessorKey: 'description',
        header: 'Description',
        size: 200,
        accessorFn: (row) => row.description || 'No Description',
      },
    ],
    []
  );

  const table = useTable({
    data: data,
    skeletonRowCount: 5,
    columns,
    enableSelectAll: false,
    enableRowActions: false,
    enableRowSelection: false,
    enableBottomToolbar: false,
    enablePagination: false,
    positionActionsColumn: 'last',
    layoutMode: 'grid',
    muiTablePaperProps: {
      sx: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        flex: 1,
      },
      elevation: 0,
    },
    muiTableContainerProps: {
      sx: {
        flex: 1,
      },
    },
    state: {
      sorting: tableState?.sorting,
    },
    manualSorting: false,
    onSortingChange: (sort) =>
      setTableState((s) => ({
        ...s,
        sorting: typeof sort === 'function' ? sort(s.sorting) : sort,
      })),
    getRowId: (row) => row.name as string,
    enableMultiSort: false,
    muiTableHeadRowProps: {
      sx: {
        ...tableBaseStyle.muiTableHeadRowProps.sx,
        '& th': {
          color: 'black',
          '& .Mui-TableHeadCell-Content': {
            alignItems: 'center',
          },
        },
      },
    },
    muiTableBodyRowProps: {
      ...tableBaseStyle.muiTableBodyRowProps.sx,
      sx: {},
    },
  });

  if (data && data.length === 0) {
    return <NoItemsFound title='No Dictionaries Found' />;
  }

  return (
    <Container>
      <Table table={table} />
    </Container>
  );
};

export default DataDictionaryTable;
