import { Connector } from 'src/services/DOM/types/connectorTypes';
import { ValidationRuleResult } from '../../connector-details/components/DataValidation/types';
import { getTableNameFromFQN } from '../../connector-details/components/DataValidation/utils/getTableNameFromFQN';

export const getActivityOverviewCheckFailures = (
  connector: Connector,
  validationRules: ValidationRuleResult[]
) => {
  let numFailedValidationChecks = 0;

  validationRules.forEach((rule) => {
    if (rule.ruleStatus !== 'Failed') {
      return;
    }
    if (rule.connectorName === connector.name) {
      numFailedValidationChecks++;
      return;
    }
    const tableNames = connector.models.flatMap((model) =>
      model.publishedTables.map((fqn) => getTableNameFromFQN(fqn))
    );
    if (tableNames.includes(rule.tableName)) {
      numFailedValidationChecks++;
    }
  });

  return numFailedValidationChecks;
};
