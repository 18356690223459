import React, { useMemo } from 'react';
import { TextField, Box, TextFieldProps, styled } from '@cherre-frontend/ui';
import { useCherreState } from '@cherre-frontend/data-fetching';
import { dataValidationRuleForm } from '../../recoil';
import StyledLabel from './Label';

export const StyledTextField = styled(TextField)`
  & .MuiInputBase-root {
    height: 34px;
  }
`;

type Props = TextFieldProps & {
  title: string;
  fieldName: string;
  required?: boolean;
};

const DefaultTextInput: React.FC<Props> = ({
  title,
  fieldName,
  required,
  ...props
}) => {
  const [form, setForm] = useCherreState(dataValidationRuleForm);

  const value = useMemo(() => {
    if (form) {
      return form[fieldName];
    } else {
      return '';
    }
  }, [form]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <StyledLabel
        style={{ fontSize: '14px', fontWeight: 600 }}
        required={required}
      >
        {title}
      </StyledLabel>
      <StyledTextField
        value={value}
        onChange={(e) => {
          if (form) {
            setForm((oldState) => {
              return {
                ...oldState,
                [fieldName]: e.target.value,
              };
            });
          }
        }}
        {...props}
      />
    </Box>
  );
};

export default DefaultTextInput;
