import React from 'react';
import { useTheme, Typography, ArrowForwardIcon } from '@cherre-frontend/ui';

type LinkWithArrowTypes = {
  linkText: string;
  onClick: () => void;
};

const LinkWithArrow: React.FC<LinkWithArrowTypes> = ({ linkText, onClick }) => {
  const theme = useTheme();
  return (
    <Typography
      onClick={onClick}
      fontSize='14px'
      fontWeight='semi-bold'
      sx={{
        color: theme.palette.primary.main,
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
          textDecoration: 'underline',
          cursor: 'pointer',
        },
      }}
    >
      {linkText}
      <ArrowForwardIcon
        sx={{
          width: '15px',
          height: '15px',
          color: theme.palette.primary.main,
          marginLeft: '6px',
        }}
      />
    </Typography>
  );
};

export default LinkWithArrow;
