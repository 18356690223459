import { useCherreValue } from '@cherre-frontend/data-fetching';
import {
  ConnectorEnvironments,
  useConnectorEnvironment,
} from 'src/hooks/useConnectorEnvironment';
import { connectorOverviewDemo } from 'src/services/ConnectorDemo/recoil';
import { connectorOverview } from 'src/services/DOM/connectorOverview/recoil';

export const useGetOverview = () => {
  const environment = useConnectorEnvironment();

  if (environment === ConnectorEnvironments.MVP) {
    const overviewData = useCherreValue(connectorOverview);
    return { overviewData };
  } else {
    const overviewData = useCherreValue(connectorOverviewDemo);
    return { overviewData };
  }
};
