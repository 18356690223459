import React from 'react';
import { Grid, Skeleton } from '@cherre-frontend/ui';

const CreateValidationRuleFormLoader: React.FC = () => {
  return (
    <Grid
      container
      direction='column'
      sx={{ width: '100%', gap: '40px' }}
      padding={2}
    >
      <Grid item xs={6}>
        <Skeleton variant='rounded' width={300} height={40} animation='wave' />
      </Grid>
      <Grid container direction='column' sx={{ width: '100%', gap: '36px' }}>
        {Array.from(new Array(5)).map((_, index) => (
          <Grid item xs={6} key={index}>
            <Skeleton
              variant='rounded'
              width={560}
              height={50}
              animation='wave'
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

CreateValidationRuleFormLoader.displayName = 'CreateValidationRuleFormLoader';
export default CreateValidationRuleFormLoader;
