import React, { useCallback, useEffect } from 'react';
import {
  styled,
  Button,
  CherreLogoIcon,
  VisibilityIcon,
  VisibilityOffIcon,
  IconButton,
} from '@cherre-frontend/ui';
import {
  useSftpSourceFormError,
  useAddConnector,
  useGoToNextStepSFTPSource,
  useSFTPSource,
  PartialSFTPSourceType,
} from '../../../dataState';
import { useConnectorUIState } from '../../../uiState/contexts';
import * as C from './styles';

const Container = styled(C.Container)`
  grid-template-areas: 'title title' 'description description' 'cherresftp cherresftp' 'or or' 'hostname password' 'username port' 'actions actions';
`;

const CherreSFTPContainer = styled('div')`
  grid-area: cherresftp;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const CherreSFTP = styled(Button)`
  background: white;
`;
const fields = ['hostname', 'password', 'username', 'port'];
const fieldTitles = {
  hostname: 'Host Name:',
  username: 'Username:',
  password: 'Password:',
  port: 'Port:',
};

/**
 * SFTPSourceFirstStep component is responsible for rendering the first step
 * of the SFTP source configuration process. It allows users to input their
 * SFTP credentials if they are not using Cherre's SFTP.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 *
 * @example
 * <SFTPSourceFirstStep />
 *
 * @remarks
 * This component uses several custom hooks:
 * - `useSFTPSource` to manage the SFTP source data state.
 * - `useConnectorUIState` to manage the UI state of the connector.
 * - `useGoToNextStepSFTPSource` to handle the submission and validation of the form.
 *
 * The component renders a form with input fields for SFTP credentials, and
 * two buttons: "Cancel" to reset the UI state, and "Next" to proceed to the
 * next step if the form is valid.
 */
const SFTPSourceFirstStep: React.FC = () => {
  const [data, setData] = useSFTPSource();
  const [, setUIState] = useConnectorUIState();
  const [addConnectorData] = useAddConnector();
  const [canSubmit, submit] = useGoToNextStepSFTPSource();
  const [showPassword, setShowPassword] = React.useState(false);
  const [error, onBlurCallback, onChangeCallback] = useSftpSourceFormError();

  const handleNextStep = useCallback((): void => {
    const sftpSourceData: PartialSFTPSourceType = { sftpType: 'cherre' };

    if (!data.username) {
      sftpSourceData.username = `ip-${addConnectorData.domOwner}`;
    }

    setData(sftpSourceData);

    //we need to submit in the next cycle so the data is updated
    setTimeout(() => submit(), 0);
  }, [submit, addConnectorData]);

  useEffect(() => {
    const foundSource = addConnectorData.sources?.find(
      (source) =>
        source.preconfiguredSourceName === data.preconfiguredSourceName
    );

    if (!foundSource || foundSource.sftpType !== 'cherre') {
      return;
    }

    handleNextStep();
  }, []);

  return (
    <Container>
      <C.Title>Configure your source:</C.Title>
      <C.Description>
        Input your SFTP credentials if you aren’t using Cherre’s SFTP.
      </C.Description>
      <CherreSFTPContainer>
        <CherreSFTP
          variant='outlined'
          startIcon={<CherreLogoIcon />}
          onClick={handleNextStep}
        >
          Cherre SFTP
        </CherreSFTP>
      </CherreSFTPContainer>
      <C.Or>Or</C.Or>
      {fields.map((field) => (
        <C.FieldBox key={field} style={{ gridArea: field }}>
          <C.FieldTitle>{fieldTitles[field]}</C.FieldTitle>
          <C.FieldInput
            size='small'
            value={data[field] || ''}
            onChange={(e) => {
              setData({ sftpType: 'custom', [field]: e.target.value });
              onChangeCallback(e.target.value, field);
            }}
            inputProps={{
              'data-testid': `sftp-source-${field}`,
              type: field === 'password' && !showPassword ? 'password' : 'text',
            }}
            error={Boolean(error[field])}
            helperText={error[field]}
            onBlur={(event) => {
              onBlurCallback(event.target.value, field);
            }}
            InputProps={{
              endAdornment:
                field === 'password' ? (
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                ) : null,
            }}
          />
        </C.FieldBox>
      ))}
      <C.Actions>
        <Button onClick={() => setUIState({ step: 'default', index: 0 })}>
          Cancel
        </Button>
        <Button onClick={submit} disabled={!canSubmit} variant='contained'>
          Next
        </Button>
      </C.Actions>
    </Container>
  );
};

export default SFTPSourceFirstStep;
