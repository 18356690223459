import React, { useMemo } from 'react';
import {
  ArrowForwardIcon,
  Box,
  Button,
  CircularProgress,
  MRT_ColumnDef,
  Typography,
} from '@cherre-frontend/ui';
import { DataValidationRuleStatusBadge } from './DataValidationRuleStatusBadge';
import { RulesQueryDataRuleLibraryItem } from '../../../../queries/types';
import { Writeable } from '../../../../../../../../../../types';
import { useCherreState } from '@cherre-frontend/data-fetching';
import { connectorDataValidationTabParam } from '../../../../../../../../recoil';
import {
  getRuleItemStatus,
  RuleStatus,
} from '../../../../helpers/getRuleItemStatus';

const muiTableBodyCellProps = {
  sx: { height: '55px', fontSize: '14px', padding: '5px 14px' },
};

export type RuleItem = Writeable<RulesQueryDataRuleLibraryItem>;

export const useDataValidationTableColumns =
  (): MRT_ColumnDef<RulesQueryDataRuleLibraryItem>[] => {
    const [, setDataValidationV2Param] = useCherreState(
      connectorDataValidationTabParam
    );

    const onClick = (rule: string) => {
      setDataValidationV2Param({
        rule,
        content: 'details',
        detailsTab: 'details',
      });
    };

    return useMemo(
      () =>
        [
          {
            accessorKey: 'displayName',
            header: 'Rule Name',
            muiTableBodyCellProps,
            Cell: ({ row: { original } }) => {
              return (
                <Typography
                  onClick={() => onClick(original.name)}
                  color='primary'
                >
                  {original.displayName}
                </Typography>
              );
            },
          },
          {
            accessorKey: 'description',
            header: 'Description',
            muiTableBodyCellProps,
            Cell: ({ row: { original } }) => {
              return (
                <Typography
                  sx={{
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 2,
                  }}
                >
                  {original.description}
                </Typography>
              );
            },
          },
          {
            accessorKey: 'categories',
            header: 'Category',
            muiTableBodyCellProps,
            Cell: ({ row: { original } }) => {
              if (!original.categories) {
                return null;
              }

              return original.categories.map((category) => (
                <Typography
                  key={category}
                  fontSize='12px'
                  bgcolor='#E4F6FF'
                  borderRadius='20px'
                  py='3px'
                  px='7px'
                >
                  {original.categories}
                </Typography>
              ));
            },
          },
          {
            accessorKey: 'status',
            header: 'Validation Status',
            muiTableBodyCellProps,
            Cell: ({ row: { original } }) => {
              return <DataValidationRuleStatusBadge ruleItem={original} />;
            },
          },
          {
            header: '',
            accessorKey: 'actions',
            muiTableBodyCellProps: {
              sx: {
                ...muiTableBodyCellProps.sx,
                justifyContent: 'end',
              },
            },
            Cell: ({ row: { original } }) => {
              const status = getRuleItemStatus(original);

              if (status === RuleStatus.CREATING) {
                return (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      minWidth: '110px',
                    }}
                  >
                    <CircularProgress size={24} />
                  </Box>
                );
              }

              if (original.active) {
                return (
                  <Button
                    variant='text'
                    endIcon={<ArrowForwardIcon style={{ fontSize: 14 }} />}
                    sx={{ minWidth: '110px' }}
                    onClick={() => onClick(original.name)}
                  >
                    View
                  </Button>
                );
              }

              return (
                <Button
                  variant='contained'
                  endIcon={<ArrowForwardIcon style={{ fontSize: 14 }} />}
                  onClick={() => onClick(original.name)}
                >
                  Activate
                </Button>
              );
            },
          },
        ] as MRT_ColumnDef<RuleItem>[],
      []
    );
  };
