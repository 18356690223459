import React from 'react';
import { Box, Grid, Typography } from '@cherre-frontend/ui';
import { useCherreState, useCherreValue } from '@cherre-frontend/data-fetching';
import {
  connectorDetailsDataValidationState,
  dataValidationRuleForm,
} from '../../recoil';
import { connectorDetailsSelectedTable } from '../../../../recoil';
import DefaultSelect from './DefaultSelect';
import { DataValidationState } from './utils/types';
import DefaultTextInput from './DefaultTextInput';
import {
  columnRuleTypeSelectOptions,
  tableRuleTypeSelectOptions,
} from './utils/const';
import StyledLabel from './Label';

type Props = {
  children?: React.ReactNode;
};

const DefaultEditFormInputs: React.FC<Props> = ({ children }) => {
  const [dataValidationState] = useCherreState(
    connectorDetailsDataValidationState
  );

  const selectedTable = useCherreValue(connectorDetailsSelectedTable);
  const form = useCherreValue(dataValidationRuleForm);

  return (
    <>
      <Grid container direction='row' spacing={3}>
        <Grid
          item
          xs={dataValidationState === DataValidationState.COLUMN ? 6 : 12}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <StyledLabel style={{ fontSize: '14px', fontWeight: 600 }} required>
              Table
            </StyledLabel>
            <Typography sx={{ fontSize: '14px' }}>{selectedTable}</Typography>
          </Box>
        </Grid>

        {dataValidationState === DataValidationState.COLUMN && (
          <Grid item xs={6}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <StyledLabel
                style={{ fontSize: '14px', fontWeight: 600 }}
                required
              >
                Column
              </StyledLabel>
              <Typography sx={{ fontSize: '14px' }}>{form?.column}</Typography>
            </Box>
          </Grid>
        )}
      </Grid>
      <Grid container direction='row' spacing={3}>
        <Grid item xs={6}>
          <DefaultTextInput
            title='Name'
            fieldName='name'
            placeholder='Enter rule name'
            required
          />
        </Grid>
        <Grid item xs={6}>
          <DefaultSelect
            title='Rule Type'
            fieldName='rule_type'
            menuOptions={
              dataValidationState === DataValidationState.COLUMN
                ? columnRuleTypeSelectOptions
                : tableRuleTypeSelectOptions
            }
            clearFormOnChange
          />
        </Grid>
      </Grid>
      {children}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DefaultTextInput
            title='Description'
            fieldName='description'
            placeholder='Write your description'
            multiline
            rows={4}
            sx={{
              '& .MuiInputBase-root': {
                height: 'unset',
              },
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DefaultEditFormInputs;
