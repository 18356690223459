import React, { FC, ReactElement, useCallback, useMemo, useRef } from 'react';
import { Button } from '@cherre-frontend/ui';
import { useAddConnector } from '../../../dataState';
import { useLazyLoadQuery } from 'react-relay';
import { PreConfiguredModelsQuery } from 'src/services/DOM/preconfiguredModels';
import { preconfiguredModelsQuery } from 'src/services/DOM/preconfiguredModels/__generated__/preconfiguredModelsQuery.graphql';
import {
  useConnectorUIState,
  useDestinationUIState,
} from '../../../uiState/contexts';
import { buildDestinationsDraft } from '../utils/buildDestinationsDraft';
import CheckboxTable from '../CheckboxTable';
import { buildTableModelsSections } from '../utils/buildTableModelsSections';
import {
  ButtonContainer,
  ComponentContainer,
  ConnectorFieldsContainer,
  ModelNameContainer,
  ModelTablesContainer,
  SourceNameContainer,
  Title,
} from './styles';
import { ModelCheckboxFormTables } from '../CheckboxTable/model';
import { ModelCheckboxFormTablesRef } from './model';
import { buildModelTablesCacheRef } from '../utils/buildModelTablesCacheRef';
import { BuildTableModelsSectionsReturn } from '../utils/buildTableModelsSections/model';

/**
 * CheckBoxConnectorChooseTables component render the model tables form .
 *
 *
 * @hook
 * - `useMemo` - It is using the function `buildTableModelsSections` responsable for building the models table list 
 * 
 * @name useAddConnector
 * @returns {Object} An object containing the selected sources and models by the user.
 * @returns {function} A function to update the global connector state
 *
 * @name useConnectorUIState
 * @returns {function} A function to manage the flow steps
 *
 * @name useDestinationUIState
 * @returns {Object} An object containing the selected destination by the user
 *
 * @name useLazyLoadQuery
 * @returns {Array} An array containing the tables belongs to the preconfigured models
 * 
 *@remarks 
  - This component is using the useRef formData to cache the checkboxes selections and save it after in draft or create the connector
 * 
 * @component
 * @returns {ReactElement} The rendered CheckBoxConnectorChooseTables component.
 */

const CheckBoxConnectorChooseTables: FC = (): ReactElement => {
  const [addConnectorData, setData] = useAddConnector();
  const [, setUIState] = useConnectorUIState();
  const [selectedDestination] = useDestinationUIState();
  const formData = useRef<ModelCheckboxFormTablesRef[]>([]);
  const { preconfiguredModels } = useLazyLoadQuery<preconfiguredModelsQuery>(
    PreConfiguredModelsQuery,
    {}
  );

  const modelSections = useMemo<BuildTableModelsSectionsReturn[]>(
    () =>
      buildTableModelsSections({
        addConnectorData,
        preconfiguredModels,
        selectedDestination,
      }),
    [addConnectorData]
  );

  const handleCancelAction = useCallback((): void => {
    setUIState({ step: 'default', index: 0 });
  }, []);

  const handleSaveSelection = useCallback((): void => {
    setData((prev) => {
      const destinations = buildDestinationsDraft({
        state: prev,
        selectedDestination,
        formData: formData.current,
      });
      const data = { ...prev, destinations };
      return data;
    });
    handleCancelAction();
  }, [selectedDestination]);

  const handleOnChangeForm = useCallback(
    (
      model: BuildTableModelsSectionsReturn,
      onChangeData: ModelCheckboxFormTables
    ): void => {
      const newFormData = buildModelTablesCacheRef({
        formCache: formData.current,
        model,
        onChangeData,
      });

      formData.current = newFormData;
    },
    []
  );

  return (
    <ComponentContainer>
      {modelSections.map((model, index) => (
        <ModelTablesContainer key={model.modelName}>
          {index === 0 && <Title>Select tables to include:</Title>}
          <ConnectorFieldsContainer>
            <SourceNameContainer>
              Source: {model.sourceDisplayName}
            </SourceNameContainer>
            <ModelNameContainer>
              Model: {model.modelDisplayName}
            </ModelNameContainer>
          </ConnectorFieldsContainer>

          <CheckboxTable
            checkboxForm={model.checkboxForm}
            options={model.options}
            selectedAll={model.selectedAll}
            onChangeForm={(data: any): void => handleOnChangeForm(model, data)}
          />
        </ModelTablesContainer>
      ))}

      <ButtonContainer>
        <Button onClick={handleCancelAction}>Cancel</Button>
        <Button onClick={handleSaveSelection} variant='contained'>
          Next
        </Button>
      </ButtonContainer>
    </ComponentContainer>
  );
};

export default CheckBoxConnectorChooseTables;
