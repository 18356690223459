import React from 'react';
import {
  Box,
  Grid,
  useTheme,
  Typography,
  ArrowForwardIcon,
  IconButton,
  Tooltip,
} from '@cherre-frontend/ui';
import ConnectorStatusChip from 'src/products/connector/components/ConnectorStatusChip';
import { useHistory } from 'react-router';
import { ConnectorDetailsTabValues } from '../../connector-details';
import { useCherreSetState } from '@cherre-frontend/data-fetching';
import { connectorDetailsTabState } from 'src/products/connector/recoil';
import { DataDeliveryScheduleItem } from './DataDeliveryScheduleSection';
import { useResponsiveScheduleName } from '../hooks/useResponsiveScheduleName';

type DataDeliveryScheduleCardTypes = {
  scheduleItem: DataDeliveryScheduleItem;
};

const DataDeliveryScheduleCard: React.FC<DataDeliveryScheduleCardTypes> = ({
  scheduleItem,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const setTab = useCherreSetState(connectorDetailsTabState);
  const { scheduleTitleMaxWidth, isXLargeScreen } = useResponsiveScheduleName();
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.grey[50],
        borderRadius: '10px',
        width: '100%',
        alignItems: 'center',
      }}
    >
      <Grid
        container
        direction='row'
        padding='10px 20px'
        justifyContent='space-between'
        alignItems='center'
        wrap='nowrap'
      >
        <Grid item xs={5}>
          <Tooltip
            title={scheduleItem.displayName ?? scheduleItem.name}
            enterDelay={200}
            componentsProps={{
              tooltip: {
                sx: {
                  maxWidth: 'none',
                },
              },
            }}
          >
            <Typography
              fontSize='14px'
              fontWeight='medium'
              sx={{
                maxWidth: scheduleTitleMaxWidth,
                overflow: isXLargeScreen ? 'hidden' : 'visible',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {scheduleItem.displayName ?? scheduleItem.name}
            </Typography>
          </Tooltip>
        </Grid>
        <Grid item xs={6}>
          <ConnectorStatusChip
            status={scheduleItem.runStatus}
            statusMessage={scheduleItem.runStatusMessage}
          />
        </Grid>
        <Grid item xs={1} display='flex' justifyContent='flex-end'>
          <IconButton
            onClick={() => {
              setTab(ConnectorDetailsTabValues.OVERVIEW);
              history.push(`/connectors/${scheduleItem.connectorId}`);
            }}
          >
            <ArrowForwardIcon
              sx={{
                width: '20px',
                height: '20px',
                color: theme.palette.primary.main,
              }}
            />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};

DataDeliveryScheduleCard.displayName = 'DataDeliveryScheduleCard';
export default DataDeliveryScheduleCard;
