import { DESTINATIONS, SOURCES } from '../../add-connector/mockData';

export const enhanceDraftConnectorList = (draftList) => {
  return draftList.map((draft) => {
    const sources = draft.sources.map((source) => {
      const { roundedImage: logo = '' } =
        SOURCES.find((mockSource) => mockSource.sourceName === source.name) ||
        {};

      return {
        logo,
        name: source.name,
      };
    });

    const models = draft.models.map((model) => {
      const destinations = model.destinations.map((destination) => {
        const {
          roundedImage: logo = '',
          displayName = '',
          destinationName: name = '',
        } = DESTINATIONS.find(
          (mockDestination) =>
            mockDestination.destinationName === destination.name
        ) || {};

        return { displayName, name, logo };
      });

      return {
        ...model,
        destinations,
      };
    });

    return {
      ...draft,
      sources,
      models,
    };
  });
};
