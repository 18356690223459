import React from 'react';
import {
  MuiAutocomplete as Autocomplete,
  Box,
  Checkbox,
  TextField,
} from '@cherre-frontend/ui';
import StyledLabel from './Label';

const NotificationFormInputs: React.FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '32px',
        width: '800px',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <StyledLabel style={{ fontSize: '14px', fontWeight: 600 }}>
          How do you want to get alerted if a check fails?
        </StyledLabel>
        <Box
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
          <Checkbox disableRipple sx={{ paddingLeft: '0px' }} />
          E-mail
        </Box>
        <Box
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
          <Checkbox disableRipple sx={{ paddingLeft: '0px' }} />
          In-app Notifications
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <StyledLabel style={{ fontSize: '14px', fontWeight: 600 }}>
          Who do you want to notify if a check fails?
        </StyledLabel>
        <Autocomplete
          multiple
          options={[{ value: 'kevin@cherre.com' }]}
          getOptionLabel={(option) => option.value}
          renderInput={(params) => <TextField {...params} variant='standard' />}
        />
      </Box>
    </Box>
  );
};

export default NotificationFormInputs;
