export enum DataValidationState {
  DASHBOARD = 'Dashboard',
  TABLE_SELECTION = 'Table_Selection',
  TABLE = 'Table',
  COLUMN = 'Column',
  DETAILS = 'Details',
}

export enum RuleTypes {
  COLUMN_VALUES_TBB = 'column_values_to_be_between',
  COLUMN_VALUE_STD_DEV_TBB = 'column_value_std_dev_to_be_between',
  COLUMN_VALUES_TO_BE_NOT_NULL = 'column_values_to_be_not_null',
  COLUMN_VALUE_LENGTHS_TBB = 'column_value_lengths_to_be_between',
  COLUMN_VALUE_MIN_TBB = 'column_value_min_to_be_between',
  COLUMN_VALUES_MISSING_COUNT = 'column_values_missing_count',
  COLUMN_VALUES_TO_BE_UNIQUE = 'column_values_to_be_unique',
  COLUMN_VALUE_MAX_TBB = 'column_value_max_to_be_between',
  COLUMN_VALUES_TO_NOT_MATCH_REGEX = 'column_values_to_not_match_regex',
  COLUMN_VALUE_MEDIAN_TBB = 'column_value_median_to_be_between',
  COLUMN_VALUES_TO_MATCH_REGEX = 'column_values_to_match_regex',
  COLUMN_VALUE_MEAN_TBB = 'column_value_mean_to_be_between',
  COLUMN_VALUES_SUM_TBB = 'column_values_sum_to_be_between',
  COLUMN_VALUES_TO_BE_IN_SET = 'column_values_to_be_in_set',
  COLUMN_VALUES_TO_BE_NOT_IN_SET = 'column_values_to_be_not_in_set',
  TABLE_ROW_COUNT_TO_EQUAL = 'table_row_count_to_equal',
  TABLE_COLUMN_COUNT_TO_EQUAL = 'table_column_count_to_equal',
  TABLE_COLUMN_NAME_TO_EXIST = 'table_column_name_to_exist',
  TABLE_ROW_COUNT_TBB = 'table_row_count_to_be_between',
  TABLE_COLUMN_COUNT_TBB = 'table_column_count_to_be_between',
  TABLE_COLUMN_TO_MATCH_SET = 'table_column_to_match_set',
  TABLE_ROW_INSERTED_COUNT_TBB = 'table_row_inserted_count_to_be_between',
  TABLE_CUSTOM_SQL_QUERY = 'table_custom_SQL_query',
}

export enum FormInputs {
  TEXT = 'text',
  SELECT = 'select',
  VALUE_LIST = 'value_list',
  SWITCH = 'switch',
}

export type FormUI = {
  title: string;
  type: FormInputs;
  required?: boolean;
  placeholder?: string;
  fieldName: string;
  menuOptions?: SelectOption[];
  multiline?: boolean;
  numeric?: boolean;
};

export type SelectOption = {
  label?: string;
  value: string;
};
