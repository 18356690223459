import React from 'react';
import { styled } from '@cherre-frontend/ui';
import Step from '../../components/Step';
import OverviewDivider from 'src/products/connector/components/OverviewDivider';
import { ValidationStatus } from 'src/products/connector/components/ValidationStatusPopover';
import { useConnectorUIState } from '../../uiState/contexts';
import { useAddConnector } from '../../dataState';
import StepComponent from '../../components/StepComponent';
import { DESTINATIONS, SOURCES } from '../../mockData';
import StepsMenu from './StepsMenu';

const Container = styled('div')`
  display: grid;
  grid-template-columns: 1fr 46px 1fr 46px 1fr;
  gap: 32px;
`;

const getSource = (sourceName: string) =>
  SOURCES.find((source) => source.sourceName === sourceName);

/**
 * `Steps` is a React functional component that renders a series of steps for adding a connector.
 * Each step allows the user to add sources, models, and destinations for the connector.
 *
 * The component uses the `useConnectorUIState` and `useAddConnector` hooks to manage the state of the steps and the connector data.
 *
 * The steps are displayed within a `Container` component, and each step is represented by a `Step` component.
 *
 * The first step allows the user to add sources. Each source is displayed with a logo and title, and provides options to edit or delete the source.
 *
 * The second step allows the user to add models to transform the data into usable tables.
 *
 * The third step allows the user to add destinations for the data to flow to.
 *
 * @returns {JSX.Element} The rendered component.
 */
const Steps: React.FC = () => {
  const [, setStep] = useConnectorUIState();
  const [data, setData] = useAddConnector();

  return (
    <Container>
      <Step
        title='Step 1: Sources'
        subtitle='Add any new or existing sources for this connector.'
        addTestID='ADD-SOURCE'
        disabled={data.sources?.length === SOURCES.length}
        onClick={() =>
          setStep({ step: 'source' as const, index: data.sources?.length || 0 })
        }
      >
        {data.sources?.map((source, index) => {
          const _source = getSource(source.preconfiguredSourceName);
          if (!_source) {
            return null;
          }
          return (
            <StepComponent
              key={source.preconfiguredSourceName}
              logo={_source.roundedImage}
              title={_source.displayName}
            >
              {(close) => (
                <StepsMenu
                  onClickEdit={() => {
                    setStep({ step: 'source' as const, index });
                    close();
                  }}
                  onClickDelete={() => {
                    setData((prev) => {
                      const sources = [...(prev.sources || [])];
                      sources.splice(index, 1);

                      if (sources.length === 0) {
                        setStep({
                          step: 'default',
                          index: 0,
                          repeatedSource: false,
                        });

                        return {
                          ...prev,
                          sources: undefined,
                          models: undefined,
                          destinations: undefined,
                        };
                      }

                      return { ...prev, sources };
                    });
                    close();
                  }}
                />
              )}
            </StepComponent>
          );
        })}
      </Step>
      <OverviewDivider status={ValidationStatus.NOT_RUN} />
      <Step
        title='Step 2: Models'
        subtitle='Add models to transform the data into usable tables.'
        addTestID='ADD-MODEL'
        disabled={!data.sources?.length}
        onClick={() =>
          setStep({ step: 'model' as const, index: data.models?.length || 0 })
        }
      >
        {data.models?.map((model, index) => {
          return (
            <StepComponent
              key={model.name}
              logo={`/assets/connector-icons/rounded-logos/${model.provider}-rounded.svg`}
              title={model.displayName}
            >
              {(close) => (
                <StepsMenu
                  onClickEdit={() => {
                    setStep({ step: 'model' as const, index });
                    close();
                  }}
                  onClickDelete={() => {
                    setData((prev) => {
                      const newModels =
                        prev.models?.filter(
                          (modelState) => modelState.name !== model.name
                        ) || [];

                      if (newModels.length === 0) {
                        setStep({ step: 'default', index: 0 });

                        return {
                          ...prev,
                          models: undefined,
                          destinations: undefined,
                        };
                      }

                      return { ...prev, models: newModels };
                    });
                    close();
                  }}
                />
              )}
            </StepComponent>
          );
        })}
      </Step>
      <OverviewDivider status={ValidationStatus.NOT_RUN} />
      <Step
        title='Step 3: Destination'
        subtitle='Add any destination you want this data to flow to.'
        addTestID='ADD-DESTINATION'
        disabled={
          !data.models?.length ||
          data.destinations?.length === DESTINATIONS.length
        }
        onClick={() =>
          setStep({
            step: 'destination' as const,
            index: data.destinations?.length || 0,
          })
        }
      >
        {data.destinations?.map((destination, index) => {
          const localDestination = DESTINATIONS.find(
            (findDestination) =>
              findDestination.destinationName === destination.destinationName
          );
          if (!localDestination) {
            return null;
          }

          return (
            <StepComponent
              key={localDestination.destinationName}
              logo={localDestination.roundedImage}
              title={localDestination.displayName}
            >
              {(close) => (
                <StepsMenu
                  onClickEdit={() => {
                    setStep({ step: 'destination' as const, index });
                    close();
                  }}
                  onClickDelete={() => {
                    setData((prev) => {
                      const newDestinations =
                        prev.destinations?.filter(
                          (destinationState) =>
                            destinationState.destinationName !==
                            destination.destinationName
                        ) || [];

                      if (newDestinations.length === 0) {
                        setStep({ step: 'default', index: 0 });

                        return {
                          ...prev,
                          destinations: undefined,
                        };
                      }

                      return { ...prev, destinations: newDestinations };
                    });
                    close();
                  }}
                />
              )}
            </StepComponent>
          );
        })}
      </Step>
    </Container>
  );
};

export default Steps;
