import React from 'react';
import { Box, Grid, Typography, useTheme } from '@cherre-frontend/ui';
import LinkWithArrow from './LinkWithArrow';
import SourceLibraryCard from './SourceLibraryCard';
import { CherreConnectorSource } from 'src/services/ConnectorDemo/data/ConnectorLanding';

type CherreSourceLibrarySection = {
  sources: CherreConnectorSource[];
};
const CherreSourceLibrarySection: React.FC<CherreSourceLibrarySection> = ({
  sources,
}) => {
  const theme = useTheme();
  return (
    <Grid container direction='column'>
      <Grid item>
        <Typography fontSize='20px' fontWeight='bold'>
          Cherre Source Library
        </Typography>
      </Grid>
      {sources.length > 0 ? (
        <Grid container direction='column' marginTop='10px' gap={1}>
          {sources.map((source, index) => (
            <Grid item key={index}>
              <SourceLibraryCard source={source} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid
          container
          direction='column'
          justifyContent='start'
          alignItems='center'
          minHeight='100px'
          marginTop='10px'
          padding={0}
          gap={1}
        >
          <Box
            sx={{
              backgroundColor: theme.palette.grey[50],
              borderRadius: '10px',
              width: '100%',
              alignItems: 'center',
              padding: '20px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Typography fontSize='14px' fontWeight='bold'>
              Source library coming soon.
            </Typography>
          </Box>
        </Grid>
      )}
      {sources.length > 0 && (
        <Grid item marginTop='18px' marginLeft='12px'>
          <LinkWithArrow
            linkText='View Source Library'
            onClick={() => console.log('click')}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default CherreSourceLibrarySection;
