import React, { useState } from 'react';
import {
  Box,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
  useTheme,
} from '@cherre-frontend/ui';
import {
  useCherreSetState,
  useCherreState,
} from '@cherre-frontend/data-fetching';
import {
  connectorDetailsDataValidationState,
  dataValidationRuleForm,
} from '../../recoil';
import { connectorDetailsSelectedTable } from '../../../../recoil';
import { DataValidationState } from './utils/types';
import { emptyForm } from './utils/const';
import StyledLabel from './Label';
import ConnectorTableAutocomplete from '../../../../components/ConnectorTableAutocomplete';
import ConnectorButton from '../Overview/ConnectorButton';
import TableSelectionFormLoader from './TableSelectionFormLoader';
import { useGetDataValidation } from './hooks/useGetDataValidation';

const TableSelectionForm: React.FC = () => {
  const theme = useTheme();
  const [testType, setTestType] = useState<DataValidationState>(
    DataValidationState.COLUMN
  );
  const setForm = useCherreSetState(dataValidationRuleForm);

  const setDataValidationState = useCherreSetState(
    connectorDetailsDataValidationState
  );

  const [selectedTable, setSelectedTable] = useCherreState(
    connectorDetailsSelectedTable
  );

  const { tables, validationRules } = useGetDataValidation();

  const handleTableSelect = (_, value) => {
    setSelectedTable(value.label);
  };

  const handleSelect = (e: SelectChangeEvent) => {
    setTestType(e.target.value as DataValidationState);
  };

  if (tables.state === 'loading' || validationRules.state === 'loading') {
    return <TableSelectionFormLoader />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '16px',
        paddingTop: '32px',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant='h4'>Create a Rule</Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '32px',
            marginTop: '40px',
            width: '800px',
          }}
        >
          <Box>
            <StyledLabel style={{ fontSize: '14px', fontWeight: 600 }} required>
              Select Table
            </StyledLabel>
            <ConnectorTableAutocomplete
              menuOptions={tables.contents}
              onChange={handleTableSelect}
              value={{
                label: selectedTable ? selectedTable : tables.contents[0],
                id: selectedTable ? selectedTable : tables.contents[0],
              }}
            />
          </Box>
          <Box>
            <StyledLabel style={{ fontSize: '14px', fontWeight: 600 }} required>
              Select column or table rule
            </StyledLabel>
            <Select
              sx={{
                borderRadius: '6px',
                width: '100%',
                '.MuiSelect-select': {
                  height: '22px',
                  paddingTop: '6px',
                  paddingBottom: '6px',
                },
                '&.MuiOutlinedInput-input': {
                  paddingTop: '6px',
                  paddingBottom: '6px',
                },
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: theme.palette.grey[400],
                  borderRadius: '6px',
                },
                '.MuiOutlinedInput-notchedOutline:hover': {
                  borderColor: theme.palette.grey[400],
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: theme.palette.grey[400],
                },
              }}
              MenuProps={{
                sx: {
                  '&& .Mui-selected': {
                    backgroundColor: 'white',
                  },
                  '&& .Mui-selected:hover': {
                    backgroundColor: theme.palette.grey[100],
                  },
                },
              }}
              input={
                <OutlinedInput
                  notched={false}
                  sx={{
                    '.MuiOutlinedInput-notchedOutline': {
                      height: '32px',
                      border: `1px solid ${theme.palette.grey[400]}`,
                    },
                  }}
                />
              }
              label={testType}
              value={testType}
              variant='outlined'
              onChange={handleSelect}
            >
              <MenuItem value={'Column'}>Column Rule</MenuItem>
              <MenuItem value={'Table'}>Table Rule</MenuItem>
            </Select>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '16px',
              justifyContent: 'flex-end',
              paddingBottom: '24px',
            }}
          >
            <ConnectorButton
              variant='text'
              onClick={() => {
                setForm(emptyForm);
                document
                  .getElementById('connector-details-header')
                  ?.scrollIntoView();
                setDataValidationState(DataValidationState.DASHBOARD);
              }}
            >
              Cancel
            </ConnectorButton>
            <ConnectorButton
              variant='contained'
              onClick={() => {
                if (selectedTable === '') {
                  setSelectedTable(tables.contents[0]);
                }
                setDataValidationState(testType);
              }}
            >
              Next
            </ConnectorButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TableSelectionForm;
