import React from 'react';
import { Box, styled, Theme, Typography, useTheme } from '@cherre-frontend/ui';
import { OverviewCardType, OverviewType } from './OverviewCard';
import ConnectorCardLogo from './ConnectorCardLogo';

type Props = {
  card: OverviewCardType<OverviewType.MODEL>;
};

const ComponentInfoText = styled(Typography)<{ color: string }>`
  color: ${({ color }) => color || 'inherit'};
`;

const getModelCardColor = (
  card: OverviewCardType<OverviewType.MODEL>,
  theme: Theme
) => {
  const cardColorMap = {
    cherre: {
      running: theme.palette.warning.light,
      default: '#ff6294',
    },
    default: {
      running: theme.palette.warning.light,
      default: theme.palette.primary.light,
    },
  };
  const providerMap =
    cardColorMap[card.provider ?? 'cherre'] || cardColorMap.default;

  if (card.statusObject?.isRunning) {
    return providerMap.running;
  }

  return providerMap.default;
};

const ModelConnectorCard: React.FC<Props> = ({ card }) => {
  const theme = useTheme();

  const componentTextColor = card.statusObject?.isRunning
    ? theme.palette.grey[600]
    : theme.palette.common.white;

  const modelCardBackgroundColor = getModelCardColor(card, theme);

  return (
    <Box
      style={{
        backgroundColor: modelCardBackgroundColor,
        borderRadius: '5px',
        padding: '10px 20px',
        marginBottom: '30px',
      }}
      data-testid='model-card-container'
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '8px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <ConnectorCardLogo provider={card.provider} />
          <Typography
            variant='h5'
            sx={{
              color: componentTextColor,
              fontWeight: 700,
            }}
          >
            {card.displayName ?? card.name}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <ComponentInfoText color={componentTextColor}>
          <b>Revision:</b> {card.revision}
        </ComponentInfoText>
        <ComponentInfoText color={componentTextColor}>
          <b>Created:</b> {card.created}
        </ComponentInfoText>
        <ComponentInfoText color={componentTextColor}>
          <b>Creator:</b> {card.creator}
        </ComponentInfoText>
      </Box>
    </Box>
  );
};

export default ModelConnectorCard;
