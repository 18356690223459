import { buildRuleDetailStatus } from 'src/products/connector/utils/buildRuleDetailStatus';

enum ValidationRuleStatus {
  RULE_SUCCESSFUL = 'rule_successful',
  RULE_NOT_SCANNED = 'rule_not_scanned',
  RULE_FAILED_SCAN_COMPLETED = 'rule_failed_scan_completed',
}

const getStatus = (ruleStatus, tableRecordCount, anomalousRecords) => {
  const localRuleStatus = buildRuleDetailStatus(anomalousRecords, ruleStatus);
  if (localRuleStatus === 'Failed' && tableRecordCount) {
    return ValidationRuleStatus.RULE_FAILED_SCAN_COMPLETED;
  }

  if (localRuleStatus === 'Scheduled' && tableRecordCount) {
    return ValidationRuleStatus.RULE_NOT_SCANNED;
  }

  if (localRuleStatus === 'Success' && tableRecordCount && !anomalousRecords) {
    return ValidationRuleStatus.RULE_SUCCESSFUL;
  }
};

export const determineFailedRecordCountText = (
  rule,
  tableRecordCount,
  anomalousRecords
) => {
  const status = getStatus(
    rule?.ruleStatus,
    tableRecordCount,
    anomalousRecords
  );

  switch (status) {
    case ValidationRuleStatus.RULE_FAILED_SCAN_COMPLETED:
      return anomalousRecords;
    case ValidationRuleStatus.RULE_NOT_SCANNED:
      return '--';
    case ValidationRuleStatus.RULE_SUCCESSFUL:
      return 0;
    default:
      return '--';
  }
};
