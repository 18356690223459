import React from 'react';
import {
  Chip,
  UpdateIcon,
  CheckIcon,
  useTheme,
  WarningIcon,
  styled,
  CancelIcon,
  EditIcon,
} from '@cherre-frontend/ui';
import { ConnectorStatus } from '../recoil';

const StyledChip = styled(Chip)`
  height: 21px;
  width: fit-content;
  color: white;
  font-size: 12px;
  font-weight: 500;
`;

type Props = {
  status: ConnectorStatus;
  statusMessage: string;
};

const ConnectorStatusChip: React.FC<Props> = ({ status, statusMessage }) => {
  const theme = useTheme();
  const renderChip = (status: ConnectorStatus) => {
    switch (status) {
      case ConnectorStatus.SUCCESS:
        return (
          <StyledChip
            label={statusMessage}
            icon={
              <CheckIcon style={{ color: 'white', width: 12, height: 12 }} />
            }
            sx={{ backgroundColor: theme.palette.success.dark }}
          />
        );
      case ConnectorStatus.SCHEDULED:
        return (
          <StyledChip
            label={statusMessage}
            icon={
              <UpdateIcon style={{ color: 'white', width: 12, height: 12 }} />
            }
            sx={{ backgroundColor: theme.palette.info.main }}
          />
        );
      case ConnectorStatus.FAILED:
        return (
          <StyledChip
            label={statusMessage}
            icon={
              <WarningIcon style={{ color: 'white', width: 12, height: 12 }} />
            }
            sx={{ backgroundColor: theme.palette.error.main }}
          />
        );
      case ConnectorStatus.DEACTIVATED:
        return (
          <StyledChip
            label={statusMessage}
            icon={
              <CancelIcon style={{ color: 'white', width: 12, height: 12 }} />
            }
            sx={{ backgroundColor: theme.palette.grey[600] }}
          />
        );
      case ConnectorStatus.DRAFT:
        return (
          <StyledChip
            label={statusMessage}
            icon={
              <EditIcon style={{ color: 'white', width: 12, height: 12 }} />
            }
            sx={{ backgroundColor: theme.palette.grey[400] }}
          />
        );
      case ConnectorStatus.LOADING:
        return (
          <StyledChip
            label={statusMessage}
            icon={
              <UpdateIcon style={{ color: 'white', width: 12, height: 12 }} />
            }
            sx={{ backgroundColor: theme.palette.info.main }}
          />
        );
      default:
        return 'N/A';
    }
  };
  return <>{renderChip(status)}</>;
};

export default ConnectorStatusChip;
