import React from 'react';
import {
  IconButton,
  MoreVertIcon,
  styled,
  Typography,
  Popover,
} from '@cherre-frontend/ui';

const Container = styled('div')`
  display: grid;
  grid-template-columns: 30px 1fr 30px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.palette.grey[50]};
  background: ${({ theme }) => theme.palette.grey[50]};
  padding: 10px 20px;
  width: 100%;
  align-items: center;
  gap: 10px;

  /* Raised Item */
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
`;

const Image = styled('img')`
  width: 30px;
  height: 30px;
`;

const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey[700]};
  font-feature-settings: 'liga' off;

  /* Headings/H5 */
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24.5px; /* 122.5% */
  letter-spacing: 0.2px;
`;

/**
 * Props for the StepComponent.
 *
 * @typedef StepComponentProps
 * @property {string} title - The title of the step.
 * @property {string} logo - The URL or path to the logo image.
 * @property {(close: () => void) => React.ReactNode} children - A render function that receives a close function and returns React nodes.
 */
type StepComponentProps = {
  title: string;
  logo: string;
  children: (close: () => void) => React.ReactNode;
};

/**
 * StepComponent is a React functional component that renders a container with an image, title, and a popover.
 * The popover is triggered by an icon button and displays the children passed to the component.
 *
 * @param {StepComponentProps} props - The properties passed to the component.
 * @param {string} props.title - The title to be displayed in the component.
 * @param {string} props.logo - The source URL of the logo image to be displayed.
 * @param {React.ReactNode} props.children - The children to be rendered inside the popover. The children function receives a handleClose function to close the popover.
 *
 * @returns {JSX.Element} The rendered StepComponent.
 */
const StepComponent: React.FC<StepComponentProps> = ({
  title,
  logo,
  children,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <Container>
      <Image src={logo} />
      <Title>{title}</Title>
      <IconButton onClick={handleClick} data-testid='STEP-MORE'>
        <MoreVertIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {open ? children(handleClose) : null}
      </Popover>
    </Container>
  );
};

export default StepComponent;
