import React from 'react';
import { Control } from 'react-hook-form';
import {
  DataValidationForm,
  useDataValidationFormContext,
} from '../dataValidationFormEngine';
import { Box, ControlledInput } from '@cherre-frontend/ui';
import { DataValidationSQLDialog } from './DataValidationSQLDialog';
import { GetValidationCustomTableQuery } from '../../../../../queries/getValidationCustomTable';
import { useLazyLoadQuery } from 'react-relay';
import { getValidationCustomTableQuery } from '../../../../../queries/__generated__/getValidationCustomTableQuery.graphql';
import { useDataValidationV2 } from '../../../../../providers/DataValidationV2Provider';

interface DataValidationTableRefFieldProps {
  control: Control<DataValidationForm>;
  label: string;
  uiReadOnly?: boolean;
  propertiesType: 'generalProperties' | 'specificProperties';
  index: number;
}

export const DataValidationTableRefField = ({
  control,
  index,
  label,
  propertiesType,
  uiReadOnly,
}: DataValidationTableRefFieldProps) => {
  const { getValues } = useDataValidationFormContext();
  const { owner } = useDataValidationV2();
  const name = `${propertiesType}.${index}.value` as const;
  const tableName = getValues(name);

  const { validationCustomTable } =
    useLazyLoadQuery<getValidationCustomTableQuery>(
      GetValidationCustomTableQuery,
      { owner, tableName }
    );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'end',
        gap: '6px',
      }}
    >
      <Box sx={{ flex: 1 }}>
        <ControlledInput
          required
          label={label}
          disabled={uiReadOnly}
          control={control}
          name={name}
        />
      </Box>

      {validationCustomTable && validationCustomTable.sqlInput && (
        <DataValidationSQLDialog sql={validationCustomTable.sqlInput} />
      )}
    </Box>
  );
};
