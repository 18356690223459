import { styled } from '@cherre-frontend/ui';

export const Title = styled('h6')`
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.5px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

export const ConnectorFieldsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 20px;
`;

export const SourceNameContainer = styled('p')`
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  line-height: 17.07px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

export const ModelNameContainer = styled('p')`
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 17.07px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

export const ButtonContainer = styled('div')`
  display: flex;
  justify-content: end;
  width: 100%;
  margin-top: 20px;
  gap: 12px;
`;

export const ModelTablesContainer = styled('div')``;

export const ComponentContainer = styled('div')``;
