import { styled } from '@cherre-frontend/ui';

export const ConnectorErrorBannerContainer = styled('div')`
  border-radius: 10px;
  padding-top: 6px;
  padding-right: 20px;
  padding-bottom: 6px;
  padding-left: 20px;
  background-color: ${({ theme }) => theme.palette.error.main};
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 1%;
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 20px;
  margin-left: 16px;
  margin-right: 16px;
`;

export const StatusContent = styled('span')`
  font-weight: 500;
`;

export const MessageContainer = styled('p')`
  margin: 0;
`;

export const MessageContent = styled('span')``;
