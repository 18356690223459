/**
 * @generated SignedSource<<f3d5fc08e69443066204086ac77fb3f5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type getConnectorsByOwnerQuery$variables = {};
export type getConnectorsByOwnerQuery$data = {
  readonly connectors: ReadonlyArray<{
    readonly models: ReadonlyArray<{
      readonly name: string;
      readonly preconfiguredModelRef: string | null;
      readonly provider: string | null;
      readonly publishedRevisions: ReadonlyArray<{
        readonly revisionNumber: number;
      }>;
    }>;
    readonly name: string;
  }>;
};
export type getConnectorsByOwnerQuery = {
  response: getConnectorsByOwnerQuery$data;
  variables: getConnectorsByOwnerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Connector",
    "kind": "LinkedField",
    "name": "connectors",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Model",
        "kind": "LinkedField",
        "name": "models",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "provider",
            "storageKey": null
          },
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "preconfiguredModelRef",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PublishedRevisionObject",
            "kind": "LinkedField",
            "name": "publishedRevisions",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "revisionNumber",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "getConnectorsByOwnerQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "getConnectorsByOwnerQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "34f96edad5f6dbac3453c0c4cd4373dd",
    "id": null,
    "metadata": {},
    "name": "getConnectorsByOwnerQuery",
    "operationKind": "query",
    "text": "query getConnectorsByOwnerQuery {\n  connectors {\n    name\n    models {\n      provider\n      name\n      preconfiguredModelRef\n      publishedRevisions {\n        revisionNumber\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9095db94132fa8a2920b2ad8308b98e4";

export default node;
