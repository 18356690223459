import { useHistory, useLocation } from 'react-router';

/**
 * Custom hook to manage search parameters in the URL.
 *
 * @template T - The type of the search parameters object.
 * @returns {Object} An object containing:
 * - `history`: The history object from react-router.
 * - `pushSearchParams`: A function to update the search parameters in the URL.
 * - `searchParams`: The current search parameters parsed from the URL.
 */
export const useSearchParams = <T extends Record<string, string>>() => {
  const history = useHistory();

  const { search } = useLocation();

  let searchParams: T & Record<string, string> = {} as T &
    Record<string, string>;

  // Get the query string from the URL. If the react-router hook returns an empty string, fallback to the window location search.
  const base64Search = (search || window.location.search).substring(1);

  if (base64Search) {
    // Decode the URI component to get the proper base64 string
    const decodedBase64 = decodeURIComponent(base64Search);

    // Now decode the base64 string to get the JSON (or original text)
    const decodedData = atob(decodedBase64);

    // Parse the decoded string into an object
    searchParams = JSON.parse(decodedData) as T & Record<string, string>;
  }

  /**
   * Updates the search parameters in the URL.
   *
   * @param {T} newParams - The new search parameters to set in the URL.
   */
  const pushSearchParams = (newParams: Partial<T>, replace?: boolean) => {
    const params = JSON.stringify({
      ...(replace ? {} : searchParams),
      ...newParams,
    });

    const base64Params = btoa(params);

    history.push({ search: `${encodeURIComponent(base64Params)}` });
  };

  return {
    history,
    pushSearchParams,
    searchParams,
  };
};
