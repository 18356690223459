import React from 'react';
import { Button, styled, Typography } from '@cherre-frontend/ui';

const ButtonContainer = styled(Button)<{ selected: boolean }>`
  border-radius: 5px;
  background: ${({ theme, selected }) =>
    selected ? `${theme.palette.primary.main}1a` : theme.palette.grey[50]};
  border: ${({ theme, selected }) =>
    selected ? `1px solid ${theme.palette.primary.main}` : '1px solid white'};
  padding: 20px;
  align-items: center;
  width: 260px;
  cursor: pointer;
  color: unset;
  &:hover {
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
    background: ${({ theme }) => theme.palette.primary.main}1a;
  }

  &:active {
    background: ${({ theme }) => theme.palette.primary.main}3a;
  }

  & span {
    flex-direction: column;
    gap: 10px;
    display: flex;
  }
`;

const Image = styled('img')`
  width: 38px;
  height: 38px;
`;

const Title = styled(Typography)`
  grid-area: title;
  color: ${({ theme }) => theme.palette.common.black};
  text-align: center;
  font-feature-settings: 'liga' off;
  font-style: normal;
  font-size: 20px;
  font-weight: 700;
  line-height: 24.5px;
  letter-spacing: 0.01em;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

const Description = styled('p')`
  font-size: 12px;
  font-weight: 500;
  line-height: 14.63px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin: 0;
  color: ${({ theme }) => theme.palette.common.black};
`;
const HeaderContainer = styled('div')`
  display: flex;
  gap: 10px;
`;

export type ModelCardProps = {
  title: string;
  image: string;
  description?: string;
  selected?: boolean;
  onClick: () => void;
};

/**
 * Component representing a model card.
 *
 * @component
 * @param {ModelCardProps} props - The properties for the ModelCard component.
 * @param {string} props.title - The title of the model card.
 * @param {string} props.image - The image URL for the model card.
 * @param {boolean} [propsdescription] - The model description rendered below title.
 * @param {boolean} [props.selected=false] - Indicates if the model card is selected.
 * @returns {JSX.Element} The rendered ModelCard component.
 */

const ModelCard: React.FC<ModelCardProps> = ({
  title,
  image,
  description,
  selected = false,
  onClick,
}) => {
  return (
    <ButtonContainer onClick={onClick} selected={selected}>
      <HeaderContainer>
        <Image src={image} />
        <Title>{title}</Title>
      </HeaderContainer>
      {description && <Description>{description}</Description>}
    </ButtonContainer>
  );
};

export default ModelCard;
