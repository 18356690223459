import React, { useMemo } from 'react';
import {
  Table,
  styled,
  tableBaseStyle,
  useTable,
  Box,
  Typography,
  Button,
  ArrowForwardIcon,
  useTheme,
  Chip,
  CloseIcon,
  Tooltip,
} from '@cherre-frontend/ui';
import { useHistory } from 'react-router';
import {
  useCherreSetState,
  useCherreState,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import { NoItemsFound } from 'src/products/data-submission-portal/components/NoBatchesFound';
import {
  connectorDetailsDataValidationState,
  dataValidationTableState,
} from '../../recoil';
import RuleStatusChip from 'src/products/connector/components/RuleStatusChip';
import { ConnectorDetailsRoute } from 'src/products/connector/routes';
import { DataValidationState } from './utils/types';
import { ValidationRuleResult } from './types';
import { selectedValidationRule } from '../../../validation-details/recoil';
import { printLocalDatetimeWithTimezone } from 'src/products/connector/utils/printLocalDatetimeWithTimezone';

const Container = styled('div')`
  padding-top: 20px;
  display: flex;
  flex: 1;
  width: '100%';
`;

type DataValidationTableTypes = {
  data: ValidationRuleResult[];
};

const statusOrder = {
  Failed: 1,
  Aborted: 2,
  Success: 3,
  undefined: 4,
};

const DataValidationTable: React.FC<DataValidationTableTypes> = ({ data }) => {
  const [tableState, setTableState] = useCherreState(dataValidationTableState);
  const route = useCherreValue(ConnectorDetailsRoute.routeParamSelector);

  const theme = useTheme();

  const history = useHistory();

  const setSelectedValidationRule = useCherreSetState(selectedValidationRule);

  const setDataValidationState = useCherreSetState(
    connectorDetailsDataValidationState
  );

  //Sort rules by status. Failed -> Aborted -> Success -> Yet to run
  const dataCopy = [...data];
  const sortedData = dataCopy.sort((a, b) => {
    const orderA = statusOrder[a.ruleStatus] || 4;
    const orderB = statusOrder[b.ruleStatus] || 4;

    return orderA - orderB;
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: 'tableName',
        header: 'Table Name',
        size: 150,
        Cell: ({ row }) => {
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Tooltip
                title={row.original.tableName}
                enterDelay={200}
                componentsProps={{
                  tooltip: {
                    sx: {
                      maxWidth: 'none',
                    },
                  },
                }}
              >
                <Typography
                  sx={{
                    maxWidth: {
                      xs: '100px',
                      sm: '150px',
                      md: '200px',
                      lg: '250px',
                      xl: '325px',
                    },
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    color: theme.palette.grey[700],
                  }}
                >
                  {row.original.tableName}
                </Typography>
              </Tooltip>
            </Box>
          );
        },
      },
      {
        accessorKey: 'ruleName',
        header: 'Rule Name',
        size: 150,
        Cell: ({ row }) => {
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Tooltip
                title={row.original.ruleName}
                enterDelay={200}
                componentsProps={{
                  tooltip: {
                    sx: {
                      maxWidth: 'none',
                    },
                  },
                }}
              >
                <Typography
                  sx={{
                    maxWidth: {
                      xs: '100px',
                      sm: '150px',
                      md: '200px',
                      lg: '250px',
                      xl: '325px',
                    },
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    color: theme.palette.grey[700],
                  }}
                >
                  {row.original.ruleName}
                </Typography>
              </Tooltip>
            </Box>
          );
        },
      },
      {
        accessorKey: 'lastRun',
        header: 'Last Run',
        size: 100,
        Cell: ({ row }) => {
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  color: theme.palette.grey[700],
                }}
              >
                {row.original.lastRun
                  ? printLocalDatetimeWithTimezone(
                      'MMM DD, YYYY h:mm A',
                      row.original.lastRun
                    )
                  : '--'}
              </Typography>
            </Box>
          );
        },
      },
      {
        accessorKey: 'watchers',
        header: 'Watchers',
        size: 100,
        Cell: ({ row }) => (
          <Box>
            {row.original.watchers.map((watcher, index) => (
              <Chip
                key={index}
                label={watcher}
                onDelete={() => console.log('delete')}
                deleteIcon={<CloseIcon />}
                sx={{
                  height: '21px',
                  width: 'fit-content',
                  fontSize: '12px',
                  fontWeight: '500',
                  maxWidth: '145px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  '& .MuiChip-deleteIcon': {
                    height: '12px',
                    width: '12px',
                    color: theme.palette.common.black,
                  },
                }}
              />
            ))}
          </Box>
        ),
      },
      {
        accessorKey: 'ruleStatus',
        header: 'Validation',
        size: 75,
        Cell: ({ row }) => {
          return (
            <>
              {row.original.ruleStatus ? (
                <RuleStatusChip status={row.original.ruleStatus} />
              ) : null}
            </>
          );
        },
      },
    ],
    []
  );

  const table = useTable({
    data: sortedData,
    skeletonRowCount: 5,
    columns,
    enableSelectAll: false,
    enableRowActions: true,
    enableRowSelection: false,
    enableBottomToolbar: true,
    enablePagination: true,
    positionActionsColumn: 'last',
    layoutMode: 'grid',
    muiTablePaperProps: {
      sx: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        flex: 1,
      },
      elevation: 0,
    },
    muiTableContainerProps: {
      sx: {
        flex: 1,
      },
    },
    state: {
      sorting: tableState?.sorting,
      pagination: tableState?.pagination,
    },
    manualSorting: false,
    onSortingChange: (sort) =>
      setTableState((s) => ({
        ...s,
        sorting: typeof sort === 'function' ? sort(s.sorting) : sort,
      })),
    onPaginationChange: (p) =>
      setTableState((s) => ({
        ...s,
        pagination: typeof p === 'function' ? p(s.pagination) : p,
      })),
    getRowId: (row) => row.displayName as string,
    enableMultiSort: false,
    muiTableHeadRowProps: {
      sx: {
        ...tableBaseStyle.muiTableHeadRowProps.sx,
        '& th': {
          color: theme.palette.grey[700],
          '& .Mui-TableHeadCell-Content': {
            alignItems: 'center',
          },
        },
      },
    },
    muiTableBodyRowProps: {
      sx: { ...tableBaseStyle.muiTableBodyRowProps.sx },
    },
    renderRowActions: ({ row }) => (
      <Button
        onClick={() => {
          setSelectedValidationRule(row.original);
          history.push(
            `/connectors/${route?.connector_name}/validationDetails/${encodeURI(
              row.original.id.toString()
            )}`
          );
          setDataValidationState(DataValidationState.DETAILS);
        }}
        sx={{ color: theme.palette.primary.main }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <Typography>View</Typography>
          <ArrowForwardIcon style={{ height: '15px' }} />
        </Box>
      </Button>
    ),
  });

  if (data && data.length === 0) {
    return <NoItemsFound title='No Rules Found' />;
  }

  return (
    <Container>
      <Table table={table} />
    </Container>
  );
};

export default DataValidationTable;
