import { PartialAddConnectorType } from '../../schemas';

export const buildMapperRequests = (
  addConnectorData: PartialAddConnectorType
) => {
  const mapper = {};
  const { models, sources, destinations } = addConnectorData;

  sources?.forEach((source) => {
    mapper[`source_${source.preconfiguredSourceName}`] = false;
  });

  models?.forEach((model) => {
    mapper[`model_${model.name}`] = false;
  });

  destinations?.forEach((destination) => {
    destination.chosenTables.forEach((model) => {
      mapper[`update_model_${model.modelName}`] = false;
      mapper[`destination_${model.modelName}`] = false;
    });
  });

  return mapper;
};
