import { PartialAddConnectorType } from '../../schemas';
import { BuildSourcePayloadReturn } from './model';

const fields = {
  username: 'SFTP_USER',
  port: 'SFTP_PORT',
  folder: 'SFTP_EXTERNAL_FOLDER_PATH',
  hostname: 'SFTP_HOST',
  password: 'SFTP_PASSWORD_SECRET_ID',
};

export const buildSourcePayload = (
  form: NonNullable<PartialAddConnectorType['sources']>[0]
): BuildSourcePayloadReturn[] => {
  const formFields = Object.keys(fields);

  return formFields.reduce((finalObject: BuildSourcePayloadReturn[], value) => {
    if (!form[value]) {
      return finalObject;
    }

    const fieldMapped = { value: form[value], name: fields[value] };

    finalObject.push(fieldMapped);

    return finalObject;
  }, []);
};
