import { useMediaQuery } from '@cherre-frontend/ui';

export const useResponsiveScheduleName = () => {
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const isMediumScreen = useMediaQuery('(max-width:900px)');
  const isLargeScreen = useMediaQuery('(max-width:1500px)');
  const isXLargeScreen = useMediaQuery('(max-width:2300px)');

  let scheduleTitleMaxWidth;
  if (isSmallScreen) {
    scheduleTitleMaxWidth = '130px';
  } else if (isMediumScreen) {
    scheduleTitleMaxWidth = '150px';
  } else if (isLargeScreen) {
    scheduleTitleMaxWidth = '160px';
  } else if (isXLargeScreen) {
    scheduleTitleMaxWidth = '240px';
  }

  return {
    scheduleTitleMaxWidth,
    isMediumScreen,
    isLargeScreen,
    isXLargeScreen,
  };
};
