import cronstrue from 'cronstrue';

export const getTranslatedCronFrequency = (cronExpression: string) => {
  try {
    const frequency = cronstrue.toString(cronExpression, { verbose: true });
    return frequency;
  } catch (error) {
    return 'N/A';
  }
};

export const getTranslatedCronFrequencyWithoutTime = (
  cronExpression: string
) => {
  const translatedFrequency = getTranslatedCronFrequency(cronExpression);
  if (translatedFrequency === 'N/A') {
    return translatedFrequency;
  }
  //Split the time off the translated frequency. For example, "At 07:45 AM, Monday through Friday" Becomes "Monday through Friday"
  const frequency = translatedFrequency.split(',')[1].trim();
  return frequency;
};
