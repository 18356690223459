import { styled, SearchInput } from '@cherre-frontend/ui';

const TableSearchInput = styled(SearchInput)`
  & .MuiInputBase-root {
    background-color: white;
  }
  & .input {
    height: 34px;
    border-radius: 6px;
  }
`;

export default TableSearchInput;
