import React, { useEffect } from 'react';
import { Container, styled } from '@cherre-frontend/ui';
import { ConnectorsPageContainer } from '../../components/ConnectorsPageContainer';
import * as Panels from './panels';
import { AddConnectorProvider, AddConnectorType } from './dataState';
import { ConnectorUIStateProvider } from './uiState/contexts';
import Skeleton from './AddConnector.skeleton';
import { useParams } from 'react-router';
import axios from 'axios';

const StyledContainer = styled(Container)`
  max-width: 1384px;
  padding-top: 20px;
  padding-bottom: 20px;
  gap: 20px;
  display: grid;
  grid-template-columns: 1fr;
`;

/**
 * `AddConnector` is a React functional component responsible for rendering the Add Connector page.
 * It fetches the initial state of a connector draft based on the `id` parameter from the URL.
 *
 * @component
 *
 * @returns {JSX.Element} The rendered Add Connector page.
 *
 * @example
 * ```tsx
 * <Route path="/add-connector/:id?" component={AddConnector} />
 * ```
 *
 * @remarks
 * - Uses `useParams` from `react-router-dom` to extract the `id` parameter from the URL.
 * - Fetches the draft data from the API if `id` is present.
 * - Displays a loading skeleton while the draft data is being fetched.
 * - Utilizes `ConnectorUIStateProvider` and `AddConnectorProvider` to manage the state and context of the connector.
 *
 * @hook
 * - `useEffect` to fetch the draft data when the `id` changes.
 *
 * @dependencies
 * - `axios` for making API requests.
 * - `ConnectorUIStateProvider`, `AddConnectorProvider`, `ConnectorsPageContainer`, `Panels`, and `StyledContainer` for UI and state management.
 */
const AddConnector: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  const [initialState, setInitialState] =
    React.useState<AddConnectorType | null>(null);

  useEffect(() => {
    if (id) {
      axios.get(`/api/v1/dom/draft/${id}`).then((response) => {
        setInitialState(response.data.draft);
      });
    }
  }, [id]);

  if (id && !initialState) {
    return <Skeleton />;
  }
  return (
    <ConnectorUIStateProvider>
      <AddConnectorProvider key={id} initialState={initialState || undefined}>
        <ConnectorsPageContainer>
          <Panels.Header />
          <StyledContainer maxWidth={false}>
            <Panels.WarningMessageAddConnector />
            <Panels.Steps />
            <Panels.StepContainer />
          </StyledContainer>
        </ConnectorsPageContainer>
      </AddConnectorProvider>
    </ConnectorUIStateProvider>
  );
};

export default AddConnector;
