import { styled } from '@cherre-frontend/ui';

export const SourceCarrousselContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow-x: auto;
  padding-top: 20px;
  gap: 10px;
`;
