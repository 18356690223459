import { useCherreValueLoadable } from '@cherre-frontend/data-fetching';
import {
  ConnectorEnvironments,
  useConnectorEnvironment,
} from 'src/hooks/useConnectorEnvironment';
import {
  openMetadataTableDataVolumeDemo,
  openMetadataTableLatestDataDemo,
  openMetadataTablesDemo,
  openMetadataThirtyDayAverageDemo,
} from 'src/services/ConnectorDemo/recoil';
import { selectedConnectorPublishedTables } from 'src/services/DOM/connectorValidationRules/recoil';
import {
  qualyticsObservabilityData,
  qualyticsTableDataVolume,
  qualyticsThirtyDayAverageData,
} from 'src/services/Qualytics/recoil';

export const useGetTableProfile = () => {
  const environment = useConnectorEnvironment();

  if (environment === ConnectorEnvironments.MVP) {
    const tables = useCherreValueLoadable(selectedConnectorPublishedTables);

    const tableDataVolume = useCherreValueLoadable(qualyticsTableDataVolume);
    const latestTableData = useCherreValueLoadable(qualyticsObservabilityData);
    const thirtyDayAverage = useCherreValueLoadable(
      qualyticsThirtyDayAverageData
    );

    return { tables, tableDataVolume, latestTableData, thirtyDayAverage };
  } else {
    const tables = useCherreValueLoadable(openMetadataTablesDemo);

    const tableDataVolume = useCherreValueLoadable(
      openMetadataTableDataVolumeDemo
    );
    const latestTableData = useCherreValueLoadable(
      openMetadataTableLatestDataDemo
    );
    const thirtyDayAverage = useCherreValueLoadable(
      openMetadataThirtyDayAverageDemo
    );

    return { tables, tableDataVolume, latestTableData, thirtyDayAverage };
  }
};
