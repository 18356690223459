import React, { useState } from 'react';

import { Box, Dialog, DialogContent, DialogTitle } from '@cherre-frontend/ui';
import { DialogContentText } from '@material-ui/core';
import { highlight } from 'sql-highlight';
import { Visibility } from '@material-ui/icons';
import styled from '@mui/system/styled';

const SQLCode = styled('code')`
  background-color: #f5f5f5;
  border: 1px solid #9e9e9e;
  color: #2d2d2d;
  border-radius: 6px;
  display: block;
  padding: 10px;
  margin-top: 10px;

  .sql-hl-keyword {
    color: #d73a49;
  }
  .sql-hl-function {
    color: #005cc5;
  }
  .sql-hl-number {
    color: #6f42c1;
  }
  .sql-hl-string {
    color: #032f62;
  }
  .sql-hl-special {
    color: #e36209;
  }
  .sql-hl-bracket {
    color: #22863a;
  }
`;

interface DataValidationSQLDialogProps {
  sql: string;
}

export const DataValidationSQLDialog = ({
  sql,
}: DataValidationSQLDialogProps) => {
  const [open, setOpen] = useState(false);

  const highlighted = highlight(sql, { html: true });

  return (
    <div>
      <Box
        sx={{
          cursor: 'pointer',
          color: 'grey.400',
          ':hover': {
            color: 'primary.main',
          },
        }}
      >
        <Visibility onClick={() => setOpen(true)} />
      </Box>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='active-rule-dialog-title'
        aria-describedby='active-rule-dialog-description'
        sx={{
          '& .MuiPaper-root': { maxWidth: '640px', padding: '20px' },
          '& .MuiDialogContent-root': { paddingBottom: 0 },
          '& .MuiDialogContentText-root': {
            fontSize: '14px',
            color: 'black',
            marginBottom: 0,
          },
        }}
      >
        <DialogTitle
          id='active-rule-dialog-title'
          sx={{
            fontSize: '20px',
            fontWeight: '700',
            padding: 0,
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          Derived Table Query
        </DialogTitle>
        <DialogContent sx={{ padding: 0 }}>
          <DialogContentText id='active-rule-dialog-description'>
            View the SQL query used to create this derived table.
            <SQLCode dangerouslySetInnerHTML={{ __html: highlighted }} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};
