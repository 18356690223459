import React, { useEffect } from 'react';
import { RelayEnvironmentProvider, useMutation } from 'react-relay';
import { runScheduledMutation } from 'src/services/DataValidation/__generated__/runScheduledMutation.graphql';
import { RunScheduledMutation } from 'src/services/DataValidation/runScheduledMutation';
import { useSelector } from 'react-redux';
import { connectorDataValidationTabParam } from '../../../../../../../../recoil';
import { useCherreState } from '@cherre-frontend/data-fetching';
import { useDspRelayEnvironment } from '../../../../hooks/useDspRelayEnvironment';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@cherre-frontend/ui';
import { DialogContentText } from '@material-ui/core';
import {
  DataValidationAlert,
  DataValidationAlertType,
} from '../DataValidationAlert';

interface DataValidationRunNowContentProps {
  onOpenChange(open: boolean): void;
  open: boolean;
}

const DataValidationRunNowContent = ({
  onOpenChange,
  open,
}: DataValidationRunNowContentProps) => {
  const [error, setError] = React.useState<string | null>(null);

  const handleOpenChange = (open: boolean) => {
    setError(null);
    onOpenChange(open);
  };

  const owner = useSelector((state) => state.user.profile.value.domOwner);

  const [commit] = useMutation<runScheduledMutation>(RunScheduledMutation);

  const [dataValidationV2Content, setDataValidationV2Content] = useCherreState(
    connectorDataValidationTabParam
  );

  useEffect(() => {
    if (open && owner) {
      commit({
        variables: { owner },
        onCompleted() {
          if (dataValidationV2Content) {
            handleOpenChange(false);
            setDataValidationV2Content({
              ...dataValidationV2Content,
              detailsTab: 'results',
            });
          }
        },
        onError(error) {
          setError(error.message);
        },
      });
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={() => handleOpenChange(false)}
      aria-labelledby='active-rule-dialog-title'
      aria-describedby='active-rule-dialog-description'
      sx={{
        '& .MuiPaper-root': { maxWidth: '640px', padding: '20px' },
        '& .MuiDialogContent-root': { paddingBottom: 0 },
        '& .MuiDialogContentText-root': {
          fontSize: '14px',
          color: 'black',
          marginBottom: 0,
        },
      }}
    >
      <DialogTitle
        id='active-rule-dialog-title'
        sx={{
          fontSize: '20px',
          fontWeight: '700',
          padding: 0,
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        <CircularProgress sx={{ color: 'primary' }} size={20} /> Running Rule
      </DialogTitle>

      <DialogContent sx={{ padding: 0 }}>
        <DialogContentText id='active-rule-dialog-description'>
          Your selected validation rule is now running. This may take a few
          minutes or longer depending on the size of the table being scanned.
          You’ll receive a notification once the run is complete—please check
          the validation rules table for the results.
        </DialogContentText>

        {error && (
          <DataValidationAlert
            message='Something went wrong!'
            type={DataValidationAlertType.ERROR}
          />
        )}
      </DialogContent>
      <DialogActions sx={{ padding: 0 }}>
        <Button
          onClick={() => handleOpenChange(false)}
          variant='contained'
          sx={{ minWidth: '80px', marginTop: '10px' }}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const DataValidationRunNowDialog = (
  props: DataValidationRunNowContentProps
) => {
  const environment = useDspRelayEnvironment();

  if (!environment) {
    return null;
  }

  return (
    <RelayEnvironmentProvider environment={environment}>
      <DataValidationRunNowContent {...props} />
    </RelayEnvironmentProvider>
  );
};
