import { Connector } from 'src/services/DOM/types/connectorTypes';

export const deduplicateDestinationsAcrossModels = (
  connectors: Connector[]
) => {
  return connectors.map((connector) => {
    //The same destination should not show across multiple models.
    //If modelA and modelB both have a destination of snowflake, then only one destination should appear across models
    const assignedDestinations = new Set<string>();

    const updatedModels = connector.models.map((model) => {
      // Filter destinations to only include those that haven't been assigned yet
      const filteredDestinations = model.destinations.filter((destination) => {
        if (assignedDestinations.has(destination.provider)) {
          return false; // Skip if the destination has already been assigned to another model
        }
        assignedDestinations.add(destination.provider);
        return true;
      });

      return {
        ...model,
        destinations: filteredDestinations,
      };
    });

    return {
      ...connector,
      models: updatedModels,
    };
  });
};
