import {
  BuildCheckboxInitialOptionsArgs,
  BuildCheckboxInitialOptionsReturn,
} from './model';

export const buildCheckboxInitialOptions = ({
  selectedAll = true,
  options,
}: BuildCheckboxInitialOptionsArgs): BuildCheckboxInitialOptionsReturn => {
  const checkboxForm = options.reduce((finalResult, value) => {
    finalResult[value] = selectedAll;
    return finalResult;
  }, {} as BuildCheckboxInitialOptionsReturn['checkboxForm']);

  return { checkboxForm, selectedAll };
};
