import React from 'react';
import {
  Typography,
  useTheme,
  Paper,
  UpgradePlanIcon,
} from '@cherre-frontend/ui';

type UpgradePlanCardTypes = {
  text: string;
};

const UpgradePlanCard: React.FC<UpgradePlanCardTypes> = ({ text }) => {
  const theme = useTheme();
  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.common.white,
        borderRadius: '10px',
      }}
    >
      <div
        style={{
          padding: '14.5px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <UpgradePlanIcon />
        <Typography
          sx={{
            marginLeft: '6px',
            color: theme.palette.grey[50],
            fontSize: '12px',
          }}
        >
          {text}
        </Typography>
      </div>
    </Paper>
  );
};

export default UpgradePlanCard;
