import React from 'react';
import { Box } from '@cherre-frontend/ui';

const DownloadTextUpgradeOverlay = () => {
  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.3)',
          backdropFilter: 'blur(2px)',
          zIndex: 1300,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '8px',
          boxSizing: 'border-box',
          textAlign: 'center',
        }}
      />
    </>
  );
};

export default DownloadTextUpgradeOverlay;
