import React from 'react';
import { Box } from '@cherre-frontend/ui';
import { onAvatarLoadFailed } from 'src/products/connector/utils/onAvatarLoadFailed';

type LogoProps = {
  provider: string | null | undefined;
};

const ConnectorCardLogo: React.FC<LogoProps> = ({ provider }) => {
  return (
    <Box style={{ paddingTop: '8px' }}>
      <img
        width='35px'
        height='35px'
        src={`/assets/connector-icons/rounded-logos/${provider}-rounded.svg`}
        onError={(e) => onAvatarLoadFailed(e)}
        alt='component'
      />
    </Box>
  );
};
ConnectorCardLogo.displayName = 'ConnectorCardLogo';

export default ConnectorCardLogo;
