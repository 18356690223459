import { ModelCheckboxTables } from '../../CheckboxTable/model';

export const buildChosenTables = (form?: ModelCheckboxTables): string[] => {
  if (!form) {
    return [];
  }
  const tables = Object.keys(form);

  return tables.filter((table) => form[table]);
};
