import { graphql } from 'relay-runtime';

export const DataValidationRulesQuery = graphql`
  query dataValidationRulesQuery(
    $owner: String!
    $connectorComponentKind: String!
    $connectorComponentName: String!
    $connectorComponentVersion: String!
  ) {
    ruleLibraryItems(
      owner: $owner
      connectorComponentRef: {
        kind: $connectorComponentKind
        name: $connectorComponentName
        version: $connectorComponentVersion
      }
    ) {
      name
      version
      provider
      displayName
      description
      tableRef
      categories
      active
      status
      rliType
      rliGeneralProperties {
        parameterName
        defaultValue
        defaultValues
        externalObjectType
        order
        uiReadOnly
        visibility
      }
      rliSpecificProperties {
        parameterName
        defaultValue
        defaultValues
        externalObjectType
        order
        uiReadOnly
        visibility
      }
      rule {
        name
        connectorComponentRef {
          kind
          name
          version
        }
        ruleGeneralProperties {
          propertyName
          propertyValue
          propertyValues
        }
        ruleSpecificProperties {
          propertyName
          propertyValue
          propertyValues
        }
        notificationProperties {
          propertyName
          propertyValue
          propertyValues
        }
        checkStatus
        checkStatusMessage
        checkId
        datastoreId
        containerId
      }
    }
  }
`.default;
