import React from 'react';
import { Grid } from '@cherre-frontend/ui';
import { useCherreState } from '@cherre-frontend/data-fetching';
import { connectorDetailsDataValidationState } from '../../recoil';
import DefaultSelect from './DefaultSelect';
import { DataValidationState } from './utils/types';
import DefaultTextInput from './DefaultTextInput';
import {
  columnRuleTypeSelectOptions,
  tableRuleTypeSelectOptions,
} from './utils/const';
import { ColumnItem } from '../../../../../../services/OpenMetadata/types';

type Props = {
  children?: React.ReactNode;
  menuOptions: ColumnItem[];
};

const DefaultFormInputs: React.FC<Props> = ({ children, menuOptions }) => {
  const [dataValidationState] = useCherreState(
    connectorDetailsDataValidationState
  );

  return (
    <>
      {dataValidationState === DataValidationState.COLUMN && (
        <DefaultSelect
          title='Column'
          fieldName='column'
          menuOptions={
            menuOptions
              ? menuOptions.map((option) => {
                  return { label: option.name, value: option.name };
                })
              : []
          }
        />
      )}
      <Grid
        container
        direction='row'
        spacing={3}
        sx={{
          '& .MuiGrid-item': {
            paddingRight: '0px',
          },
        }}
      >
        <Grid item xs={6}>
          <DefaultTextInput
            title='Name'
            fieldName='name'
            placeholder='Enter rule name'
            required
          />
        </Grid>
        <Grid item xs={6}>
          <DefaultSelect
            title='Rule Type'
            fieldName='rule_type'
            menuOptions={
              dataValidationState === DataValidationState.COLUMN
                ? columnRuleTypeSelectOptions
                : tableRuleTypeSelectOptions
            }
            clearFormOnChange
          />
        </Grid>
      </Grid>
      {children}
      <DefaultTextInput
        title='Description'
        fieldName='description'
        placeholder='Write your description'
        multiline
        rows={4}
        sx={{
          '& .MuiInputBase-root': {
            height: 'unset',
          },
        }}
      />
    </>
  );
};

export default DefaultFormInputs;
