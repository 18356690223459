import {
  BuildCheckboxOptionFromStateArgs,
  BuildCheckboxOptionFromStateReturn,
} from './model';

export const buildCheckboxOptionFromState = ({
  connectorDataDestinations,
  selectedDestination,
  options,
  model,
}: BuildCheckboxOptionFromStateArgs): BuildCheckboxOptionFromStateReturn => {
  const findDestination = connectorDataDestinations
    .find(
      (destination) =>
        destination.destinationName === selectedDestination.destinationName
    )
    ?.chosenTables.find((destination) => destination.modelName === model.name);

  const checkboxForm = options.reduce((finalResult, value) => {
    if (!findDestination) {
      finalResult[value] = true;
      return finalResult;
    }

    finalResult[value] = Boolean(findDestination.tables.includes(value));
    return finalResult;
  }, {} as BuildCheckboxOptionFromStateReturn['checkboxForm']);

  return {
    checkboxForm,
    selectedAll: findDestination ? Boolean(findDestination.selectedAll) : true,
  };
};
