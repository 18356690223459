export const SOURCES = [
  {
    displayName: 'Voyager Commercial (ETL)',
    sourceName: 'yardi-etl',
    provider: 'yardi',
    image: '/assets/connector-icons/regular-logos/yardi.svg',
    roundedImage: '/assets/connector-icons/rounded-logos/yardi-rounded.svg',
    sourceType: 'SFTP' as const,
  },
];

export const DESTINATIONS = [
  {
    displayName: 'Snowflake',
    destinationName: 'snowflake',
    image: '/assets/connector-icons/regular-logos/snowflake.svg',
    roundedImage: '/assets/connector-icons/rounded-logos/snowflake-rounded.svg',
  },
];
