import React from 'react';
import { styled } from '@cherre-frontend/ui';
import SourceSelector from './SourceCarrousel';
import { SourceUIStateProvider } from '../../uiState/contexts';
import SourceForm from './SourceForm';

const Container = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
`;

const Source: React.FC = () => {
  return (
    <SourceUIStateProvider>
      <Container>
        <SourceSelector />
        <SourceForm />
      </Container>
    </SourceUIStateProvider>
  );
};

export default Source;
