import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { styled } from '@cherre-frontend/ui';

export const ValidationDetailsTabs = styled(Tabs)`
  align-self: flex-start;
  .MuiTabs-indicator {
    border-radius: 0;
  }
`;

export const ValidationDetailsTab = styled(Tab)`
  min-width: unset;
  color: ${({ theme }) => theme.palette.grey[400]};
`;
