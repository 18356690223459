import React, { useMemo } from 'react';
import { styled, Switch, Box } from '@cherre-frontend/ui';
import { useCherreState } from '@cherre-frontend/data-fetching';
import { Typography } from '@material-ui/core';
import { dataValidationRuleForm } from '../../recoil';

const StyledSwitch = styled(Switch)`
  width: 40px;
  height: 18px;
  padding: 0px;
  margin-right: 10px;
  & .MuiSwitch-switchBase {
    padding: 4px;
  }
  & .MuiSwitch-thumb {
    box-shadow: 0 2px 4px 0 rgb(0 35 11 / 20%);
    width: 10px;
    height: 10px;
    border-radius: 6px;
  }
  & .MuiSwitch-track {
    border-radius: 20px;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
  }
`;

type Props = {
  title: string;
  fieldName: string;
};

const FormSwitch: React.FC<Props> = ({ title, fieldName }) => {
  const [form, setForm] = useCherreState(dataValidationRuleForm);

  const value = useMemo(() => {
    if (form) {
      return form[form.rule_type][fieldName];
    } else {
      return false;
    }
  }, [form]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
      <StyledSwitch
        color='primary'
        checked={value}
        onChange={(e) => {
          if (form) {
            setForm((oldState) => {
              return {
                ...oldState,
                [form.rule_type]: {
                  ...oldState[form.rule_type],
                  [fieldName]: e.target.checked,
                },
              };
            });
          }
        }}
      />
      <Typography style={{ fontWeight: '700' }}>{title}</Typography>
    </Box>
  );
};

export default FormSwitch;
