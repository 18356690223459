import React from 'react';
import { Box } from '@cherre-frontend/ui';

type Props = {
  children?: React.ReactNode;
};

const LayoutWithoutSideNav: React.FC<Props> = ({ children }) => {
  return (
    <Box width='100%' height='100%'>
      {children}
    </Box>
  );
};

export default LayoutWithoutSideNav;
