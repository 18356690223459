/**
 * @generated SignedSource<<4f3b8880ce04dbd909faf853285e55f3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type getValidationCustomTableQuery$variables = {
  owner?: string | null;
  tableName: string;
};
export type getValidationCustomTableQuery$data = {
  readonly validationCustomTable: {
    readonly dialect: string;
    readonly displayName: string;
    readonly name: string;
    readonly ready: boolean | null;
    readonly sqlInput: string | null;
    readonly sqlParsed: string | null;
  } | null;
};
export type getValidationCustomTableQuery = {
  response: getValidationCustomTableQuery$data;
  variables: getValidationCustomTableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "owner"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tableName"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "owner",
        "variableName": "owner"
      },
      {
        "kind": "Variable",
        "name": "tableName",
        "variableName": "tableName"
      }
    ],
    "concreteType": "ValidationCustomTable",
    "kind": "LinkedField",
    "name": "validationCustomTable",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "displayName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "sqlInput",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "sqlParsed",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dialect",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ready",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getValidationCustomTableQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getValidationCustomTableQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "cadd4ad326512614b0bdc737a8aee4cf",
    "id": null,
    "metadata": {},
    "name": "getValidationCustomTableQuery",
    "operationKind": "query",
    "text": "query getValidationCustomTableQuery(\n  $owner: String\n  $tableName: String!\n) {\n  validationCustomTable(owner: $owner, tableName: $tableName) {\n    name\n    displayName\n    sqlInput\n    sqlParsed\n    dialect\n    ready\n  }\n}\n"
  }
};
})();

(node as any).hash = "476adb6b79c793ba30658b8dad0ed143";

export default node;
