import React, { useMemo } from 'react';
import {
  useCherreSetState,
  useCherreState,
} from '@cherre-frontend/data-fetching';
import {
  Table,
  styled,
  tableBaseStyle,
  useTable,
  useTheme,
  Box,
  Button,
  ArrowForwardIcon,
  Typography,
  Tooltip,
} from '@cherre-frontend/ui';
import { NoItemsFound } from 'src/products/data-submission-portal/components/NoBatchesFound';
import {
  connectorDetailsTabState,
  connectorInventoryTableState,
  ConnectorStatus,
} from '../../../recoil';
import { useHistory } from 'react-router';
import TableStatusChip from 'src/products/connector/components/ConnectorStatusChip';
import { ConnectorDetailsTabValues } from '../../connector-details';
import { StandardizedConnector } from 'src/products/connector/types/connectorTypes';
import { onAvatarLoadFailed } from 'src/products/connector/utils/onAvatarLoadFailed';
import RunningComponentIndicator from 'src/products/connector/components/RunningComponentIndicator';

const Container = styled('div')`
  padding-top: 20px;
  display: flex;
  flex: 1;
  max-height: 80%;
`;

type Props = {
  data: StandardizedConnector[];
};

const ConnectorInventoryTable: React.FC<Props> = ({ data }) => {
  const theme = useTheme();
  const [tableState, setTableState] = useCherreState(
    connectorInventoryTableState
  );
  const setTab = useCherreSetState(connectorDetailsTabState);
  const history = useHistory();

  const columns = useMemo(
    () => [
      {
        accessorKey: 'displayName',
        header: 'Name',
        size: 90,
      },
      {
        accessorKey: 'sources',
        header: 'Sources',
        size: 190,
        sortingFn: (rowA, rowB) => {
          if (
            rowA.original.sources[0].name.localeCompare(
              rowB.original.sources[0].name
            ) === 0
          ) {
            return rowB.original.sources.length > rowA.original.sources.length
              ? -1
              : rowB.original.sources.length < rowA.original.sources.length
              ? 1
              : 0;
          }
          return rowA.original.sources[0].name.localeCompare(
            rowB.original.sources[0].name
          );
        },
        Cell: ({ row }) => {
          return (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {row.original.sources.map((source, index) => (
                <Tooltip
                  title={source.displayName ?? source.name}
                  enterDelay={200}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        maxWidth: 'none',
                      },
                    },
                  }}
                >
                  <img
                    src={source.logo}
                    style={{
                      height: '40px',
                      width: '40px',
                      marginLeft: index === 0 ? '0' : '-15px',
                    }}
                    onError={(e) => onAvatarLoadFailed(e)}
                  />
                </Tooltip>
              ))}
            </Box>
          );
        },
      },
      {
        accessorKey: 'destinations',
        header: 'Destination',
        size: 190,
        sortingFn: (rowA, rowB) => {
          const nameA = rowA.original.models[0]?.destinations[0]?.name || '';
          const nameB = rowB.original.models[0]?.destinations[0]?.name || '';

          const nameComparison = nameA.localeCompare(nameB);

          if (nameComparison === 0) {
            const lengthA = rowA.original.models[0]?.destinations.length || 0;
            const lengthB = rowB.original.models[0]?.destinations.length || 0;

            return lengthB - lengthA;
          }

          return nameComparison;
        },
        Cell: ({ row }) => {
          return (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {row.original.models
                .flatMap((model) => model.destinations)
                .map((destination, index) => {
                  if (!destination) {
                    return null;
                  }

                  return (
                    <Tooltip
                      title={destination.displayName ?? destination.name}
                      enterDelay={200}
                      componentsProps={{
                        tooltip: {
                          sx: {
                            maxWidth: 'none',
                          },
                        },
                      }}
                    >
                      <img
                        key={index}
                        src={destination.logo}
                        style={{
                          height: '40px',
                          width: '40px',
                          marginLeft: index === 0 ? '0' : '-15px',
                        }}
                        onError={(e) => onAvatarLoadFailed(e)}
                      />
                    </Tooltip>
                  );
                })}
            </Box>
          );
        },
      },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 170,
        Cell: ({ row }) => {
          if (
            row.original.status === ConnectorStatus.DRAFT ||
            row.original.status === ConnectorStatus.LOADING ||
            row.original.status === ConnectorStatus.FAILED
          ) {
            return (
              <Box>
                <TableStatusChip
                  status={row.original.status}
                  statusMessage={row.original.statusMessage}
                />
              </Box>
            );
          }

          return (
            <Box>
              <TableStatusChip
                status={row.original.status}
                statusMessage={row.original.statusMessage}
              />
              {row.original.connectorRunningObject.isRunning ? (
                <Box display='flex' justifyContent='flex-start'>
                  <RunningComponentIndicator
                    message={row.original.connectorRunningObject.runningMessage}
                  />
                </Box>
              ) : (
                <Typography
                  sx={{
                    color: theme.palette.grey[500],
                    fontSize: '10px',
                    fontWeight: '400',
                    paddingLeft: '4px',
                  }}
                >
                  {row.original.status === ConnectorStatus.SUCCESS
                    ? `Next Run: ${row.original.nextRun}`
                    : `Last Run: ${row.original.lastRun}`}
                </Typography>
              )}
            </Box>
          );
        },
      },
    ],
    []
  );

  const table = useTable({
    data: data,
    skeletonRowCount: 5,
    columns,
    enableSelectAll: false,
    enableRowActions: true,
    enableRowSelection: false,
    enableBottomToolbar: true,
    enablePagination: true,
    positionActionsColumn: 'last',
    layoutMode: 'grid',
    muiTablePaperProps: {
      sx: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        flex: 1,
        borderRadius: '16px',
      },
      elevation: 0,
    },
    muiTableContainerProps: {
      sx: {
        flex: 1,
        overflow: 'hidden',
        '& tbody': {
          overflowX: 'hidden',
          overflowY: 'scroll',
          maxHeight: '500px',
        },
      },
    },
    renderRowActions: ({ row }) => (
      <Button
        onClick={() => {
          setTab(ConnectorDetailsTabValues.OVERVIEW);
          const pathToPush =
            row.original.status === ConnectorStatus.DRAFT
              ? `/connectors/add/${row.original.id}`
              : `/connectors/${row.original.name}`;
          history.push(pathToPush);
        }}
        disabled={row.original.disabled}
        sx={{ color: theme.palette.primary.main }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <Typography>View</Typography>
          <ArrowForwardIcon style={{ height: '20px' }} />
        </Box>
      </Button>
    ),
    state: {
      sorting: tableState?.sorting,
      pagination: tableState?.pagination,
    },
    manualSorting: false,
    onSortingChange: (sort) =>
      setTableState((s) => ({
        ...s,
        sorting: typeof sort === 'function' ? sort(s.sorting) : sort,
      })),
    onPaginationChange: (p) =>
      setTableState((s) => ({
        ...s,
        pagination: typeof p === 'function' ? p(s.pagination) : p,
      })),
    getRowId: (row) => row.name,
    enableMultiSort: false,
    enableStickyHeader: true,
    muiTableHeadRowProps: {
      sx: {
        ...tableBaseStyle.muiTableHeadRowProps.sx,
        backgroundColor: theme.palette.secondary.main,
        '& th': {
          color: 'white',
          height: '50px',
          borderBottom: 'none',
          '& .Mui-TableHeadCell-Content': {
            alignItems: 'center',
            '& .MuiTableSortLabel-icon': {
              color: 'white !important',
            },
          },
        },
        '& th:last-child': {
          width: '50px',
        },
      },
    },

    muiTableBodyRowProps: ({ row }) => ({
      sx: {
        '&.MuiTableRow-root': {
          '& td': {
            backgroundColor: row.original.connectorRunningObject.isRunning
              ? theme.palette.warning.light
              : theme.palette.common.white,
            height: '80px',
          },
        },
        '& td': {
          height: '80px',
        },
        '& td:last-child': {
          width: '50px',
          display: 'flex',
          justifyContent: 'flex-end',
        },
      },
    }),
  });

  if (data && data.length === 0) {
    return <NoItemsFound title='No Connectors Found' />;
  }

  return (
    <Container>
      <Table table={table} />
    </Container>
  );
};

export default ConnectorInventoryTable;
