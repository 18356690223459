import { Connector } from 'src/services/DOM/types/connectorTypes';

export const getComponentRunningMessage = (
  connector: Connector
): string | null => {
  const isConnectorRunning = connector.statusObject?.isRunning;

  const runningSources = connector.sources.find(
    (source) => source.statusObject?.isRunning
  );

  const runningModels = connector.models.find(
    (source) => source.statusObject?.isRunning
  );

  if (!isConnectorRunning) {
    return null;
  }
  //Show sources over models if both are running
  if (runningSources && runningModels) {
    return 'Source Running';
  }
  if (runningSources) {
    return 'Source Running';
  }
  if (runningModels) {
    return 'Model Running';
  }
  return null;
};
