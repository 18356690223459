import { Skeleton, styled } from '@cherre-frontend/ui';

export const SourceCarrouselSkeletonsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  gap: 20px;
`;

export const SourceCardContainer = styled('div')`
  display: flex;
  gap: 20px;
`;

export const StyledSkeleton = styled(Skeleton)`
  border-radius: 5px;
`;
