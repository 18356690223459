import { graphql } from 'relay-runtime';

export const ValidationContainerFieldsQuery = graphql`
  query validationContainerFieldsQuery($containerId: Int!) {
    validationContainerFields(containerId: $containerId) {
      ID: id
      name
      type
      nativeType
    }
  }
`.default;
