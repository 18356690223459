import React from 'react';
import { useTheme, Chip } from '@cherre-frontend/ui';

export enum RuleStatusChipType {
  SUCCESS = 'Success',
  FAILED = 'Failed',
  ABORTED = 'Aborted',
  SCHEDULED = 'Scheduled',
}

type RuleStatusChip = {
  status: RuleStatusChipType;
};
const RuleStatusChip: React.FC<RuleStatusChip> = ({ status }) => {
  const theme = useTheme();
  const determineStatusColor = (status: RuleStatusChipType) => {
    switch (status) {
      case RuleStatusChipType.SUCCESS:
        return theme.palette.success.dark;
      case RuleStatusChipType.FAILED:
        return theme.palette.error.main;
      case RuleStatusChipType.ABORTED:
        return theme.palette.warning.main;
      case RuleStatusChipType.SCHEDULED:
        return theme.palette.info.main;
      default:
        return theme.palette.grey[700];
    }
  };
  return (
    <Chip
      label={status}
      sx={{
        height: '21px',
        width: 'fitContent',
        fontSize: '12px',
        fontWeight: '500',
        backgroundColor: determineStatusColor(status),
        color: theme.palette.common.white,
      }}
    />
  );
};

export default RuleStatusChip;
