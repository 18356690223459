import React, { useEffect } from 'react';
import {
  Box,
  CheckIcon,
  DownloadFileIcon,
  Grid,
  Typography,
  UpdateIcon,
  useTheme,
} from '@cherre-frontend/ui';
import {
  useCherreState,
  useCherreSetState,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import { connectorDetailsSelectedTable } from '../../recoil';
import { ValidationDetailsTabs, ValidationDetailsTab } from './styles';
import LayoutWithoutSideNav from '../../components/LayoutWithoutSideNav';
import {
  dataValidationRuleForm,
  dataValidationTestsTrigger,
} from '../connector-details/recoil';
import {
  DataValidationState,
  RuleTypes,
} from '../connector-details/components/DataValidation/utils/types';
import {
  validationDetailsDataValidationState,
  validationDetailsTabState,
} from './recoil';
import EditValidationRuleForm from '../connector-details/components/DataValidation/EditValidationRuleForm';
import RuleStatusChip, {
  RuleStatusChipType,
} from '../../components/RuleStatusChip';
import ObservabilityCard from '../../components/ObservabilityCard';
import { getTableAndColumnName } from './utils/const';
import DataValidationTestDetailsTable from './components/DataValidationRuleDetailsTable';
import DownloadTextUpgradeOverlay from './components/DownloadTextUpgradeOverlay';
import { ValidationTestDetailsRoute } from '../../routes';
import axios from 'axios';
import { useSnackbarCherre } from 'src/products/core-prospect/hooks';
import { VariantType } from 'notistack';
import { useHistory } from 'react-router';
import DataValidationTestResultsLoader from './components/DataValidationTestResultsLoader';
import { useGetDataValidationRuleDetails } from './hooks/useGetDataValidationRuleDetails';
import RuleResultsChart from './components/RuleResultsChart';
import {
  ConnectorEnvironments,
  useConnectorEnvironment,
} from 'src/hooks/useConnectorEnvironment';
import { ConnectorsPageContainer } from '../../components/ConnectorsPageContainer';

export enum ValidationDetailsTabValues {
  VALIDATION_DETAILS = 'Validation Rule Details',
  VALIDATION_RESULTS = 'Validation Rule Results',
}

const ValidationDetailsPage: React.FC = () => {
  const { ruleDetails } = useGetDataValidationRuleDetails();

  const setDataValidationState = useCherreSetState(
    validationDetailsDataValidationState
  );

  const setValidationTestsId = useCherreSetState(dataValidationTestsTrigger);

  const setSelectedTable = useCherreSetState(connectorDetailsSelectedTable);

  const setForm = useCherreSetState(dataValidationRuleForm);

  const [tab, setTab] = useCherreState(validationDetailsTabState);

  const theme = useTheme();

  const route = useCherreValue(ValidationTestDetailsRoute.routeParamSelector);

  const history = useHistory();

  const { enqueueSnackbar } = useSnackbarCherre();

  const environment = useConnectorEnvironment();

  const needsUpgrade = environment === ConnectorEnvironments.DEMO;

  useEffect(() => {
    if (ruleDetails.state === 'hasValue') {
      setDataValidationState(
        ruleDetails.contents?.entityLink.includes('::columns::')
          ? DataValidationState.COLUMN
          : DataValidationState.TABLE
      );
      const { column, table } = getTableAndColumnName(
        ruleDetails.contents?.entityLink
      );
      if (table) {
        setSelectedTable(table);
      }
      let rule_type =
        ruleDetails.contents?.testDefinition.name
          .split(/(?=[A-Z])/)
          .map((string) => string.toLowerCase())
          .join('_') ?? '';
      if (rule_type === 'table_custom_s_q_l_query') {
        rule_type = RuleTypes.TABLE_CUSTOM_SQL_QUERY;
      }
      if (
        rule_type &&
        rule_type !== RuleTypes.COLUMN_VALUES_TO_BE_NOT_NULL &&
        rule_type !== RuleTypes.COLUMN_VALUES_TO_BE_UNIQUE
      ) {
        const mappedParameters = ruleDetails.contents?.parameterValues.map(
          (parameter) => {
            const values = Object.values(parameter) as any[];
            return { [values[0]]: values[1] };
          }
        );
        if (mappedParameters) {
          const formValues = Object.assign({}, ...mappedParameters);
          setForm((prevState) => ({
            ...prevState,
            name: ruleDetails.contents?.ruleName ?? '',
            column,
            rule_type: rule_type,
            description: ruleDetails.contents?.testDefinition.description ?? '',
            [rule_type]: formValues,
          }));
        }
      } else if (rule_type) {
        setForm((prevState) => ({
          ...prevState,
          column,
          name: ruleDetails.contents?.ruleName ?? '',
          rule_type: rule_type,
          description: ruleDetails.contents?.testDefinition.description ?? '',
        }));
      }
    }
  }, [ruleDetails.state]);

  const handleRuleDelete = async () => {
    let message = '';
    let variant: VariantType = 'success';
    try {
      await axios.delete(
        `/api/v1/openmetadata/dataQuality/testCases/${ruleDetails.contents.id}`,
        {
          params: {
            sakuraConnectorRuleId: ruleDetails.contents.sakuraConnectorRuleId,
          },
        }
      );
      message = 'Successfully deleted rule.';

      setValidationTestsId(ruleDetails.contents.id ?? '');
      history.push(`/connectors/${route?.connector_name}`);
    } catch (error) {
      message = 'An error occurred while deleting rule. Please try again.';
      variant = 'error';
    }
    enqueueSnackbar(message, {
      variant: variant,
    });
  };

  const handleDownloadCSV = async () => {
    try {
      const response = await axios.get(
        `/api/v1/qualytics/downloadSourceRecordCSV/${ruleDetails.contents?.anomalyID}`
      );
      const blob = new Blob([response.data], {
        type: response.headers['content-type'],
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', 'check_failures.csv');
      link.click();
    } catch (error) {
      console.error(error);
      enqueueSnackbar('An error occurred while downloading the CSV file.', {
        variant: 'error',
      });
    }
  };

  if (ruleDetails.state === 'loading' || ruleDetails.contents === null) {
    return (
      <>
        <DataValidationTestResultsLoader />
      </>
    );
  }
  if (ruleDetails.state === 'hasError') {
    return (
      <>
        <h1>Error</h1>
      </>
    );
  }

  const hasStatusFailed = ruleDetails.contents?.status === 'Failed';
  const hasStatusSuccess = ruleDetails.contents?.status === 'Success';
  const haveFailedRowHistory =
    ruleDetails.contents?.failedRowCountHistory.length > 0;
  const haveToRenderDetailsTable =
    hasStatusFailed &&
    haveFailedRowHistory &&
    ruleDetails.contents?.failedSourceRecords.records;
  const haveToRenderTableEmptyState = hasStatusSuccess;

  return (
    <ConnectorsPageContainer>
      <Box sx={{ height: '100%', width: '100%' }}>
        <Box sx={{ paddingTop: '20px' }}>
          <Box sx={{ paddingLeft: '32px' }}>
            <Typography variant='h4'>
              {ruleDetails.contents?.ruleName}
            </Typography>
            <Grid container direction='column' sx={{ marginTop: '16px' }}>
              <ValidationDetailsTabs
                indicatorColor='primary'
                textColor='primary'
                variant='standard'
                value={tab}
                onChange={(e, newValue) => {
                  setTab(newValue);
                }}
              >
                <ValidationDetailsTab
                  value={ValidationDetailsTabValues.VALIDATION_DETAILS}
                  label={ValidationDetailsTabValues.VALIDATION_DETAILS}
                  style={{ minWidth: 'unset' }}
                  disabled={environment === ConnectorEnvironments.MVP}
                />
                <ValidationDetailsTab
                  value={ValidationDetailsTabValues.VALIDATION_RESULTS}
                  label={ValidationDetailsTabValues.VALIDATION_RESULTS}
                  style={{ minWidth: 'unset' }}
                />
              </ValidationDetailsTabs>
            </Grid>
          </Box>
          <Box sx={{ paddingX: '16px' }}>
            <Box sx={{ marginTop: '-32px' }}>
              {tab === ValidationDetailsTabValues.VALIDATION_DETAILS && (
                <LayoutWithoutSideNav>
                  <EditValidationRuleForm
                    handleDelete={handleRuleDelete}
                    canEdit={ruleDetails.contents?.canEdit ?? false}
                  />
                </LayoutWithoutSideNav>
              )}
            </Box>
            <Box sx={{ marginTop: '50px', paddingX: '16px' }}>
              {tab === ValidationDetailsTabValues.VALIDATION_RESULTS && (
                <LayoutWithoutSideNav>
                  <Grid container direction='column'>
                    <Grid container direction='row'>
                      <Grid item xs={6}>
                        <Grid container direction='column'>
                          <Grid container direction='row' gap={2}>
                            <Grid item>
                              <Typography fontSize='14px' fontWeight='bold'>
                                Status:
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography>
                                {ruleDetails.contents.status ? (
                                  <RuleStatusChip
                                    status={
                                      ruleDetails.contents
                                        ?.status as RuleStatusChipType
                                    }
                                  />
                                ) : (
                                  'N/A'
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            direction='row'
                            gap={1}
                            marginTop='20px'
                          >
                            <Grid item xs={4}>
                              <ObservabilityCard
                                cardHeader='Last Run'
                                cardContent={
                                  ruleDetails.contents
                                    ? ruleDetails.contents.lastRun
                                    : ''
                                }
                              />
                            </Grid>
                            <Grid item xs={4}>
                              {ruleDetails.contents.tableRecordCount ===
                              null ? (
                                <ObservabilityCard
                                  cardHeader='Table Has Not Been Profiled'
                                  cardContent='Please contact an admin'
                                />
                              ) : (
                                <ObservabilityCard
                                  cardHeader='Total # of Check Failures'
                                  cardContent={`${ruleDetails.contents.failedRecordCount} Rows / ${ruleDetails.contents.tableRecordCount} Rows`}
                                />
                              )}
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            direction='row'
                            marginTop='28px'
                            marginLeft='12px'
                            alignItems='center'
                            position='relative'
                          >
                            {needsUpgrade && <DownloadTextUpgradeOverlay />}
                            <Grid item>
                              <DownloadFileIcon
                                color='primary'
                                sx={{ width: '14px', height: '16px' }}
                              />
                            </Grid>
                            <Grid item marginLeft='8px'>
                              <Typography
                                color='primary'
                                sx={{
                                  '&:hover': {
                                    cursor: 'pointer',
                                    textDecoration: 'underline',
                                  },
                                }}
                                fontWeight='semi-bold'
                                fontSize='14px'
                                onClick={handleDownloadCSV}
                              >
                                Download Check Failures to CSV
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={6} justifyContent='flex-end'>
                        {ruleDetails.contents &&
                        ruleDetails.contents.failedRowCountHistory.length >
                          0 ? (
                          <RuleResultsChart
                            data={ruleDetails.contents.failedRowCountHistory}
                          />
                        ) : (
                          <Box
                            width='100%'
                            height='200px'
                            display='flex'
                            justifyContent='center'
                            alignItems='center'
                            sx={{
                              borderRadius: '10px',
                              backgroundColor: theme.palette.grey[100],
                            }}
                          >
                            <Typography fontSize='14px' fontWeight='bold'>
                              No history of failed row counts.
                            </Typography>
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container marginTop='20px'>
                      {ruleDetails.contents?.failedSourceRecords && (
                        <Grid item xs={12}>
                          {haveToRenderDetailsTable ? (
                            <DataValidationTestDetailsTable
                              data={ruleDetails.contents.failedSourceRecords}
                              showUpgradeOverlay={needsUpgrade}
                            />
                          ) : (
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: theme.palette.grey[100],
                                width: '100%',
                                padding: '20px',
                                borderRadius: '10px',
                              }}
                            >
                              {haveToRenderTableEmptyState && (
                                <>
                                  <CheckIcon
                                    sx={{
                                      color: theme.palette.success.dark,
                                      width: '24px',
                                      height: '24px',
                                    }}
                                  />
                                  <Typography
                                    fontSize='14px'
                                    marginLeft='4px'
                                    fontWeight='bold'
                                  >
                                    All current checks have passed! No current
                                    failures to display.
                                  </Typography>
                                </>
                              )}
                              {ruleDetails.contents.status === 'Scheduled' && (
                                <>
                                  <UpdateIcon
                                    sx={{
                                      color: theme.palette.info.main,
                                      width: '24px',
                                      height: '24px',
                                    }}
                                  />
                                  <Typography
                                    fontSize='14px'
                                    marginLeft='4px'
                                    fontWeight='bold'
                                  >
                                    Check passes or failures will be displayed
                                    after the next run.
                                  </Typography>
                                </>
                              )}
                            </Box>
                          )}
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </LayoutWithoutSideNav>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </ConnectorsPageContainer>
  );
};

export default ValidationDetailsPage;
