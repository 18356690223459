import React from 'react';
import { Box, styled, Typography, WatchLaterIcon } from '@cherre-frontend/ui';

const PageTitle = styled(Typography)(() => ({
  fontSize: '24px',
  fontWeight: 700,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  marginTop: '20px',
  width: '100%',
  height: '140px',
  display: 'flex',
  gap: '4px',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  borderRadius: '10px',
  backgroundColor: theme.palette.grey[100],
}));

const StyledIcon = styled(WatchLaterIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  width: '20px',
  height: '20px',
}));

const ComingSoonText = styled(Typography)(({ theme }) => ({
  fontSize: '24px',
  fontWeight: 700,
  color: theme.palette.grey[700],
}));

const StayTunedText = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 400,
  color: theme.palette.grey[700],
}));

type Props = {
  pageName: string;
};

const ComingSoon: React.FC<Props> = ({ pageName }) => {
  return (
    <>
      <PageTitle>{pageName}</PageTitle>
      <StyledBox>
        <StyledIcon />
        <ComingSoonText>Coming Soon!</ComingSoonText>
        <StayTunedText>STAY TUNED</StayTunedText>
      </StyledBox>
    </>
  );
};

export default ComingSoon;
