import React, { useCallback, useState } from 'react';
import {
  Box,
  WarningIcon,
  Typography,
  Popover,
  useTheme,
  GppGoodIcon,
  Button,
  styled,
} from '@cherre-frontend/ui';
import {
  useCherreSetState,
  useCherreState,
} from '@cherre-frontend/data-fetching';
import {
  connectorDetailsTabState,
  dataValidationRuleStatusFilter,
} from '../recoil';
import { ConnectorDetailsTabValues } from '../pages/connector-details';
import { TestStatusList } from '../pages/connector-details/components/DataValidation/constants';

export enum ValidationStatus {
  PASSED = 'Passed',
  FAILED = 'Failed',
  NOT_RUN = 'Not Run',
}

const CustomButton = styled(Button)`
  &:hover {
    background-color: transparent;
  }
`;

type Props = {
  status: ValidationStatus;
};

const ValidationStatusPopover: React.FC<Props> = ({ status }) => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const setTab = useCherreSetState(connectorDetailsTabState);
  const [, setDataValidationFilter] = useCherreState(
    dataValidationRuleStatusFilter
  );
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const redirectToValidationsTab = useCallback(() => {
    setDataValidationFilter([TestStatusList.FAILED]);
    setTab(ConnectorDetailsTabValues.VALIDATION);
  }, []);

  const open = Boolean(anchorEl);

  const getStatusIcon = (status: ValidationStatus) => {
    switch (status) {
      case ValidationStatus.PASSED:
        return (
          <Box
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            <GppGoodIcon
              style={{
                height: '35px',
                width: '35px',
                color: theme.palette.success.dark,
              }}
            />
          </Box>
        );
      case ValidationStatus.FAILED:
        return (
          <CustomButton
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            onClick={redirectToValidationsTab}
          >
            <WarningIcon
              sx={{
                height: '35px',
                width: '35px',
                color: theme.palette.error.main,
              }}
            />
          </CustomButton>
        );
      case ValidationStatus.NOT_RUN:
        return (
          <Box
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            <GppGoodIcon
              style={{
                height: '35px',
                width: '35px',
                color: theme.palette.grey[400],
              }}
            />
          </Box>
        );
      default:
        return;
    }
  };

  const getStatusMessage = (status: ValidationStatus) => {
    switch (status) {
      case ValidationStatus.PASSED:
        return 'All validation checks have successfully passed.';
      case ValidationStatus.FAILED:
        return 'There are validation check failures within the connection.';
      case ValidationStatus.NOT_RUN:
        return 'Validation checks have not run.';
      default:
        return '';
    }
  };

  return (
    <>
      {getStatusIcon(status)}
      <Popover
        sx={{
          pointerEvents: 'none',
          '& .MuiPaper-root': {
            backgroundColor: theme.palette.grey[900],
          },
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1, color: theme.palette.grey[200] }}>
          {getStatusMessage(status)}
        </Typography>
      </Popover>
    </>
  );
};

export default ValidationStatusPopover;
