import React from 'react';
import { Box, Typography, LinearProgress, styled } from '@cherre-frontend/ui';

const ProgressBar = styled(LinearProgress)`
  width: 50px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.grey[400]};
  & > span {
    background-color: ${({ theme }) => theme.palette.success.dark};
  }
`;

type ColumnProfileTableProgressElementProps = {
  value: number;
};

const ColumnProfileTableProgressElement: React.FC<
  ColumnProfileTableProgressElementProps
> = ({ value }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <Typography sx={{ width: '50px', textAlign: 'center' }}>
        <span>{value}%</span>
      </Typography>
      <ProgressBar variant='determinate' value={value} />
    </Box>
  );
};

ColumnProfileTableProgressElement.displayName =
  'ColumnProfileTableProgressElement';
export default ColumnProfileTableProgressElement;
