/**
 * @generated SignedSource<<419c83e0e56d38da19234d53e21582db>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type dataValidationCustomRulesQuery$variables = {
  owner?: string | null;
};
export type dataValidationCustomRulesQuery$data = {
  readonly customRules: ReadonlyArray<{
    readonly active: boolean | null;
    readonly categories: ReadonlyArray<string> | null;
    readonly description: string;
    readonly displayName: string;
    readonly name: string;
    readonly provider: string | null;
    readonly rliGeneralProperties: ReadonlyArray<{
      readonly defaultValue: string | null;
      readonly defaultValues: ReadonlyArray<string | null> | null;
      readonly externalObjectType: string | null;
      readonly order: number | null;
      readonly parameterName: string;
      readonly uiReadOnly: boolean;
      readonly visibility: boolean;
    }> | null;
    readonly rliSpecificProperties: ReadonlyArray<{
      readonly defaultValue: string | null;
      readonly defaultValues: ReadonlyArray<string | null> | null;
      readonly externalObjectType: string | null;
      readonly order: number | null;
      readonly parameterName: string;
      readonly uiReadOnly: boolean;
      readonly visibility: boolean;
    }> | null;
    readonly rule: {
      readonly checkId: number | null;
      readonly checkStatus: string | null;
      readonly checkStatusMessage: string | null;
      readonly connectorComponentRef: {
        readonly kind: string | null;
        readonly name: string | null;
        readonly version: string | null;
      } | null;
      readonly containerId: number | null;
      readonly datastoreId: number | null;
      readonly name: string;
      readonly notificationProperties: ReadonlyArray<{
        readonly propertyName: string;
        readonly propertyValue: string | null;
        readonly propertyValues: ReadonlyArray<string | null> | null;
      } | null> | null;
      readonly ruleGeneralProperties: ReadonlyArray<{
        readonly propertyName: string;
        readonly propertyValue: string | null;
        readonly propertyValues: ReadonlyArray<string | null> | null;
      } | null> | null;
      readonly ruleSpecificProperties: ReadonlyArray<{
        readonly propertyName: string;
        readonly propertyValue: string | null;
        readonly propertyValues: ReadonlyArray<string | null> | null;
      } | null> | null;
    } | null;
    readonly status: string | null;
    readonly tableRef: string;
    readonly version: string;
  } | null> | null;
};
export type dataValidationCustomRulesQuery = {
  response: dataValidationCustomRulesQuery$data;
  variables: dataValidationCustomRulesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "owner"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "version",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "parameterName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "defaultValue",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "defaultValues",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "externalObjectType",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "order",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "uiReadOnly",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "visibility",
    "storageKey": null
  }
],
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "propertyName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "propertyValue",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "propertyValues",
    "storageKey": null
  }
],
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "owner",
        "variableName": "owner"
      }
    ],
    "concreteType": "RuleLibraryItem",
    "kind": "LinkedField",
    "name": "customRules",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "provider",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "displayName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "tableRef",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "categories",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "active",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "RuleLibraryItemParameter",
        "kind": "LinkedField",
        "name": "rliGeneralProperties",
        "plural": true,
        "selections": (v3/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "RuleLibraryItemParameter",
        "kind": "LinkedField",
        "name": "rliSpecificProperties",
        "plural": true,
        "selections": (v3/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Rule",
        "kind": "LinkedField",
        "name": "rule",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ConnectorComponentRef",
            "kind": "LinkedField",
            "name": "connectorComponentRef",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "kind",
                "storageKey": null
              },
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RuleProperty",
            "kind": "LinkedField",
            "name": "ruleGeneralProperties",
            "plural": true,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RuleProperty",
            "kind": "LinkedField",
            "name": "ruleSpecificProperties",
            "plural": true,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RuleProperty",
            "kind": "LinkedField",
            "name": "notificationProperties",
            "plural": true,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "checkStatus",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "checkStatusMessage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "checkId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "datastoreId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "containerId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "dataValidationCustomRulesQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "dataValidationCustomRulesQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "1f9b9473e0cf138722c33e91af690519",
    "id": null,
    "metadata": {},
    "name": "dataValidationCustomRulesQuery",
    "operationKind": "query",
    "text": "query dataValidationCustomRulesQuery(\n  $owner: String\n) {\n  customRules(owner: $owner) {\n    name\n    version\n    provider\n    displayName\n    description\n    tableRef\n    categories\n    active\n    status\n    rliGeneralProperties {\n      parameterName\n      defaultValue\n      defaultValues\n      externalObjectType\n      order\n      uiReadOnly\n      visibility\n    }\n    rliSpecificProperties {\n      parameterName\n      defaultValue\n      defaultValues\n      externalObjectType\n      order\n      uiReadOnly\n      visibility\n    }\n    rule {\n      name\n      connectorComponentRef {\n        kind\n        name\n        version\n      }\n      ruleGeneralProperties {\n        propertyName\n        propertyValue\n        propertyValues\n      }\n      ruleSpecificProperties {\n        propertyName\n        propertyValue\n        propertyValues\n      }\n      notificationProperties {\n        propertyName\n        propertyValue\n        propertyValues\n      }\n      checkStatus\n      checkStatusMessage\n      checkId\n      datastoreId\n      containerId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "62f7d786ce06095decc3dc8173e06d49";

export default node;
