import React from 'react';
import { Skeleton, Box, styled, Grid } from '@cherre-frontend/ui';
import OverviewDivider from '../../../../components/OverviewDivider';
import { ValidationStatus } from '../../../../components/ValidationStatusPopover';

const StyledOverviewLoadingSkeletonContainer = styled(Box)`
  width: 100%;
  padding: 0 16px;
`;

const StyledSkeletonConnectorContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 32px;
  margin-top: 40px;
`;

const StyledSkeleton = styled(Skeleton)`
  min-height: 315px;
  padding: 20px 30px 0px;
  height: fit-content;
  width: 100%;
`;

const StyledButtonContainer = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 40px;
`;

const StyledButtonSkeleton = styled(Skeleton)`
  width: 150px;
  height: 40px;
  border-radius: 6px;
`;

const OverviewLoadingSkeleton = () => {
  return (
    <>
      <Grid
        width='100%'
        display='flex'
        flexDirection='row'
        gap={1}
        paddingLeft='16px'
        paddingTop='8px'
      >
        <Skeleton variant='rectangular' width='80px' height='25px' />
        <Skeleton variant='rectangular' width='150px' height='25px' />
        <Skeleton variant='rectangular' width='120px' height='25px' />
        <Skeleton variant='rectangular' width='80px' height='25px' />
        <Skeleton variant='rectangular' width='80px' height='25px' />
      </Grid>
      <StyledOverviewLoadingSkeletonContainer>
        <StyledSkeletonConnectorContainer>
          <StyledSkeleton variant='rectangular' />
          <OverviewDivider status={ValidationStatus.NOT_RUN} />
          <StyledSkeleton variant='rectangular' />
          <OverviewDivider status={ValidationStatus.NOT_RUN} />
          <StyledSkeleton variant='rectangular' />
        </StyledSkeletonConnectorContainer>
        <StyledButtonContainer>
          <StyledButtonSkeleton variant='rounded' />
        </StyledButtonContainer>
      </StyledOverviewLoadingSkeletonContainer>
    </>
  );
};

OverviewLoadingSkeleton.displayName = 'OverviewLoadingSkeleton';
export default OverviewLoadingSkeleton;
