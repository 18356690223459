import React, { Suspense, useCallback, useEffect } from 'react';
import { useSourceUIState } from '../../../uiState/contexts';
import SourceCarroussel from './main';
import SourceCarrouselSkeletons from './skeleton';
import { useAddConnector } from '../../../dataState';
import { Container, Title } from './index.styles';

//@TODO: Implement SourceSelector component
/**
 * SourceSelector component allows users to choose a source from a list of available sources.
 *
 * This component uses the `useSourceUIState` hook
 * to get the currently selected source. It then renders a list of `SourceCard` components inside a `SourceCarroussel`.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 */
const SourceSelector: React.FC = () => {
  const [selectedSource, setSelectedSource] = useSourceUIState();
  const [addConnectorData] = useAddConnector();

  useEffect(() => {
    if (selectedSource.sourceName || !addConnectorData.sources) {
      return;
    }

    const source = addConnectorData.sources[0];

    setSelectedSource((prev) => ({
      ...prev,
      sourceName: source.preconfiguredSourceName,
      provider: source.provider,
    }));
  }, [addConnectorData, selectedSource]);

  const handleChoseSource = useCallback((source): void => {
    setSelectedSource(source);
  }, []);

  return (
    <Container>
      <Title>Choose a source:</Title>

      <Suspense fallback={<SourceCarrouselSkeletons />}>
        <SourceCarroussel
          chosenSource={selectedSource}
          onChooseSource={handleChoseSource}
        />
      </Suspense>
    </Container>
  );
};

export default SourceSelector;
