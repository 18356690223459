import React from 'react';
import {
  DynamicField,
  useDataValidationFormContext,
} from '../dataValidationFormEngine';
import { ControlledCheckbox, ControlledInput } from '@cherre-frontend/ui';
import { ParameterName, parameterNameLabels } from './maps';
import { DataValidationTables } from '../DataValidationTables';
import { DataValidationTableFields } from '../DataValidationTableFields';
import { DataValidationTableRefField } from './DataValidationTableRefField';

interface DynamicDataValidationFieldProps extends DynamicField {
  index: number;
  propertiesType: 'generalProperties' | 'specificProperties';
  isCreateMode: boolean;
}

export const DynamicDataValidationField = ({
  index,
  uiReadOnly,
  isCreateMode,
  parameterName,
  propertiesType,
  externalObjectType,
}: DynamicDataValidationFieldProps) => {
  const { control } = useDataValidationFormContext();

  const _parameterName = parameterName as ParameterName;

  const label = parameterNameLabels[_parameterName] || parameterName;

  if (isCreateMode) {
    if (parameterName === 'tableRef') {
      return (
        <DataValidationTables index={index} propertiesType={propertiesType} />
      );
    }

    if (parameterName === 'field' || parameterName === 'fieldsArray') {
      return (
        <DataValidationTableFields
          index={index}
          propertiesType={propertiesType}
        />
      );
    }
  }

  if (externalObjectType === 'boolean') {
    return (
      <ControlledCheckbox
        required
        label={label}
        disabled={uiReadOnly}
        control={control}
        name={`${propertiesType}.${index}.value`}
        sx={{
          padding: 0,
          marginLeft: 0,
          marginRight: '5px',
          '& .MuiFormControlLabel-root': { marginLeft: 0 },
        }}
      />
    );
  }

  if (_parameterName === 'tableRef') {
    return (
      <DataValidationTableRefField
        control={control}
        index={index}
        label={label}
        propertiesType={propertiesType}
        uiReadOnly={uiReadOnly}
      />
    );
  }

  return (
    <ControlledInput
      required
      label={label}
      disabled={uiReadOnly}
      control={control}
      name={`${propertiesType}.${index}.value`}
    />
  );
};
