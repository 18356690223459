import { BuildCronScheduleArgs, BuildCronScheduleReturn } from './model';

export const buildCronSchedule = ({
  availability,
  updateFrequency,
}: BuildCronScheduleArgs): BuildCronScheduleReturn => {
  if (updateFrequency.type === 'weekdays') {
    return `${availability.minute} ${availability.hour} * * 1-5`;
  }

  if (updateFrequency.type === 'daily') {
    return `${availability.minute} ${availability.hour} * * *`;
  }

  if (updateFrequency.type === 'monthly') {
    return `${availability.minute} ${availability.hour} ${updateFrequency.day} * *`;
  }

  return `${availability.minute} ${availability.hour} * * ${updateFrequency.day}`;
};
