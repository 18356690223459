import React, { FC, ReactElement, useEffect } from 'react';
import { SOURCES } from '../../../mockData';
import SourceCard from '../SourceCard';
import { SourceCarrousselContainer } from './styles';
import { SourceCarrousselProps } from './model';
import { useLazyLoadQuery } from 'react-relay';
import { ConnectorsListQuery } from 'src/services/DOM/queries/connectorsList';
import { connectorsListQuery } from 'src/services/DOM/queries/connectorsList/__generated__/connectorsListQuery.graphql';
import { verifyTakenSourceByConnector } from '../../util/verifyTakenSourceByConnector';
import { useConnectorUIState } from '../../../uiState/contexts';

/**
 * SourceCarroussel component allows users to choose a source from a list of available sources.
 *
 * @component
 *
 * @returns {JSX.Element} The rendered sources card list.
 *
 *
 * @hook
 * - `useEffect` to verify if the selected source is already been taken by other active connector.
 * - `useConnectorUIState` to update the global state with value repeatedSource
 * - `useLazyLoadQuery` to get the connectors list
 *
 * @dependencies
 * - `AddConnectorProvider` for managing the global state.
 * - `verifyTakenSourceByConnector` to verify if the chosen source is being used in some actived connector
 */

const SourceCarroussel: FC<SourceCarrousselProps> = ({
  onChooseSource,
  chosenSource,
}): ReactElement => {
  const sources = SOURCES;
  const [, setUiState] = useConnectorUIState();

  const { connectors } = useLazyLoadQuery<connectorsListQuery>(
    ConnectorsListQuery,
    {},
    { fetchPolicy: 'store-only' }
  );

  useEffect(() => {
    const alreadyHasTheSource = verifyTakenSourceByConnector({
      connectors,
      chosenSource: chosenSource.provider,
    });

    setUiState((prev) => ({
      ...prev,
      repeatedSource: alreadyHasTheSource,
    }));
  }, [connectors, chosenSource]);

  return (
    <SourceCarrousselContainer>
      {sources.map((source) => (
        <SourceCard
          key={source.sourceName}
          title={source.displayName}
          image={source.image}
          isLightning
          onClick={() => onChooseSource(source)}
          selected={
            source.sourceName === chosenSource.sourceName &&
            source.sourceType === chosenSource.sourceType
          }
        />
      ))}
    </SourceCarrousselContainer>
  );
};

export default SourceCarroussel;
