import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { styled } from '@cherre-frontend/ui';

export const ConnectorDetailTabs = styled(Tabs)`
  align-self: flex-start;
  .MuiTabs-indicator {
    top: 0;
    border-radius: 0;
  }
`;

export const ConnectorDetailTab = styled(Tab)`
  min-width: unset;
  color: ${({ theme }) => theme.palette.grey[400]};
`;

export const ConnectorTitle = styled('h1')`
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 1%;
  margin: 0;
  margin-left: 16px;
  margin-top: 20px;
  margin-bottom: 20px;
`;
