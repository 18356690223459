import React from 'react';
import { Grid, Box, Skeleton, styled } from '@cherre-frontend/ui';

export const StyledGridContainer = styled(Grid)`
  height: 100%;
  width: 100%;
  padding: 10px 32px;
  background-color: ${({ theme }) => theme.palette.grey[100]};
  overflow-y: scroll;
`;

export const StyledGridRow = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  width: 100%;
`;

export const StyledBox = styled(Box)<{ gap: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ gap }) => gap};
`;

export const StyledSkeleton = styled(Skeleton)<{ borderRadius?: string }>`
  border-radius: ${({ borderRadius }) => borderRadius || '0'};
`;

const ConnectorInventorySkeletonLoader = () => {
  return (
    <StyledGridContainer container direction='column'>
      <Grid item xs={12}>
        <StyledGridRow container direction='row'>
          <StyledBox gap='32px'>
            <Skeleton variant='rounded' height='80px' width='100px' />
            <Skeleton variant='text' width='180px' height='50px' />
          </StyledBox>
          <StyledBox gap='16px'>
            <StyledSkeleton
              variant='rounded'
              width='325px'
              height='30px'
              borderRadius='20px'
            />
            <StyledSkeleton
              variant='rounded'
              width='120px'
              height='30px'
              borderRadius='20px'
            />
            <StyledSkeleton
              variant='rounded'
              width='170px'
              height='35px'
              borderRadius='6px'
            />
          </StyledBox>
        </StyledGridRow>
        <Grid item xs={12} sx={{ width: '100%', paddingTop: '20px' }}>
          <StyledSkeleton
            variant='rounded'
            width='100%'
            height='500px'
            borderRadius='16px 16px 0px 0px'
          />
        </Grid>
      </Grid>
    </StyledGridContainer>
  );
};

ConnectorInventorySkeletonLoader.displayName =
  'ConnectorInventorySkeletonLoader';
export default ConnectorInventorySkeletonLoader;
