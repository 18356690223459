import React from 'react';
import { Grid, Skeleton } from '@cherre-frontend/ui';

const DataDictionarySkeleton: React.FC = () => {
  return (
    <Grid container direction='column' sx={{ width: '100%' }} padding={2}>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        sx={{ width: '100%' }}
      >
        <Grid item xs={6}>
          <Skeleton variant='text' width={210} height={40} animation='wave' />
        </Grid>

        <Grid item xs={6}>
          <Grid
            container
            justifyContent='flex-end'
            direction='row'
            alignItems='center'
            sx={{ gap: '16px' }}
          >
            <Grid item width='480px'>
              <Skeleton
                variant='rounded'
                width={480}
                height={40}
                animation='wave'
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container paddingTop='12px'>
        <Grid item width='100%'>
          <Skeleton
            variant='rounded'
            width='100%'
            height={200}
            animation='wave'
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

DataDictionarySkeleton.displayName = 'DataDictionarySkeleton';
export default DataDictionarySkeleton;
