import React, { FC, ReactElement } from 'react';
import { styled } from '@cherre-frontend/ui';
import { ModelUIStateProvider } from '../../uiState/contexts';
import ModelSelector from './ModelSelector';

const Container = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
`;

const Model: FC = (): ReactElement => {
  return (
    <ModelUIStateProvider>
      <Container>
        <ModelSelector />
      </Container>
    </ModelUIStateProvider>
  );
};

export default Model;
