import { useState } from 'react';
import { useDataValidationV2 } from '../../../providers/DataValidationV2Provider';

export type DataValidationListFilters = {
  search: string;
  category: string;
  validationStatus: string;
};

export const useDataValidationListData = () => {
  const { ruleLibraryItems } = useDataValidationV2();
  const [filters, setFilters] = useState<DataValidationListFilters>({
    search: '',
    category: 'all',
    validationStatus: 'all',
  });

  const data = ruleLibraryItems.filter((item) => {
    if (filters.search) {
      return item.displayName
        .toLowerCase()
        .includes(filters.search.toLowerCase());
    }

    if (filters.category !== 'all') {
      return item.categories?.includes(filters.category);
    }

    if (filters.validationStatus !== 'all') {
      return item.status === filters.validationStatus;
    }

    return true;
  });

  const categories = ruleLibraryItems.reduce<string[]>((acc, curr) => {
    if (curr.categories) {
      curr.categories.forEach((category) => {
        if (!acc.includes(category)) {
          acc.push(category);
        }
      });
    }

    return acc;
  }, []);

  return {
    data,
    categories,
    filters,
    setFilters,
  };
};
