import moment from 'moment-timezone';

export const printLocalDatetimeWithTimezone = (
  format: string,
  datetime?: string | null | Date
): string => {
  if (!datetime) {
    return '';
  }

  const localTime = new Date(datetime);
  const formatedTime = moment(localTime).format(format);
  const timeZone = moment.tz.guess();
  const timezoneMoment = moment().tz(timeZone).format('z');
  const timezoneLabel = /[a-zA-Z]/g.test(timezoneMoment)
    ? timezoneMoment
    : `UTC${timezoneMoment}`;

  return `${formatedTime} ${timezoneLabel}`;
};
