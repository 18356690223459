import React from 'react';
import { Button, ButtonProps } from '@cherre-frontend/ui';

const ConnectorButton: React.FC<ButtonProps> = ({ style, ...props }) => {
  return (
    <Button
      style={{
        height: '33px',
        padding: '8px 12px',
        fontWeight: 600,
        ...style,
      }}
      {...props}
    >
      {props.children}
    </Button>
  );
};

export default ConnectorButton;
