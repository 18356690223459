import { useCherreValueLoadable } from '@cherre-frontend/data-fetching';
import {
  ConnectorEnvironments,
  useConnectorEnvironment,
} from 'src/hooks/useConnectorEnvironment';

import { openMetadataDataValidationTestDetails } from 'src/services/OpenMetadata/recoil';
import { qualyticsValidationRuleDetails } from 'src/services/Qualytics/recoil';

export const useGetDataValidationRuleDetails = () => {
  const environment = useConnectorEnvironment();

  if (environment === ConnectorEnvironments.MVP) {
    const ruleDetails = useCherreValueLoadable(qualyticsValidationRuleDetails);

    return { ruleDetails };
  } else {
    const ruleDetails = useCherreValueLoadable(
      openMetadataDataValidationTestDetails
    );

    return { ruleDetails };
  }
};
