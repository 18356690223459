import { createGlobalState } from '../utils';
import type { PartialSFTPSourceType, PartialAddConnectorType } from './schemas';

export const [SFTPSourceProvider, useSFTPSource] =
  createGlobalState<PartialSFTPSourceType>({});

export const [AddConnectorProvider, useAddConnector, useHasChanged] =
  createGlobalState<PartialAddConnectorType>({
    name: 'Untitled Connector',
  });
