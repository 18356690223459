import { styled } from '@cherre-frontend/ui';

export const WarningMessageAddConnectorContainer = styled('div')`
  border-radius: 10px;
  padding-top: 6px;
  padding-right: 20px;
  padding-bottom: 6px;
  padding-left: 20px;
  background-color: ${({ theme }) => theme.palette.warning.dark};
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 1%;
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const MessageContainer = styled('p')`
  margin: 0;
`;
