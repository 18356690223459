import React, { FC, ReactElement } from 'react';
import { styled } from '@cherre-frontend/ui';
import { DestinationUIStateProvider } from '../../uiState/contexts';
import DestinationSelector from './DestinationSelector';
import ModelTablesSection from './ModelTablesSection';

const Destination: FC = (): ReactElement => {
  const Container = styled('div')`
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  `;

  return (
    <DestinationUIStateProvider>
      <Container>
        <DestinationSelector />
        <ModelTablesSection />
      </Container>
    </DestinationUIStateProvider>
  );
};

export default Destination;
