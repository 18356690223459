import React from 'react';
import { ArrowForwardIcon, Box, useTheme } from '@cherre-frontend/ui';
import ValidationStatusPopover, {
  ValidationStatus,
} from './ValidationStatusPopover';

export enum DividerStatus {
  ACTIVE = 'Active',
  INACTIVE = 'inactive',
}

type Props = {
  status?: ValidationStatus;
};

const OverviewDivider: React.FC<Props> = ({ status }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {status && <ValidationStatusPopover status={status} />}
      <ArrowForwardIcon sx={{ color: theme.palette.grey[400] }} />
    </Box>
  );
};

export default OverviewDivider;
