import React from 'react';
import { useSourceUIState } from '../../uiState/contexts';
import SFTPSource from './SFTPSource';

/**
 * SourceForm component that renders different source components based on the sourceType.
 *
 * @component
 * @returns {JSX.Element} The component to render based on the sourceType.
 *
 * @throws {Error} Throws an error if the sourceType is unknown.
 */
const SourceForm: React.FC = () => {
  const [{ sourceType }] = useSourceUIState();
  switch (sourceType) {
    case 'SFTP':
      return <SFTPSource />;
    default:
      throw new Error(`Unknown source type: ${sourceType}`);
  }
};

export default SourceForm;
