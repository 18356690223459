import { z } from 'zod';
import { RuleTypes } from './types';

const requiredFields = [
  'missingCountValue',
  'value',
  'columnCount',
  'columnName',
  'columnNames',
];

const parameterObjectArraySchema = z.object({
  name: z.string(),
  value: z.array(z.string()),
});

const parameterObjectStringSchema = z
  .object({
    name: z.string(),
    value: z.union([z.string().optional(), z.boolean().optional()]),
  })
  .refine((parameter) => {
    if (requiredFields.includes(parameter.name) && parameter.value === '') {
      return false;
    }
    return true;
  });
type Element =
  | z.infer<typeof parameterObjectStringSchema>
  | z.infer<typeof parameterObjectArraySchema>;

function refineNames(elements: Element[], strings: string[]): boolean {
  return strings.every((string: string) =>
    elements.some((parameter) => parameter.name === string)
  );
}

const shared = z.object({
  name: z.string().min(1),
  testDefinition: z.string().min(1),
});

export const schema = z.discriminatedUnion('testDefinition', [
  shared.extend({
    testDefinition: z.enum([
      RuleTypes.COLUMN_VALUES_TO_BE_NOT_NULL,
      RuleTypes.COLUMN_VALUES_TO_BE_UNIQUE,
    ]),
    column: z.string().min(1),
  }),
  shared.extend({
    testDefinition: z.literal(RuleTypes.COLUMN_VALUES_TBB),
    parameterValues: z
      .array(parameterObjectStringSchema)
      .refine((elements) => refineNames(elements, ['minValue', 'maxValue'])),
  }),
  shared.extend({
    testDefinition: z.literal(RuleTypes.TABLE_ROW_COUNT_TBB),
    parameterValues: z
      .array(parameterObjectStringSchema)
      .refine((elements) => refineNames(elements, ['minValue', 'maxValue'])),
  }),
  shared.extend({
    testDefinition: z.literal(RuleTypes.COLUMN_VALUE_STD_DEV_TBB),
    column: z.string().min(1),
    parameterValues: z
      .array(parameterObjectStringSchema)
      .refine((elements) =>
        refineNames(elements, [
          'minValueForStdDevInCol',
          'maxValueForStdDevInCol',
        ])
      ),
  }),
  shared.extend({
    testDefinition: z.literal(RuleTypes.COLUMN_VALUE_LENGTHS_TBB),
    column: z.string().min(1),
    parameterValues: z
      .array(parameterObjectStringSchema)
      .refine((elements) => refineNames(elements, ['minLength', 'maxLength'])),
  }),
  shared.extend({
    testDefinition: z.literal(RuleTypes.COLUMN_VALUE_MAX_TBB),
    column: z.string().min(1),
    parameterValues: z
      .array(parameterObjectStringSchema)
      .refine((elements) =>
        refineNames(elements, ['minValueForMaxInCol', 'maxValueForMaxInCol'])
      ),
  }),
  shared.extend({
    testDefinition: z.literal(RuleTypes.COLUMN_VALUE_MIN_TBB),
    column: z.string().min(1),
    parameterValues: z
      .array(parameterObjectStringSchema)
      .refine((elements) =>
        refineNames(elements, ['minValueForMinInCol', 'maxValueForMinInCol'])
      ),
  }),
  shared.extend({
    testDefinition: z.literal(RuleTypes.COLUMN_VALUE_MEAN_TBB),
    column: z.string().min(1),
    parameterValues: z
      .array(parameterObjectStringSchema)
      .refine((elements) =>
        refineNames(elements, ['minValueForMeanInCol', 'maxValueForMeanInCol'])
      ),
  }),
  shared.extend({
    testDefinition: z.literal(RuleTypes.COLUMN_VALUE_MEDIAN_TBB),
    column: z.string().min(1),
    parameterValues: z
      .array(parameterObjectStringSchema)
      .refine((elements) =>
        refineNames(elements, [
          'minValueForMedianInCol',
          'maxValueForMedianInCol',
        ])
      ),
  }),
  shared.extend({
    testDefinition: z.literal(RuleTypes.COLUMN_VALUES_SUM_TBB),
    column: z.string().min(1),
    parameterValues: z
      .array(parameterObjectStringSchema)
      .refine((elements) =>
        refineNames(elements, ['minValueForColSum', 'maxValueForColSum'])
      ),
  }),
  shared.extend({
    testDefinition: z.literal(RuleTypes.TABLE_COLUMN_COUNT_TBB),
    parameterValues: z
      .array(parameterObjectStringSchema)
      .refine((elements) =>
        refineNames(elements, ['minColValue', 'maxColValue'])
      ),
  }),
  shared.extend({
    testDefinition: z.literal(RuleTypes.COLUMN_VALUES_MISSING_COUNT),
    parameterValues: z
      .array(parameterObjectStringSchema)
      .refine((elements) =>
        refineNames(elements, ['missingCountValue', 'missingValueMatch'])
      ),
  }),
  shared.extend({
    testDefinition: z.literal(RuleTypes.COLUMN_VALUES_TO_MATCH_REGEX),
    column: z.string().min(1),
    parameterValues: z
      .array(parameterObjectStringSchema)
      .refine((elements) => refineNames(elements, ['regex'])),
  }),
  shared.extend({
    testDefinition: z.literal(RuleTypes.COLUMN_VALUES_TO_NOT_MATCH_REGEX),
    column: z.string().min(1),
    parameterValues: z
      .array(parameterObjectStringSchema)
      .refine((elements) => refineNames(elements, ['forbiddenRegex'])),
  }),
  shared.extend({
    testDefinition: z.literal(RuleTypes.COLUMN_VALUES_TO_BE_IN_SET),
    column: z.string().min(1),
    parameterValues: z
      .array(parameterObjectArraySchema)
      .refine((elements) => refineNames(elements, ['allowedValues'])),
  }),
  shared.extend({
    testDefinition: z.literal(RuleTypes.COLUMN_VALUES_TO_BE_NOT_IN_SET),
    column: z.string().min(1),
    parameterValues: z
      .array(parameterObjectArraySchema)
      .refine((elements) => refineNames(elements, ['forbiddenValues'])),
  }),
  shared.extend({
    testDefinition: z.literal(RuleTypes.TABLE_ROW_COUNT_TO_EQUAL),
    parameterValues: z
      .array(parameterObjectStringSchema)
      .refine((elements) => refineNames(elements, ['value'])),
  }),
  shared.extend({
    testDefinition: z.literal(RuleTypes.TABLE_COLUMN_COUNT_TO_EQUAL),
    parameterValues: z
      .array(parameterObjectStringSchema)
      .refine((elements) => refineNames(elements, ['columnCount'])),
  }),
  shared.extend({
    testDefinition: z.literal(RuleTypes.TABLE_COLUMN_NAME_TO_EXIST),
    parameterValues: z
      .array(parameterObjectStringSchema)
      .refine((elements) => refineNames(elements, ['columnName'])),
  }),
  shared.extend({
    testDefinition: z.literal(RuleTypes.TABLE_COLUMN_TO_MATCH_SET),
    parameterValues: z
      .array(parameterObjectStringSchema)
      .refine((elements) => refineNames(elements, ['columnNames', 'ordered'])),
  }),
  shared.extend({
    testDefinition: z.literal(RuleTypes.TABLE_CUSTOM_SQL_QUERY),
    parameterValues: z
      .array(parameterObjectStringSchema)
      .refine((elements) =>
        refineNames(elements, ['sqlExpression', 'strategy', 'threshold'])
      ),
  }),
]);
