import {
  BuildModelTablesCacheRefArgs,
  BuildModelTablesCacheRefReturn,
} from './model';

export const buildModelTablesCacheRef = ({
  formCache,
  model,
  onChangeData,
}: BuildModelTablesCacheRefArgs): BuildModelTablesCacheRefReturn => {
  const { modelDisplayName, modelName, modelStableVersion } = model;
  const findModelTableForm = formCache.find(
    (localData) => localData.modelName === modelName
  );

  const newFormData = {
    modelName: modelName,
    displayName: modelDisplayName,
    selectedAll: onChangeData.selectedAll,
    stableVersion: modelStableVersion,
    form: onChangeData.form,
  };

  if (!findModelTableForm) {
    formCache.push(newFormData);

    return formCache;
  }

  return formCache.map((localData) => {
    if (localData.modelName === modelName) {
      return newFormData;
    }

    return localData;
  });
};
