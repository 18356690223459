/**
 * @generated SignedSource<<d63336594c85cbd5f34985c301f171bc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RulePropertyInput = {
  externalObjectType: string;
  propertyName: string;
  propertyValue?: string | null;
  propertyValues?: ReadonlyArray<string | null> | null;
};
export type RuleLibraryItemRefInput = {
  kind?: string | null;
  name?: string | null;
  version?: string | null;
};
export type ConnectorComponentRefInput = {
  kind?: string | null;
  name?: string | null;
  version?: string | null;
};
export type activateRuleMutation$variables = {
  connectorComponentRef?: ConnectorComponentRefInput | null;
  notificationProperties?: ReadonlyArray<RulePropertyInput | null> | null;
  ruleGeneralProperties: ReadonlyArray<RulePropertyInput>;
  ruleLibraryItemRef: RuleLibraryItemRefInput;
  ruleSpecificProperties?: ReadonlyArray<RulePropertyInput | null> | null;
};
export type activateRuleMutation$data = {
  readonly activateRule: {
    readonly checkStatus: string | null;
    readonly checkStatusMessage: string | null;
    readonly connectorComponentRef: {
      readonly kind: string | null;
      readonly name: string | null;
      readonly version: string | null;
    } | null;
    readonly name: string;
    readonly ruleGeneralProperties: ReadonlyArray<{
      readonly propertyName: string;
      readonly propertyValue: string | null;
    } | null> | null;
    readonly ruleSpecificProperties: ReadonlyArray<{
      readonly propertyName: string;
      readonly propertyValue: string | null;
    } | null> | null;
  };
};
export type activateRuleMutation = {
  response: activateRuleMutation$data;
  variables: activateRuleMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connectorComponentRef"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "notificationProperties"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ruleGeneralProperties"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ruleLibraryItemRef"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ruleSpecificProperties"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "propertyName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "propertyValue",
    "storageKey": null
  }
],
v7 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "connectorComponentRef",
        "variableName": "connectorComponentRef"
      },
      {
        "kind": "Variable",
        "name": "notificationProperties",
        "variableName": "notificationProperties"
      },
      {
        "kind": "Variable",
        "name": "ruleGeneralProperties",
        "variableName": "ruleGeneralProperties"
      },
      {
        "kind": "Variable",
        "name": "ruleLibraryItemRef",
        "variableName": "ruleLibraryItemRef"
      },
      {
        "kind": "Variable",
        "name": "ruleSpecificProperties",
        "variableName": "ruleSpecificProperties"
      }
    ],
    "concreteType": "Rule",
    "kind": "LinkedField",
    "name": "activateRule",
    "plural": false,
    "selections": [
      (v5/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ConnectorComponentRef",
        "kind": "LinkedField",
        "name": "connectorComponentRef",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "kind",
            "storageKey": null
          },
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "version",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "RuleProperty",
        "kind": "LinkedField",
        "name": "ruleGeneralProperties",
        "plural": true,
        "selections": (v6/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "RuleProperty",
        "kind": "LinkedField",
        "name": "ruleSpecificProperties",
        "plural": true,
        "selections": (v6/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "checkStatus",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "checkStatusMessage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "activateRuleMutation",
    "selections": (v7/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "activateRuleMutation",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "d48ad5c4d03cf6493614952e5d00c89a",
    "id": null,
    "metadata": {},
    "name": "activateRuleMutation",
    "operationKind": "mutation",
    "text": "mutation activateRuleMutation(\n  $ruleGeneralProperties: [RulePropertyInput!]!\n  $ruleSpecificProperties: [RulePropertyInput]\n  $ruleLibraryItemRef: RuleLibraryItemRefInput!\n  $connectorComponentRef: ConnectorComponentRefInput\n  $notificationProperties: [RulePropertyInput]\n) {\n  activateRule(ruleGeneralProperties: $ruleGeneralProperties, ruleSpecificProperties: $ruleSpecificProperties, ruleLibraryItemRef: $ruleLibraryItemRef, connectorComponentRef: $connectorComponentRef, notificationProperties: $notificationProperties) {\n    name\n    connectorComponentRef {\n      kind\n      name\n      version\n    }\n    ruleGeneralProperties {\n      propertyName\n      propertyValue\n    }\n    ruleSpecificProperties {\n      propertyName\n      propertyValue\n    }\n    checkStatus\n    checkStatusMessage\n  }\n}\n"
  }
};
})();

(node as any).hash = "8c5605bb0bcb9776b8fddc6cd283df4c";

export default node;
