import { useEffect, useState } from 'react';
import { dspEnvironmentFactory } from '../../../../../../shell/DSPRelayEnvironment';
import RelayModernEnvironment from 'relay-runtime/lib/store/RelayModernEnvironment';

export const useDspRelayEnvironment = (role?: string) => {
  const [environment, setEnvironment] = useState<RelayModernEnvironment>();

  useEffect(() => {
    dspEnvironmentFactory(role)().then(setEnvironment);
  }, []);

  return environment;
};
