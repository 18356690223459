import { atom, refine, selector } from '@cherre-frontend/data-fetching';
import {
  bool,
  object,
  string,
  withDefault,
  writableArray,
  number,
} from '@recoiljs/refine';
import { ValidationTestDetailsRoute } from 'src/products/connector/routes';
import { ValidationRuleResult } from '../../connector-details/components/DataValidation/types';

export const validationDetailsTabState = atom<string>({
  key: 'validation-details-tab-state',
  default: 'Validation Rule Results',
});

export const validationDetailsRuleId = selector({
  key: 'validation-details-test-name',
  get: ({ get }) => get(ValidationTestDetailsRoute.routeParamSelector)?.rule_id,
});

export const validationDetailsDataValidationState =
  ValidationTestDetailsRoute.searchParamsSelectorFamily({
    key: 'validation-details-data-validation-state',
    refine: withDefault(
      refine.stringLiterals([
        'Dashboard',
        'Table_Selection',
        'Table',
        'Column',
        'Details',
      ] as const),
      'Dashboard'
    ),
  });

export const dataValidationDetailsTableState =
  ValidationTestDetailsRoute.searchParamsSelectorFamily({
    key: 'data-validation-details-table-state',
    refine: withDefault(
      object({
        pagination: object({
          pageSize: refine.allowedNumbers([25, 50, 100]),
          pageIndex: number(),
        }),
        sorting: writableArray(object({ id: string(), desc: bool() })),
      }),
      {
        pagination: { pageSize: 25, pageIndex: 0 },
        sorting: [],
      }
    ),
  });

export const selectedValidationRule = atom<ValidationRuleResult | null>({
  key: 'SELECTED-VALIDATION-RULE',
  default: null,
});
