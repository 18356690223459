import { useForm, useFormContext } from 'react-hook-form';
import zod from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { RulesQueryDataRuleLibraryItem } from '../../../../queries/types';

export const dynamicField = zod
  .object({
    parameterName: zod.string(),
    externalObjectType: zod.string().optional(),
    order: zod.number().optional(),
    value: zod.any(),
    uiReadOnly: zod.boolean().optional(),
    visibility: zod.boolean().optional(),
  })
  .refine(
    ({ value, externalObjectType }) => {
      if (externalObjectType === 'boolean') {
        return ['true', 'false', true, false].includes(value);
      }

      return !!value;
    },
    {
      message: 'Required field',
      path: ['value'],
    }
  );

export type DynamicField = zod.infer<typeof dynamicField>;

export const dataValidationFormSchema = zod
  .object({
    isPredefinedRule: zod.boolean().default(true),
    displayName: zod.string().optional(),
    name: zod.string(),
    version: zod.string().optional(),
    description: zod.string().min(1, 'Required field'),
    inAppNotification: zod.boolean().default(false),
    emailNotification: zod.boolean().default(false),
    notificationEmails: zod.array(zod.string().email()).optional(),
    generalProperties: zod.array(dynamicField),
    specificProperties: zod.array(dynamicField),
  })
  .refine((data) => {
    const { generalProperties, specificProperties } = data;

    const hasValue = [...generalProperties, ...specificProperties].some(
      (field) => field.value !== ''
    );

    return hasValue;
  });

export type DataValidationForm = zod.infer<typeof dataValidationFormSchema>;

type RliType = 'rliGeneralProperties' | 'rliSpecificProperties';

const rliTypeToRule: Record<RliType, string> = {
  rliGeneralProperties: 'ruleGeneralProperties',
  rliSpecificProperties: 'ruleSpecificProperties',
};

/**
 * Maps a string value to its appropriate default value.
 *
 * @param {string | null} value - The value to be mapped.
 * @returns {boolean | string} - Returns a boolean if the value is 'true' or 'false', otherwise returns the original value or an empty string if the value is null.
 */
export const mapDefaultValue = (
  value: string | string[] | null
): boolean | string | string[] => {
  if (value === 'true' || value === 'false') {
    return value === 'true';
  }

  return value ?? '';
};

/**
 * Maps an array of dynamic fields to their appropriate default values.
 *
 * @param {Array<RulesQueryDataRuleLibraryItem['rliGeneralProperties'] | RulesQueryDataRuleLibraryItem['rliSpecificProperties']>} fields - The fields to be mapped.
 * @returns {Array<DynamicField>} - Returns an array of dynamic fields with their default values.
 */
export const mapDynamicFields = (
  rule:
    | Omit<RulesQueryDataRuleLibraryItem, 'rliType' | 'notificationProperties'>
    | undefined,
  type: RliType
): DynamicField[] => {
  if (!rule) {
    return [];
  }

  const fields = rule[type];

  const sortedFields = [...(fields || [])].sort(
    (a, b) => (a.order || 0) - (b.order || 0)
  );

  return sortedFields.map((field) => {
    let value = (field.defaultValues as string[] | null) || field.defaultValue;

    if (rule.rule) {
      const ruleType = rliTypeToRule[type];

      const currentRule = rule.rule[ruleType]?.find(
        (ruleProp) => ruleProp?.propertyName === field.parameterName
      );

      const currentValue =
        currentRule?.propertyValues || currentRule?.propertyValue;

      value = currentValue ?? value;
    }

    return {
      parameterName: field.parameterName ?? '',
      externalObjectType: field.externalObjectType ?? '',
      order: field.order ?? 0,
      value: mapDefaultValue(value),
      uiReadOnly: field.uiReadOnly ?? true,
      visibility: field.visibility ?? false,
    };
  });
};

export const findNotificationEmails = (
  rule?: RulesQueryDataRuleLibraryItem
) => {
  const notificationProperties = rule?.rule?.notificationProperties?.find(
    (property) => property?.propertyName === 'emailAudience'
  );

  if (!notificationProperties) {
    return [];
  }

  return notificationProperties.propertyValues as string[];
};

export const useDataValidationForm = (rule?: RulesQueryDataRuleLibraryItem) => {
  return useForm<DataValidationForm>({
    resolver: zodResolver(dataValidationFormSchema),
    defaultValues: {
      isPredefinedRule: rule?.rliType === 'PRECONFIGURED',
      name: rule?.name || '',
      description: rule?.description || '',
      inAppNotification: false,
      emailNotification: false,
      generalProperties: mapDynamicFields(rule, 'rliGeneralProperties'),
      specificProperties: mapDynamicFields(rule, 'rliSpecificProperties'),
      notificationEmails: findNotificationEmails(rule),
    },
  });
};

export const useDataValidationFormContext = () => {
  return useFormContext<DataValidationForm>();
};
