import React from 'react';
import {
  Grid,
  Typography,
  useTheme,
  Button,
  OpenInNew,
} from '@cherre-frontend/ui';

type TechnicalLandingPageHeaderTypes = {
  firstName: string;
  lastSeen: string;
};
const TechnicalLandingPageHeader: React.FC<TechnicalLandingPageHeaderTypes> = ({
  firstName,
  lastSeen,
}) => {
  const theme = useTheme();
  return (
    <Grid
      container
      direction='row'
      justifyContent='space-between'
      sx={{ width: '100%' }}
    >
      <Grid item>
        <Grid item>
          <Typography fontSize='27px' fontWeight='bold'>
            Welcome back, {firstName}!
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            fontSize='14px'
            fontWeight='normal'
            sx={{ color: theme.palette.grey[700] }}
          >
            Last seen on: {lastSeen}
          </Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Button
          variant='outlined'
          endIcon={<OpenInNew />}
          onClick={() => {
            const win = window.open('/core-api', '_blank') as Window;
            win.focus();
          }}
        >
          API Documentation
        </Button>
      </Grid>
    </Grid>
  );
};

export default TechnicalLandingPageHeader;
