import { useSearchParams } from './useSearchParams';

export enum DataValidationV2TabContent {
  LIST = 'list',
  DETAILS = 'details',
}

export type DataValidationV2SearchParams = {
  'connector-details-tab-param': string;
  dataValidationV2Content: DataValidationV2TabContent;
  ruleLibraryItemName?: string;
};

export const useDataValidationV2SearchParams = () => {
  return useSearchParams<DataValidationV2SearchParams>();
};
