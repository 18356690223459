import React from 'react';
import { Box, CheckIcon, useTheme, styled } from '@cherre-frontend/ui';
import { Avatar, Typography } from '@material-ui/core';

export enum StatusBoxState {
  SUCCESS = 'success',
  LOADING = 'loading',
  SCHEDULED = 'scheduled',
  FAILED = 'failed',
  DEACTIVATED = 'deactivated',
  NOT_READY = 'not_ready',
  UPSTREAM_FAILED = 'upstream_failed',
  RUNNING = 'running',
}

const InProgress = styled('div')`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: #df2467;
`;

const AvatarStyled = styled(Avatar)`
  background: ${({ theme }) => theme.palette.grey[100]};
  height: 20px;
  width: 20px;
  border: 2px solid #df2467;
`;

type Props = {
  label: string;
  status?: StatusBoxState;
};

const StatusBox: React.FC<Props> = ({ label, status }) => {
  const theme = useTheme();

  const renderStatusIcon = (status?: StatusBoxState) => {
    switch (status) {
      case StatusBoxState.SUCCESS:
        return (
          <Avatar
            style={{
              backgroundColor: theme.palette.success.dark,
              height: '20px',
              width: '20px',
            }}
          >
            <CheckIcon style={{ height: '12px', width: '12px' }} />
          </Avatar>
        );
      case StatusBoxState.FAILED:
      case StatusBoxState.UPSTREAM_FAILED:
      case StatusBoxState.RUNNING:
        return (
          <AvatarStyled>
            <InProgress />
          </AvatarStyled>
        );
      default:
        return <AvatarStyled />;
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.grey[100],
        display: 'flex',
        flexDirection: 'row',
        padding: '10px',
        borderRadius: '10px',
        gap: '10px',
      }}
    >
      {renderStatusIcon(status)}
      <Typography style={{ fontWeight: '700' }}>{label}</Typography>
    </Box>
  );
};

export default StatusBox;
