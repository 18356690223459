import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { styled } from '@cherre-frontend/ui';
import { Typography, Box } from '@mui/material';
import React from 'react';
import { DataValidationForm } from './components/DataValidationForm';
import { useDataValidationV2 } from '../../providers/DataValidationV2Provider';
import { RulesQueryDataRuleLibraryItem } from '../../queries/types';
import { useCherreState } from '@cherre-frontend/data-fetching';
import { connectorDataValidationTabParam } from '../../../../../../recoil';
import { DatavaValidationActions } from './components/DatavaValidationActions';
import {
  DataValidationAlert,
  DataValidationAlertProps,
} from './components/DataValidationAlert';
import { DataValidationResults } from './components/DataValidationResults/DataValidationResults';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const CustomTabs = styled(Tabs)`
  align-self: flex-start;
  .MuiTabs-indicator {
    bottom: 0;
    border-radius: 0;
    height: 2px;
  }
`;

export const CustomTab = styled(Tab)`
  min-width: unset;
  color: ${({ theme }) => theme.palette.grey[400]};
`;

export const DataValidationDetails = () => {
  const [dataValidationV2Content, setDataValidationV2Content] = useCherreState(
    connectorDataValidationTabParam
  );

  const tabValue = dataValidationV2Content?.detailsTab === 'results' ? 1 : 0;

  const { ruleLibraryItems } = useDataValidationV2();

  const ruleItem: RulesQueryDataRuleLibraryItem | undefined =
    ruleLibraryItems.find(
      (rule) => rule.name === dataValidationV2Content?.rule
    );

  const isCreateMode = dataValidationV2Content?.rule === 'new' && !ruleItem;

  if (!ruleItem && !isCreateMode) {
    return <div>Rule not found</div>;
  }

  const [alert, setAlert] = React.useState<DataValidationAlertProps | null>(
    null
  );

  const handleChange = (_, newValue) => {
    setDataValidationV2Content({
      ...dataValidationV2Content!,
      detailsTab: newValue === 0 ? 'details' : 'results',
    });
  };

  const { rule } = ruleItem || {};

  const hasRuleResults =
    rule && rule.checkId && rule.datastoreId && rule.containerId;

  return (
    <Box padding={2} data-testid='data-validation-v2-details'>
      {alert && (
        <Box marginBottom={2}>
          <DataValidationAlert message={alert.message} type={alert.type} />
        </Box>
      )}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant='h4' fontWeight={700}>
          {isCreateMode ? 'Create Rule ' : ruleItem?.displayName}
        </Typography>

        {ruleItem && (
          <DatavaValidationActions rule={ruleItem} onAlert={setAlert} />
        )}
      </Box>

      <Box sx={{ marginTop: '20px' }}>
        <CustomTabs
          value={tabValue}
          onChange={handleChange}
          aria-label='simple tabs example'
        >
          <CustomTab label='Validation Rule Details' />
          <CustomTab
            label='Validation Rule Results'
            disabled={!hasRuleResults}
          />
        </CustomTabs>
        <TabPanel value={tabValue} index={0}>
          <DataValidationForm ruleItem={ruleItem} isCreateMode={isCreateMode} />
        </TabPanel>
        {ruleItem && (
          <TabPanel value={tabValue} index={1}>
            <DataValidationResults rule={ruleItem} />
          </TabPanel>
        )}
      </Box>
    </Box>
  );
};
