import React, { ChangeEvent, useState } from 'react';
import { Grid, Typography } from '@cherre-frontend/ui';
import { connectorDetailsColumnProfileTableSearch } from '../../recoil';
import {
  useCherreState,
  useCherreStateDebounced,
} from '@cherre-frontend/data-fetching';
import ObservabilityCard from '../../../../components/ObservabilityCard';
import ColumnProfileTable from './ColumnProfileTable';
import TableSearchInput from '../TableSearchInput';
import { formatBytes } from 'src/utils/formatBytes';
import { formatCreationDate } from 'src/utils/formatCreationDate';
import ColumnProfileContentLoader from './ColumnProfileContentLoader';
import { connectorDetailsSelectedTable } from 'src/products/connector/recoil';
import ConnectorTableAutocomplete from 'src/products/connector/components/ConnectorTableAutocomplete';
import { useGetColumnProfile } from './hooks/useGetColumnProfile';

const ColumnProfileContent: React.FC = () => {
  const [selectedTable, setSelectedTable] = useCherreState(
    connectorDetailsSelectedTable
  );

  const [search, , setSearch] = useCherreStateDebounced(
    connectorDetailsColumnProfileTableSearch,
    {
      ms: 100,
    }
  );
  const [filteredColumns, setFilteredColumns] = useState([]);

  const { tables, latestTableData } = useGetColumnProfile();

  const handleTableSearch = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearch(e.target.value);
    if (search) {
      const filteredData = latestTableData.contents.columns.filter((column) =>
        column.name.toLowerCase().includes(search?.toLowerCase())
      );
      setFilteredColumns(filteredData);
    }
  };

  const handleTableSelect = (_, value) => {
    setSelectedTable(value.label);
  };

  if (tables.state === 'loading' || latestTableData.state === 'loading') {
    return <ColumnProfileContentLoader />;
  }

  if (tables.state === 'hasError' || latestTableData.state === 'hasError') {
    return <h1>An error occurred</h1>;
  }
  return (
    <Grid container direction='column' sx={{ width: '100%' }} padding={2}>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        sx={{ width: '100%' }}
      >
        <Grid item xs={6}>
          <Typography fontSize='24px' lineHeight='30px' fontWeight='bold'>
            Column Profile
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Grid
            container
            justifyContent='flex-end'
            direction='row'
            alignItems='center'
          >
            <Grid item width='480px'>
              <ConnectorTableAutocomplete
                menuOptions={tables.contents}
                onChange={handleTableSelect}
                value={{
                  label: selectedTable
                    ? selectedTable
                    : tables.contents[0] || '',
                  id: selectedTable ? selectedTable : tables.contents[0] || '',
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {latestTableData.contents && (
        <Grid container direction='row' paddingTop='30px' columnSpacing='30px'>
          <Grid item sx={{ height: '70px' }} xs={12 / 5}>
            <ObservabilityCard
              cardHeader='Row Count'
              cardContent={`${
                latestTableData.contents.profile.rowCount
                  ? latestTableData.contents.profile.rowCount.toLocaleString()
                  : '--'
              }`}
            />
          </Grid>
          <Grid item sx={{ height: '70px' }} xs={12 / 5}>
            <ObservabilityCard
              cardHeader='Column Count'
              cardContent={`${
                latestTableData.contents.profile.columnCount
                  ? latestTableData.contents.profile.columnCount.toString()
                  : '--'
              }`}
            />
          </Grid>
          <Grid item sx={{ height: '70px' }} xs={12 / 5}>
            <ObservabilityCard
              cardHeader='Profile Sample'
              cardContent={`${
                latestTableData.contents.profile.profileSample
                  ? latestTableData.contents.profile.profileSample
                  : '--'
              }`}
            />
          </Grid>
          <Grid item sx={{ height: '70px' }} xs={12 / 5}>
            <ObservabilityCard
              cardHeader='Size'
              cardContent={`${
                latestTableData.contents.profile.sizeInByte
                  ? formatBytes(latestTableData.contents.profile.sizeInByte)
                  : '0 Bytes'
              }`}
            />
          </Grid>
          <Grid item sx={{ height: '70px' }} xs={12 / 5}>
            <ObservabilityCard
              cardHeader='Creation Date'
              cardContent={
                latestTableData.contents.profile.createDateTime
                  ? formatCreationDate(
                      latestTableData.contents.profile.createDateTime
                    )
                  : '--'
              }
            />
          </Grid>
        </Grid>
      )}
      <Grid
        container
        paddingTop='20px'
        width={'100%'}
        justifyContent='flex-end'
      >
        <Grid item>
          <TableSearchInput
            value={search ?? ''}
            onChange={(e) => handleTableSearch(e)}
            placeholder='Find in table'
          />
        </Grid>
      </Grid>
      <Grid container paddingTop='12px'>
        {latestTableData.contents ? (
          <ColumnProfileTable
            data={
              search === '' ? latestTableData.contents.columns : filteredColumns
            }
          />
        ) : (
          <div
            style={{
              height: 200,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Typography fontSize='16px' lineHeight='19.5px' fontWeight='bold'>
              No Data Available
            </Typography>
          </div>
        )}
      </Grid>
    </Grid>
  );
};

ColumnProfileContent.displayName = 'ColumnProfileContent';

export default ColumnProfileContent;
