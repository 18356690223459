import React, { ReactElement } from 'react';
import { Skeleton, styled } from '@cherre-frontend/ui';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  gap: 20px;
`;

const ModelCardContainer = styled('div')`
  display: flex;
  gap: 20px;
`;

const StyledSkeleton = styled(Skeleton)`
  border-radius: 5px;
`;

/**
 * Component representing the skeletor loading state when the client is trying to retrieve the data from graphql query.
 *
 * @returns {JSX.Element} The rendered ModelCarrouselSkeleton component.
 */

const ModelCarrouselSkeleton = (): ReactElement => (
  <Container>
    <ModelCardContainer>
      <StyledSkeleton
        variant='rectangular'
        animation='wave'
        width={240}
        height={137}
      />
      <StyledSkeleton
        variant='rectangular'
        animation='wave'
        width={240}
        height={137}
      />
    </ModelCardContainer>

    <Skeleton variant='rectangular' width='100%' height={37} />
  </Container>
);

export default ModelCarrouselSkeleton;
