import React from 'react';
import Source from './Source';
import { useConnectorUIState } from '../uiState/contexts';
import Actions from './Actions';
import Model from './Model';
import Destination from './Destination';

/**
 * StepContainer component that renders different components based on the current step in the connector UI state.
 *
 * @component
 * @returns {JSX.Element} The component to be rendered for the current step.
 *
 * The component uses the `useConnectorUIState` hook to get the current step and renders the corresponding component:
 * - 'source': Renders the <Source /> component.
 * - 'model': Renders the <Model /> component.
 * - 'destination': Renders the <Destination /> component.
 * - default: Renders the <Actions /> component.
 */
const StepContainer: React.FC = () => {
  const [{ step }] = useConnectorUIState();

  switch (step) {
    case 'source':
      return <Source />;
    case 'model':
      return <Model />;
    case 'destination':
      return <Destination />;
    default:
      return <Actions />;
  }
};

export default StepContainer;
