import React, { useMemo, useState } from 'react';
import {
  Grid,
  useTheme,
  Typography,
  ChevronRightIcon,
  ChevronLeftIcon,
  IconButton,
  Box,
} from '@cherre-frontend/ui';
import { DataDeliveryTab, DataDeliveryTabs } from '../styles';
import moment from 'moment';
import DataDeliveryScheduleCard from './DataDeliveryScheduleCard';
import { ScheduleItem } from 'src/services/ConnectorDemo/data/ConnectorLanding';
import { generateDataDeliverySchedule } from '../utils/generateDataDeliverySchedule';

const generateTabDates = (startDay) => {
  const dates: string[] = [];
  for (let i = 0; i < 5; i++) {
    dates.push(moment(startDay).add(i, 'days').format('MMM D'));
  }
  return dates;
};

type DataDeliveryScheduleSectionTypes = {
  scheduleData: ScheduleItem[];
};

export type DataDeliveryScheduleItem = ScheduleItem & {
  parsedScheduleDates: string[];
  shortDates: string[];
  runStatusMessage: string;
  scheduledRunTime: string;
};

const DataDeliveryScheduleSection: React.FC<
  DataDeliveryScheduleSectionTypes
> = ({ scheduleData }) => {
  const [startDay, setStartDay] = useState(moment());
  const [activeDate, setActiveDate] = useState(moment());

  const dayLabels = generateTabDates(startDay);
  const theme = useTheme();

  const handleScheduleRightClick = () => {
    setStartDay(moment(startDay).add(1, 'days'));
  };
  const handleScheduleLeftClick = () => {
    setStartDay(moment(startDay).subtract(1, 'days'));
  };

  const dataDeliverySchedule = useMemo(() => {
    if (!scheduleData?.length) {
      return [];
    }
    return scheduleData.map((item) => {
      //Generate data delivery schedule for 10 days in past and 10 days in future
      const parsedCron = generateDataDeliverySchedule(
        item.cronExpression,
        4,
        moment(activeDate).subtract(2, 'days').toDate()
      );
      return {
        ...item,
        parsedScheduleDates: parsedCron.formattedDates,
        shortDates: parsedCron.shortDates,
        runStatusMessage: '',
        scheduledRunTime: '',
      };
    });
  }, [activeDate, scheduleData]);

  //Filter the data being shown in the schedule by the short date of the converted cron expression
  const filteredScheduleData = useMemo(
    () =>
      dataDeliverySchedule
        .reduce(
          (acc: DataDeliveryScheduleItem[], item: DataDeliveryScheduleItem) => {
            const dateIndex = item.shortDates.indexOf(
              activeDate.format('MMM D')
            );
            if (dateIndex !== -1) {
              acc.push({
                ...item,
                scheduledRunTime: item.parsedScheduleDates[dateIndex],
                runStatusMessage: `Scheduled ${item.parsedScheduleDates[dateIndex]}`,
              });
            }
            return acc;
          },
          []
        )
        .sort(
          (a, b) =>
            new Date(a.scheduledRunTime).getTime() -
            new Date(b.scheduledRunTime).getTime()
        ),
    [dataDeliverySchedule, activeDate]
  );

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <Typography fontSize='20px' fontWeight='bold'>
          Data Delivery Schedule
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          wrap='nowrap'
        >
          <Grid item>
            <IconButton onClick={handleScheduleLeftClick}>
              <ChevronLeftIcon sx={{ color: theme.palette.grey[700] }} />
            </IconButton>
          </Grid>
          <Grid item xs zeroMinWidth>
            <DataDeliveryTabs
              value={activeDate.format('MMM D')}
              onChange={(e, newValue) => {
                setActiveDate(moment(newValue));
              }}
              variant='fullWidth'
              aria-label='tabs with chevrons'
            >
              {dayLabels.map((day) => (
                <DataDeliveryTab
                  label={day === moment().format('MMM D') ? 'Today' : day}
                  value={day}
                  style={{ minWidth: '75px', width: '75px' }}
                />
              ))}
            </DataDeliveryTabs>
          </Grid>

          <Grid item>
            <IconButton onClick={handleScheduleRightClick}>
              <ChevronRightIcon sx={{ color: theme.palette.grey[700] }} />
            </IconButton>
          </Grid>
        </Grid>
        {filteredScheduleData.length > 0 ? (
          <Grid container direction='column' marginTop='20px' gap={1}>
            {filteredScheduleData.map((item, index) => (
              <Grid item key={index}>
                <DataDeliveryScheduleCard scheduleItem={item} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid
            container
            direction='column'
            justifyContent='center'
            alignItems='center'
            minHeight='100px'
            paddingX='20px'
          >
            <Box
              sx={{
                backgroundColor: theme.palette.grey[50],
                borderRadius: '10px',
                width: '100%',
                alignItems: 'center',
                padding: '20px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Typography fontSize='14px' fontWeight='bold'>
                No data activity.
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default DataDeliveryScheduleSection;
