import React, { useMemo } from 'react';
import { Box } from '@cherre-frontend/ui';
import { useCherreState } from '@cherre-frontend/data-fetching';
import { dataValidationRuleForm } from '../../recoil';
import StyledLabel from './Label';
import { StyledTextField } from './DefaultTextInput';

type Props = {
  title: string;
  placeholder: string;
  required?: boolean;
  multiline?: boolean;
  fieldName: string;
  numeric?: boolean;
};

const FormTextInput: React.FC<Props> = ({
  title,
  placeholder,
  required,
  multiline,
  fieldName,
  numeric,
}) => {
  const [form, setForm] = useCherreState(dataValidationRuleForm);

  const value = useMemo(() => {
    if (form) {
      return form[form.rule_type][fieldName];
    } else {
      return '';
    }
  }, [form]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <StyledLabel
        style={{ fontSize: '14px', fontWeight: 600 }}
        required={required}
      >
        {title}
      </StyledLabel>
      <StyledTextField
        placeholder={placeholder}
        value={value}
        multiline={multiline}
        rows={multiline ? 4 : 1}
        sx={{
          '& .MuiInputBase-root': {
            height: multiline ? 'unset' : '34px',
          },
        }}
        onChange={(e) => {
          if (e.target.value.match(/[^0-9]/) && numeric) {
            e.preventDefault();
          } else {
            if (form) {
              setForm((oldState) => {
                return {
                  ...oldState,
                  [form.rule_type]: {
                    ...oldState[form.rule_type],
                    [fieldName]: e.target.value,
                  },
                };
              });
            }
          }
        }}
      />
    </Box>
  );
};

export default FormTextInput;
