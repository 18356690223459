import React from 'react';
import { Grid, Skeleton } from '@cherre-frontend/ui';

const TableSelectionFormLoader: React.FC = () => {
  return (
    <Grid
      container
      direction='column'
      sx={{ width: '100%', gap: '40px', marginTop: '30px' }}
      padding={2}
    >
      <Grid item xs={6}>
        <Skeleton variant='rounded' width={300} height={30} animation='wave' />
      </Grid>
      <Grid container direction='column' sx={{ width: '100%', gap: '36px' }}>
        {Array.from(new Array(2)).map((_, index) => (
          <Grid item xs={6} key={index}>
            <Skeleton
              variant='rounded'
              width={800}
              height={50}
              animation='wave'
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

TableSelectionFormLoader.displayName = 'TableSelectionFormLoader';
export default TableSelectionFormLoader;
