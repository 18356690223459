import React, { useMemo } from 'react';
import { AddCircleIcon, Box, Grid, Typography } from '@cherre-frontend/ui';
import {
  connectorDetailsDataValidationState,
  dataValidationTableSearch,
  dataValidationRuleForm,
} from '../../recoil';
import {
  useCherreSetState,
  useCherreState,
  useCherreStateDebounced,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import ObservabilityCard from '../../../../components/ObservabilityCard';
import TableSearchInput from '../TableSearchInput';
import DataValidationTable from './DataValidationTable';
import {
  dataValidationRuleStatusFilter,
  dataValidationSelectedTableFilter,
} from '../../../../recoil';
import DataValidationContentLoader from './DataValidationContentLoader';
import { DataValidationState } from './utils/types';
import { emptyForm } from './utils/const';
import ConnectorButton from '../Overview/ConnectorButton';
import RuleStatusFilter from 'src/products/connector/components/RuleStatusFilter';
import RuleTableFilter from 'src/products/connector/components/RuleTableFilter';
import { useGetDataValidation } from './hooks/useGetDataValidation';
import { ValidationRuleResult } from './types';
import {
  ConnectorEnvironments,
  useConnectorEnvironment,
} from 'src/hooks/useConnectorEnvironment';

const DataValidationContent: React.FC = () => {
  const [tablefilterState, setTableFilterState] = useCherreState(
    dataValidationSelectedTableFilter
  );

  const setDataValidationState = useCherreSetState(
    connectorDetailsDataValidationState
  );

  const setForm = useCherreSetState(dataValidationRuleForm);
  const filters = useCherreValue(dataValidationRuleStatusFilter);

  const environment = useConnectorEnvironment();

  const [search, , setSearch] = useCherreStateDebounced(
    dataValidationTableSearch,
    {
      ms: 100,
    }
  );

  const {
    validationRules,
    validationRulesSummary,
    tables,
    filteredTableTests,
  } = useGetDataValidation();

  const getTableData = useMemo(() => {
    if (
      validationRules.state === 'loading' ||
      filteredTableTests.state === 'loading' ||
      validationRules.contents === undefined ||
      filteredTableTests.contents === undefined
    ) {
      return [];
    }

    let filteredData: ValidationRuleResult[] = [];
    if (
      filteredTableTests.contents.data.length > 0 &&
      tablefilterState !== ''
    ) {
      filteredData = filteredTableTests.contents.data;
    }
    if (
      filteredTableTests.contents.data.length === 0 &&
      tablefilterState === ''
    ) {
      filteredData = validationRules.contents.data;
    }
    if (filters && filters.length > 0) {
      filteredData = filteredData.filter((rule) => {
        if (rule.ruleStatus) {
          return filters.includes(rule.ruleStatus);
        }
      });
    }
    if (search) {
      const lowercaseSearch = search.toLowerCase();
      filteredData = filteredData.filter((test) => {
        return (
          test.ruleName.toLowerCase().includes(lowercaseSearch) ||
          test.tableName.toLowerCase().includes(lowercaseSearch)
        );
      });
    }
    return filteredData;
  }, [
    filters,
    search,
    tablefilterState,
    validationRules.state,
    filteredTableTests.state,
  ]);

  const handleFilterChange = async (value: string) => {
    if (value === 'All Tables') {
      setTableFilterState('');
    } else {
      setTableFilterState(value);
    }
  };

  if (
    validationRules.state === 'loading' ||
    validationRulesSummary.state === 'loading' ||
    tables.state === 'loading' ||
    filteredTableTests.state === 'loading'
  ) {
    return <DataValidationContentLoader />;
  }

  if (
    validationRules.state === 'hasError' ||
    validationRulesSummary.state === 'hasError' ||
    tables.state === 'hasError' ||
    filteredTableTests.state === 'hasError'
  ) {
    return <h1>An error occurred</h1>;
  }

  return (
    <Grid container direction='column' sx={{ width: '100%' }} padding={2}>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        sx={{ width: '100%' }}
      >
        <Grid item xs={6}>
          <Typography fontSize='24px' lineHeight='30px' fontWeight='bold'>
            Data Validation Rules
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Grid
            container
            justifyContent='flex-end'
            direction='row'
            alignItems='center'
            sx={{ gap: '16px' }}
          >
            <Grid item>
              <ConnectorButton
                variant='contained'
                onClick={() => {
                  setForm(emptyForm);
                  setDataValidationState(DataValidationState.TABLE_SELECTION);
                }}
                disabled={environment === ConnectorEnvironments.MVP}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '4px',
                  }}
                >
                  <Typography>Create Rule</Typography>
                  <AddCircleIcon />
                </Box>
              </ConnectorButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {validationRulesSummary.contents && (
        <Grid container direction='row' paddingTop='20px' columnSpacing='30px'>
          <Grid item sx={{ height: '70px' }} xs={3}>
            <ObservabilityCard
              cardHeader='Total'
              cardContent={validationRulesSummary.contents.data.total.toString()}
            />
          </Grid>

          <Grid item sx={{ height: '70px' }} xs={3}>
            <ObservabilityCard
              cardHeader='Success'
              cardContent={validationRulesSummary.contents.data.success.toString()}
            />
          </Grid>
          <Grid item sx={{ height: '70px' }} xs={3}>
            <ObservabilityCard
              cardHeader='Aborted'
              cardContent={validationRulesSummary.contents.data.aborted.toString()}
            />
          </Grid>
          <Grid item sx={{ height: '70px' }} xs={3}>
            <ObservabilityCard
              cardHeader='Failed'
              cardContent={validationRulesSummary.contents.data.failed.toString()}
            />
          </Grid>
        </Grid>
      )}
      <Grid
        container
        paddingTop='20px'
        width={'100%'}
        gap={1}
        justifyContent='flex-start'
      >
        <Grid item>
          {validationRules.contents && (
            <TableSearchInput
              value={search ?? ''}
              onChange={(e) => setSearch(e.target.value)}
              placeholder='Search for a field'
            />
          )}
        </Grid>
        <Grid item height='30px'>
          <RuleStatusFilter />
        </Grid>
        <Grid item height='30px'>
          <RuleTableFilter
            tables={tables.contents}
            tableFilterState={tablefilterState ?? ''}
            onTableChange={handleFilterChange}
          />
        </Grid>
      </Grid>
      <Grid container paddingTop='12px'>
        {validationRules.contents ? (
          <Grid item width='100%'>
            <DataValidationTable data={getTableData} />
          </Grid>
        ) : (
          <div
            style={{
              height: 200,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Typography fontSize='16px' lineHeight='19.5px' fontWeight='bold'>
              No Data Available
            </Typography>
          </div>
        )}
      </Grid>
    </Grid>
  );
};

DataValidationContent.displayName = 'DataValidationContent';

export default DataValidationContent;
