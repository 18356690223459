import React, { Suspense, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Menu,
  MenuItem,
  ArrowDropDownIcon,
} from '@cherre-frontend/ui';
import ConnectorDetailsHeader from './components/Header';
import {
  useCherreState,
  useCherreSetState,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import {
  addConnectorSelectedNavLink,
  connectorDataValidationTabParam,
  connectorDetailsTabParam,
  connectorDetailsTabState,
} from '../../recoil';
import {
  ConnectorDetailTabs,
  ConnectorDetailTab,
  ConnectorTitle,
} from './styles';
import TableProfileContent from './components/TableProfileContent/TableProfileContent';
import ColumnProfileContent from './components/ColumnProfile/ColumnProfileContent';
import LayoutWithoutSideNav from '../../components/LayoutWithoutSideNav';
import DataValidationContent from './components/DataValidation/DataValidationContent';
import {
  connectorDetailsDataValidationState,
  dataValidationRuleForm,
} from './recoil';
import { DataValidationState } from './components/DataValidation/utils/types';
import CreateValidationRuleForm from './components/DataValidation/CreateValidationRuleForm';
import TableSelectionForm from './components/DataValidation/TableSelectionForm';
import DataDictionaryContent from './components/DataDictionary/DataDictionaryContent';
import OverviewContent from './components/Overview/OverviewContent';
import DataModelContent from './components/DataModel/DataModelContent';
import { emptyForm } from './components/DataValidation/utils/const';
import ValidationDetailsPage from '../validation-details';
import { useHistory, useLocation } from 'react-router';
import {
  ConnectorDetailsRoute,
  ValidationTestDetailsRoute,
} from '../../routes';
import HeaderLoadingSkeleton from './components/Overview/HeaderLoadingSkeleton';
import OverviewLoadingSkeleton from './components/Overview/OverviewLoadingSkeleton';
import { ConnectorsPageContainer } from '../../components/ConnectorsPageContainer';
import { DataValidationV2Content } from './components/DataValidationV2';
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';
import { DataValidationV2TabContent } from './components/DataValidationV2/hooks/useDataValidationV2SearchParams';
import { useGetOverview } from './components/Overview/hooks/useGetOverview';
import ConnectorErrorBanner from './components/Overview/ConnectorErrorBanner';

export enum ConnectorDetailsTabValues {
  OVERVIEW = 'Overview',
  OBSERVABILITY = 'Observability',
  VALIDATION = 'Data Validation',
  DATA_MODEL = 'Data Model',
  DICTIONARY = 'Dictionary',
  API_METRICS = 'API Metrics',
}

const ConnectorDetailsPage: React.FC = () => {
  const path = useLocation().pathname;
  const history = useHistory();
  const route = useCherreValue(
    path.includes('/validation-details')
      ? ValidationTestDetailsRoute.routeParamSelector
      : ConnectorDetailsRoute.routeParamSelector
  );

  const [activeNavLink, setActiveNavLink] = useCherreState(
    addConnectorSelectedNavLink
  );
  const [dataValidationState] = useCherreState(
    connectorDetailsDataValidationState
  );
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { overviewData } = useGetOverview();

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const setForm = useCherreSetState(dataValidationRuleForm);

  const [tab, setTab] = useCherreState(connectorDetailsTabState);
  const [tabParam, setTabParam] = useCherreState(connectorDetailsTabParam);
  const [dataValidationV2Param, setDataValidationV2Param] = useCherreState(
    connectorDataValidationTabParam
  );

  const isDataValidationV2 = useFeatureFlag('ConnectorsDataValidationV2');

  useEffect(() => {
    const hasValidationDetailsPath = path.includes('/validationDetails');
    const isValidationTab = tab === ConnectorDetailsTabValues.VALIDATION;

    if (hasValidationDetailsPath && !isValidationTab) {
      setTab(ConnectorDetailsTabValues.VALIDATION);
      return;
    }

    if (isDataValidationV2 && dataValidationV2Param?.content) {
      setTab(ConnectorDetailsTabValues.VALIDATION);
      setDataValidationV2Param(dataValidationV2Param);
      return;
    }

    if (!tabParam || !tab || tabParam === tab) {
      return;
    }

    setTab(tab);
  }, [path, tabParam, tab, setTab]);

  const dataValidationContent = useMemo(() => {
    if (isDataValidationV2) {
      return <DataValidationV2Content />;
    }

    switch (dataValidationState) {
      case DataValidationState.DASHBOARD:
        return <DataValidationContent />;
      case DataValidationState.DETAILS:
        return <ValidationDetailsPage />;
      case DataValidationState.TABLE_SELECTION:
        return <TableSelectionForm />;
      case DataValidationState.TABLE:
      case DataValidationState.COLUMN:
        return <CreateValidationRuleForm />;
      default:
        return <DataValidationContent />;
    }
  }, [dataValidationState]);

  return (
    <ConnectorsPageContainer>
      <Box sx={{ height: '100%', width: '100%' }}>
        <Suspense fallback={<HeaderLoadingSkeleton />}>
          <ConnectorDetailsHeader />
        </Suspense>

        <Suspense fallback={<OverviewLoadingSkeleton />}>
          <Grid container direction='column'>
            <ConnectorDetailTabs
              indicatorColor='primary'
              textColor='primary'
              variant='standard'
              value={tab}
              onChange={(e, newValue) => {
                const isObservabilityTab =
                  newValue === ConnectorDetailsTabValues.OBSERVABILITY;

                const isDataValidationTab =
                  newValue === ConnectorDetailsTabValues.VALIDATION;

                setForm(emptyForm);

                if (path.includes('/validationDetails')) {
                  history.push(`/connectors/${route?.connector_name}`);
                }

                if (isDataValidationTab) {
                  setTab(ConnectorDetailsTabValues.VALIDATION);
                  setTabParam(ConnectorDetailsTabValues.VALIDATION);
                  setDataValidationV2Param({
                    content: DataValidationV2TabContent.LIST,
                    rule: null,
                    detailsTab: null,
                  });
                  return;
                }

                if (!isObservabilityTab) {
                  setDataValidationV2Param(null);
                  setTab(newValue);
                  setTabParam(newValue);
                }
              }}
            >
              <ConnectorDetailTab
                value={ConnectorDetailsTabValues.OVERVIEW}
                label={ConnectorDetailsTabValues.OVERVIEW}
                style={{ minWidth: 'unset' }}
              />
              <ConnectorDetailTab
                value={ConnectorDetailsTabValues.OBSERVABILITY}
                label={
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      style={{ fontSize: '14px', fontWeight: 'bold' }}
                    >
                      {ConnectorDetailsTabValues.OBSERVABILITY}
                    </Typography>
                    <ArrowDropDownIcon />
                  </Box>
                }
                style={{ minWidth: 'unset' }}
                onClick={handleOpen}
              />
              <ConnectorDetailTab
                value={ConnectorDetailsTabValues.VALIDATION}
                label={ConnectorDetailsTabValues.VALIDATION}
                style={{ minWidth: 'unset' }}
              />
              <ConnectorDetailTab
                value={ConnectorDetailsTabValues.DATA_MODEL}
                label={ConnectorDetailsTabValues.DATA_MODEL}
                style={{ minWidth: 'unset' }}
              />
              <ConnectorDetailTab
                value={ConnectorDetailsTabValues.DICTIONARY}
                label={ConnectorDetailsTabValues.DICTIONARY}
                style={{ minWidth: 'unset' }}
              />
            </ConnectorDetailTabs>
          </Grid>
          <Menu
            id='observability-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            slotProps={{
              paper: {
                style: {
                  width: anchorEl ? anchorEl.offsetWidth : 'unset',
                  borderRadius: '10px 10px',
                },
              },
            }}
          >
            <MenuItem
              style={{ paddingLeft: '12px' }}
              onClick={() => {
                setTab(ConnectorDetailsTabValues.OBSERVABILITY);
                setTabParam(ConnectorDetailsTabValues.OBSERVABILITY);
                setActiveNavLink('Table Profile');
                handleClose();
              }}
            >
              Table Profile
            </MenuItem>

            <MenuItem
              style={{ paddingLeft: '12px' }}
              onClick={() => {
                setTab(ConnectorDetailsTabValues.OBSERVABILITY);
                setTabParam(ConnectorDetailsTabValues.OBSERVABILITY);
                setActiveNavLink('Column Profile');
                handleClose();
              }}
            >
              Column Profile
            </MenuItem>
          </Menu>

          <ConnectorTitle>{overviewData?.data.displayName}</ConnectorTitle>
          {overviewData?.data.failedComponentName && (
            <ConnectorErrorBanner
              connectorComponentName={overviewData?.data.failedComponentName}
              statusMessage={overviewData?.data.statusMessage}
            />
          )}
          {tab === ConnectorDetailsTabValues.OVERVIEW && (
            <LayoutWithoutSideNav>
              <OverviewContent />
            </LayoutWithoutSideNav>
          )}
          {tab === ConnectorDetailsTabValues.OBSERVABILITY &&
            activeNavLink === 'Table Profile' && (
              <LayoutWithoutSideNav>
                <TableProfileContent />
              </LayoutWithoutSideNav>
            )}
          {tab === ConnectorDetailsTabValues.OBSERVABILITY &&
            activeNavLink === 'Column Profile' && (
              <LayoutWithoutSideNav>
                <ColumnProfileContent />
              </LayoutWithoutSideNav>
            )}
          {tab === ConnectorDetailsTabValues.VALIDATION && (
            <LayoutWithoutSideNav>{dataValidationContent}</LayoutWithoutSideNav>
          )}
          {tab === ConnectorDetailsTabValues.DATA_MODEL && (
            <LayoutWithoutSideNav>
              <DataModelContent />
            </LayoutWithoutSideNav>
          )}
          {tab === ConnectorDetailsTabValues.DICTIONARY && (
            <LayoutWithoutSideNav>
              <DataDictionaryContent />
            </LayoutWithoutSideNav>
          )}
        </Suspense>
      </Box>
    </ConnectorsPageContainer>
  );
};

export default ConnectorDetailsPage;
